import { Box, InputLabel, Typography, styled } from '@mui/material'

export const StyledBoxWrapper = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: palette.common.white,
  padding: '16px 24px',
  gap: '24px 0',
  '@media (min-width: 768px)': {
    display: 'grid',
    gridTemplateColumns: '40% 30% 30%',
    padding: '32px 24px',
  },
}))

export const StyledBoxContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  fontSize: 14,
  div: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    color: palette.grey[800],
    img: {
      width: 20,
      height: 20,
    },
    fontSize: 16,
  },
  span: {
    color: palette.grey[700],
    fontSize: 14,
  },
}))

export const StyledTitle = styled('h2')(({ theme: { palette } }) => ({
  color: palette.grey[800],
  fontSize: '1.0rem',
  fontWeight: '600',
  textTransform: 'uppercase',
}))

export const StyledSubtitle = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.grey[700],
  fontSize: '0.875rem',
}))

export const StyledSubtitleLabel = styled(InputLabel)(({ theme: { palette } }) => ({
  color: palette.grey[800],
  fontSize: '0.875rem',
}))

export const StyledWrapperBoxInput = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginBottom: 16,
  'input,select,div': {
    width: '100%',
    borderRadius: '0',
    paddingBlock: 4,
  },
}))

export const StyledWrapperBoxTextarea = styled(Box)(() => ({
  label: {
    marginBottom: 4,
  },
}))
