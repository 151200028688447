import { IBrandDetail } from 'interfaces'
import { Box, Typography } from '@mui/material'
import jaguarLogo from 'assets/svgs/jaguar_black_logo.svg'
import rangeRoverLogo from 'assets/svgs/range_rover_black_logo.svg'
import defenderLogo from 'assets/svgs/defender_black_logo.svg'
import discoveryLogo from 'assets/svgs/discovery_black_logo.svg'

import jaguarWhiteLogo from 'assets/svgs/jaguar_white.svg'
import rangeRoverWhiteLogo from 'assets/svgs/range_rover_white_logo.svg'
import defenderWhiteLogo from 'assets/svgs/defender_white_logo.svg'
import discoveryWhiteLogo from 'assets/svgs/discovery_white_logo.svg'

interface IBrandHeaderProps {
  brand: IBrandDetail
  standalone?: boolean
}

export default function BrandHeader({ brand, standalone }: IBrandHeaderProps) {
  const { brandName } = brand

  const BrandLogo = () => {
    switch (brandName) {
      case 'Jaguar':
        return <img src={standalone ? jaguarLogo : jaguarWhiteLogo} alt="Jaguar Logo" />
      case 'Range Rover':
        return (
          <img
            src={standalone ? rangeRoverLogo : rangeRoverWhiteLogo}
            alt="Range Rover Logo"
          />
        )
      case 'Discovery':
        return (
          <img
            src={standalone ? discoveryLogo : discoveryWhiteLogo}
            alt="Discovery Logo"
          />
        )
      case 'Defender':
        return (
          <img src={standalone ? defenderLogo : defenderWhiteLogo} alt="Defender Logo" />
        )
      default:
        return (
          <Typography
            color={standalone ? 'inherit' : 'white'}
            letterSpacing={2.4}
            textTransform="uppercase"
            align="center"
          >
            {brandName}
          </Typography>
        )
    }
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" py={1}>
      <BrandLogo />
    </Box>
  )
}
