import { Box, Checkbox, FormGroup, Grid, Typography } from '@mui/material'
import { IMyStaffState, IValueLabel } from 'interfaces'
import {
  selectSelectedFilters,
  setMyStaffFilters,
  useAppDispatch,
  useAppSelector,
} from 'store'
import {
  CheckedIcon,
  StyledFormControlLabel,
  UncheckedIcon,
} from 'components/StaffMembers/Filters/styles'
import actionRequiredIcon from 'assets/svgs/icon_ls_expired.svg'
import { FormattedMessage, useIntl } from 'react-intl'

interface LevelsActionsProps {
  levelOptions?: IValueLabel[]
  actionOptions?: IValueLabel[]
}

type FilterOptions = {
  name: string
  value: keyof IMyStaffState['filters']
}

const LevelsActions = ({ levelOptions, actionOptions }: LevelsActionsProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const selectedFilters = useAppSelector(selectSelectedFilters)

  const filtersOptions: FilterOptions[] = [
    {
      name: intl.formatMessage({
        id: 'applicationTerm.certifiedLevel',
      }),
      value: 'certifiedLevel',
    },
    {
      name: intl.formatMessage({
        id: 'applicationTerm.workingLevel',
      }),
      value: 'workingLevel',
    },
    {
      name: intl.formatMessage({
        id: 'applicationTerm.targetLevel',
      }),
      value: 'targetLevel',
    },
  ]

  const checkChecked = (filter: keyof typeof selectedFilters, value: string) => {
    if (selectedFilters && selectedFilters[filter] !== null) {
      return selectedFilters[filter]?.includes(value)
    }
    return false
  }
  const setChecked = (filter: keyof typeof selectedFilters, value: string) => {
    dispatch(setMyStaffFilters({ filter, value }))
  }

  if (!levelOptions || !actionOptions) return null

  return (
    <>
      {levelOptions?.length > 0 &&
        filtersOptions.map((filter, index) => (
          <Grid item xs={4} sm={4} md={3} lg={2} key={index}>
            <Box>
              <Typography gutterBottom>{filter.name}</Typography>
              <FormGroup sx={{ ml: 0.5, mt: 1.5 }}>
                {levelOptions.map(option => {
                  const isChecked = checkChecked(filter.value, option.value as string)
                  return (
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          size="small"
                          onChange={() =>
                            setChecked(filter.value, option.value as string)
                          }
                          icon={<UncheckedIcon />}
                          checkedIcon={<CheckedIcon />}
                        />
                      }
                      label={option.label}
                      key={option.value}
                    />
                  )
                })}
              </FormGroup>
            </Box>
          </Grid>
        ))}
      {actionOptions?.length > 0 ? (
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box>
            <Box display="flex" gap="3px" marginBottom="12px">
              <img src={actionRequiredIcon} alt="Action Required" width="25" />
              <Typography>
                <FormattedMessage id="app.labels.actionRequired" />
              </Typography>
            </Box>
            <FormGroup sx={{ ml: 1 }}>
              {actionOptions.map(option => {
                const isChecked = checkChecked('actions', option.value as string)
                return (
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        size="small"
                        onChange={() => setChecked('actions', option.value as string)}
                        icon={<UncheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                      />
                    }
                    label={option.label}
                    key={option.value}
                  />
                )
              })}
            </FormGroup>
          </Box>
        </Grid>
      ) : null}
    </>
  )
}

export default LevelsActions
