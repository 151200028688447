// EVButton is a custom styled version of the MUI Button compoennt, adding additional
// props for EV styles.

import {
  alpha,
  Button as MUIButton,
  ButtonProps,
  CircularProgress,
  lighten,
  styled,
} from '@mui/material'

interface Props extends ButtonProps {
  evColor?: string
  isLoading?: boolean
}

// Extracts the evColor from props, so that when spread onto the DOM node evColor is not included as it is not a valid HTML attribute
const extractRest = (props: Props) => {
  const { evColor: _, isLoading: __, ...rest } = props
  return { ...rest }
}

// ToDo: create our own variation for this component
const StyledEvButton = styled((props: Props) => <MUIButton {...extractRest(props)} />)(
  ({ itemProp: evColor }: Props) =>
    evColor
      ? {
          backgroundColor: evColor,
          borderColor: evColor === '#fff' ? 'rgba(0, 0, 0, 0.87)' : evColor,
          color: evColor === '#fff' ? 'rgba(0, 0, 0, 0.87)' : 'white',
          padding: '0 16px',
          height: '40px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: lighten(evColor, 0.1),
            borderColor: lighten(
              evColor === '#fff' ? 'rgba(0, 0, 0, 0.87)' : evColor,
              0.1,
            ),
          },
          '&:focus-visible': {
            outline: `1px solid ${evColor}`,
            outlineOffset: 1,
          },
          '&:disabled': {
            color: alpha('rgb(255,255,255)', 0.5),
            backgroundColor: lighten(evColor, 0.5),
          },
        }
      : {},
)

export default function Button(props: Props) {
  const {
    evColor,
    variant = 'contained',
    isLoading = false,
    children,
    disabled,
    ...rest
  } = props

  const Component: React.ElementType = evColor ? StyledEvButton : MUIButton
  return (
    <Component
      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
        props.isLoading ? e.preventDefault() : props.onClick
      }
      {...{ variant, disabled, itemProp: evColor, ...rest }}
    >
      {isLoading ? <CircularProgress size={20} color="inherit" /> : children}
    </Component>
  )
}
