import FullScreenLoader from 'components/common/FullScreenLoader'
import { StorageKeys } from 'constant/localStorage'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch, setIsExternalLoggedIn } from 'store'
import { useGetMeQuery, useRetrieveBearerTokenMutation } from 'store/api'
import { selectUser } from 'store/slices'
import { getLocalStorage } from 'utils/localStorage'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

const RequireUser = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const intl = useIntl()

  const ottFromParams = searchParams.get('token')
  const storedBearerToken = getLocalStorage(StorageKeys.token)

  const [getBearerToken, getBearerTokenState] = useRetrieveBearerTokenMutation({})

  useEffect(() => {
    const { isUninitialized, isLoading, isSuccess } = getBearerTokenState
    const bearerToken = getBearerTokenState.data?.data?.bearerToken

    if (isUninitialized && ottFromParams?.length) {
      setSearchParams(params => {
        params.delete('token')
        return params
      })
      getBearerToken({ oneTimeToken: ottFromParams })
      dispatch(setIsExternalLoggedIn(true))
    }

    // guard clause, if loading a bearer token code below wont run
    if (isLoading && !isSuccess) return

    // user has no bearer token in local storage and no bearer token is being generated
    if (!storedBearerToken?.length && !ottFromParams && !bearerToken) {
      console.error('No token found in local storage or in query param')
      navigate('/logout', { state: location.pathname })
    }

    // endpoint will return 200 http code, but bearer token will return null if unsuccessful
    if (isSuccess && !bearerToken) {
      console.error('No bearer token found in response')
      navigate('/logout', { state: location.pathname })
    }
  }, [ottFromParams, getBearerTokenState])

  if (getBearerTokenState.isLoading) {
    return (
      <FullScreenLoader
        title={intl.formatMessage({
          id: 'appNavigation.messages.fetching',
        })}
      />
    )
  }

  // Proceed to get the user data and continue loading the rest of the app
  if (storedBearerToken.length && !ottFromParams) {
    return <FetchUserDataAndProceed />
  }

  return null
}

export default RequireUser

// made into a different component so the user data is not automatically triggered on mount before a bearer token is recieved.
const FetchUserDataAndProceed = () => {
  const intl = useIntl()

  const { isLoading, isFetching } = useGetMeQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const user = useAppSelector(selectUser)

  if (isLoading || isFetching) {
    return (
      <FullScreenLoader
        title={intl.formatMessage({
          id: 'appNavigation.messages.fetching',
        })}
      />
    )
  }

  if (user) {
    return <Outlet />
  }

  return null
}
