import { Box, Button, MenuItem, styled } from '@mui/material'

export const StyledARButton = styled(Button)(() => ({
  transition: '200ms',
  transitionProperty: 'color backgroundColor border',
  backgroundColor: '#FFF',
  borderRadius: 0,
  width: 'auto',
  height: '30px',
  border: '1px solid #C4C4C4',
  fontSize: '12px',

  '&:hover': {
    color: '#FFF',
    backgroundColor: '#1E1E1E',
    border: '1px solid transparent',
  },
}))

export const StyledARIconButton = styled(StyledARButton)(() => ({
  maxWidth: '165px',
  height: 'auto',
  padding: '4px 8px',
  img: {
    width: '21px',
    height: '21px',
  },
}))

export const StyledARGenericButton = styled(StyledARButton)(() => ({
  color: '#1E1E1E',
}))
export const StyledARRejectButton = styled(StyledARButton)(() => ({
  color: '#FFF',
  backgroundColor: '#A82F45',
}))
export const StyledMenuItem = styled(MenuItem)(() => ({
  width: '100%',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingInline: '8px',
  gap: 10,

  '&:hover': {
    backgroundColor: '#F2F2F2',
  },

  '& > div[aria-label="current-level"]': {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#D1EBFE',
    padding: '4px 8px',
    gap: 4,
    borderRadius: '3px',
    alignItems: 'self-end',
    '& > img': {
      marginBottom: '1px',
      width: 17,
      height: 17,
    },
  },

  '&:not(:first-of-type)': {
    borderTop: '1px solid #C4C4C4',
  },
}))
export const StyledNameBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 10,
}))

export const StyledConfirmDowngradeBox = styled(Box)(({ theme }) => ({
  maxWidth: '250px',
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  backgroundColor: theme.palette.error.main,

  '& p': {
    color: '#FFF',
    lineHeight: '1.5',
    '&:first-of-type': {
      letterSpacing: '1.4px',
    },
  },
  '& img': {
    display: 'inline-block',
    marginRight: 5,
  },
}))
