// Language component.  Handles the selected language stored in Redux and uses React-Intl
// Intl-Provider to provide the language file to all children within the app.

import { IntlProvider } from 'react-intl'
import { de, en, es, fr, it, ja, ko, nl, pt, ptBR, ru, tr, zh } from 'languages'

import { useAppSelector } from 'store'
import { getSelectedLanguage } from 'store/slices'

const languageMap: Record<string, any> = {
  de,
  en,
  es,
  fr,
  it,
  ja,
  ko,
  nl,
  pt,
  'pt-br': ptBR,
  ru,
  tr,
  zh,
}

interface Props {
  children: React.ReactNode
}

export default function Language({ children }: Props) {
  const { isoCode } = useAppSelector(getSelectedLanguage)
  const lang = languageMap[isoCode.toLowerCase()]

  return (
    <IntlProvider locale={isoCode} messages={lang}>
      {children}
    </IntlProvider>
  )
}
