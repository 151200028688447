import { styled } from '@mui/material/styles'
import MuiDialog from '@mui/material/Dialog'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { Theme } from '@mui/system'

const commonPaperStyles = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  width: '100%',
  height: 80,
  marginBlock: 5,
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  '&:first-of-type': {
    marginTop: 8,
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
})

export const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiDialogContent-root': {
    backgroundColor: theme.palette.grey[100],
    paddingInline: '30px',
  },
  '& .MuiDialog-paper': {
    width: '100%',
  },
  'h2.MuiTypography-root': {
    button: {
      textTransform: 'uppercase',
      height: '30px',
      fontSize: '16px',
    },
  },
}))

export const StyledFormLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  marginBottom: 8,
  marginTop: 16,
  '&::after': {
    content: '"*"',
    top: '5px',
    left: '10px',
    fontSize: '16px',
    color: '#9E1B32',
  },
}))

export const StyledBatteryBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  justifyContent: 'space-between',
  padding: 20,
  border: `1px solid ${theme.palette.grey[400]}`,
  height: '100px',
  marginBottom: 10,
}))

export const StyledBrandItem = styled(Paper)(({ theme }) => ({
  ...commonPaperStyles(theme),
  justifyContent: 'center',
}))

export const StyledModelItem = styled(Paper)(({ theme }) => ({
  ...commonPaperStyles(theme),

  justifyContent: 'space-between',
  padding: 20,
  height: '100px',
}))

export const StyledInputFile = styled('input')(() => ({
  display: 'none',
}))

export const StyledLabelFile = styled('label')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  cursor: 'pointer',
  color: theme.palette.grey[500],
  ':hover': {
    color: theme.palette.grey[700],
  },
}))

export const StyledErrorMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '40px',
  backgroundColor: theme.palette.error.main,
  marginTop: '-8px',
  paddingLeft: theme.spacing(2),
  gap: theme.spacing(1),
}))
