// Redux ev selection feature slice to store all user selections, for example open and closed
// drawers and filter selections.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

export interface IEVLevelsState {
  levelUUID: string
  rolesAndResponsibilities: boolean
  documents: boolean
  modulesAndCourses: Record<string, boolean>
}
export interface IEVSelectionState {
  evLevels: Record<string, IEVLevelsState>
}

const initialState: IEVSelectionState = {
  evLevels: {},
}

const evSelectionSlice = createSlice({
  name: 'evSelectionSlice',
  initialState: initialState,
  reducers: {
    toggleRolesAndResponsibilitiesDrawer(state, action: PayloadAction<string>) {
      state.evLevels[action.payload].rolesAndResponsibilities =
        !state.evLevels[action.payload].rolesAndResponsibilities
    },
    toggleDocumentsLibraryDrawer(state, action: PayloadAction<string>) {
      state.evLevels[action.payload].documents = !state.evLevels[action.payload].documents
    },
    toggleModuleAndCourseDrawer(
      state,
      action: PayloadAction<{ levelUUID: string; moduleAndCourseKey: string }>,
    ) {
      const { levelUUID, moduleAndCourseKey } = action.payload

      state.evLevels[levelUUID].modulesAndCourses[moduleAndCourseKey] =
        !state.evLevels[levelUUID].modulesAndCourses[moduleAndCourseKey]
    },
    setEvLevels(state, action: PayloadAction<Record<string, IEVLevelsState>>) {
      state.evLevels = action.payload
    },
  },
})

// Exported actions for use within useAppDispatcher hook.
export const {
  toggleRolesAndResponsibilitiesDrawer,
  toggleDocumentsLibraryDrawer,
  toggleModuleAndCourseDrawer,
  setEvLevels,
} = evSelectionSlice.actions

export const selectEVLevelState = (state: RootState) => state.evSelection.evLevels

export default evSelectionSlice.reducer
