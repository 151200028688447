import LoginForm from 'components/Login/LoginForm'
import BrandWrapper from 'components/common/BrandWrapper'

const Login = () => {
  return (
    <BrandWrapper>
      <LoginForm />
    </BrandWrapper>
  )
}

export default Login
