import { StorageKeys } from 'constant/localStorage'
import { setLocalStorage } from 'utils/localStorage'
import { useAppSelector } from 'store'
import { useGetGenerateOttMutation, useGenerateAntiCSRFTokenMutation } from 'store/api'
import { selectInitialConfig } from 'store/slices'
import { useEffect } from 'react'
import { IInitialConfig } from 'interfaces/IInitialConfig'
interface Arguments {
  url: string
  environment: keyof IInitialConfig
  params?: Params
}

interface Params {
  [key: string]: string
}

export default function useOttRedirect({ url, environment, params }: Arguments) {
  const initialConfig = useAppSelector(selectInitialConfig)
  const [getGenerateAntiCSRFToken, antiCSRFState] = useGenerateAntiCSRFTokenMutation()
  const [getGenerateOtt, ottState] = useGetGenerateOttMutation()

  useEffect(() => {
    // generate an ott once local storage is updated with the correct anticsrf token
    const onStorageUpdate = () => {
      getGenerateOtt()
    }

    window.addEventListener('storage', onStorageUpdate)

    return () => {
      window.removeEventListener('storage', onStorageUpdate)
    }
  }, [])

  useEffect(() => {
    //csrf token needs to be generated first before generating an OTT (header from token will be set in local storage)
    if (antiCSRFState.isSuccess) {
      setLocalStorage(StorageKeys.antiCSRFToken, antiCSRFState.data)
      // need to wait for local storage to update
      window.dispatchEvent(new Event('storage'))
    }
  }, [antiCSRFState.isSuccess])

  useEffect(() => {
    function buildPortalRedirect(token: string = '') {
      const baseUrl = initialConfig?.[environment]

      const redirectUrl = new URL(url, baseUrl)
      redirectUrl.searchParams.set('token', token)

      //iterate through the object and allow additional params to be set,
      if (params) {
        Object.entries(params).forEach(([key, value]) => {
          redirectUrl.searchParams.set(key, value)
        })
      }

      return redirectUrl.toString()
    }

    if (ottState.isSuccess && !!ottState.data) {
      const url = buildPortalRedirect(ottState.data)
      window.open(url, '_self')
    }
  }, [ottState.isSuccess, ottState.data, initialConfig?.portalLocation])

  return [getGenerateAntiCSRFToken, ottState] as const
}
