// Status Icon renders the correct icon for the status provided

import { StatusType } from 'types'
import availableIcon from 'assets/svgs/icon_ls_not_started.svg'
import completeIcon from 'assets/svgs/icon_ls_completed.svg'
import lockedIcon from 'assets/svgs/icon_ls_locked.svg'
import inProgressIcon from 'assets/svgs/icon_ls_in_progress.svg'
import failedIcon from 'assets/svgs/icon_cs_failed.svg'

type StatusIconProps = {
  status: StatusType
  isLocked: boolean
  isAvailable: boolean
  size?: 'small' | 'regular' | 'big'
}

export default function StatusIcon({
  status,
  size = 'regular',
  isAvailable,
  isLocked,
}: StatusIconProps) {
  const pxs = {
    small: 22,
    regular: 35,
    big: 40,
  }[size]

  if (isAvailable) {
    if (isLocked) {
      // Locked should override everthing
      return <img src={lockedIcon} alt="Locked" width={pxs} height={pxs} />
    }

    // These states only apply when the level is available
    if (status === 'NOT_STARTED') {
      return <img src={availableIcon} alt="Available" width={pxs} height={pxs} />
    }

    if (status === 'COMPLETED') {
      return <img src={completeIcon} alt="Complete" width={pxs} height={pxs} />
    }

    if (status === 'FAILED') {
      return <img src={failedIcon} alt="Failed" width={pxs} height={pxs} />
    }

    if (status === 'IN_PROGRESS') {
      return <img src={inProgressIcon} alt="In Progress" width={pxs} height={pxs} />
    }
  }

  return null
}
