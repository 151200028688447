// Renders in order or precidence, the status if provided, if no status then the counter if a count is
// provided, and finally if no status or count then nothing is rendered.

import { StatusType } from 'types'

import { StatusIcon } from 'icons/EVIcons'
import Counter from 'components/common/Counter'

type Props = {
  status?: StatusType
  isAvailable: boolean
  isLocked: boolean
  count?: number
}

export default function StatusOrCount({ status, isAvailable, isLocked, count }: Props) {
  if (isAvailable) {
    if (status !== undefined && isAvailable !== undefined && isLocked !== undefined) {
      return (
        <StatusIcon
          data-testid="status-icon"
          status={status}
          size="big"
          isAvailable={isAvailable}
          isLocked={isLocked}
        />
      )
    }
  }

  if (count) {
    return <Counter data-testid="counter" value={count} />
  }

  return null
}
