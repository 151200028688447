import { Button, Typography, DialogTitle as MUIDialogTitle } from '@mui/material'
import { Stack } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  title?: React.ReactNode
  closeButtonLabel: React.ReactNode
  onClose: () => void
}

export default function DialogTitle({ title, onClose, closeButtonLabel }: Props) {
  return (
    <MUIDialogTitle sx={{ backgroundColor: 'rgb(243,247,250)', py: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>{title}</Typography>
        <Button endIcon={<CloseIcon />} color="inherit" onClick={onClose}>
          {closeButtonLabel}
        </Button>
      </Stack>
    </MUIDialogTitle>
  )
}
