// EV Training Centre Home Page

import { Box, Button, useMediaQuery, useTheme, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import playButtonIcon from 'assets/svgs/icon_play_button.svg'
import EVTitle from 'components/TrainingCentre/EVTitle'
import TrainingTilesDesktop from 'components/TrainingCentre/TrainingTilesDesktop'
import TrainingTilesMobile from 'components/TrainingCentre/TrainingTilesMobile'
import WelcomeVideo from 'components/TrainingCentre/WelcomeVideo'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import { useEffect, useState } from 'react'
import { selectUser } from 'store/slices'
import { useAppSelector, useAppDispatch } from 'store/hooks/useStoreHooks'
import { setUserHasViewedVideo, selectUserHasViewedVideo } from 'store/slices'

import { IUser } from 'interfaces'

export default function TrainingCentre() {
  const theme = useTheme()

  const hasViewedVideo = useAppSelector(selectUserHasViewedVideo)
  const user = useAppSelector(selectUser)
  const {
    certifiedLevel,
    workingLevel,
    targetLevel,
    isFirstAccess,
    hasEvTrainingJobRole,
    isManager,
    isRetailerAdmin,
    welcomeVideoLink,
  } = user || ({} as IUser)

  const [isVideoOpen, setVideoOpen] = useState(false)
  const isXSScreen = useMediaQuery(theme.breakpoints.only('xs'))

  const dispatch = useAppDispatch()
  const onOpenVideo = () => {
    setVideoOpen(true)
    dispatch(setUserHasViewedVideo(true))
  }
  useEffect(() => {
    if (isFirstAccess && !hasViewedVideo) {
      onOpenVideo()
    }
  }, [isFirstAccess])

  const gridColumns = isXSScreen ? undefined : 'repeat(auto-fit, minmax(250px, 250px))'
  return (
    <>
      <Stack flex={1} paddingY={5}>
        <EVTitle />
        <Box
          p={{ xs: 2, sm: 0 }}
          display={isXSScreen ? 'flex' : 'grid'}
          flexDirection={isXSScreen ? 'column' : 'row'}
          gridTemplateColumns={gridColumns}
          gridAutoRows={isXSScreen ? undefined : 'auto'}
          justifyContent="center"
          gap={1.5}
          order={{ xs: 3, sm: 2 }}
          flex={isXSScreen ? 1 : 0}
          alignContent="end"
        >
          {isXSScreen ? (
            <TrainingTilesMobile
              certifiedLevel={certifiedLevel}
              targetLevel={targetLevel}
              workingLevel={workingLevel}
              hasEvTrainingJobRole={hasEvTrainingJobRole}
              isManager={isManager}
              isRetailerAdmin={isRetailerAdmin}
            />
          ) : (
            <TrainingTilesDesktop
              certifiedLevel={certifiedLevel}
              targetLevel={targetLevel}
              workingLevel={workingLevel}
              hasEvTrainingJobRole={hasEvTrainingJobRole}
              isManager={isManager}
              isRetailerAdmin={isRetailerAdmin}
            />
          )}
        </Box>

        <WelcomeVideo
          welcomeVideoLink={welcomeVideoLink}
          open={isVideoOpen}
          onClose={() => setVideoOpen(false)}
        />

        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          color="white"
          order={{ xs: 2, sm: 3 }}
          alignItems="center"
        >
          <Button
            onClick={onOpenVideo}
            color="inherit"
            endIcon={<img src={playButtonIcon} alt="Play" />}
            sx={{ textTransform: 'none' }}
          >
            <FormattedMessage id="trainingCentre.video.link" />
          </Button>
        </Box>
      </Stack>

      {!isXSScreen ? <ApplicationFooter /> : null}
    </>
  )
}
