import { Box, Button, LinearProgress, Typography, useTheme } from '@mui/material'
import { useEffect, useState, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import SettingsIcon from 'components/common/SettingsIcon'
import {
  StyledBoxCollapseButtons,
  StyledBoxCollapseItem,
  StyledBoxWrapperPagination,
  StyledButtonFilter,
  StyledCollapse,
  StyledContainerWrapper,
} from './style'
import BatteryListRowCollapse from './RowCollapse'
import { StyledHeaderMobile, StyledPagination, StyledWrapperTableCollapse } from './style'
import {
  selectFilterConfig,
  selectJobRoles,
  selectPageConfig,
  selectQualifications,
  selectQualificationsAndJobRoles,
  selectRowAndCollapseData,
  selectSearchTermBatteryList,
  setPage,
  useAppDispatch,
  useAppSelector,
  setClearAllFilters,
  setRowAndCollapseData,
  selectSelectedBusinessUnits,
} from 'store'
import SearchBar from 'components/BatteryList/BatteryListDesktopInfo/Components/SearchBar'
import Qualifications from 'components/BatteryList/BatteryListDesktopInfo/Components/Qualifications'
import JobRoles from 'components/BatteryList/BatteryListDesktopInfo/Components/JobRoles'
import { useGetSearchStaffQualificationsQueryMutation } from 'store/api'

const BatteryListMobileInfo = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const selectedQualificationsAndJobRoles = useAppSelector(
    selectQualificationsAndJobRoles,
  )
  const selectedRowAndCollapseData = useAppSelector(selectRowAndCollapseData)
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)
  const selectedJobRoles = useAppSelector(selectJobRoles)
  const selectedQualifications = useAppSelector(selectQualifications)
  const selectedSearchStringBatteryList = useAppSelector(selectSearchTermBatteryList)
  const [allValueFilters, setAllValueFilters] = useState(0)
  const selectedQueryConfig = useAppSelector(selectPageConfig)
  const [getStaffQualificationsQuery, { data, isLoading }] =
    useGetSearchStaffQualificationsQueryMutation()

  const selectedFilterConfig = useAppSelector(selectFilterConfig)

  const businessUnits = useMemo(
    () => selectedBusinessUnits?.map(({ value }) => value) || [],
    [selectedBusinessUnits],
  )

  // filter count logic
  const totalSelectedJobRole = selectedJobRoles!.length
  const totalSelectedQualifications = selectedQualifications!.length
  const totalSelectedSearchString = selectedSearchStringBatteryList!.length !== 0 ? 1 : 0

  useEffect(() => {
    setAllValueFilters(
      totalSelectedJobRole + totalSelectedSearchString + totalSelectedQualifications,
    )
  }, [totalSelectedJobRole, totalSelectedSearchString, totalSelectedQualifications])

  // collapse and rows data destructuring
  const records = selectedRowAndCollapseData?.records
  const totalRecords = selectedRowAndCollapseData?.totalRecords
  const totalPages = selectedRowAndCollapseData?.totalPages

  // selects data destructuring
  const qualifications = selectedQualificationsAndJobRoles?.qualifications
  const jobRoles = selectedQualificationsAndJobRoles?.jobRoles

  const hasResults = records?.length > 0

  const { page: currentPage, pageSize: resultsPerPage } = selectedQueryConfig

  const limitsRowsCalc = resultsPerPage * currentPage
  const totalRecordsFind = totalRecords === 0 ? 1 : Number(totalRecords)
  const limitsRowsPerPage =
    limitsRowsCalc > totalRecordsFind ? totalRecordsFind : limitsRowsCalc

  const actualRows =
    records?.length === 0
      ? 1
      : currentPage === 1
      ? currentPage
      : totalPages === currentPage
      ? totalRecordsFind - (records!.length - 1)
      : resultsPerPage * (currentPage - 1) + 1

  const cleanQueryParam = () => {
    const { jobRoles, qualifications, searchTerm } = selectedFilterConfig

    return {
      businessUnits,
      ...selectedQueryConfig,
      ...(jobRoles?.length && { jobRoles }),
      ...(qualifications?.length && { qualifications }),
      ...(searchTerm && { searchTerm }),
    }
  }

  const handleFilterQuery = async () => {
    await getStaffQualificationsQuery(cleanQueryParam())
  }

  const handleClearAllFilters = async () => {
    dispatch(setClearAllFilters())
    await getStaffQualificationsQuery({ ...selectedQueryConfig, businessUnits })
  }

  useEffect(() => {
    handleFilterQuery()
  }, [selectedQueryConfig])

  useEffect(() => {
    dispatch(setRowAndCollapseData(data))
  }, [records, data])

  return (
    <StyledContainerWrapper>
      <StyledButtonFilter
        open={isOpen}
        onClick={() => setIsOpen(state => !state)}
        sx={{
          marginBottom: isOpen ? '0' : '10px',
        }}
      >
        <SettingsIcon isOpen={isOpen} />
        <FormattedMessage id="filters.label" />: + {allValueFilters}
      </StyledButtonFilter>
      <StyledCollapse in={isOpen} {...{ isOpen }} sx={{ px: { xs: 1.25, sm: 2, md: 3 } }}>
        <StyledBoxCollapseItem>
          <Qualifications {...{ qualifications }} />
        </StyledBoxCollapseItem>

        <StyledBoxCollapseItem>
          <JobRoles {...{ jobRoles }} />
          <SearchBar />
        </StyledBoxCollapseItem>

        <StyledBoxCollapseButtons>
          <Button onClick={handleClearAllFilters}>
            <FormattedMessage id="filters.clear" />
          </Button>
          <Button onClick={handleFilterQuery}>
            <FormattedMessage id="filters.search" />
          </Button>
        </StyledBoxCollapseButtons>
      </StyledCollapse>

      <StyledWrapperTableCollapse sx={{ px: { xs: 1.25, sm: 2, md: 3 } }}>
        <StyledHeaderMobile>
          <Typography>
            <FormattedMessage id="staffMembers.page.title" />
          </Typography>
        </StyledHeaderMobile>
        {isLoading && <LinearProgress variant="indeterminate" sx={{ mt: '10px' }} />}
        {hasResults ? (
          records.map(record => (
            <BatteryListRowCollapse key={record.staffMemberUUID} row={record} />
          ))
        ) : (
          <Box display="flex" justifyContent="center" mt={2}>
            <Typography color={theme.palette.grey[700]}>
              <FormattedMessage id="general.lists.noRows" />
            </Typography>
          </Box>
        )}

        {isLoading && <LinearProgress variant="indeterminate" sx={{ mt: '10px' }} />}
        {hasResults && (
          <StyledBoxWrapperPagination>
            <Typography color={theme.palette.grey[700]}>
              <FormattedMessage
                id="general.table.showingResults"
                values={{
                  actualRows,
                  limitsRowsPerPage,
                  totalRecordsFind,
                }}
              />
            </Typography>
            <StyledPagination
              color="primary"
              variant="outlined"
              shape="rounded"
              page={currentPage}
              count={totalPages}
              onChange={(_, newPage) => dispatch(setPage(newPage))}
            />
          </StyledBoxWrapperPagination>
        )}
      </StyledWrapperTableCollapse>
    </StyledContainerWrapper>
  )
}

export default BatteryListMobileInfo
