import {
  Box,
  Button,
  Collapse,
  Container,
  Pagination,
  Select,
  styled,
} from '@mui/material'

type ButtonFilter = {
  open: boolean
}
export const StyledButtonFilter = styled(Button)<ButtonFilter>(
  ({ theme: { palette }, open }) => ({
    display: 'flex',
    gap: 12,
    background: open ? palette.grey[600] : palette.grey[50],
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: 4,
    color: open ? palette.common.white : palette.grey[800],
    '&:hover': {
      background: open ? palette.grey[600] : palette.grey[50],
      borderColor: palette.grey[600],
      outline: 'none',
    },
  }),
)

export const StyledHeaderMobile = styled(Box)(({ theme: { spacing, palette } }) => ({
  background: palette.grey[600],
  paddingBlock: spacing(1.75),
  p: {
    marginLeft: spacing(2),
    color: palette.common.white,
  },
}))

export const StyledPagination = styled(Pagination)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  button: {
    borderRadius: 0,
  },
  '.MuiPaginationItem-page': {
    borderRadius: 0,
    ':hover': {
      color: 'white',
      backgroundColor: palette.grey[700],
    },
  },
  '.Mui-selected': {
    color: `${palette.common.white} !important`,
    backgroundColor: `${palette.grey[800]} !important`,
  },
  '.MuiPaginationItem-previousNext': {
    border: 'none',
    ':hover': {
      backgroundColor: palette.grey[50],
    },
  },
}))

export const StyledWrapperTableCollapse = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
  width: '100%',
}))

export const StyledContainerWrapper = styled(Container)(({ theme: { spacing } }) => ({
  padding: '0 !important',
  marginBlock: spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}))

type CollapseProps = {
  isOpen: boolean
}

export const StyledCollapse = styled(Collapse)<CollapseProps>(
  ({ theme: { palette }, isOpen }) => ({
    width: '100%',
    background: palette.grey[100],
    paddingBlock: '42px 16px',
    marginBlock: '-20px 10px ',
    display: isOpen ? 'flex' : 'none',
  }),
)

export const StyledBoxCollapseItem = styled(Box)(({ theme: { spacing } }) => ({
  '>div': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
    marginBottom: spacing(2.5),
    '>div': {
      padding: '0px !important',
    },
  },
}))

export const StyledSelected = styled(Select)(({ theme: { spacing, palette } }) => ({
  background: palette.common.white,
  height: spacing(5),
  padding: '0 !important',
  borderRadius: '0 !important',
  '>div': {
    paddingBottom: '0 !important',
  },
}))

export const StyledBoxCollapseButtons = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    button: {
      padding: '8px 12px',
      background: 'transparent',
      textTransform: 'uppercase',
      fontSize: spacing(1.5),
      border: '1px solid #8C8C8C',
      ':nth-of-type(2)': {
        background: '#1E1E1E',
        border: '1px solid #1E1E1E',
        color: palette.common.white,
      },

      '&:hover': {
        background: '#1E1E1E',
        border: '1px solid #1E1E1E',
        color: palette.common.white,
      },
    },
  }),
)

export const StyledBoxWrapperPagination = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: spacing(2),
  gap: spacing(2),
}))
