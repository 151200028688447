import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import { StorageKeys } from 'constant/localStorage'
import { IRetrieveBearerTokenResponse } from 'interfaces'
import { RootState } from 'store/store'
import { getLocalStorage, setLocalStorage } from 'utils/localStorage'

const mutex = new Mutex()

// used across the application after login
export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, appApi, extraOptions) => {
  const baseUrl = (appApi.getState() as RootState).app.EVBaseURL

  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const token = getLocalStorage(StorageKeys.token)
      if (token.length && appApi.endpoint !== 'retrieveBearerToken') {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  })
  return rawBaseQuery(args, appApi, extraOptions)
}

// used to login and get the bearer token
export const baseQueryLMS: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, appApi, extraOptions) => {
  const baseUrl = (appApi.getState() as RootState).app.initialConfig?.lmsApiLocation

  const rawBaseQuery = fetchBaseQuery({ baseUrl })
  return rawBaseQuery(args, appApi, extraOptions)
}

// Apply a custom fetch function to the baseQuery to intercept invalid token responses
export const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)

  const token = getLocalStorage(StorageKeys.token)
  const antiCSRFToken = getLocalStorage(StorageKeys.antiCSRFToken)

  if (result.error && [401, 'FETCH_ERROR'].includes(result.error.status)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQueryLMS(
          {
            url: 'Users/GenerateOTT',
            method: 'POST',
            headers: {
              anticsrftoken: antiCSRFToken,
              authorization: `Bearer ${token}`,
            },
            credentials: 'include',
          },
          api,
          extraOptions,
        )
        if (refreshResult.data) {
          const payload = refreshResult.data as IRetrieveBearerTokenResponse
          setLocalStorage(StorageKeys.antiCSRFToken, payload.AntiCSRFToken)

          result = await baseQuery(args, api, extraOptions)
        } else {
          const currentPath = location.pathname
          const redirectPath =
            currentPath === null || currentPath === '/'
              ? '/login'
              : `/login?redirect=${currentPath}`
          localStorage.clear()
          window.location.href = redirectPath
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}
