import { Box, Typography } from '@mui/material'
import { StorageKeys } from 'constant/localStorage'
import { FormattedMessage } from 'react-intl'
import { useLocation, Navigate } from 'react-router-dom'
import { useAppSelector } from 'store'
import { selectUser } from 'store/slices'
import { getLocalStorage } from 'utils/localStorage'
import { useValidateTokenQuery } from 'store/api'

// Role types match those found in the IUser interface
type Role = 'isManager' | 'isRetailerAdmin'

type Props = {
  children: JSX.Element
  restrictTo?: Role[]
  ignoreUserData?: boolean
}

const pollingInterval = 300000 //5 minutes in ms

export default function RequireAuth({ children, restrictTo, ignoreUserData }: Props) {
  const user = useAppSelector(selectUser)
  const token = getLocalStorage(StorageKeys.token)
  const hasToken = token.length

  // poll this endpoint to make sure the users session has not expired
  useValidateTokenQuery(undefined, { pollingInterval })

  const location = useLocation()

  // If there is no restrictTo, has token and does not need the user details then permit access (update-password)
  if (ignoreUserData && hasToken && !restrictTo) {
    return children
  }

  // If there is no user or token present at all then user needs to login so redirect
  if (!user || !hasToken) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  // If there is no restrictTo then permit access
  if (!restrictTo) {
    return children
  }

  // If restrictTo is present then verify that the user has the relevant permissions

  if (restrictTo) {
    let permitAccess = false
    restrictTo.forEach(restriction => {
      if (user[restriction as keyof typeof user] === true) {
        permitAccess = true
      }
    })

    if (permitAccess) {
      return children
    }
  }

  // If none of the above the no permissions
  return (
    <Box mt={10}>
      <Typography fontSize={36} textAlign="center" color="white">
        <FormattedMessage id="requireAuth.notFound.title" />
      </Typography>
      <Typography mt={1} color="white" textAlign="center">
        <FormattedMessage id="requireAuth.notFound.text" />
      </Typography>
    </Box>
  )
}
