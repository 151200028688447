import { useAppSelector } from 'store'
import { selectHelpLanguageInfo, setSelectedHelpLanguage } from 'store/slices'
import { useGetHelpConfigQuery } from 'store/api'
import { FormattedMessage } from 'react-intl'
import LanguageSelector from '.'

import { useDispatch } from 'react-redux'

export default function LanguageSelectorHelp() {
  const dispatch = useDispatch()

  const {
    selectedLanguage: { value: languageId, label: languageName, isoCode },
  } = useAppSelector(selectHelpLanguageInfo)

  const { isLoading, data: languageOptions } = useGetHelpConfigQuery()

  const onHandleSelectLanguage = (languageId: string) => {
    const languageSelected = languageOptions?.find(lo => lo.value === languageId)

    if (languageSelected) {
      dispatch(setSelectedHelpLanguage(languageSelected))
    }
  }

  return (
    <LanguageSelector
      onHandleSelect={onHandleSelectLanguage}
      languageOptions={languageOptions!}
      isLoading={isLoading}
      languageId={languageId}
      languageName={languageName}
      isoCode={isoCode}
    >
      <FormattedMessage id="login.language" />
    </LanguageSelector>
  )
}
