import { Box, ButtonBase, Collapse, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import Counter from 'components/common/Counter'

type Props = {
  modelName: string
  modelImage: string
  unavaiableInMarket?: boolean
  open: boolean
  onToggleOpen: () => void
  children?: React.ReactNode
  count?: number
}

export default function VehicleModel({
  open,
  onToggleOpen,
  modelName,
  modelImage,
  unavaiableInMarket = false,
  children,
  count,
}: Props) {
  return (
    <Paper
      elevation={0}
      sx={{
        border: '1px solid rgb(238 238 238)',
      }}
    >
      <ButtonBase
        onClick={onToggleOpen}
        sx={{
          px: 2,
          py: 3,
          width: '100%',
          ':focus-visible': { outline: '1px solid #cad1d7' },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <Box sx={{ opacity: unavaiableInMarket ? 0.25 : 1 }}>
            <img src={`${modelImage}`} alt="Vehicle" width={120} />
          </Box>
          <Stack flex={1} alignItems="flex-start" justifyContent="flex-start">
            <Typography
              data-testid="primary-text"
              textAlign="left"
              textTransform="uppercase"
              color="#525252"
            >
              {modelName}
            </Typography>
            {unavaiableInMarket ? (
              <Typography variant="body2" color="text.secondary">
                Unavailable
              </Typography>
            ) : null}
          </Stack>
          {count && count > 0 ? <Counter value={count} /> : null}
        </Stack>
      </ButtonBase>
      <Collapse in={open}>
        <Box p={1}>{children}</Box>
      </Collapse>
    </Paper>
  )
}
