import { Box, Button, Table, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledInputFile = styled('input')(() => ({
  display: 'none',
}))

export const StyledLabelFile = styled('label')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  cursor: 'pointer',
  color: theme.palette.grey[500],
  ':hover': {
    color: theme.palette.grey[700],
  },
}))

export const StyledFormLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  marginBottom: 8,
  marginTop: 16,
  '&::after': {
    content: '"*"',
    top: '5px',
    left: '10px',
    fontSize: '16px',
    color: '#9E1B32',
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 8px',
  alignSelf: 'flex-start',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.grey[500]}`,
  ':hover': {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
  '&:disabled': {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}))

export const StyledBoxWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  paddingInline: 32,
  paddingBottom: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}))

export const StyledTable = styled(Table)(({ theme }) => ({
  'th, td': {
    border: 'none',
  },
  th: {
    background: theme.palette.grey[600],
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    p: {
      color: theme.palette.secondary.main,
      fontSize: 14,
    },
  },
  td: {
    paddingBlock: 8,
    p: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
  },

  border: `1px solid ${theme.palette.grey[200]}`,
}))

interface IStyledWrapperFileInput {
  error: string
}

export const StyledWrapperFileInput = styled(Box)<IStyledWrapperFileInput>(
  ({ theme: { spacing, palette }, error }) => {
    const isError = error === 'true'
    return {
      display: 'flex',
      alignItems: 'center',
      border: `${isError ? 2 : 1}px solid`,
      borderColor: isError ? palette.error.main : palette.grey[400],
      height: spacing(5),
      width: '260px',
      padding: spacing(1),
      backgroundColor: 'white',
      ':hover': {
        borderColor: palette.primary.main,
      },
    }
  },
)
