// Redux app feature slice to store all global app state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IInitialConfig } from 'interfaces'
import { RootState } from 'store/store'

interface IAppState {
  initialConfig: IInitialConfig | undefined
  initialFetchSuccess?: boolean
  selectedLanguage?: string
  EVBaseURL?: string
  isExternalLoggedIn: boolean
}

const initialState: IAppState = {
  initialConfig: undefined,
  isExternalLoggedIn: false,
}

const appSlice = createSlice({
  name: 'appSlice',
  initialState: initialState,
  reducers: {
    setAppInitialConfig(state, action: PayloadAction<IInitialConfig>) {
      state.initialConfig = action.payload
      state.initialFetchSuccess = true
    },
    setEVBaseURL(state, action: PayloadAction<string>) {
      state.EVBaseURL = action.payload
    },
    setInitialFetchFailure(state) {
      state.initialFetchSuccess = false
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.selectedLanguage = action.payload
    },
    setIsExternalLoggedIn(state, action: PayloadAction<boolean>) {
      state.isExternalLoggedIn = action.payload
    },
  },
})

export const {
  setAppInitialConfig,
  setInitialFetchFailure,
  setLanguage,
  setEVBaseURL,
  setIsExternalLoggedIn,
} = appSlice.actions

export const selectInitialConfig = (state: RootState) => state.app.initialConfig
export const selectEVBaseURL = (state: RootState) => state.app.EVBaseURL

export default appSlice.reducer
