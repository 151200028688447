// Redux business units feature slice to store all global user state related data.

import { createSlice } from '@reduxjs/toolkit'
import {
  IDashboardEvCapabilityResponse,
  IGetDashboardEvLiveWorkingCapabilytyQueryResponse,
} from 'interfaces'
import { RootState } from 'store'

interface IInitalStateDashboardProps {
  cardsData: IDashboardEvCapabilityResponse
  capabilityData: IGetDashboardEvLiveWorkingCapabilytyQueryResponse['data']
}

const initialState: IInitalStateDashboardProps = {
  cardsData: {
    actionsRequired: {
      confirmEvTrainingCount: 0,
      confirmExperienceCount: 0,
      expiredCount: 0,
      recertificationCount: 0,
      totalAction: 0,
    },
    isAvailableForServicing: false,
    isCompliant: false,
    levelRecommendations: [],
    levels: [],
    status: undefined,
    statusRecommendation: '',
  },
  capabilityData: {
    records: [],
  },
}

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    setDashboardCardsData(state, action) {
      state.cardsData = action.payload
    },
    setDashboardCapabilityData(state, action) {
      state.capabilityData = action.payload
    },
  },
})

export const { setDashboardCardsData, setDashboardCapabilityData } =
  dashboardSlice.actions

export const selectSelectedDashboardCards = (state: RootState) =>
  state.dashboard.cardsData

export const selectSelectedDashboardCapability = (state: RootState) =>
  state.dashboard.capabilityData

export default dashboardSlice.reducer
