import { Box, Stack, Typography } from '@mui/material'
import { StyledARGenericButton } from 'components/StaffMembers/ActionRequired/style'

interface ToDoProps {
  handleClose: () => void
}

const ToDo = ({ handleClose }: ToDoProps) => {
  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: '14px' }}>Not Implemented yet.</Typography>
      <Box
        sx={{
          alignSelf: 'end',
          backgroundColor: 'green',
          width: 'fit-content',
        }}
      >
        <StyledARGenericButton onClick={() => handleClose()}>ToDo</StyledARGenericButton>
      </Box>
    </Stack>
  )
}

export default ToDo
