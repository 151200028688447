import {
  Pagination,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  styled,
} from '@mui/material'

export const StyledTableContainerWrapper = styled(TableContainer)(
  ({ theme: { spacing } }) => ({
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    mb: spacing(2),
  }),
)

export const StyledTable = styled(Table)(_ => ({
  borderCollapse: 'separate',
  borderSpacing: '0 6px',
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
}))

export const StyledHeaderTable = styled(TableHead)(({ theme: { palette } }) => ({
  tr: {
    height: '56px',

    backgroundColor: palette.grey[600],
    th: {
      color: palette.common.white,
    },
  },
}))

export const StyledHeaderTableCell = styled(TableCell)(
  ({ theme: { spacing }, role }) => ({
    padding: 0,
    paddingLeft: role ? 0 : spacing(1.25),

    ':nth-of-type(5),:nth-of-type(6)': {
      textAlign: 'center',
      paddingLeft: 0,
    },
  }),
)

export const StyledPagination = styled(Pagination)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  button: {
    borderRadius: 0,
  },
  '.MuiPaginationItem-page': {
    borderRadius: 0,
    ':hover': {
      color: 'white',
      backgroundColor: palette.grey[700],
    },
  },
  '.Mui-selected': {
    color: `${palette.common.white} !important`,
    backgroundColor: `${palette.grey[800]} !important`,
  },
  '.MuiPaginationItem-previousNext': {
    border: 'none',
    ':hover': {
      backgroundColor: palette.grey[50],
    },
  },
}))
