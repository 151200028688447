import { DialogContent as MUIDialogContent, Box } from '@mui/material'

type Props = {
  children: React.ReactNode
}

export default function DialogContent({ children }: Props) {
  return (
    <MUIDialogContent>
      <Box pt={2}>{children}</Box>
    </MUIDialogContent>
  )
}
