import { Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

interface StyledTabProps {
  label: ReactNode
  value: string
}

export const EVTabs = styled(Tabs)({
  border: 0,
  minHeight: '40px',
  backgroundColor: '#ffffff',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
})

export const EVTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    minHeight: '40px',
    transition: 'all 0.2s ease',
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.grey[600],
    },
  }),
)
