import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Radio,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import SubHeaderLabel from 'components/layout/SubHeaderLabel'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch, useAppSelector } from 'store'
import {
  selectSelectedBusinessUnits,
  selectUserBusinessUnits,
  setSelectedBusinessUnits,
} from 'store/slices'
import { ValueLabelType } from 'types'
import { CheckedIcon, UncheckedIcon } from 'components/StaffMembers/Filters/styles'

interface BusinessUnitProps {
  setShouldRefetch?: React.Dispatch<React.SetStateAction<boolean>>
  shouldRefetch?: boolean
  readOnly?: boolean
  singleSelect?: boolean
}

export default function BusinessUnitSelector({
  setShouldRefetch,
  shouldRefetch,
  readOnly = false,
  singleSelect = false,
}: BusinessUnitProps) {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [tempSelection, setTempSelection] = useState<ValueLabelType[]>([])
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)
  const userBusinessUnits = useAppSelector(selectUserBusinessUnits)
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()

  const dispatch = useAppDispatch()

  useEffect(() => {
    setTempSelection([...selectedBusinessUnits])
  }, [selectedBusinessUnits])

  const handleSelectBusinessUnit = (id: string) => {
    const unitIndex = tempSelection.findIndex(unit => unit.value === id)
    if (unitIndex === -1) {
      const selectedBusinessUnit = userBusinessUnits!.find(ubu => ubu.value === id)!
      setTempSelection(current =>
        singleSelect ? [selectedBusinessUnit] : [...current, selectedBusinessUnit],
      )
    } else {
      !singleSelect &&
        setTempSelection(current => current.filter(unit => unit.value !== id))
    }
  }
  useEffect(() => {
    if (!isSmallScreen) {
      setTempSelection(!anchorElement ? [] : selectedBusinessUnits)
    }
  }, [anchorElement, selectedBusinessUnits, isSmallScreen])

  const handleBusinessUnitSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget)
    setIsOpen(state => !state)
  }

  const onClose = () => {
    setAnchorElement(null)
  }

  const handleOnApply = () => {
    dispatch(setSelectedBusinessUnits(tempSelection))
    onClose()
    setIsOpen(false)
    setShouldRefetch && setShouldRefetch(!shouldRefetch)
  }

  const handleClearFilters = () => {
    dispatch(setSelectedBusinessUnits([]))
    setTempSelection([])
    onClose()
    setIsOpen(false)
    setShouldRefetch && setShouldRefetch(!shouldRefetch)
  }

  const noOfSelectedUnits = selectedBusinessUnits.length
  const noOfUserUnits = userBusinessUnits?.length ?? 0

  let businessUnitsToDisplay = ''
  let remainingUnits = 0

  if (userBusinessUnits) {
    if (noOfSelectedUnits > 0) {
      // Display only the selected units
      if (noOfSelectedUnits > 2) {
        businessUnitsToDisplay = selectedBusinessUnits
          .slice(0, 2)
          .map(unit => unit.label)
          .join(', ')
        remainingUnits = noOfSelectedUnits - 2
      } else {
        businessUnitsToDisplay = selectedBusinessUnits.map(unit => unit.label).join(', ')
      }
    } else {
      // When none are selected display as many as possible of the user units
      if (noOfUserUnits > 2) {
        businessUnitsToDisplay = userBusinessUnits
          .slice(0, 2)
          .map(businessUnit => businessUnit.label)
          .join(', ')
        remainingUnits = noOfUserUnits - 2
      } else {
        businessUnitsToDisplay = userBusinessUnits.map(unit => unit.label).join(', ')
      }
    }
  }

  return (
    <>
      <ButtonBase
        onClick={!readOnly ? handleBusinessUnitSelect : undefined}
        sx={{
          ':focus-visible': {
            outline: '1px solid white',
            outlineOffset: '5px',
          },
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <SubHeaderLabel>{businessUnitsToDisplay}</SubHeaderLabel>
          {remainingUnits > 0 ? (
            <Typography fontFamily="JLR Emeric ExtraLight" fontWeight={100} color="white">
              {'+ '}
              <FormattedMessage
                id="common.businessUnitSelector.remainingUnitText"
                values={{
                  number: remainingUnits,
                }}
              />
            </Typography>
          ) : null}
          {!readOnly && <KeyboardArrowDownIcon htmlColor="white" />}
        </Stack>
      </ButtonBase>

      {isSmallScreen ? (
        <Collapse in={isOpen}>
          <Box>
            <List
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 1,
                li: {
                  ':hover': {
                    background: 'transparent',
                  },
                  div: {
                    p: 0,
                    minWidth: '20px',
                    div: {
                      ':nth-of-type(2)': {},
                      span: {
                        color: theme.palette.common.white,
                      },
                    },
                  },
                },
              }}
            >
              {userBusinessUnits?.map(businessUnit => (
                <ListItem disablePadding key={businessUnit.value}>
                  <ListItemButton
                    onClick={() => handleSelectBusinessUnit(businessUnit.value)}
                  >
                    <ListItemIcon>
                      {!singleSelect ? (
                        <Checkbox
                          edge="start"
                          disableRipple
                          checked={Boolean(
                            tempSelection.find(unit => unit.value === businessUnit.value),
                          )}
                          icon={<UncheckedIcon />}
                          checkedIcon={<CheckedIcon color="white" icon="primary" />}
                          sx={{ py: 0 }}
                        />
                      ) : (
                        <Radio
                          name="businessUnit"
                          edge="start"
                          disableRipple
                          checked={Boolean(
                            tempSelection.find(unit => unit.value === businessUnit.value),
                          )}
                          icon={<UncheckedIcon />}
                          checkedIcon={<CheckedIcon color="white" icon="primary" />}
                          sx={{ py: 0 }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={businessUnit.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Stack
              direction="row"
              justifyContent="space-between"
              px={0}
              pb={1}
              color="#5B788F"
            >
              <Button
                onClick={handleOnApply}
                sx={{
                  justifyContent: 'flex-start',
                  padding: '6px 6px 6px 0',
                  color: 'white',
                }}
              >
                <FormattedMessage id="forms.apply" />
              </Button>
              {!singleSelect && (
                <Button
                  sx={{
                    justifyContent: 'flex-end',
                    padding: '6px 0 6px',
                    color: 'white',
                  }}
                  onClick={handleClearFilters}
                >
                  <FormattedMessage id="forms.clear" />
                </Button>
              )}
            </Stack>
          </Box>
        </Collapse>
      ) : (
        <Popover
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          onClose={onClose}
          PaperProps={{ sx: { minWidth: 400 } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ mt: 1 }}
        >
          <List>
            {userBusinessUnits?.map(businessUnit => (
              <ListItem disablePadding key={businessUnit.value}>
                <ListItemButton
                  onClick={() => handleSelectBusinessUnit(businessUnit.value)}
                  sx={{
                    '.Mui-selected': {
                      backgroundColor: '#EBF1F6',
                    },
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      disableRipple
                      checked={Boolean(
                        tempSelection.find(unit => unit.value === businessUnit.value),
                      )}
                      sx={{ py: 0 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={businessUnit.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Stack
            direction="row"
            justifyContent="space-between"
            px={1}
            pb={1}
            color="#5B788F"
          >
            <Button color="inherit" onClick={handleOnApply}>
              <FormattedMessage id="forms.apply" />
            </Button>
            {!singleSelect && (
              <Button color="inherit" onClick={handleClearFilters}>
                <FormattedMessage id="forms.clear" />
              </Button>
            )}
          </Stack>
        </Popover>
      )}
    </>
  )
}
