// Redux language feature slice to store all global language state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface ILanguageState {
  selectedLanguage: {
    value: string
    label: string
    isoCode: string
  }
  translations: Record<string, string>
}

const initialState: ILanguageState = {
  selectedLanguage: {
    value: '33e5850a-473f-11e8-9661-7bf55cea83d1',
    label: 'English',
    isoCode: 'en',
  },
  translations: {},
}

const languageSlice = createSlice({
  name: 'languageSlice',
  initialState: initialState,
  reducers: {
    updateTranslations(state, action: PayloadAction<Record<string, string>>) {
      state.translations = action.payload
    },
    setSelectedLanguage(
      state,
      action: PayloadAction<ILanguageState['selectedLanguage']>,
    ) {
      state.selectedLanguage = action.payload
    },
  },
})

export const { updateTranslations, setSelectedLanguage } = languageSlice.actions

export const selectLanguageInfo = (state: RootState) => state.language
export const getSelectedLanguage = (state: RootState) => state.language.selectedLanguage

export default languageSlice.reducer
