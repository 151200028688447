// Course header component

import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { StatusType } from 'types'
import { CourseType } from 'types'
import { EVCourseIcon, StatusIcon } from 'icons/EVIcons'

import { getCourseTypeLabel } from 'utils/EVUtils/courseUtils/getCourseTypeLabel'

type Props = {
  primaryText: string
  completedDate?: string | null
  status?: StatusType
  courseType: CourseType
  isAvailable: boolean
  isLocked: boolean
  isBookingSession?: boolean
  courseCode?: string
}

export default function CourseHeader({
  primaryText,
  status,
  courseType,
  courseCode,
  isAvailable,
  isLocked,
  isBookingSession,
}: Props) {
  const isMediumScreen = useMediaQuery('(min-width: 768px)')
  const isSmallScreen = useMediaQuery('(min-width: 768px)')
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      width="100%"
      minWidth={isMediumScreen ? 650 : isSmallScreen ? 'auto' : 375}
    >
      <Box minWidth={70} display="flex" justifyContent="center">
        <EVCourseIcon courseType={courseType} />
      </Box>
      <Stack flex={1} alignItems="flex-start">
        <Typography
          data-testid="primary-text"
          color={isBookingSession ? '#1e1e1e' : '#525252'}
          textTransform="uppercase"
          textAlign="left"
          fontSize={isBookingSession ? 18 : 16}
          letterSpacing={isBookingSession ? '1.8px' : 'inherit'}
        >
          {primaryText}
        </Typography>
        {!isBookingSession && courseCode && (
          <Typography
            data-testid="primary-text"
            color="rgb(151,151,151)"
            fontSize={14}
            textAlign="left"
          >
            {courseCode}
          </Typography>
        )}
        {isBookingSession && courseCode && (
          <Typography
            data-testid="primary-text"
            color="rgb(151,151,151)"
            fontSize={14}
            textAlign="left"
          >
            {courseCode}
          </Typography>
        )}
        {isBookingSession && (
          <Typography
            data-testid="primary-text"
            color="#525252"
            textAlign="left"
            fontSize={16}
          >
            {getCourseTypeLabel(courseType)}
          </Typography>
        )}
      </Stack>
      {status ? (
        <StatusIcon
          status={status}
          size="big"
          isAvailable={isAvailable}
          isLocked={isLocked}
        />
      ) : null}
    </Stack>
  )
}
