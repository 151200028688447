import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import genericIcon from 'assets/svgs/icon_level_round_empty.svg'
import actionRequiredIcon from 'assets/svgs/icon_ls_expired.svg'
import { IRowCollapseProps } from 'interfaces'
import { FC, useState } from 'react'
import {
  StyledBoxCollapseItem,
  StyledBoxCollapseItemImg,
  StyledButtonDetails,
  StyledListCollapse,
  StyledListCollapseItem,
  StyledListCollapseItemBoxAction,
} from './styles'

import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ActionRequiredComponent from 'components/StaffMembers/ActionRequired'

const RowCollapse: FC<IRowCollapseProps> = ({ row }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  return (
    <Box component={Paper} key={row.pin + row.jobRoles} mb={0.5}>
      <StyledListCollapse>
        <ListItem>
          <ListItemButton
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            onClick={() => setOpen(state => !state)}
          >
            <StyledBoxCollapseItemImg>
              {row.workingLevel?.value ? (
                <img
                  src={`/assets/${row.workingLevel?.value}`}
                  alt={String(row.workingLevel.value)}
                />
              ) : (
                <img src={genericIcon} alt="Without Working Level" />
              )}
              <Box>
                <Typography color={theme.palette.grey[600]}>{row.pin}</Typography>
                <Typography>{row.name}</Typography>
              </Box>
            </StyledBoxCollapseItemImg>
            {row.actionsRequired?.length && (
              <img src={actionRequiredIcon} alt="Action Required Icon" />
            )}
          </ListItemButton>
        </ListItem>
      </StyledListCollapse>

      <Collapse in={open}>
        <StyledBoxCollapseItem>
          <StyledListCollapseItem>
            <ListItem alignItems="flex-start">
              <Typography>
                <FormattedMessage id="membersList.jobRole" />:
              </Typography>
              <Typography flex={1}>{row.jobRoles.join(', ')}</Typography>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Typography>
                <FormattedMessage id="membersList.businessUnit" />:
              </Typography>
              <Typography flex={1}>{row.businessUnits.join(', ')}</Typography>
            </ListItem>
            {row.certifiedLevel?.value && (
              <ListItem sx={{ gap: 1.5 }}>
                <Typography width="110px">
                  <FormattedMessage id="applicationTerm.certifiedLevel" />:
                </Typography>
                <Typography display="flex" alignItems="center" gap={1.25}>
                  {row.certifiedLevel?.value && (
                    <img
                      src={`/assets/${row.certifiedLevel?.value}`}
                      alt={row.certifiedLevel?.label}
                    />
                  )}

                  {row.certifiedLevel?.label}
                </Typography>
              </ListItem>
            )}
            {row.workingLevel?.value && (
              <ListItem sx={{ gap: 1.5 }}>
                <Typography width="110px">
                  <FormattedMessage id="applicationTerm.workingLevel" />:
                </Typography>
                <Typography display="flex" alignItems="center" gap={1.25}>
                  {row.workingLevel?.value && (
                    <img
                      src={`/assets/${row.workingLevel?.value}`}
                      alt={row.workingLevel?.label}
                    />
                  )}

                  {row.workingLevel?.label}
                </Typography>
              </ListItem>
            )}
            {row.targetLevel?.value && (
              <ListItem sx={{ gap: 1.5 }}>
                <Typography width="110px">
                  <FormattedMessage id="applicationTerm.targetLevel" />:
                </Typography>
                <Typography display="flex" alignItems="center" gap={1.25}>
                  {row.targetLevel?.value && (
                    <img
                      src={`/assets/${row.targetLevel?.value}`}
                      alt={row.targetLevel?.label}
                    />
                  )}
                  {row.targetLevel?.label}
                </Typography>
              </ListItem>
            )}

            <ListItem>
              <StyledButtonDetails
                variant="outlined"
                size="small"
                onClick={() => navigate(`/user-details/${row.staffMemberUUID}`)}
              >
                <FormattedMessage id="membersList.viewDetails" />
              </StyledButtonDetails>
            </ListItem>
          </StyledListCollapseItem>
        </StyledBoxCollapseItem>

        {row.actionsRequired && (
          <StyledListCollapseItemBoxAction>
            {row?.actionsRequired[0] && (
              <ActionRequiredComponent
                actionRequired={row.actionsRequired[0]}
                userUUID={row.staffMemberUUID}
              />
            )}
          </StyledListCollapseItemBoxAction>
        )}
      </Collapse>
    </Box>
  )
}

export default RowCollapse
