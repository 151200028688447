import { styled, Table, TableBody, TableHead } from '@mui/material'

export const StyledTable = styled(Table)(({ theme: { palette } }) => ({
  borderCollapse: 'separate',
  borderSpacing: '0 2px',
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
  display: 'flex',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
  marginBottom: 16,
  background: palette.common.white,

  '@media (min-width: 940px)': {
    display: 'table',
    boxShadow: 'none',
    marginBottom: 0,
  },
}))

export const StyledTHead = styled(TableHead)(({ theme: { palette } }) => ({
  background: palette.grey[600],
  paddingBottom: '0.5rem',
  width: '120px',
  '& th': {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: '400',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '56px',
    '&:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4)': {
      padding: '0 16px',
      height: '25px',
    },

    '&:nth-of-type(5)': {
      padding: '0 16px',
      height: '40px',
    },
  },
  '@media (min-width: 940px)': {
    '& th': {
      display: 'table-cell',
      '&:nth-of-type(1)': {
        width: '18%',
      },
      '&:nth-of-type(2)': {
        width: '10%',
      },
      '&:nth-of-type(3)': {
        width: '15%',
      },
      '&:nth-of-type(4)': {
        width: '15%',
      },
      '&:nth-of-type(5)': {
        width: '20%',
      },
      '&:nth-of-type(6)': {
        width: '20%',
      },
    },
  },
}))

export const StyledTBody = styled(TableBody)(({ theme: { palette } }) => ({
  '& td': {
    fontSize: '0.75rem',
    background: palette.common.white,
    border: 'none',
    color: palette.grey[700],
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '56px',
    justifyContent: 'center',
    '&:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4)': {
      padding: '0 16px',
      height: '25px',
    },
    '&:nth-of-type(5)': {
      padding: '0 16px',
      height: '40px',
    },
  },

  '@media (min-width: 940px)': {
    '& td': {
      display: 'table-cell !important',
      width: 'auto',
      height: 'auto',
    },
  },
}))

export const StyledTitle = styled('h2')(() => ({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '1.0rem',
  fontWeight: 'normal',
}))
