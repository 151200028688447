import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import GetConfig from 'components/layout/GetConfig'
import { customTheme } from 'customTheme'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from 'store'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient()
root.render(
  <Provider store={store}>
    <GetConfig>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={customTheme}>
            <App />
          </ThemeProvider>
        </QueryClientProvider>
      </Router>
    </GetConfig>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
