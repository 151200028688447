import { FC } from 'react'
import { Typography } from '@mui/material'
import { IEvSettings } from 'interfaces'
import jaguarLogo from 'assets/svgs/jaguar_black_logo.svg'
import rangeRoverLogo from 'assets/svgs/range_rover_black_logo.svg'
import defenderLogo from 'assets/svgs/defender_black_logo.svg'
import discoveryLogo from 'assets/svgs/discovery_black_logo.svg'
import { StyledCardBoxContent } from 'components/SettingsComponents/style'
import CardCarContent from 'components/SettingsComponents/CardCarContent'

const MobileContentWrapperCards: FC<{
  cars: IEvSettings['models']
  brand: string
}> = ({ cars, brand }) => {
  const BrandLogo = ({ brandName }: { brandName: string }) => {
    switch (brandName) {
      case 'Jaguar':
        return <img src={jaguarLogo} alt="Jaguar Logo" />
      case 'Range Rover':
        return <img src={rangeRoverLogo} alt="Range Rover Logo" />
      case 'Discovery':
        return <img src={discoveryLogo} alt="Discovery Logo" />
      case 'Defender':
        return <img src={defenderLogo} alt="Defender Logo" />
      default:
        return (
          <StyledCardBoxContent>
            <Typography>{brandName}</Typography>
          </StyledCardBoxContent>
        )
    }
  }

  return (
    <StyledCardBoxContent>
      <BrandLogo brandName={brand} />
      {cars.map(({ year, model, isEnabled }) => (
        <CardCarContent key={model.modelUUID} {...{ model, year, isEnabled }} />
      ))}
    </StyledCardBoxContent>
  )
}

export default MobileContentWrapperCards
