import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import { IValueLabel } from 'interfaces'
import { FormattedMessage, useIntl } from 'react-intl'
import { selectJobRoles, setJobRoles, useAppDispatch, useAppSelector } from 'store'

import { CheckedIcon, StlyedBoxWrapper, UncheckedIcon } from './style'

interface JobRolesProps {
  jobRoles?: IValueLabel[]
}

const JobRoles = ({ jobRoles }: JobRolesProps) => {
  const selectedJobRoles = useAppSelector(selectJobRoles)
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const theme = useTheme()

  if (!jobRoles) return null

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const { value } = e.target
    dispatch(setJobRoles(value as string[]))
  }

  return (
    <StlyedBoxWrapper>
      <label style={{ color: theme.palette.grey[800] }}>
        <FormattedMessage id="membersList.jobRole" />
      </label>
      <Select
        IconComponent={KeyboardArrowDown}
        displayEmpty
        id="job-role-select"
        value={selectedJobRoles || []}
        multiple
        sx={{
          background: theme.palette.common.white,
          height: theme.spacing(5),

          borderRadius: '0 !important',
          '>div': {
            padding: '8.5px 12px !important',
            marginRight: '32px',
          },
        }}
        onChange={handleChange}
        renderValue={selected => {
          if (selected.length === 0) {
            return intl.formatMessage({
              id: 'app.labels.select',
            })
          }
          const selectedValues = jobRoles.map(jobRole => {
            if (selected && selected.includes(jobRole.value as string)) {
              return jobRole.label
            }
            return null
          })
          return selectedValues.filter(Boolean).join(', ')
        }}
      >
        {jobRoles.map(jobRole => (
          <MenuItem
            value={jobRole.value}
            key={jobRole.label}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#EBF1F6',
              },
              padding: '6px 6px !important',
            }}
          >
            <Checkbox
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              checked={
                selectedJobRoles &&
                selectedJobRoles.findIndex((item: string) => item === jobRole.value) >= 0
              }
              size="small"
              disableRipple
            />
            <ListItemText
              primary={jobRole.label}
              primaryTypographyProps={{ variant: 'subtitle1' }}
            />
          </MenuItem>
        ))}
      </Select>
    </StlyedBoxWrapper>
  )
}

export default JobRoles
