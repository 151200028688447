import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import Button from 'components/common/Button'
import SanitizedString from 'components/common/SanitizedString'
import {
  IAcceptOrDeclineTrainingLevelProposalMutationParams,
  ISubmitEvTrainingRequestMutationParams,
  ITrainingLevelAgreementResponse,
  ITrainingLevelDetails,
} from 'interfaces'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  useAcceptOrDeclineTrainingLevelProposalMutation,
  useSubmitEvTrainingRequestMutation,
} from 'store/api'
import { useAppSelector } from 'store'
import { selectUserIsImpersonate } from 'store/slices'

type Props = {
  evLevelData: ITrainingLevelDetails
  onConfirm: Dispatch<SetStateAction<boolean>>
  data?: ITrainingLevelAgreementResponse | undefined
}

export default function LevelAccessRequestDetail({
  onConfirm,
  data,
  evLevelData,
}: Props) {
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const [declinedReason, setToggleDeclinedReason] = useState(false)
  const [confirmed, setConfirmed] = useState<ISubmitEvTrainingRequestMutationParams>({
    levelUUID: evLevelData.levelUUID,
    hasConfirmedAcceptance: false,
    hasConfirmedLocalLegislation: false,
  })
  const [acceptOrDecline, setAcceptedOrDeclined] =
    useState<IAcceptOrDeclineTrainingLevelProposalMutationParams>({
      levelUUID: evLevelData.levelUUID,
      declineReason: '',
      hasConfirmedAcceptance: false,
      hasConfirmedLocalLegislation: false,
      isAccepted: false,
    })

  const requestReason = evLevelData.isLevelTrainingRequestable ? 'request' : 'acceptance'

  const onConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'hasConfirmedAcceptance' | 'hasConfirmedLocalLegislation',
  ) => {
    if (requestReason === 'request') {
      setConfirmed({
        ...confirmed,
        [type]: event.target.checked,
      })
    } else {
      setAcceptedOrDeclined({
        ...acceptOrDecline,
        [type]: event.target.checked,
        isAccepted: Boolean(type === 'hasConfirmedAcceptance' && event.target.checked),
      })
    }
  }

  const [submitEvTrainingRequest, { isLoading: isLoadingSubmit, data: submitResponse }] =
    useSubmitEvTrainingRequestMutation()

  const [
    acceptOrDeclineProposal,
    { isLoading: isLoadingProposal, data: submitProposal },
  ] = useAcceptOrDeclineTrainingLevelProposalMutation()

  const handleAccessRequestConfirm = async (reason: 'request' | 'acceptance') => {
    if (reason === 'request') {
      await submitEvTrainingRequest({ ...confirmed })
    } else {
      await acceptOrDeclineProposal({ ...acceptOrDecline })
    }
  }

  useEffect(() => {
    if (submitResponse?.success) {
      onConfirm(false)
    }
    if (submitResponse?.errors && submitResponse?.errors?.length > 0) {
      console.error(submitResponse?.errors[0]?.message)
    }
  }, [submitResponse])

  useEffect(() => {
    if (submitProposal?.success) {
      onConfirm(false)
    }
    if (submitProposal?.errors && submitProposal?.errors?.length > 0) {
      console.error(submitProposal?.errors[0]?.message)
    }
  }, [submitProposal])

  const {
    confirmationAcceptanceText,
    confirmationCheckboxText,
    localLegislationCheckboxText,
  } = data!.data

  return (
    <>
      <SanitizedString string={confirmationAcceptanceText} />
      <Box py={2} ml={-1}>
        {confirmationCheckboxText ? (
          <FormControlLabel
            sx={{ m: 0, mb: 2, alignItems: 'flex-start' }}
            control={
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onConfirmationChange(event, 'hasConfirmedAcceptance')
                }
                sx={{
                  mr: 1,
                  mt: -1,
                  color: 'gray',
                  '&.Mui-checked': { color: 'rgb(80,109,130)' },
                }}
                value={
                  confirmed.hasConfirmedAcceptance ||
                  acceptOrDecline.hasConfirmedAcceptance
                }
                checked={
                  confirmed.hasConfirmedAcceptance ||
                  acceptOrDecline.hasConfirmedAcceptance
                }
              />
            }
            label={confirmationCheckboxText}
          />
        ) : null}
        {localLegislationCheckboxText ? (
          <FormControlLabel
            sx={{ m: 0, mb: 2, alignItems: 'flex-start' }}
            control={
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onConfirmationChange(event, 'hasConfirmedLocalLegislation')
                }
                sx={{
                  mr: 1,
                  mt: -1,
                  color: 'gray',
                  '&.Mui-checked': { color: 'rgb(80,109,130)' },
                }}
                value={
                  confirmed.hasConfirmedLocalLegislation ||
                  acceptOrDecline.hasConfirmedLocalLegislation
                }
                checked={
                  confirmed.hasConfirmedLocalLegislation ||
                  acceptOrDecline.hasConfirmedLocalLegislation
                }
              />
            }
            label={localLegislationCheckboxText}
          />
        ) : null}
        {submitResponse?.errors?.[0]?.message ? (
          <Box color="error.main" mt={2}>
            {submitResponse?.errors[0].message}
          </Box>
        ) : null}
        {submitProposal?.errors?.[0]?.message ? (
          <Box color="error.main" mt={2}>
            {submitProposal?.errors[0].message}
          </Box>
        ) : null}
        <Button
          onClick={() => handleAccessRequestConfirm(requestReason)}
          disabled={
            isImpersonate ||
            (requestReason === 'request'
              ? !confirmed.hasConfirmedAcceptance
              : !acceptOrDecline.hasConfirmedAcceptance)
          }
          sx={{ mt: 2, height: 48 }}
          fullWidth
          isLoading={
            isLoadingSubmit ||
            Boolean(acceptOrDecline.declineReason.length > 0 && isLoadingProposal)
          }
        >
          <FormattedMessage
            id={
              requestReason === 'acceptance'
                ? 'evLevel.requestTraining.acceptButton'
                : 'app.buttons.confirm'
            }
          />
        </Button>
        {requestReason === 'acceptance' ? (
          <Button
            evColor="#9E1B32"
            onClick={() => setToggleDeclinedReason(true)}
            sx={{ mt: 2, height: 48 }}
            fullWidth
            disabled={isImpersonate}
          >
            <FormattedMessage id="evLevel.requestTraining.declineButton" />
          </Button>
        ) : null}

        {declinedReason ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              mt: 0,
              p: 2,
              backgroundColor: '#9E1B32',
            }}
          >
            <Typography color="white">
              <FormattedMessage id="evLevel.requestTraining.declineReason" />
            </Typography>
            <TextField
              multiline
              fullWidth
              rows={4}
              value={acceptOrDecline.declineReason}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAcceptedOrDeclined({
                  ...acceptOrDecline,
                  declineReason: event.target.value,
                  isAccepted: false,
                })
              }
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mt: 1,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setToggleDeclinedReason(false)}
                sx={{
                  mt: 2,
                  color: 'white',
                  borderColor: 'white',
                  height: 48,
                  '&:hover': {
                    borderColor: 'rgba(255,255,255, 0.7)',
                    color: 'rgba(255,255,255, 0.7)',
                  },
                }}
              >
                <FormattedMessage id="app.buttons.cancel" />
              </Button>

              <Button
                isLoading={isLoadingProposal}
                variant="outlined"
                onClick={() => handleAccessRequestConfirm(requestReason)}
                disabled={Boolean(acceptOrDecline.declineReason.length < 10)}
                sx={{
                  mt: 2,
                  color: 'white',
                  borderColor: 'white',
                  height: 48,
                  '&:hover': {
                    borderColor: 'rgba(255,255,255, 0.7)',
                    color: 'rgba(255,255,255, 0.7)',
                  },
                  '&.Mui-disabled': {
                    color: 'rgba(255,255,255, 0.4)',
                    borderColor: 'rgba(255,255,255, 0.4)',
                  },
                }}
              >
                <FormattedMessage id="evLevel.requestTraining.declineButton" />
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  )
}
