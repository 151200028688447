import { yupResolver } from '@hookform/resolvers/yup'
import { Paper, Popover, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import ProposeAlternative from 'components/StaffMembers/ActionRequired/components/ProposeAlternative'
import { StyledARButton } from 'components/StaffMembers/ActionRequired/style'
import { IActionRequiredFormInput } from 'components/StaffMembers/ActionRequired/types'
import { IEVApiResponse, ISubmitEvTrainingNominationParams } from 'interfaces'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSubmitEvTrainingNominationCommandMutation } from 'store/api'
import * as yup from 'yup'

const ChangeTargetLevel = () => {
  const { user: userUUID } = useParams<{ user: string }>()
  const intl = useIntl()
  const [isOpen, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const proposeAlternativeTitle = intl.formatMessage({
    id: 'evLevel.EVDetail.changeTargetLevel.proposeAlternativeTitle',
  })
  const changeTargetSuccessMessage = intl.formatMessage({
    id: 'evLevel.EVDetail.changeTargetLevel.changeTargetSuccessMessage',
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const [submitEvTrainingNomination, { isLoading, isSuccess, error }] =
    useSubmitEvTrainingNominationCommandMutation()

  const schema = yup.object().shape({
    newTargetLevel: yup.string().required('New target level is required'),
  })

  const methods = useForm<IActionRequiredFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      newTargetLevel: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (isSuccess) {
      handleClose()
      toast.success(changeTargetSuccessMessage)
    }
  }, [isSuccess])

  useEffect(() => {
    if (error) {
      toast.error((error as IEVApiResponse).data.errors[0].message)
    }
  }, [error])

  const onFormSubmit = (data: IActionRequiredFormInput) => {
    const { newTargetLevel } = data
    const proposeAlternativePayload: ISubmitEvTrainingNominationParams = {
      userUUID: userUUID as string,
      levelUUID: newTargetLevel as string,
    }
    submitEvTrainingNomination(proposeAlternativePayload)
  }

  return (
    <Stack spacing={2} width={isSmall ? '100%' : 'auto'}>
      <StyledARButton
        onClick={handleClick}
        sx={{
          background: isSmall ? 'transparent' : '#FFF',
        }}
      >
        <Typography variant="button">
          <FormattedMessage id="evLevel.EVDetail.changeTargetLevel.changeButtonText" />
        </Typography>
      </StyledARButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableRestoreFocus
        disablePortal
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onFormSubmit)}>
            <Paper sx={{ padding: '14px', width: '260px' }}>
              <ProposeAlternative
                {...{
                  title: proposeAlternativeTitle,
                  userUUID: userUUID as string,
                  isLoadingAlternative: isLoading,
                  isUserDetails: true,
                  handleClose,
                }}
              />
            </Paper>
          </form>
        </FormProvider>
      </Popover>
    </Stack>
  )
}

export default ChangeTargetLevel
