export const getCourseTypeLabel = (courseType: string) => {
  const courseTypeMap = {
    ONLINE_LEARNING_ELEARNING: 'eLearning',
    ONLINE_LEARNING_PSM: 'PSM',
    ONLINE_LEARNING_QUICKLEARNING: 'Quick Learning',
    CLASSROOM_PHYSICAL: 'Physical Classroom',
    CLASSROOM_VIRTUAL: 'Virtual Classroom',
    EXAM: 'Exam',
    EXAM_ENDTEST: 'End Test',
  }

  return courseTypeMap[courseType as keyof typeof courseTypeMap]
}
