import { IGetEvSettingsParams, IGetEvSettingsResponse } from 'interfaces'

import { evAPI } from './api'

export const settingsApi = evAPI.injectEndpoints({
  endpoints: builder => ({
    getEvSettings: builder.query<IGetEvSettingsResponse['data'], IGetEvSettingsParams>({
      query: ({ businessUnitUUID }) => {
        return {
          url: `Management/Settings/GetEvSettingsQuery?businessUnitUUID=${businessUnitUUID}`,
          method: 'GET',
        }
      },
      transformResponse: ({ data }: IGetEvSettingsResponse) => data,
    }),
  }),
})

export const { useLazyGetEvSettingsQuery, useGetEvSettingsQuery } = settingsApi
