import {
  Box,
  Button,
  Switch,
  Tooltip,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

export const StyledDashboardTitleWrapper = styled(Box)(() => ({
  width: '100%',
  paddingBlock: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingInline: 0,
  justifyContent: 'center',
  gap: 20,
  '@media (min-width: 1200px)': {
    flexDirection: 'row',
    paddingInline: '24px',
    justifyContent: 'space-between',
  },
}))

export const StyledDashboardTitle = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.grey[800],
  fontWeight: 'bold',
  fontSize: 18,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: 10,
  letterSpacing: '.15rem',
  '>p': {
    color: palette.grey[700],
    fontWeight: 'normal',
    letterSpacing: 'initial',
  },
  '@media (min-width: 1200px)': {
    fontWeight: 'normal',
    fontSize: 24,
    flexDirection: 'row',
    gap: 16,
  },
}))

export const StyledDashboardSideTextContainer = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,

  '>p': {
    color: palette.grey[700],
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },

  '@media (min-width: 1200px)': {
    flexDirection: 'row',
  },
}))

export const StyledDisabledTooltipContainer = styled(Box)(({ theme: { palette } }) => ({
  color: palette.grey[700],
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}))

export const StyledBoxAvailable = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  '>div': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
}))

type StyledStatusProps = TypographyProps & {
  enabled: string
}

export const StyledTypographyStatus = styled(Typography)<StyledStatusProps>(
  ({ theme: { palette }, enabled }) => {
    const isEnabled = enabled === 'true'
    return {
      padding: '8px 6px',
      boxShadow: isEnabled ? 'none' : `0 0 0 1px ${palette.error.main}`,
      borderRadius: 4,

      ':hover': {
        background: isEnabled ? 'transparent' : palette.common.white,
      },

      button: {
        backgroundColor: isEnabled ? '#a6c3c1' : palette.error.main,
        ':hover': {
          backgroundColor: isEnabled ? '#a6c3c1' : '#811428',
        },
      },
    }
  },
)

export const StyledStatusButton = styled(Button)(({ theme: { palette } }) => ({
  color: palette.common.white,
  textTransform: 'uppercase',
  borderRadius: 4,
  fontSize: 14,
  padding: '2px 12px',
  marginLeft: 8,
}))

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.grey[600],
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    marginTop: '24px !important',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: '12px 16px',
    maxWidth: 360,
    '& .MuiTooltip-arrow': {
      ':before': {
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
      },
    },
  },
}))

export const StyledLightTooltipContainer = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  '>p': {
    color: palette.grey[700],
    fontSize: 14,
  },
}))

export const StyledLightTooltipContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  '>p': {
    color: palette.grey[700],
    fontSize: 14,
    fontWeight: 'bold',
  },

  img: {
    width: 28,
    height: 28,
  },

  '>div': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,

    div: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
      span: {
        fontSize: '16px',
      },
    },
  },
}))
