import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { closeAllBookingModals, useAppDispatch, useAppSelector } from 'store'

const BookingConfirmation: FC = () => {
  const { palette } = useTheme()
  const dispatch = useAppDispatch()
  const { submitMessage } = useAppSelector(state => state.booking)
  const isBelowMediumScreen = useMediaQuery('@media (max-width: 599px)')

  useEffect(() => {
    console.log('isSmall', isBelowMediumScreen)
  }, [isBelowMediumScreen])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 1,
        padding: '16px 24px',
        background: palette.common.white,
        width: '100%',
        height: isBelowMediumScreen ? 'calc(100vh - 70px)' : '100%',
      }}
    >
      <Box
        sx={{
          minHeight: '150px',
          p: {
            color: palette.grey[700],
          },
        }}
      >
        {!submitMessage?.success ? (
          <>
            {submitMessage?.errors ? (
              submitMessage?.errors.map((msg, i) => (
                <Typography key={i}>
                  {[
                    'BOOKING_FAILED_INCOMPLETE_PREREQUISITE',
                    'BOOKING_FAILED_COLLISION_WITH_OTHER_SESSION',
                  ].includes(msg.code as string) ? (
                    <FormattedMessage id={`errorCodes.${msg.code}`} values={msg.values}>
                      {txt => {
                        return (
                          <>
                            {(txt[0] as string).split('|').map((t, i) => (
                              <>
                                {i === 1 ? <br /> : null}
                                <Typography key={i}>
                                  {i > 0 ? '- ' : null}
                                  {t}
                                </Typography>
                              </>
                            ))}
                          </>
                        )
                      }}
                    </FormattedMessage>
                  ) : (
                    <FormattedMessage id={`errorCodes.${msg.code}`} values={msg.values} />
                  )}
                </Typography>
              ))
            ) : (
              <Typography>{submitMessage?.message}</Typography>
            )}
          </>
        ) : (
          <Typography>{submitMessage.message}</Typography>
        )}
      </Box>

      <Box width="100%">
        <Divider sx={{ marginBottom: 2 }} />

        <Box display="flex" gap={1} alignItems="center" justifyContent="flex-end">
          <Button
            color="info"
            variant="outlined"
            size="small"
            sx={{
              fontSize: '0.75rem',
            }}
            onClick={() => {
              dispatch(closeAllBookingModals())
            }}
          >
            <FormattedMessage id="app.buttons.close" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default BookingConfirmation
