// Redux helplanguage feature slice to store all global helplanguage state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface IHelpState {
  selectedLanguage: {
    value: string
    label: string
    isoCode: string
  }
  translations: Record<string, string>
  selectedTab: number
  selectedCategories: Record<string, boolean>
  selectedHelpContent: Record<string, boolean>
}

const initialState: IHelpState = {
  selectedLanguage: {
    value: '33e5850a-473f-11e8-9661-7bf55cea83d1',
    label: 'English',
    isoCode: 'en',
  },
  selectedTab: 0,
  selectedCategories: {},
  selectedHelpContent: {},
  translations: {},
}

const helpSlice = createSlice({
  name: 'helpSlice',
  initialState: initialState,
  reducers: {
    updateHelpTranslations(state, action: PayloadAction<Record<string, string>>) {
      state.translations = action.payload
    },
    setSelectedHelpLanguage(
      state,
      action: PayloadAction<IHelpState['selectedLanguage']>,
    ) {
      state.selectedLanguage = action.payload
    },
    setSelectedTab(state, action: PayloadAction<number>) {
      state.selectedTab = action.payload
    },
    toggleSelectedCategories(state, action: PayloadAction<string>) {
      state.selectedCategories[action.payload] = !state.selectedCategories[action.payload]
    },
    toggleSelectedHelpContent(state, action: PayloadAction<string>) {
      state.selectedHelpContent[action.payload] =
        !state.selectedHelpContent[action.payload]
    },
  },
})

export const {
  updateHelpTranslations,
  setSelectedHelpLanguage,
  setSelectedTab,
  toggleSelectedCategories,
  toggleSelectedHelpContent,
} = helpSlice.actions

export const selectHelpLanguageInfo = (state: RootState) => state.help
export const getSelectedHelpLanguage = (state: RootState) => state.help.selectedLanguage
export const selectSelectedTab = (state: RootState) => state.help.selectedTab
export const selectSelectedCategories = (state: RootState) =>
  state.help.selectedCategories
export const selectSelectedHelpContent = (state: RootState) =>
  state.help.selectedHelpContent

export default helpSlice.reducer
