import { Box, BoxProps, Button, ButtonBase, Switch, styled } from '@mui/material'

type StyledDashboardWrapperProps = BoxProps & {
  isLastItem: boolean
}

export const StyledDashboardMobileWrapper = styled(Box)<StyledDashboardWrapperProps>(
  ({ theme: { palette }, isLastItem }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    padding: '0 16px',

    '.swiper-pagination': {
      top: isLastItem ? '30.5%' : 152,
    },

    '@media (min-width: 1200px)': {
      padding: '0',
    },

    '.mobile-swiper': {
      width: '100%',
      paddingBottom: '16px',
    },

    '.swiper-pagination-bullet': {
      background: palette.grey[500],
      width: 6,
      height: 6,
    },
  }),
)

export const StyledDashboardDesktopWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
}))

export const StyledCardsDesktopWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 20,
  justifyContent: 'space-between',
  paddingInline: 24,
}))

export const StyledCardWrapper = styled(Box)(({ theme: { palette } }) => ({
  background: palette.grey[100],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  borderRadius: 8,
  padding: '12px 16px',
  width: 'auto !important',

  '@media (min-width: 1200px)': {
    width: '200px !important',
  },
}))

export const StyledCardActionWrapper = styled(StyledCardWrapper)(() => ({
  gap: 0,
  padding: '0',
  width: '100% !important',
  '@media (min-width: 1200px)': {
    width: '270px !important',
  },
}))

export const StyledCard = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 6,

  h1: {
    color: palette.grey[800],
  },

  p: {
    color: palette.grey[700],
  },

  hr: {
    width: '100%',
    marginTop: 28,
    borderBottomWidth: '2px',
  },
  img: {
    width: 60,
    height: 60,
  },
  [breakpoints.up('md')]: {
    hr: {
      marginTop: 20,
    },
  },
}))

export const StyledCardAction = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',
  paddingBlock: 12,
  alignItems: 'center',

  background: '#E2EAF0',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  position: 'relative',

  img: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 12,
    left: 16,
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 16,

    gap: 6,
    h1: {
      color: palette.grey[800],
    },

    p: {
      color: palette.grey[700],
      textTransform: 'uppercase',
    },
  },

  '@media (min-width: 1200px)': {
    padding: '12px 0 0',
  },
}))

export const StyledCardInfo = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,

  div: {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      color: palette.grey[700],
      fontSize: 14,
    },
    span: {
      color: palette.grey[800],
      fontSize: 14,
    },

    [breakpoints.down('md')]: {
      'p,span': {
        fontSize: 16,
      },
    },
  },
}))

export const StyledCardActionInfo = styled(StyledCardInfo)(() => ({
  marginBlock: 18,
  div: {
    paddingInline: 16,
  },
}))

// Mobile

export const StyledFilterBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginTop: 20,
}))

export const StyledBackgroundFilter = styled(Box)(({ theme: { palette } }) => ({
  background: palette.grey[100],
  width: '100%',
  padding: '49.5px 0 16px',
  marginTop: '-19.25px',

  // this do reference for component StyledFilterBoxContent

  '> div': {
    width: '270px',
  },
}))

export const StyledFilterBoxContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  margin: '0 auto',
  gap: '30px 40px',
  alignItems: 'center',
  '>div': {
    display: 'flex',
    alignItems: 'center',
    color: palette.grey[700],
  },
}))

type ButtonFilter = {
  open: boolean
}

export const StyledButtonFilter = styled(Button)<ButtonFilter>(
  ({ theme: { palette }, open }) => ({
    display: 'flex',
    gap: 12,
    background: open ? palette.grey[600] : palette.grey[50],
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: 4,
    color: open ? palette.common.white : palette.grey[800],
    '&:hover': {
      background: open ? palette.grey[600] : palette.grey[50],
      borderColor: palette.grey[600],
      outline: 'none',
    },
  }),
)

export const StyledButtonSwitchBoxWrapper = styled(Box)(({ theme: { palette } }) => ({
  marginTop: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100% !important',
  gap: 10,

  p: {
    color: palette.grey[700],
  },
}))

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 19,
  padding: 0,
  display: 'flex',
  borderRadius: 20,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(17px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(17px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.grey[600],
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 15,
    height: 15,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

export const StyledCardBoxWrapper = styled(Box)(() => ({
  margin: '20px 16px',
  padding: '16px 10px',
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledTitleCardBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  marginBottom: 24,
  img: {
    width: 70,
    height: 70,
  },
  p: {
    color: palette.grey[800],
    textAlign: 'center',
    width: '20ch',
  },
}))

export const StyledCardBoxContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  alignItems: 'center',
  marginTop: 14,

  '> p': {
    textTransform: 'uppercase',
    color: palette.grey[800],
    fontSize: 16,
    letterSpacing: '.15rem',
  },

  '@media (min-width: 1200px)': {
    p: {
      fontSize: 18,
    },
  },
}))

export const CardCarCertifieldWrapper = styled(Box)(({ theme: { palette } }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  border: `1px solid ${palette.grey[400]}`,
  padding: '8px 16px 8px 8px',
  alignItems: 'center',
  width: '100%',
  gap: 5,

  img: {
    width: 109,
    height: 35,
    objectFit: 'cover',
  },
}))

export const CardCarCertifieldContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',

  p: {
    textTransform: 'uppercase',
    color: palette.grey[700],

    '&:nth-of-type(2)': {
      color: palette.grey[600],
    },
  },
}))

export const CardCarCertifieldCount = styled(Box)(({ theme: { palette } }) => ({
  background: palette.grey[100],
  width: 46,
  height: 46,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  button: {
    color: palette.grey[700],
    fontSize: 18,
    fontFamily: 'JLR Emeric Regular,JLR Emeric ExtraLight',
  },
}))

// Desktop

export const StyledCardCarsWrapper = styled(Box)(() => ({
  paddingInline: 24,
}))

export const StyledFilterBoxDesktopWrapper = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: 20,
  '& > div:last-of-type': {
    alignSelf: 'end',
  },
  '> div': {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    color: palette.grey[700],
  },
}))

export const StyledSwitchBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginInline: '20px 5px',
  gap: 10,
}))

export const StyledCardCarrsBoxWrapper = styled(Box)(() => ({
  padding: '24px 20px',
  display: 'flex',
  borderRadius: 10,
  marginBlock: '20px 40px',
}))

export const StyledColumnIconsBox = styled(Box)(() => ({
  '> div': {
    '&:nth-of-type(1)': {
      height: '193px',
      display: 'flex',
      alignItems: 'center',
      padding: '14px 52px 14px 14px',
    },
    '&:nth-of-type(2)': {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      padding: '14px',
      width: '100%',
    },
  },
  zIndex: 1,
}))

export const StyledCardCarsContent = styled(Box)(() => ({
  position: 'relative',
  ':after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: -115,
    width: '1112px',
    height: '80PX',
    background: '#F6F8FA',
    borderRadius: '5px',
  },
  '.card-cars-swiper': {
    width: '997px',
  },
  '.swiper-button-next': {
    transform: 'scale(.5)',
    color: '#1E1E1E',
    right: '5px',
    top: '110px',
  },
  '.swiper-button-prev': {
    transform: 'scale(.5)',
    color: '#1E1E1E',
    left: '5px',
    top: '110px',
  },

  '.swiper-button-disabled': {
    color: 'transparent',
  },
}))

export const StyledCarInfoBox = styled(Box)(() => ({
  height: '193px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
}))

export const StyledCarInfoBrandBox = styled(Box)(() => ({
  height: '41px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  p: {
    textTransform: 'uppercase',
  },
}))

export const StyledCarInfoContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1.5,

  img: {
    width: '230px',
    height: '80px',
    objectFit: 'cover',
  },

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    textTransform: 'uppercase',

    p: {
      fontSize: 14,
      '&:nth-of-type(2)': {
        color: palette.grey[600],
      },
    },
  },
}))

export const StyledCarCount = styled(ButtonBase)(({ theme: { palette } }) => ({
  height: '80px',
  width: '100%',
  fontFamily: 'JLR Emeric Regular,JLR Emeric ExtraLight',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: palette.grey[800],
  fontWeight: 'bold',
  fontSize: 40,
}))
