import { ButtonBase, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Swiper } from 'swiper/react'

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  maxWidth: '100%',
  backgroundImage:
    'linear-gradient( rgba(245,248,251,1) 0%, rgba(245,248,251,1) 35px, rgba(231,239,244,1) 35px, rgba(231,239,244,1) 100%)',
  backgroundSize: '100% 146px',
  backgroundRepeat: 'no-repeat',
  overflow: 'visible',
  overflowX: 'clip',

  [theme.breakpoints.down('md')]: {
    '.swiper-button-next, .swiper-button-prev': {
      top: '46.5%',
    },
  },
  [theme.breakpoints.up('lg')]: {
    backgroundPosition: '0 7px',
    '.swiper-wrapper': {
      paddingInline: '40px',
      marginTop: '7px',
    },
    '.swiper-button-next, .swiper-button-prev': {
      top: '42%',
    },
    '.swiper-pagination': {
      display: 'none',
    },
  },
  '.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction':
    {
      bottom: '25px',
    },

  '.swiper-pagination-bullet': {
    width: '12px',
    height: '12px',
    position: 'relative',
    top: '-40px',
  },

  '.swiper-button-next:after, .swiper-button-prev:after': {
    fontSize: '19px',
    color: '#1E1E1E',
    bottom: '10px',
  },

  '.swiper-button-disabled': {
    opacity: '0',
  },
  '.swiper-notification': {
    display: 'none',
  },
}))

export const StyledButtonBase = styled(ButtonBase)(({ theme, ...props }) => {
  // ToDo: make this dynamic based on api data in near future
  const getColor = () => {
    switch (props['aria-label']) {
      case 'EVIP':
        return 'rgba(45, 125, 255, 0.5)'
      case 'EVCP':
        return 'rgba(0, 128, 0, 0.5)'
      case 'EVAP':
        return 'rgba(255, 165, 0, 0.5)'
      case 'EVSAP':
        return 'rgba(255, 0, 0, 0.5)'
      case 'Live Battery Repairs':
        return 'rgba(75, 0, 130, 0.5)'
      default:
        return 'rgba(0, 0, 0, 0.5)'
    }
  }

  return {
    border: '2px solid transparent',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      'div:first-of-type': {
        paddingLeft: '30px',
      },
      '::before': {
        content: '""',
        position: 'absolute',
        bottom: '-50px',
        left: 'calc(50% - 15px)',
        width: '30px',
        height: '30px',
        transform: 'rotate(45deg)',
        border: '1px solid #CDD7D9',
        borderRight: 'none',
        borderBottom: 'none',
        borderTopLeftRadius: '4px',
        backgroundColor: '#EBF1F6',
        display: props['aria-checked'] ? 'block' : 'none',
      },
    },

    '&:focus-visible': {
      borderRadius: '6px',
      border: `2px dashed ${getColor()}`,
    },
  }
})

export const StyledMobileSelectorLabel = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
})
