import { yupResolver } from '@hookform/resolvers/yup'
import { Typography, useMediaQuery, useTheme, Box, Stack } from '@mui/material'
import InputTextField from 'components/common/InputTextField'
import Button from 'components/common/Button'
import FormValidationMessage from 'components/common/FormValidationMessage'
import { FC, useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store'
import { useChangePasswordMutation, useUpdatePasswordMutation } from 'store/api'
import { getSelectedLanguage } from 'store/slices'
import * as yup from 'yup'

interface IResetPasswordFormProps {
  isUpdatePassword?: boolean
}

type IFormInput = {
  pin?: string
  confirmationCode?: string
  newPassword: string
  confirmPassword: string
}

const ResetPasswordForm: FC<IResetPasswordFormProps> = ({ isUpdatePassword }) => {
  const intl = useIntl()

  const defaultValidation = (id: string) => {
    return yup.string().required(intl.formatMessage({ id }))
  }

  const baseSchema = yup.object().shape({
    newPassword: defaultValidation('app.validations.passwordRequired'),
    confirmPassword: defaultValidation(
      'resetPassword.confirmPassword.validationMessage.required',
    ).oneOf(
      [yup.ref('newPassword'), null],
      intl.formatMessage({
        id: 'resetPassword.confirmPassword.validationMessage.mustMatch',
      }),
    ),
  })

  const resetPasswordSchema = baseSchema.shape({
    pin: defaultValidation('app.validations.userPinRequired'),
    confirmationCode: defaultValidation(
      'resetPassword.confirmationCode.validationMessage.required',
    ),
  })

  const {
    control,
    formState: { errors },
    reset,
    trigger: revalidateForm,
    handleSubmit,
  } = useForm<IFormInput>({
    resolver: yupResolver(isUpdatePassword ? baseSchema : resetPasswordSchema),
  })

  const theme = useTheme()
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'))
  const navigate = useNavigate()

  const [changePassword, { isLoading, data, isSuccess }] = useChangePasswordMutation()
  const [
    resetPassword,
    { isLoading: isLoadingUpdate, data: updateData, isSuccess: isSuccessUpdate },
  ] = useUpdatePasswordMutation()
  const [message, setMessage] = useState({
    text: '',
    isVisible: false,
  })
  const { value: languageId } = useAppSelector(getSelectedLanguage)

  const currentLanguage = useRef(languageId)
  useEffect(() => {
    if (languageId !== currentLanguage.current) {
      currentLanguage.current = languageId
      if (Object.keys(errors).length > 0) {
        revalidateForm()
      }
    }
  }, [languageId])

  useEffect(() => {
    if (data?.Errors) {
      setMessage({ text: data.Errors[0].Description!, isVisible: true })
    } else if (updateData?.Errors?.length! > 0) {
      setMessage({ text: updateData?.Errors?.[0].Description!, isVisible: true })
    } else {
      setMessage({ text: '', isVisible: false })
    }

    if (
      (isSuccess && !data?.Errors) ||
      (isSuccessUpdate && updateData?.Errors?.length === 0)
    ) {
      navigate('/logout', {
        state: {
          fromResetPassword: true,
        },
      })
    }
  }, [data, isSuccess, isSuccessUpdate])

  const handleCancel = () => {
    reset()
    navigate('/login')
  }

  const onFormSubmit: SubmitHandler<IFormInput> = formData => {
    const { pin, newPassword: password, confirmationCode, confirmPassword } = formData

    if (isUpdatePassword) {
      resetPassword({
        newPassword: password,
        confirmedNewPassword: confirmPassword,
        languageId,
      })
    } else {
      changePassword({
        username: pin!,
        confirmationCode: confirmationCode!,
        password,
        languageId,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={2}>
        <Typography
          textTransform={isLargeUp ? 'uppercase' : 'none'}
          textAlign="center"
          sx={{
            fontSize: isLargeUp ? '18px' : '16px',
            gridColumn: '1',
            gridRow: '1',
            backgroundColor: !isLargeUp ? 'common.white' : 'transparent',
            p: 1,
          }}
          color="black"
        >
          <FormattedMessage
            id={`${isUpdatePassword ? 'update' : 'reset'}Password.title`}
          />
        </Typography>

        <Stack spacing={1}>
          {!isUpdatePassword ? (
            <>
              <Box>
                <Controller
                  name="pin"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <InputTextField
                      {...field}
                      label={<FormattedMessage id="app.labels.userPIN" />}
                      isDark={!isLargeUp}
                    />
                  )}
                />
                <FormValidationMessage
                  message={errors.pin?.message ?? ''}
                  isOpen={!!errors.pin?.message}
                  boxProps={{ mt: { lg: 1 / 2, xs: '1px' } }}
                  mode={isLargeUp ? 'light' : 'dark'}
                />
              </Box>
              <Box>
                <Controller
                  name="confirmationCode"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputTextField
                      {...field}
                      label={
                        <FormattedMessage id="resetPassword.confirmationCode.label" />
                      }
                      isDark={!isLargeUp}
                    />
                  )}
                />
                <FormValidationMessage
                  message={errors.confirmationCode?.message ?? ''}
                  isOpen={!!errors.confirmationCode?.message}
                  boxProps={{ mt: { lg: 1 / 2, xs: '1px' } }}
                  mode={isLargeUp ? 'light' : 'dark'}
                />
              </Box>
            </>
          ) : null}
          <Box>
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputTextField
                  {...field}
                  type="password"
                  label={<FormattedMessage id="resetPassword.newPassword.label" />}
                  isDark={!isLargeUp}
                />
              )}
            />
            <FormValidationMessage
              message={errors.newPassword?.message ?? ''}
              isOpen={!!errors.newPassword?.message}
              boxProps={{ mt: { lg: 1 / 2, xs: '1px' } }}
              mode={isLargeUp ? 'light' : 'dark'}
            />
          </Box>
          <Box>
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputTextField
                  {...field}
                  type="password"
                  label={<FormattedMessage id="resetPassword.confirmPassword.label" />}
                  isDark={!isLargeUp}
                />
              )}
            />
            <FormValidationMessage
              message={errors.confirmPassword?.message ?? ''}
              isOpen={!!errors.confirmPassword?.message}
              boxProps={{ mt: { lg: 1 / 2, xs: '1px' } }}
              mode={isLargeUp ? 'light' : 'dark'}
            />
          </Box>

          <FormValidationMessage
            message={message.text}
            isOpen={Boolean(message.isVisible)}
            mode={isLargeUp ? 'light' : 'dark'}
          />

          <Stack direction="row" gap={2}>
            {!isUpdatePassword ? (
              <Button
                variant="outlined"
                fullWidth
                type="button"
                onClick={() => handleCancel()}
                sx={{
                  color: { xs: 'white', md: 'black' },
                  borderColor: { xs: 'white', md: '#8C8C8C' },
                }}
              >
                <FormattedMessage id="app.buttons.cancel" />
              </Button>
            ) : null}
            <Button fullWidth type="submit" isLoading={isLoading || isLoadingUpdate}>
              <FormattedMessage id="app.buttons.confirm" />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  )
}

export default ResetPasswordForm
