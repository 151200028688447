import { IEVTrainingLevel } from 'interfaces'
import { useIntl } from 'react-intl'

export default function useGetEVTrainingLevelLabel() {
  const intl = useIntl()

  function getLevelAchievementLabel(
    isCertifiedLevel: boolean,
    isWorkingLevel: boolean,
    isTargetLevel: boolean,
  ): string {
    if (isCertifiedLevel && !isTargetLevel) {
      // return 'Certified Level'
      return intl.formatMessage({ id: 'applicationTerm.certifiedLevel' })
    }

    if (isCertifiedLevel && isTargetLevel) {
      // return 'Certified and Target Level'
      return intl.formatMessage({ id: 'applicationTerm.certifiedAndTargetLevel' })
    }

    if (isWorkingLevel && !isTargetLevel) {
      // return 'Working Level'
      return intl.formatMessage({ id: 'applicationTerm.workingLevel' })
    }

    if (isWorkingLevel && isTargetLevel) {
      // return 'Working and Target Level'
      return intl.formatMessage({ id: 'applicationTerm.workingAndTargetLevel' })
    }

    if (!isWorkingLevel && isTargetLevel) {
      // return 'Target Level'
      return intl.formatMessage({ id: 'applicationTerm.targetLevel' })
    }

    return ''
  }

  function formatLevelAchievementLabel(levelLabel: string | null, name: string) {
    if (!levelLabel) return name
    return `${levelLabel} : ${name}`
  }

  function getEVTrainingLevelLabel(
    selectedLevelIndex: number,
    evTrainingLevels?: IEVTrainingLevel[],
  ) {
    if (evTrainingLevels) {
      const { isCertifiedLevel, isWorkingLevel, isTargetLevel, name } =
        evTrainingLevels[selectedLevelIndex]
      const rawLevelLabel = getLevelAchievementLabel(
        isCertifiedLevel,
        isWorkingLevel,
        isTargetLevel,
      )
      return formatLevelAchievementLabel(rawLevelLabel, name)
    }

    return ''
  }

  return getEVTrainingLevelLabel
}
