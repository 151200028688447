import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Box,
  Collapse,
  Fade,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import InputTextField from 'components/common/InputTextField'
import Button from 'components/common/Button'
import FormValidationMessage from 'components/common/FormValidationMessage'
import { FormattedMessage, useIntl } from 'react-intl'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useForgotPasswordMutation, useLoginUserMutation } from 'store/api'
import { useAppSelector } from 'store'
import { getSelectedLanguage } from 'store/slices'
import { useState, useEffect, useRef } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

type UIMode = 'LOGIN' | 'FORGOT' | 'RESET'

type IFormInput = {
  pin: string
  password: string
  uiMode: UIMode
}

interface LocationProps {
  fromResetPassword: boolean
}

export default function LoginForm() {
  const intl = useIntl()
  const theme = useTheme()
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'))
  const location = useLocation()
  const navigate = useNavigate()

  const state = location.state as LocationProps
  const fromResetPassword = state?.fromResetPassword! ?? false

  const defaultValidation = (id: string) => {
    return yup.string().required(intl.formatMessage({ id }))
  }

  const schema = yup.object().shape({
    pin: defaultValidation('app.validations.userPinRequired'),
    password: yup.string().when('uiMode', {
      is: (uiMode: UIMode) => uiMode === 'LOGIN',
      then: defaultValidation('app.validations.passwordRequired'),
    }),
  })

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    trigger: revalidateForm,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      pin: '',
      password: '',
      uiMode: 'LOGIN',
    },
  })

  const uiMode = watch('uiMode')

  const [loginUser, { isLoading: isLoginLoading, data: loginData }] =
    useLoginUserMutation()
  const [
    forgotPassword,
    { isLoading: isFPLoading, data: FPData, isSuccess: isFPSuccess },
  ] = useForgotPasswordMutation()

  const { value: languageId } = useAppSelector(getSelectedLanguage)

  const [message, setMessage] = useState({
    text: '',
    isVisible: false,
  })

  const resetMessage = () => {
    if (message.isVisible) {
      setMessage({ text: '', isVisible: false })
    }
  }

  const currentLanguage = useRef(languageId)
  useEffect(() => {
    if (languageId !== currentLanguage.current) {
      currentLanguage.current = languageId
      if (Object.keys(errors).length > 0) {
        revalidateForm()
      }
    }
  }, [languageId])

  useEffect(() => {
    if (loginData?.Errors || FPData?.Errors) {
      setMessage({
        text: loginData?.Errors?.[0].Description! || FPData?.Errors?.[0].Description!,
        isVisible: true,
      })
    }

    resetMessage()
    if (isFPSuccess && !FPData?.Errors) {
      setValue('uiMode', 'RESET')
    }
  }, [loginData, FPData, isFPSuccess, uiMode])

  const onFormSubmit: SubmitHandler<IFormInput> = formData => {
    const { pin: username, password } = formData

    if (uiMode === 'LOGIN') {
      loginUser({ username, password, languageId })
    }

    if (uiMode === 'FORGOT') {
      forgotPassword({ username, languageId })
    }
  }

  if (loginData?.Data) {
    if (loginData?.Data.IsPasswordExpired) {
      return <Navigate to="/update-password" replace />
    }
    const searchParam = location.search

    if (loginData?.Data?.Credentials?.AuthenticationToken) {
      const redirectUrl = searchParam.split('?redirect=')[1]
      return <Navigate to={`${redirectUrl ? redirectUrl : '/'}`} />
    }
  }

  return (
    <Stack spacing={2}>
      {isLargeUp ? (
        <Box display="grid">
          <Fade in={uiMode === 'LOGIN'} timeout={500}>
            <Box>
              <Typography
                textTransform="uppercase"
                textAlign="center"
                sx={{ fontSize: '18px', gridColumn: '1', gridRow: '1' }}
              >
                <FormattedMessage id="appNavigation.menuItem.excellence" />
              </Typography>
              <Typography
                textTransform="uppercase"
                textAlign="center"
                sx={{ fontSize: '18px', gridColumn: '1', gridRow: '1' }}
              >
                <FormattedMessage id="app.buttons.logIn" />
              </Typography>
            </Box>
          </Fade>
          <Fade in={uiMode === 'FORGOT' || uiMode === 'RESET'} timeout={500}>
            <Typography
              textTransform="uppercase"
              textAlign="center"
              sx={{ fontSize: '18px', gridColumn: '1', gridRow: '1' }}
            >
              <FormattedMessage id="login.forgotPassword.title" />
            </Typography>
          </Fade>
        </Box>
      ) : null}
      {fromResetPassword && uiMode === 'LOGIN' ? (
        <Alert severity="success">
          <FormattedMessage id="login.resetPassword.success" />
        </Alert>
      ) : null}

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Collapse in={uiMode === 'LOGIN' || uiMode === 'FORGOT'}>
          <Stack spacing={1}>
            <Collapse in={uiMode === 'LOGIN' || uiMode === 'FORGOT'}>
              <Controller
                name="pin"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    label={<FormattedMessage id="app.labels.userPIN" />}
                    onFocus={() => resetMessage()}
                    isDark={!isLargeUp}
                  />
                )}
                defaultValue=""
              />

              <FormValidationMessage
                message={errors.pin?.message ?? ''}
                isOpen={Boolean(errors.pin?.message)}
                boxProps={{ mt: { lg: 1 / 2, xs: '1px' } }}
                mode={isLargeUp ? 'light' : 'dark'}
              />
            </Collapse>

            <Collapse in={uiMode === 'LOGIN'}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    label={<FormattedMessage id="login.password" />}
                    type="password"
                    onFocus={() => resetMessage()}
                    isDark={!isLargeUp}
                  />
                )}
                defaultValue=""
              />
              <FormValidationMessage
                message={errors.password?.message ?? ''}
                isOpen={Boolean(errors.password?.message)}
                boxProps={{ mt: { lg: 1 / 2, xs: '1px' } }}
                mode={isLargeUp ? 'light' : 'dark'}
              />
            </Collapse>
            <FormValidationMessage
              message={message.text}
              isOpen={Boolean(message.isVisible)}
              mode={isLargeUp ? 'light' : 'dark'}
            />

            <Box display="grid" gridTemplateColumns="1" gridTemplateRows="1">
              <Fade in={uiMode === 'LOGIN'} timeout={500}>
                <Box gridColumn="1" gridRow="1">
                  <Button isLoading={isLoginLoading} fullWidth type="submit">
                    <FormattedMessage id="app.buttons.logIn" />
                  </Button>
                </Box>
              </Fade>

              <Fade in={uiMode === 'FORGOT'} timeout={500}>
                <Box gridColumn="1" gridRow="1">
                  <Stack spacing={1} direction="row">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => setValue('uiMode', 'LOGIN')}
                      sx={{
                        color: { xs: 'white', md: 'black' },
                        borderColor: { xs: 'white', md: '#8C8C8C' },
                      }}
                    >
                      <FormattedMessage id="app.buttons.cancel" />
                    </Button>
                    <Button isLoading={isFPLoading} fullWidth type="submit">
                      <FormattedMessage id="app.buttons.confirm" />
                    </Button>
                  </Stack>
                </Box>
              </Fade>
            </Box>

            <Collapse in={uiMode === 'LOGIN'}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={() => setValue('uiMode', 'FORGOT')}
                  sx={{
                    color: { xs: 'white', md: 'rgb(82,82,82)' },
                    borderColor: { xs: 'white', md: '#8C8C8C' },
                  }}
                >
                  <FormattedMessage id="login.forgotYourPassword" />
                </Button>
              </Box>
            </Collapse>
          </Stack>
        </Collapse>

        <Collapse in={uiMode === 'RESET'}>
          <Typography align="center">
            <FormattedMessage id="login.forgotPassword.resetMessage" />
            <Button
              sx={{ mt: '-4px' }}
              variant="text"
              onClick={() => navigate('/change-forgotten-password')}
            >
              <FormattedMessage id="resetPassword.title" />
            </Button>
          </Typography>
        </Collapse>
      </form>
    </Stack>
  )
}
