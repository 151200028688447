import { Box } from '@mui/material'
import calendarIcon from 'assets/svgs/book-staff/calendar_icon.svg'
import costIcon from 'assets/svgs/book-staff/cost_icon.svg'
import locationIcon from 'assets/svgs/book-staff/location_icon.svg'
import spacesIcon from 'assets/svgs/book-staff/spaces_icon.svg'
import timeIcon from 'assets/svgs/book-staff/time_icon.svg'
import { FC } from 'react'
import { formatDateTime } from 'utils/formatDate'
import { StyledBoxContent, StyledBoxWrapper } from './styles'
import { FormattedMessage } from 'react-intl'
import { ISession } from 'interfaces'

const RequestBookingApprovalHeader: FC<ISession> = sessionInfo => {
  return (
    <StyledBoxWrapper>
      <StyledBoxContent>
        <Box>
          <img src={locationIcon} alt="" />
          <FormattedMessage id="app.labels.location" />
        </Box>
        <span>{sessionInfo.location}</span>
      </StyledBoxContent>
      <StyledBoxContent>
        <Box>
          <img src={spacesIcon} alt="" />
          <FormattedMessage id="bookingStaff.availableSessions.column.availableSpaces" />
        </Box>
        <span>{sessionInfo.availableSpaces}</span>
      </StyledBoxContent>
      <StyledBoxContent>
        <Box>
          <img src={costIcon} alt="" />
          <FormattedMessage id="bookingStaff.requestBookingApproval.header.labels.totalCost" />
        </Box>
        <span>
          {sessionInfo.totalCost} {sessionInfo.currency}
        </span>
      </StyledBoxContent>
      <StyledBoxContent>
        <Box>
          <img src={calendarIcon} alt="" />
          <FormattedMessage id="app.labels.courseDuration" />
        </Box>
        <span>
          {sessionInfo.duration} {sessionInfo.durationType?.toLowerCase()}
        </span>
      </StyledBoxContent>
      <StyledBoxContent>
        <Box>
          <img src={timeIcon} alt="" />
          <FormattedMessage id="bookingStaff.requestBookingApproval.header.labels.startTime" />
        </Box>
        <span>{formatDateTime(sessionInfo.startDate, sessionInfo.timezone)}</span>
      </StyledBoxContent>
      <StyledBoxContent>
        <Box>
          <img src={timeIcon} alt="" />
          <FormattedMessage id="bookingStaff.requestBookingApproval.header.labels.endTime" />
        </Box>
        <span>{formatDateTime(sessionInfo.endDate, sessionInfo.timezone)}</span>
      </StyledBoxContent>
    </StyledBoxWrapper>
  )
}

export default RequestBookingApprovalHeader
