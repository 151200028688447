import { Paper } from '@mui/material'

type Props = {
  children: React.ReactNode
}

export default function FrontFacePaper({ children }: Props) {
  return (
    <Paper
      elevation={0}
      sx={{
        transition: 'all 250ms',
        p: { xs: 2, sm: 4 },
        height: '100%',
        width: '100%',
        backgroundColor: '#EBF1F6',
        borderRadius: 2,
        ':hover': {
          backgroundColor: 'white',
        },
      }}
    >
      {children}
    </Paper>
  )
}
