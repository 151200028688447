import { IUserAppInfoResponse } from 'interfaces'
import { setSelectedLanguage, setUser } from 'store/slices'
import { evAPI } from './api'

export const userApi = evAPI.injectEndpoints({
  endpoints: builder => ({
    getMe: builder.query<IUserAppInfoResponse, null>({
      query: () => 'Users/GetCurrentUserDetailsQuery',

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data, errors },
          } = await queryFulfilled

          if (data) {
            dispatch(setUser(data))
            dispatch(
              setSelectedLanguage({
                value: data.languageUUID ?? '',
                label: '',
                isoCode: data.languageIsoCode?.toLowerCase() ?? '',
              }),
            )
          }
          if (errors?.length! > 0) {
            const errorInfo = errors![0]
            if (
              errorInfo.code === 'UNKNOWN_CODE' &&
              errorInfo.message === 'Unable to retrieve user'
            ) {
              window.location.href = '/update-password'
            }
          }
        } catch (error) {
          console.error('GetMe error --', error)
        }
      },
      providesTags: ['User'],
    }),
  }),
})

export const { useGetMeQuery } = userApi
