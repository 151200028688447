import { Close as CloseIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Dialog from 'components/common/Dialog'
import AvailableSessionsTable from 'components/EVLevel/ModulesList/BookSession/AvailableSessionsTable'
import BookingConfirmation from 'components/EVLevel/ModulesList/BookSession/BookingConfirmation'
import RequestBookingApproval from 'components/EVLevel/ModulesList/BookSession/RequestBookingApproval'
import CourseCard from 'components/EVLevel/ModulesList/Course/CourseCard'
import CourseDescription from 'components/EVLevel/ModulesList/Course/CourseDescription'
import CourseDuration from 'components/EVLevel/ModulesList/Course/CourseDuration'
import CourseHeader from 'components/EVLevel/ModulesList/Course/CourseHeader'
import { CourseInfo } from 'interfaces'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { closeAllBookingModals, useAppDispatch, useAppSelector } from 'store'
import { useGetClassroomDetailsForBookingQuery } from 'store/api'
import { StyledTitle } from 'components/EVLevel/ModulesList/BookSession/AvailableSessionsTable/styles'

type Props = {
  onClosePortalModal: () => void
  isManagerView: boolean
}
const BookSessionModal = ({ onClosePortalModal, isManagerView }: Props) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.only('xs'))
  const { user: userUUIDParam } = useParams()
  const dispatch = useAppDispatch()
  const isAboveMedium = useMediaQuery('@media (max-width: 940px)')
  const {
    selectedCourse: courseUUID,
    bookingModalOpen,
    currentStep,
    currentBookingSessionId,
    submitMessage,
  } = useAppSelector(state => state.booking)

  const {
    data: classroomData,
    isFetching,
    isLoading,
  } = useGetClassroomDetailsForBookingQuery(
    {
      courseUUID: courseUUID as string,
      userUUID: userUUIDParam as string,
    },
    {
      skip: !courseUUID || !bookingModalOpen,
      refetchOnMountOrArgChange: true,
    },
  )

  const course = classroomData?.course as CourseInfo

  useEffect(() => {
    if (currentStep === 4) {
      dispatch(closeAllBookingModals())
    }
  }, [currentStep])

  const { duration, durationType } = course || { duration: 0, durationType: '' }
  const currentSessionInfo = classroomData?.availableSessions?.find(bookingS => {
    return bookingS.id === currentBookingSessionId
  })

  const renderModalContent = () => (
    <DialogContent sx={{ width: '100%', padding: 0 }}>
      <Box width="100%" padding="0">
        <CourseCard
          onToggleOpen={() => undefined}
          open={true}
          key={1}
          status={undefined}
          isAvailable={true}
          isLocked={false}
          displayType="moduleDecendent"
          launchPath={course?.launchPath}
          onClosePortalModal={onClosePortalModal}
          isManagerView={isManagerView}
          header={
            currentStep === 2 && submitMessage ? (
              <Typography color={theme.palette.grey[800]}>
                {submitMessage.title}
              </Typography>
            ) : (
              <CourseHeader
                courseType={course.type}
                primaryText={course.title}
                status={undefined}
                completedDate={undefined}
                isAvailable={true}
                isLocked={false}
                courseCode={course.code}
                isBookingSession
              />
            )
          }
        >
          {currentStep === 0 && classroomData && (
            <Box p="16px 24px" width="100%" bgcolor="#F6F8FA">
              <CourseDuration {...{ duration, durationType }} />
              <CourseDescription isBookingSession notPadding>
                {course.description}
              </CourseDescription>

              {classroomData.availableSessions?.length > 0 ? (
                isAboveMedium ? (
                  <>
                    <StyledTitle>
                      <FormattedMessage id="bookingStaff.availableSessions.title" />
                    </StyledTitle>
                    {classroomData.availableSessions.map(session => (
                      <AvailableSessionsTable
                        key={session.id}
                        {...{
                          bookingSession: session,
                          isAvailableTable: true,
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <AvailableSessionsTable
                    {...{ sessions: classroomData.availableSessions }}
                  />
                )
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Typography variant="body1" color="text.secondary">
                    <FormattedMessage id="bookingStaff.noSessionsAvailable" />
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {currentStep === 1 && classroomData && (
            <RequestBookingApproval
              {...{
                allowsWaitlist: !!currentSessionInfo?.allowsWaitlist,
              }}
            />
          )}
          {currentStep === 2 && <BookingConfirmation />}
        </CourseCard>
      </Box>
    </DialogContent>
  )

  return (
    <Dialog
      open={bookingModalOpen}
      onClose={() => dispatch(closeAllBookingModals())}
      maxWidth="md"
      fullScreen={isSmall}
    >
      {currentStep === 0 && (isFetching || isLoading) ? (
        <DialogContent sx={{ backgroundColor: '#F6F8FA' }}>
          <CircularProgress size={20} />
        </DialogContent>
      ) : (
        <>
          <Button
            variant="text"
            sx={{
              position: 'absolute',
              right: 15,
              top: 20,
              padding: 0,
              margin: 0,
              zIndex: 100,
              minWidth: 'auto',
            }}
            onClick={() => dispatch(closeAllBookingModals())}
          >
            <CloseIcon />
          </Button>

          {course && renderModalContent()}

          {currentStep < 1 && (
            <>
              <Divider />

              <Box
                display="flex"
                gap={1}
                alignItems="center"
                justifyContent="flex-end"
                bgcolor="#F6F8FA"
                sx={{
                  padding: '24px 24px 24px',
                }}
              >
                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  onClick={() => dispatch(closeAllBookingModals())}
                >
                  <FormattedMessage id="app.buttons.close" />
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Dialog>
  )
}

export default BookSessionModal
