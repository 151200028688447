import { Button, TableCell, TableRow, Typography, styled } from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme: { spacing } }) => ({
  fontSize: spacing(1.75),
  padding: 0,
  paddingLeft: spacing(1.25),
  p: {
    fontSize: spacing(1.75),
  },
  div: {
    button: {
      padding: 0,
    },
  },

  ':nth-of-type(5),:nth-of-type(6)': {
    p: {
      marginLeft: 0,
    },
    '@media (min-width: 1160px)': {
      p: {
        marginLeft: spacing(2.25),
      },
    },
  },
}))

export const StyledTableRow = styled(TableRow)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  height: '60px',
}))

export const StyledButtonDetails = styled(Button)(({ theme: { spacing } }) => ({
  padding: `${spacing(0.5)} ${spacing(1)} !important`,
  fontSize: spacing(1.5),
  width: '100px',
}))

export const StyledTypographyRounded = styled(Typography)(
  ({ theme: { spacing, palette } }) => ({
    background: palette.grey[100],
    padding: spacing(2),
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
)
