import { Typography, Box } from '@mui/material'
interface Props {
  primaryText: React.ReactNode
  secondaryText: React.ReactNode
  icon: React.ReactNode
}

export default function TrainingCentreButtonContent({
  primaryText,
  secondaryText,
  icon,
}: Props) {
  return (
    <Box display="flex" flexDirection={{ xs: 'row-reverse', sm: 'column' }} gap={3}>
      <div>{icon}</div>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        <Typography
          data-testid="ev-tc-bc-primary-text"
          textAlign={{ xs: 'left', sm: 'center' }}
          textTransform="uppercase"
        >
          {primaryText}
        </Typography>
        <Typography
          textAlign={{ xs: 'left', sm: 'center' }}
          mt={{ xs: 1, sm: 3 }}
          variant="body2"
          color="text.secondary"
          data-testid="ev-tc-bc-secondary-text"
        >
          {secondaryText}
        </Typography>
      </Box>
    </Box>
  )
}
