import { EVLevelIcon } from 'icons/EVIcons'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { EVLevelIconName } from 'types'
import FlipTile from 'components/TrainingCentre/FlipTile'
import FrontFacePaper from 'components/TrainingCentre/FrontFacePaper'
import MyTrainingTileSummary from 'components/TrainingCentre/MyTrainingTileSummary'
import TrainingCentreButtonContent from 'components/TrainingCentre/TrainingCentreButtonContent'
import { IValueLabel } from 'interfaces'
import helpIcon from 'assets/svgs/icon_help_round.svg'
import exploreIcon from 'assets/svgs/icon_explore_ev_big.svg'
import dashboardBigIcon from 'assets/svgs/icon_dashboard_big.svg'
import myStaffBigIcon from 'assets/svgs/icon_my_staff_big.svg'
import { useAppSelector } from 'store'
import { selectUser } from 'store/slices'
import { getMyTrainingTileText } from 'utils/EVUtils/levelUtils/getMyTrainingTileText'

type Props = {
  hasEvTrainingJobRole: boolean | undefined
  isManager: boolean | undefined
  isRetailerAdmin: boolean | undefined
  workingLevel?: IValueLabel | null
  certifiedLevel?: IValueLabel | null
  targetLevel?: IValueLabel | null
}

export default function TrainingTilesDesktop({
  hasEvTrainingJobRole,
  isManager,
  isRetailerAdmin,
  workingLevel,
  certifiedLevel,
  targetLevel,
}: Props) {
  const [showFace, setShowFace] = useState<'front' | 'back'>('front')
  const navigate = useNavigate()

  const user = useAppSelector(selectUser)

  return (
    <>
      {Boolean(!hasEvTrainingJobRole) && (
        <FlipTile
          onShowFront={() => setShowFace('front')}
          onShowBack={() => setShowFace('back')}
          showFace={showFace}
          onFrontClick={() => navigate('/ev-level')}
          frontFace={
            <FrontFacePaper>
              <TrainingCentreButtonContent
                primaryText={<FormattedMessage id="app.labels.exploreEV" />}
                secondaryText={<FormattedMessage id="trainingCentre.LearnAboutEV" />}
                icon={<img src={exploreIcon} alt="Explore" width={75} height={75} />}
              />
            </FrontFacePaper>
          }
        />
      )}

      {Boolean(hasEvTrainingJobRole) && (
        <FlipTile
          onShowFront={() => setShowFace('front')}
          onShowBack={() => setShowFace('back')}
          showFace={showFace}
          onFrontClick={() => navigate(`ev-level/${user?.workingLevelUUID}`)}
          frontFace={
            <FrontFacePaper>
              <TrainingCentreButtonContent
                primaryText={<FormattedMessage id="app.labels.myEVTraining" />}
                secondaryText={getMyTrainingTileText(workingLevel, certifiedLevel)}
                icon={<EVLevelIcon evLevel={workingLevel?.value as EVLevelIconName} />}
              />
            </FrontFacePaper>
          }
          backFace={
            <MyTrainingTileSummary
              certifiedLevel={certifiedLevel}
              workingLevel={workingLevel}
              targetLevel={targetLevel}
            />
          }
        />
      )}
      {isManager || isRetailerAdmin ? (
        <FlipTile
          onFrontClick={() => navigate('/dashboard')}
          frontFace={
            <FrontFacePaper>
              <TrainingCentreButtonContent
                primaryText={<FormattedMessage id="app.labels.dashboard" />}
                secondaryText={
                  <FormattedMessage id="trainingCentre.dashboard.secondaryText" />
                }
                icon={
                  <img src={dashboardBigIcon} alt="Dashboard" width={75} height={75} />
                }
              />
            </FrontFacePaper>
          }
        />
      ) : null}
      {isManager || isRetailerAdmin ? (
        <FlipTile
          onFrontClick={() => navigate('/staff-members')}
          frontFace={
            <FrontFacePaper>
              <TrainingCentreButtonContent
                primaryText={<FormattedMessage id="app.labels.myStaff" />}
                secondaryText={<FormattedMessage id="app.labels.manageYourStaff" />}
                icon={<img src={myStaffBigIcon} alt="Staff" width={75} height={75} />}
              />
            </FrontFacePaper>
          }
        />
      ) : null}

      <FlipTile
        onFrontClick={() => navigate('/help-center')}
        frontFace={
          <FrontFacePaper>
            <TrainingCentreButtonContent
              primaryText={<FormattedMessage id="app.buttons.help" />}
              secondaryText={<FormattedMessage id="trainingCentre.help.secondaryText" />}
              icon={<img src={helpIcon} alt="Help" width={75} height={75} />}
            />
          </FrontFacePaper>
        }
      />
    </>
  )
}
