import { Box, Divider, Typography } from '@mui/material'
import { FC } from 'react'
import {
  StyledCard,
  StyledCardInfo,
  StyledCardWrapper,
} from 'components/DashboardInfos/style'
import { FormattedMessage } from 'react-intl'
type CardInfoProps = {
  data: {
    levelIcon: string
    certifiedCount: number
    targetCount: number
    workingCount: number
    levelName: string
  }
}

const CardInfo: FC<CardInfoProps> = ({ data }) => {
  return (
    <StyledCardWrapper>
      <StyledCard>
        <img src={`./assets/${data.levelIcon}`} alt="icon level round" />
        <Typography>{data.levelName}</Typography>
        <Typography variant="h1" fontSize={40}>
          {data.certifiedCount}
        </Typography>
        <Typography>
          <FormattedMessage id="dashboard.evCapability.section.cards.title.certified" />
        </Typography>

        <Divider />
      </StyledCard>
      <StyledCardInfo>
        <Box>
          <Typography>
            <FormattedMessage id="applicationTerm.targetLevel" />
          </Typography>
          <span>{data.targetCount}</span>
        </Box>
        <Box>
          <Typography>
            <FormattedMessage id="applicationTerm.workingLevel" />
          </Typography>
          <span>{data.workingCount}</span>
        </Box>
      </StyledCardInfo>
    </StyledCardWrapper>
  )
}

export default CardInfo
