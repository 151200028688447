import { Box, TextField } from '@mui/material'
import searchIcon from 'assets/svgs/icon_search.svg'
import { useIntl } from 'react-intl'
import {
  selectSearchTermBatteryList,
  setSearchTermBatteryList,
  useAppDispatch,
  useAppSelector,
} from 'store'

const SearchBar = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const selectedSearchStringBatteryList = useAppSelector(selectSearchTermBatteryList)
  const placeholderInput = intl.formatMessage({
    id: 'filters.searchStaff',
  })

  return (
    <Box>
      <TextField
        value={selectedSearchStringBatteryList}
        onChange={e => {
          dispatch(setSearchTermBatteryList(e.target.value))
        }}
        InputProps={{
          startAdornment: (
            <img
              src={searchIcon}
              alt="Search Staff"
              width="15"
              style={{ marginRight: '10px' }}
            />
          ),
          sx(theme) {
            return {
              borderRadius: '0px',
              'input::placeholder': {
                color: theme.palette.primary.main,
                opacity: 1,
              },
              'input ~ fieldset': {
                borderWidth: '1px !important',
              },
            }
          },
        }}
        placeholder={placeholderInput}
      />
    </Box>
  )
}

export default SearchBar
