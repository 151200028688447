import {
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ActionRequired } from 'interfaces'
import { useFormContext } from 'react-hook-form'
import {
  StyledARGenericButton,
  StyledARRejectButton,
} from 'components/StaffMembers/ActionRequired/style'
import { IActionRequiredFormInput } from 'components/StaffMembers/ActionRequired/types'
import { FormattedMessage } from 'react-intl'
import { selectUserIsImpersonate } from 'store/slices'
import { useAppSelector } from 'store'

interface ConfirmEvTrainingProps {
  isLoadingApproval: boolean
}

const ConfirmEvTraining = ({ isLoadingApproval }: ConfirmEvTrainingProps) => {
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { setValue, watch } = useFormContext<IActionRequiredFormInput>()
  const actionRequired = watch('actionRequired') as ActionRequired

  const RenderActionRequiredButton = () => {
    return actionRequired.showProposalAlternative ||
      actionRequired.levelName !== 'Live Battery Repairs' ? (
      <StyledARGenericButton
        onClick={() => setValue('formStep', 1)}
        sx={isSmallScreen ? { width: '100%' } : {}}
        disabled={isImpersonate}
      >
        <FormattedMessage id="staffMembers.actionRequired.confirmEvTraining.proposeAlternative" />
      </StyledARGenericButton>
    ) : (
      <></>
    )
  }

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: '14px' }}>
        <FormattedMessage
          id="staffMembers.actionRequired.confirmEvTraining.text"
          values={{
            evLevelName: actionRequired.levelName,
          }}
        />
      </Typography>
      {isSmallScreen && <RenderActionRequiredButton />}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}
      >
        <StyledARRejectButton
          onClick={() => setValue('formStep', 2)}
          sx={isSmallScreen ? { width: '46.5%' } : {}}
          disabled={isImpersonate}
        >
          <FormattedMessage id="staffMembers.actionRequired.confirmEvTraining.rejectButton.text" />
        </StyledARRejectButton>
        {!isSmallScreen && <RenderActionRequiredButton />}
        <StyledARGenericButton
          type="submit"
          sx={isSmallScreen ? { width: '46.5%' } : {}}
          disabled={isImpersonate}
        >
          {isLoadingApproval ? (
            <CircularProgress size={15} />
          ) : (
            <FormattedMessage id="app.buttons.confirm" />
          )}
        </StyledARGenericButton>
      </Stack>
    </Stack>
  )
}

export default ConfirmEvTraining
