import { Box, ButtonBase, Collapse, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { StatusType } from 'types'

import BigCounter from 'components/common/BigCounter'
import { FormattedMessage } from 'react-intl'

type Props = {
  modelName: string
  modelImage: string
  modelYear: string
  open: boolean
  onToggleOpen: () => void
  children?: React.ReactNode
  status?: StatusType
  count?: number
}

export default function LiveBatteryRepairsVehicle({
  open,
  onToggleOpen,
  modelName,
  modelImage,
  children,
  count,
  modelYear,
}: Props) {
  return (
    <Paper
      elevation={0}
      sx={{
        background: '#F6F8FA',
        border: '1px solid rgb(238 238 238)',
      }}
    >
      <ButtonBase
        onClick={onToggleOpen}
        sx={{
          px: 2,
          py: 2,
          width: '100%',
          ':focus-visible': { outline: '1px solid #cad1d7' },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <Box>
            <img src={`${modelImage}`} alt="Vehicle" width={120} />
          </Box>
          <Stack flex={1} alignItems="flex-start" justifyContent="flex-start">
            <Typography data-testid="primary-text" textAlign="left" variant="button">
              {modelName}
            </Typography>
            <Typography color="#5B788F">
              <FormattedMessage id="app.labels.modelYears" />
            </Typography>
            <Typography data-testid="primary-text" textAlign="left" variant="button">
              {modelYear}
            </Typography>
          </Stack>

          {count && count > 0 ? <BigCounter value={count} /> : null}
        </Stack>
      </ButtonBase>
      <Collapse in={open}>
        <Box p={1}>{children}</Box>
      </Collapse>
    </Paper>
  )
}
