import BrandWrapper from 'components/common/BrandWrapper'
import ResetPasswordForm from 'components/ResetPasswordForm'

const UpdatePassword = () => {
  return (
    <BrandWrapper>
      <ResetPasswordForm isUpdatePassword />
    </BrandWrapper>
  )
}

export default UpdatePassword
