import { Badge, Box, Stack, Typography } from '@mui/material'
import { StatusType } from 'types'
import defaultIcon from 'assets/svgs/icon_battery.svg'
import Counter from 'components/common/Counter'

type Props = {
  primaryText: string
  secondaryText?: string
  status?: StatusType
  courseCount?: number
  icon?: any
  iconAlt?: string
}

export default function BatteryRepairHeader({
  primaryText,
  secondaryText,
  status,
  courseCount,
  icon,
  iconAlt,
}: Props) {
  return (
    <Stack direction="row" alignItems="center" spacing={2} width="100%">
      <Box minWidth={70} display="flex" justifyContent="center">
        <Badge
          color="primary"
          badgeContent={courseCount}
          invisible={!status}
          sx={{
            '& .MuiBadge-badge	': {
              bgcolor: 'rgb(220,225,229)',
              color: 'rgb(82,82,82)',
            },
          }}
        >
          <img src={icon ? icon : defaultIcon} alt={iconAlt ? iconAlt : 'Module'} />
        </Badge>
      </Box>
      <Stack flex={1} alignItems="flex-start">
        <Typography textTransform="uppercase" data-testid="primary-text" color="#525252">
          {primaryText}
        </Typography>
        {secondaryText ? (
          <Typography
            data-testid="secondary-text"
            sx={{ color: 'text.secondary' }}
            variant="body2"
          >
            {secondaryText}
          </Typography>
        ) : null}
      </Stack>
      {courseCount ? <Counter value={courseCount} /> : null}
    </Stack>
  )
}
