// Displays the icon for the specified EV Level

import evipIcon from 'assets/svgs/icon_level_round_EVIP.svg'
import evapIcon from 'assets/svgs/icon_level_round_EVAP.svg'
import evcpIcon from 'assets/svgs/icon_level_round_EVCP.svg'
import evsapIcon from 'assets/svgs/icon_level_round_EVSAP.svg'
import evLiveWorking from 'assets/svgs/icon_level_round_Live_Working.svg'
import { EVLevelIconName } from 'types'

type Props = {
  size?: number
  evLevel: EVLevelIconName
}

export default function EVLevelIcon({ evLevel, size = 75 }: Props) {
  switch (evLevel) {
    case 'icon_level_EVIP.svg':
      return (
        <img
          src={evipIcon}
          alt="EVIP"
          width={size}
          height={size}
          data-testid="level-icon-evip"
        />
      )
    case 'icon_level_EVAP.svg':
      return (
        <img
          src={evapIcon}
          alt="EVAP"
          width={size}
          height={size}
          data-testid="level-icon-evap"
        />
      )
    case 'icon_level_EVCP.svg':
      return (
        <img
          src={evcpIcon}
          alt="EVCP"
          width={size}
          height={size}
          data-testid="level-icon-evcp"
        />
      )
    case 'icon_level_EVSAP.svg':
      return (
        <img
          src={evsapIcon}
          alt="EVSAP"
          width={size}
          height={size}
          data-testid="level-icon-evsap"
        />
      )

    case 'icon_level_Live_Working.svg':
      return (
        <img
          src={evLiveWorking}
          alt="Live Battery Repairs"
          width={size}
          height={size}
          data-testid="level-icon-live-working"
        />
      )
    default:
      return null
  }
}
