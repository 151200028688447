import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import Dialog from 'components/common/Dialog'
import DialogContent from 'components/common/DialogContent'
import DialogTitle from 'components/common/DialogTitle'
import { ITrainingLevelDetails } from 'interfaces'
import { Dispatch, SetStateAction } from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetEvTrainingLevelAgreementConfigQuery } from 'store/api'
import LevelAccessRequestDetail from 'components/EVLevel/LevelAccessRequest/LevelAccessRequestDetail'

type Props = {
  open: boolean
  onClose: () => void
  onConfirm: Dispatch<SetStateAction<boolean>>
  evLevelData: ITrainingLevelDetails
}

export default function LevelAccessRequest({
  open,
  onClose,
  onConfirm,
  evLevelData,
}: Props) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.only('xs'))

  const { currentData, isFetching } = useGetEvTrainingLevelAgreementConfigQuery(
    {
      levelUUID: evLevelData.levelUUID,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !evLevelData.levelUUID || open === false,
    },
  )

  if (isSmall) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullScreen>
        <DialogTitle
          onClose={onClose}
          closeButtonLabel={<FormattedMessage id="app.buttons.close" />}
        />
        <DialogContent>
          {isFetching || !currentData ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={20} />
            </Box>
          ) : (
            <LevelAccessRequestDetail
              evLevelData={evLevelData}
              data={currentData}
              onConfirm={onConfirm}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle
        title={<FormattedMessage id="evLevel.requestTraining.dialogTitle" />}
        onClose={onClose}
        closeButtonLabel={<FormattedMessage id="app.buttons.close" />}
      />
      <DialogContent>
        {isFetching || !currentData ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <LevelAccessRequestDetail
            evLevelData={evLevelData}
            data={currentData}
            onConfirm={onConfirm}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
