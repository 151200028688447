import {
  IApiResponse,
  IDashboardConfigQueryParams,
  IGetDashboardConfigQueryResponse,
  IGetDashboardEvLiveWorkingCapabilytyQueryResponse,
  IGetStaffQualificationsConfigQueryResponse,
  ISearchStaffQualificationsQueryParams,
  ISearchStaffQualificationsQueryResponse,
  IToggleServicingParams,
} from 'interfaces'

import { evAPI } from './api'
import { setDashboardCapabilityData, setDashboardCardsData } from 'store/slices'

export const dashboardApi = evAPI.injectEndpoints({
  endpoints: builder => ({
    getDashboardCapabilityQuery: builder.mutation<
      IGetDashboardConfigQueryResponse['data'],
      IDashboardConfigQueryParams
    >({
      query: props => {
        return {
          url: 'Management/Dashboard/GetDashboardEvCapabilityQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          dispatch(setDashboardCardsData(data))
        } catch (error) {
          console.log(error)
        }
      },
      transformResponse: ({ data }: IGetDashboardConfigQueryResponse) => data,
    }),
    getDashboardEvLiveWorkingCapabilytyQuery: builder.mutation<
      IGetDashboardEvLiveWorkingCapabilytyQueryResponse['data'],
      IDashboardConfigQueryParams
    >({
      query: props => {
        return {
          url: 'Management/Dashboard/SearchDashboardEvLiveWorkingCapabilityQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          dispatch(setDashboardCapabilityData(data))
        } catch (error) {
          console.log(error)
        }
      },

      transformResponse: ({ data }: IGetDashboardEvLiveWorkingCapabilytyQueryResponse) =>
        data,
    }),
    getDashboardToggleServicingAvailablityCommand: builder.mutation<
      IApiResponse,
      IToggleServicingParams
    >({
      query: props => {
        return {
          url: 'Management/Dashboard/ToggleServicingAvailablityCommand',
          method: 'POST',
          body: { ...props },
        }
      },
    }),
    getStaffQualificationsConfigQuery: builder.mutation<
      IGetStaffQualificationsConfigQueryResponse['data'],
      IDashboardConfigQueryParams
    >({
      query: props => {
        return {
          url: 'Management/Dashboard/GetStaffQualificationsConfigQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      transformResponse: ({ data }: IGetStaffQualificationsConfigQueryResponse) => data,
    }),

    getSearchStaffQualificationsQuery: builder.mutation<
      ISearchStaffQualificationsQueryResponse['data'],
      ISearchStaffQualificationsQueryParams
    >({
      query: props => {
        return {
          url: 'Management/Dashboard/SearchStaffQualificationsQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      transformResponse: ({ data }: ISearchStaffQualificationsQueryResponse) => data,
    }),
  }),
})

export const {
  useGetDashboardCapabilityQueryMutation,
  useGetDashboardEvLiveWorkingCapabilytyQueryMutation,
  useGetDashboardToggleServicingAvailablityCommandMutation,
  useGetStaffQualificationsConfigQueryMutation,
  useGetSearchStaffQualificationsQueryMutation,
} = dashboardApi
