import { FC } from 'react'
import { StyledCardCarContent } from 'components/SettingsComponents/style'
import { Checkbox, useMediaQuery } from '@mui/material'
import { ICardCarContent } from 'interfaces'
import { CheckedIcon, UncheckedIcon } from 'components/StaffMembers/Filters/styles'
import CardCar from 'components/SettingsComponents/CardCar'

const CardCarContent: FC<ICardCarContent> = ({ model, year, isEnabled }) => {
  const isLargeScreen = useMediaQuery('(min-width: 1200px)')

  if (isLargeScreen) {
    return (
      <StyledCardCarContent selected={isEnabled} key={model.modelUUID}>
        <label htmlFor={model.modelName}>
          <CardCar
            modelName={model.modelName}
            modelImage={model.modelImage}
            year={year}
          />
        </label>
        <Checkbox
          id={model.modelName}
          edge="start"
          disableRipple
          checked={isEnabled}
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          sx={{ py: 0 }}
        />
      </StyledCardCarContent>
    )
  } else {
    return (
      <StyledCardCarContent selected={isEnabled} key={model.modelUUID}>
        <CardCar
          modelName={model.modelName}
          modelImage={model.modelImage}
          year={year}
          isEnabled={isEnabled}
        />
      </StyledCardCarContent>
    )
  }
}

export default CardCarContent
