import React, { useState, useEffect } from 'react'
import { setLocalStorage } from 'utils/localStorage'
import { StorageKeys } from 'constant/localStorage'
import { Paper, Collapse, Box, ButtonBase } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useAppSelector } from 'store'
import { StatusType } from 'types'
import IframeModal from 'components/IframeModal'
import { useGetGenerateOttMutation, useGenerateAntiCSRFTokenMutation } from 'store/api'
import Button from 'components/common/Button'
import { selectUserIsImpersonate } from 'store/slices'

type DisplayType =
  | 'standard'
  | 'moduleDecendent'
  | 'vehicleModelDecendent'
  | 'vehicleModuleDecendent'

type Props = {
  open: boolean
  onToggleOpen: () => void
  header: React.ReactNode
  children: React.ReactNode
  status?: StatusType
  isAvailable: boolean
  isLocked: boolean
  displayType?: DisplayType
  launchPath: string
  onClosePortalModal: () => void
  isManagerView: boolean
}

function displayProfile(displayType: DisplayType) {
  switch (displayType) {
    case 'standard':
      return {
        cardBgColor: 'white',
        cardBorder: 'none',
        cardContentPadding: 4,
        cardActionArea: { px: 3, py: 3, width: '100%' },
        cardActionsPadding: { px: 4, pb: 3 },
        elevation: 1,
      }
    case 'moduleDecendent':
      return {
        cardBgColor: 'rgb(244,247,249)',
        cardBorder: '1px solid rgb(238 238 238)',
        cardContentPadding: 3,
        cardActionArea: { px: 2, py: 3, width: '100%' },
        cardActionsPadding: { px: 3, pb: 3 },
        elevation: 0,
      }
    case 'vehicleModelDecendent':
      return {
        cardBgColor: 'rgb(244,247,249)',
        cardBorder: '1px solid rgb(238 238 238)',
        cardContentPadding: 3,
        cardActionArea: { px: 2, py: 3, width: '100%' },
        cardActionsPadding: { px: 2, pb: 2 },
        elevation: 0,
      }

    case 'vehicleModuleDecendent':
      return {
        cardBgColor: 'white',
        cardBorder: '1px solid rgb(238 238 238)',
        cardContentPadding: 2,
        cardActionArea: { px: 1, py: 3, width: '100%' },
        cardActionsPadding: { px: 2, pb: 2 },
        elevation: 0,
      }
  }
}

export default function CourseCard({
  open,
  onToggleOpen,
  header,
  children,
  displayType = 'standard',
  launchPath,
  onClosePortalModal,
  isManagerView,
}: Props) {
  const displaySettings = displayProfile(displayType)
  const isExternalLoggedIn = useAppSelector(state => state.app.isExternalLoggedIn)
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const [isOpen, setIsOpen] = useState(false)

  const [getGenerateOtt, { data: ott, isLoading, reset }] = useGetGenerateOttMutation()
  const [getGenerateAntiCSRFToken, antiCSRFState] = useGenerateAntiCSRFTokenMutation()

  useEffect(() => {
    if (antiCSRFState.isSuccess) {
      setLocalStorage(StorageKeys.antiCSRFToken, antiCSRFState.data)
      getGenerateOtt()
    }
  }, [antiCSRFState.isSuccess])

  const handleClose = () => {
    onClosePortalModal()
    setIsOpen(false)
    reset()
  }

  const handleOpenClick = () => {
    if (isExternalLoggedIn) {
      getGenerateAntiCSRFToken()
    } else {
      getGenerateOtt()
    }

    setIsOpen(true)
  }

  return (
    <>
      <Paper
        elevation={displaySettings.elevation}
        sx={{
          bgcolor: displaySettings.cardBgColor,
          border: displaySettings.cardBorder,
        }}
      >
        <ButtonBase
          onClick={onToggleOpen}
          sx={{
            ...displaySettings.cardActionArea,
            ':focus-visible': { outline: '1px solid #cad1d7' },
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {header}
        </ButtonBase>
        <Collapse in={open}>
          <Box>{children}</Box>

          {launchPath && !isImpersonate && !isManagerView ? (
            <Box sx={{ ...displaySettings.cardActionsPadding }}>
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                isLoading={isLoading || antiCSRFState.isLoading}
                disabled={isLoading || antiCSRFState.isLoading}
                onClick={() => handleOpenClick()}
              >
                <FormattedMessage id="app.buttons.open" />
              </Button>
            </Box>
          ) : null}
        </Collapse>
      </Paper>

      {isOpen && ott && (
        <IframeModal
          src={`${launchPath}?fromev=true&token=${ott}`}
          isOpen={isOpen}
          onClose={() => handleClose()}
        />
      )}
    </>
  )
}
