import { useMediaQuery, useTheme } from '@mui/material'
import { Box, Container } from '@mui/system'
import desktopBg from 'assets/images/login_splash_image.jpg'
import evBolt from 'assets/svgs/ev_bolt.svg'
import jlrLogo from 'assets/svgs/JLR_white.svg'
import Footer from 'components/common/BrandWrapper/Footer'
import LanguageSelectorUser from 'components/common/LanguageSelector/LanguageSelectorUser'
import { FormattedMessage } from 'react-intl'
import useFitText from 'use-fit-text'

interface Props {
  children: React.ReactNode
}

export default function BrandWrapper({ children }: Props) {
  const { fontSize, ref } = useFitText()
  const theme = useTheme()
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Box
      sx={{
        backgroundImage: `url(${desktopBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center left',
      }}
    >
      <Box
        overflow="auto"
        height="100%"
        bgcolor={{ xs: 'rgb(0,0,0,0.20)', lg: 'rgba(0,0,0,0.40)' }}
        sx={{
          backgroundImage: `url(${evBolt})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: {
            xs: 'right -90px top -100px',
            lg: 'center top -100px',
          },
        }}
      >
        <Container
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              sm: '1fr 2fr 1fr',
              lg: '280px 1fr 403px',
            },
            gridTemplateRows: { lg: '1fr 350px 1fr', xs: '150px auto 80px 1fr' },
            height: '100vh',
            pt: { xs: 4, lg: 0 },
          }}
        >
          <Box
            gridRow={{ xs: 1, lg: 2 }}
            gridColumn={{ xs: 1, sm: 2, lg: 1 }}
            textAlign="center"
          >
            <img
              src={jlrLogo}
              alt="Jaguar Landrover"
              style={{
                width: '110px',
                marginTop: '10%',
              }}
            />
          </Box>
          <Box
            gridRow={2}
            gridColumn={{ xs: 1, sm: '1/-1', lg: 1 }}
            justifySelf="center"
            alignSelf={{ xs: 'center', lg: 'end' }}
            width="100%"
            sx={{
              paddingBottom: '30px',
            }}
          >
            <Box
              sx={{
                color: 'white',
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: isLargeUp ? 48 : 36,
                fontWeight: '100',
                letterSpacing: '4px',
                lineHeight: '1.2',
              }}
            >
              <div
                ref={ref}
                style={{
                  fontSize,
                  height: isLargeUp ? 100 : 60,
                  width: '100%',
                }}
              >
                <FormattedMessage id="application.title" />
              </div>
            </Box>
          </Box>

          <Box
            gridRow={{ xs: 5, lg: '1/4' }}
            gridColumn={{ xs: 1, sm: 2, lg: 3 }}
            justifySelf="end"
            alignSelf="center"
            width="100%"
            borderRadius={2}
            px={{ xs: 3, lg: 5 }}
            py={3}
            my={{ xs: 0, lg: 0 }}
            sx={{
              backgroundColor: {
                xs: 'transparent',
                lg: '#F6F8FAcc',
              },
            }}
          >
            {children}
          </Box>

          {isLargeUp ? <Footer /> : null}

          <Box
            gridRow={{ xs: 3, lg: 3 }}
            gridColumn={{ xs: 1, sm: 2, lg: 3 }}
            color="white"
            justifySelf={isLargeUp ? 'end' : 'center'}
            alignSelf={isLargeUp ? 'end' : 'center'}
            py={{ lg: 3 }}
          >
            <LanguageSelectorUser />
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
