import { FC, useEffect, useState } from 'react'
import {
  StyledButtonFilter,
  StyledDashboardMobileWrapper,
  StyledFilterBoxContent,
  StyledFilterBoxWrapper,
  StyledBackgroundFilter,
  AntSwitch,
  StyledButtonSwitchBoxWrapper,
  StyledCardBoxWrapper,
  StyledTitleCardBox,
} from 'components/DashboardInfos/style'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import iconBatteryQualification from 'assets/svgs/icon_battery_qualification.svg'
import { camelCase } from 'lodash'
import 'swiper/css'
import 'swiper/css/pagination'
import { Box, Checkbox, Collapse, Paper, Typography } from '@mui/material'
import { StyledDashboardTitle } from 'routes/Dashboard/style'
import { FormattedMessage } from 'react-intl'
import SettingsIcon from 'components/common/SettingsIcon'
import { UncheckedIcon, CheckedIcon } from 'components/StaffMembers/Filters/styles'
import {
  selectSelectedDashboardCapability,
  selectSelectedDashboardCards,
  setClearJobRoleAndSearchTermFilters,
  useAppDispatch,
  useAppSelector,
} from 'store'
import { filterByDashboard } from 'utils/filterByDashboard'
import CardInfo from 'components/DashboardInfos/CardInfo'
import CardActions from 'components/DashboardInfos/CardActions'
import ContentModelCars from 'components/DashboardInfos/ContentModelCars'
import { useNavigate } from 'react-router-dom'

interface IFilterState {
  jaguar: boolean
  rangeRover: boolean
  discovery: boolean
  defender: boolean
}

const DashboardInfoMobile: FC = () => {
  const [isLastElement, setIsLastElement] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useAppDispatch()

  const [filterState, setFilterState] = useState<IFilterState>({
    jaguar: false,
    rangeRover: false,
    discovery: false,
    defender: false,
  })

  const [countFilter, setCountFilter] = useState(0)

  const { levels, actionsRequired } = useAppSelector(selectSelectedDashboardCards)
  const { records } = useAppSelector(selectSelectedDashboardCapability)

  const [filteredArray, setFilteredArray] = useState<typeof records>(records ?? [])
  const [selectedBrands, setSelectedBrands] = useState<string[]>([])

  const [isCertified, setIsCertified] = useState<boolean>(false)

  useEffect(() => {
    setFilteredArray(records)
  }, [records])

  useEffect(() => {
    setFilteredArray(
      records.filter(record =>
        filterByDashboard(filterState, isCertified, record, setSelectedBrands),
      ),
    )
  }, [
    filterState.defender,
    filterState.discovery,
    filterState.jaguar,
    filterState.rangeRover,
    isCertified,
  ])

  useEffect(() => {
    setCountFilter(
      Number(filterState.jaguar) +
        Number(filterState.rangeRover) +
        Number(filterState.discovery) +
        Number(filterState.defender) +
        Number(isCertified),
    )
  }, [
    filterState.defender,
    filterState.discovery,
    filterState.jaguar,
    filterState.rangeRover,
    isCertified,
  ])

  useEffect(() => {
    if (
      countFilter === 0 ||
      (countFilter === 1 && !Object.values(filterState).some(value => value))
    )
      setSelectedBrands(Object.keys(filterState))
  }, [countFilter])

  const navigate = useNavigate()

  const handleNavigateToBatteryList = () => {
    dispatch(setClearJobRoleAndSearchTermFilters())
    navigate('/dashboard/battery-list')
  }

  return (
    <Box>
      <StyledDashboardMobileWrapper isLastItem={isLastElement}>
        <Swiper
          pagination={{
            clickable: true,
          }}
          centeredSlides={true}
          slidesPerView={1}
          modules={[Pagination]}
          className="mobile-swiper"
          onActiveIndexChange={swiper => {
            setIsLastElement(swiper.isEnd)
          }}
        >
          {levels.map(certification => (
            <SwiperSlide key={certification.levelUUID}>
              <CardInfo key={certification.levelUUID} data={certification} />
            </SwiperSlide>
          ))}

          <SwiperSlide>
            <CardActions data={actionsRequired} />
          </SwiperSlide>
        </Swiper>

        <Box width="100%">
          <StyledDashboardTitle as="div">
            <FormattedMessage id="dashboard.liveWorkingCapability.section.title" />
            <Typography onClick={handleNavigateToBatteryList}>
              <FormattedMessage id="dashboard.liveWorkingCapability.section.button.viewList" />
            </Typography>
          </StyledDashboardTitle>
        </Box>
      </StyledDashboardMobileWrapper>

      <StyledFilterBoxWrapper>
        <StyledButtonFilter open={isOpen} onClick={() => setIsOpen(state => !state)}>
          <SettingsIcon isOpen={isOpen} />
          <FormattedMessage id="filters.label" />: + {countFilter}
        </StyledButtonFilter>
        <Collapse in={isOpen} sx={{ width: '100%' }}>
          <StyledBackgroundFilter>
            <StyledFilterBoxContent>
              <Box>
                <Checkbox
                  id="jaguar"
                  edge="start"
                  disableRipple
                  checked={filterState.jaguar}
                  onChange={() =>
                    setFilterState({ ...filterState, jaguar: !filterState.jaguar })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="jaguar">Jaguar</label>
              </Box>
              <Box>
                <Checkbox
                  id="discovery"
                  edge="start"
                  disableRipple
                  checked={filterState.discovery}
                  onChange={() =>
                    setFilterState({
                      ...filterState,
                      discovery: !filterState.discovery,
                    })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="discovery">Discovery</label>
              </Box>
              <Box>
                <Checkbox
                  id="range-rover"
                  edge="start"
                  disableRipple
                  checked={filterState.rangeRover}
                  onChange={() =>
                    setFilterState({
                      ...filterState,
                      rangeRover: !filterState.rangeRover,
                    })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="range-rover">Range Rover</label>
              </Box>
              <Box>
                <Checkbox
                  id="defender"
                  edge="start"
                  disableRipple
                  checked={filterState.defender}
                  onChange={() =>
                    setFilterState({
                      ...filterState,
                      defender: !filterState.defender,
                    })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="defender">Defender</label>
              </Box>
            </StyledFilterBoxContent>
            <StyledButtonSwitchBoxWrapper>
              <AntSwitch
                checked={isCertified}
                onChange={() => setIsCertified(state => !state)}
              />
              <Typography>
                <FormattedMessage id="dashboard.liveWorkingCapability.section.switch.showCertifiedOnly" />
              </Typography>
            </StyledButtonSwitchBoxWrapper>
          </StyledBackgroundFilter>
        </Collapse>
      </StyledFilterBoxWrapper>

      <StyledCardBoxWrapper as={Paper}>
        <StyledTitleCardBox>
          <img src={iconBatteryQualification} alt="Icon Battery Qualification" />
          <Typography>
            <FormattedMessage id="dashboard.liveWorkingCapability.section.liveBatteryRepairCertified.title" />
          </Typography>
        </StyledTitleCardBox>
        {selectedBrands.map(brand => (
          <ContentModelCars
            brand={brand}
            key={brand}
            cars={filteredArray.filter(
              record => camelCase(record.brand.brandName) === brand,
            )}
          />
        ))}
      </StyledCardBoxWrapper>
    </Box>
  )
}

export default DashboardInfoMobile
