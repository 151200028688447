import { Box, styled } from '@mui/material'

export const StyledCheckbox = styled(Box)(({ theme: { palette } }) => ({
  width: 45,
  height: 45,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: palette.grey[100],
  position: 'relative',

  img: {
    position: 'absolute',
    top: '17px',
    left: '13.5px',

    width: '100%',
    height: '100%',
    objtiveFit: 'cover',
    zIndex: 2,
  },
}))

export const StyledCheckboxCount = styled(Box)(({ theme: { palette } }) => ({
  width: 45,
  height: 45,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: palette.grey[100],
  position: 'relative',

  img: {
    position: 'absolute',
    top: '16px',
    left: '11.5px',
    width: '100%',
    height: '100%',
    objtiveFit: 'cover',
  },
}))

export const StyledSelectedEvStatusWrapper = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: 40,
  background: palette.grey[100],
  gap: 20,
  borderRadius: 10,
}))

export const StyledSelectedEvStatusTitleCount = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
  marginBlock: '46px 28px',
  img: {
    width: 55,
    height: 55,
  },
  p: {
    fontSize: 18,
    color: palette.grey[800],
    textTransform: 'uppercase',
    letterSpacing: 1.8,
    textAlign: 'center',
  },
}))

export const StyledSelectedEvStatusContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 28,
  width: '100%',

  '@media (min-width: 995px)': {
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

export const StyledCardCountWrapper = styled(Box)(() => ({
  height: '100%',
  margin: '0 auto',
  background: '#F6F8FA',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledCardCountContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 28,
  padding: '46px 92px',
  width: '100%',
  overflow: 'auto',

  '>div img': {
    width: '55px',
    height: '55px',
  },
}))

export const StyledButtonWrapper = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',

  '>div': {
    gap: 10,
    margin: 20,
    display: 'flex',
    button: {
      color: palette.grey[700],
      background: 'transparent',
      border: `1px solid ${palette.grey[500]}`,
      fontSize: 12,

      '&:hover': {
        background: palette.grey[800],
        color: palette.common.white,
      },
    },
  },
}))

export const StyledSelectedCountEvStatusContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
  width: 640,

  justifyContent: 'center',

  '>div': {
    width: '100%',
  },
}))

interface StyledSelectedEvStatusCardProps {
  selected: boolean
}

export const StyledSelectedEvStatusCardWrapper = styled(
  Box,
)<StyledSelectedEvStatusCardProps>(({ theme: { palette }, selected }) => ({
  padding: '16px 24px',
  width: '100%',

  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: palette.common.white,
  borderRadius: 5,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  p: {
    fontSize: 16,
    textTransform: 'capitalize',
    color: palette.grey[700],
  },
  '@media (min-width: 995px)': {
    width: 270,
  },

  ':hover': {
    background: selected ? palette.common.white : palette.grey[600],
    p: {
      color: selected ? palette.grey[700] : palette.common.white,
    },
  },
}))

export const StyledBrandBannersCountWrapper = styled(Box)(({ theme: { palette } }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 45,
  padding: 30,
  marginTop: 20,

  ':after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: palette.common.white,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },

  div: {
    width: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,

    p: {
      fontSize: 16,
      color: palette.grey[800],
      textTransform: 'uppercase',
      letterSpacing: 2.4,
    },
  },
}))

export const StyledCarsCardCountContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  width: '100%',
  height: 377,
  justifyContent: 'center',
  padding: '20px 30px',
  gap: 30,
  overflow: 'auto',
  zIndex: 1,

  '>div': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
}))

export const CardCarCertifieldWrapper = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  padding: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: 5,
  '>img': {
    width: 150,
    height: 78,
    objectFit: 'cotain',
  },

  '>div': {
    justifySelf: 'flex-start',
    p: {
      textAlign: 'left',
    },
  },

  '@media (min-width: 1200px)': {
    display: 'flex',
    flexDirection: 'column',
    img: {
      width: 210,
      height: 78,
      objectFit: 'cover',
    },
    '>div': {
      justifySelf: 'center',
      p: {
        textAlign: 'center',
      },
    },
  },
}))

export const CardCarCertifieldContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',

  p: {
    textTransform: 'uppercase',
    color: palette.grey[700],
    textAlign: 'center',
    fontSize: 14,

    '&:nth-of-type(2)': {
      color: palette.grey[600],
    },
  },
}))

type CardCarProps = {
  selected: boolean
}

export const StyledCardCarContent = styled(Box)<CardCarProps>(
  ({ theme: { palette }, selected }) => ({
    boxShadow: `0 0 0 1px ${palette.grey[400]}`,
    width: '100%',

    '@media (min-width: 1200px)': {
      position: 'relative',
      display: 'flex',
      boxShadow: selected ? `0 0 0 1px ${palette.grey[600]}` : 'none',
      background: selected ? palette.grey[100] : 'transparent',
      borderRadius: 5,

      label: {
        width: '100%',
        cursor: 'pointer',
      },

      '>span': {
        position: 'absolute',
        top: 8,
        right: 0,
      },
    },
  }),
)

export const StyledBackgroundWrapper = styled(Box)(() => ({
  height: 'calc(100vh - 196px)',
  background: 'linear-gradient(90deg, #4F6C83 0%, #89A3A2 100%)',
  paddingBlock: 20,
  display: 'flex',
}))

export const StyledCardBoxContent = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  alignItems: 'center',
  width: '100%',

  '> p': {
    color: palette.grey[800],
    fontSize: 16,
    letterSpacing: '.15rem',
  },

  '@media (min-width: 1200px)': {
    p: {
      fontSize: 18,
    },
  },
}))

export const CardCarCertifieldChecked = styled(Box)(({ theme: { palette } }) => ({
  background: palette.grey[100],
  width: 46,
  height: 46,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  button: {
    color: palette.grey[700],
    fontSize: 18,
    fontFamily: 'JLR Emeric Regular,JLR Emeric ExtraLight',
  },
}))
