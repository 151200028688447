import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import downgradeIcon from 'assets/svgs/icon_downgrade.svg'
import currentTargetLevel from 'assets/svgs/icon_target_level.svg'
import { EVLevelIcon } from 'icons/EVIcons'
import { ProposableLevelProps } from 'interfaces'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import {
  useGetAlternativeProposalConfigQueryQuery,
  useGetNominationConfigQuery,
} from 'store/api'
import { EVLevelIconName } from 'types'
import {
  StyledARGenericButton,
  StyledConfirmDowngradeBox,
  StyledMenuItem,
  StyledNameBox,
} from 'components/StaffMembers/ActionRequired/style'
import { IActionRequiredFormInput } from 'components/StaffMembers/ActionRequired/types'
import { useAppSelector } from 'store'
import { selectUserIsImpersonate } from 'store/slices'

interface ProposeAlternativeProps {
  userUUID: string
  isLoadingAlternative: boolean
  title: string
  isUserDetails?: boolean
  handleClose?: () => void
}

const ProposeAlternative = ({
  userUUID,
  isLoadingAlternative,
  title,
  isUserDetails = false,
  handleClose,
}: ProposeAlternativeProps) => {
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const [showMessage, setShowMessage] = useState(false)
  const { setValue, watch, control, clearErrors } =
    useFormContext<IActionRequiredFormInput>()
  const [newTargetLevel] = watch(['newTargetLevel'])

  const { data: nominationData, isLoading: isNominationLoading } =
    useGetNominationConfigQuery(userUUID, {
      refetchOnMountOrArgChange: true,
    })

  const { data: proposalData, isLoading: isProposalLoading } =
    useGetAlternativeProposalConfigQueryQuery(userUUID, {
      refetchOnMountOrArgChange: true,
    })

  const isLoading = isNominationLoading || isProposalLoading
  const alternativeData = !isUserDetails ? proposalData : nominationData

  const handleChangeTargetLevel = (value: string) => {
    setValue('newTargetLevel', value)

    const availableLevels = alternativeData?.proposableLevels as ProposableLevelProps[]
    const userTargetLevelIndex = availableLevels.findIndex(item => item.isTargetLevel)
    const selectedTargetLevelIndex = availableLevels.findIndex(
      item => item.levelUUID === value,
    )

    if (selectedTargetLevelIndex! < userTargetLevelIndex!) {
      setShowMessage(true)
    } else {
      setShowMessage(false)
    }
  }

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: '14px' }}>{title}</Typography>
      <Controller
        name="newTargetLevel"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth size="small">
            <InputLabel id="propose-new-target-level">
              <FormattedMessage id="app.labels.select" />
            </InputLabel>
            <Select
              {...field}
              onOpen={() => setShowMessage(false)}
              labelId="propose-new-target-level"
              id="demo-simple-select"
              value={newTargetLevel}
              label="Target Level"
              onChange={e => handleChangeTargetLevel(e.target.value as string)}
              MenuProps={{
                disablePortal: true,
                MenuListProps: { disablePadding: true },
              }}
              renderValue={value => {
                const selectedLevel = alternativeData?.proposableLevels.find(
                  item => item.levelUUID === value,
                )
                const selectedLevelName = selectedLevel?.levelName
                return (
                  <StyledNameBox>
                    <EVLevelIcon
                      evLevel={selectedLevel?.levelIcon as EVLevelIconName}
                      size={25}
                    />
                    <Typography variant="body2">{selectedLevelName}</Typography>
                  </StyledNameBox>
                )
              }}
              error={Boolean(error)}
            >
              {!isLoading && alternativeData ? (
                alternativeData.proposableLevels.map(item => (
                  <StyledMenuItem key={item.levelUUID} value={item.levelUUID}>
                    <StyledNameBox>
                      <EVLevelIcon
                        evLevel={item.levelIcon as EVLevelIconName}
                        size={25}
                      />
                      <Typography variant="body2">{item.levelName}</Typography>
                    </StyledNameBox>
                    {item.isTargetLevel && (
                      <Box aria-label="current-level">
                        <img src={currentTargetLevel} alt="target icon" />
                        <Typography variant="body2">
                          <FormattedMessage id="evLevel.EVDetail.changeTargetLevel.proposeAlternative.currentTarget" />
                        </Typography>
                      </Box>
                    )}
                  </StyledMenuItem>
                ))
              ) : (
                <StyledMenuItem value="">
                  <Typography variant="body2">
                    <FormattedMessage id="app.messages.loading" />
                  </Typography>
                </StyledMenuItem>
              )}
            </Select>
            <FormHelperText
              error={Boolean(error)}
              sx={{ margin: '4px 0 0  !important', fontSize: '13px' }}
            >
              {error ? error.message : null}
            </FormHelperText>
          </FormControl>
        )}
        defaultValue=""
      />
      {showMessage && (
        <StyledConfirmDowngradeBox>
          <Typography variant="body2" gutterBottom>
            <img src={downgradeIcon} alt="downgrade icon" />
            <FormattedMessage id="bookingStaff.confirmDowngrade.title" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="bookingStaff.confirmDowngrade.message">
              {txt => {
                return (
                  <>
                    {(txt[0] as string).split('|').map((t, i) => (
                      <span key={i}>
                        {t}
                        {i === 0 && <br />}
                      </span>
                    ))}
                  </>
                )
              }}
            </FormattedMessage>
          </Typography>
        </StyledConfirmDowngradeBox>
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledARGenericButton
          onClick={() => {
            setValue('formStep', 0)
            setValue('newTargetLevel', '')
            clearErrors('newTargetLevel')
            handleClose && handleClose()
          }}
          disabled={isImpersonate}
        >
          <FormattedMessage id="app.buttons.cancel" />
        </StyledARGenericButton>
        <StyledARGenericButton type="submit" disabled={isImpersonate}>
          {isLoadingAlternative ? (
            <CircularProgress size={15} />
          ) : (
            <FormattedMessage id="app.buttons.confirm" />
          )}
        </StyledARGenericButton>
      </Stack>
    </Stack>
  )
}

export default ProposeAlternative
