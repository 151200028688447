// Application footer component

import { Container, Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export default function ApplicationFooter() {
  const theme = useTheme()
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'))

  const textAlignment = isExtraSmallScreen ? 'center' : 'left'

  return (
    <Box bgcolor="black" color="white" p={3}>
      <Container>
        <Stack spacing={1}>
          <Typography variant="body2" align={textAlignment} textTransform="uppercase">
            <FormattedMessage id="footer.jaguarLandRoverLimited" />
          </Typography>
          <Typography variant="body2" align={textAlignment}>
            <FormattedMessage id="footer.registeredOffice" />
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}
