import { Box, Stack, Typography, useTheme } from '@mui/material'
import batteryEmptySelection from 'assets/svgs/icon_battery.svg'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { IFormInput } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair'
import { StyledBatteryBox } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/styles'

const QualifiedBatteryBox = () => {
  const theme = useTheme()
  const { watch } = useFormContext<IFormInput>()
  const battery = watch('brandAndModel')
  return (
    <StyledBatteryBox direction="row">
      <Stack direction="row" spacing={battery ? 2 : 4} alignItems="center">
        <img src={battery ? battery.modelImage : batteryEmptySelection} width="100" />
        {battery ? (
          <Box>
            <Typography>{battery.modelName}</Typography>
            <Typography color={theme.palette.grey[600]}>
              <FormattedMessage id="app.labels.modelYears" />
            </Typography>
            <Typography>{battery.moduleLevelTitle}</Typography>
          </Box>
        ) : (
          <Typography color={theme.palette.grey[500]} variant="body1">
            <FormattedMessage id="evLevel.logBatteryLiveRepair.form.label.noBattery" />
          </Typography>
        )}
      </Stack>
    </StyledBatteryBox>
  )
}

export default QualifiedBatteryBox
