import { Box, Button, Stack, Typography } from '@mui/material'
import {
  selectEVLevelState,
  setIsBookingModalOpen,
  setSelectedCourse,
  toggleModuleAndCourseDrawer,
} from 'store/slices'
import { useAppDispatch, useAppSelector } from 'store'
import BatteryRepairContainer from 'components/EVLevel/EVDetail/LiveWorkingDetail/BatteryRepairContainer'
import BatteryRepairHeader from 'components/EVLevel/EVDetail/LiveWorkingDetail/BatteryRepairHeader'
import VehicleBrand from 'components/EVLevel/EVDetail/LiveWorkingDetail/Vehicle/VehicleBrand'
import VehicleModel from 'components/EVLevel/EVDetail/LiveWorkingDetail/Vehicle/VehicleModel'
import VehicleNavigator from 'components/EVLevel/EVDetail/LiveWorkingDetail/Vehicle/VehicleNavigator'
import CourseAlsoQualifiesFor from 'components/EVLevel/ModulesList/Course/CourseAlsoQualifiesFor'
import CourseCard from 'components/EVLevel/ModulesList/Course/CourseCard'
import CourseDescription from 'components/EVLevel/ModulesList/Course/CourseDescription'
import CourseHeader from 'components/EVLevel/ModulesList/Course/CourseHeader'
import ModuleCard from 'components/EVLevel/ModulesList/ModuleCard'
import ModuleCourseList from 'components/EVLevel/ModulesList/ModuleCourseList'
import ModuleHeader from 'components/EVLevel/ModulesList/ModuleHeader'
import { IBrand, IBrandModel, ICourse } from 'interfaces'
import BookedSessionTable from 'components/EVLevel/EVDetail/BookSession/BookedSessionTable'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatDate } from 'utils/formatDate'

type Props = {
  evLevelUUID: string
  brands: IBrand[]
  onClosePortalModal: () => void
  isManagerView: boolean
}

export default function LiveWorkingDetail({
  evLevelUUID,
  brands,
  onClosePortalModal,
  isManagerView,
}: Props) {
  const evLevelState = useAppSelector(selectEVLevelState)[evLevelUUID]
  const dispatch = useAppDispatch()
  const moduleAndCourseState = evLevelState.modulesAndCourses
  const intl = useIntl()
  const batteryRepairHeaderTitle = intl.formatMessage({
    id: 'evLevel.EVDetail.liveWorkingDetail.header.title',
  })

  const handleCourseModuleToggle = (instanceId: string) => {
    dispatch(
      toggleModuleAndCourseDrawer({
        levelUUID: evLevelUUID,
        moduleAndCourseKey: instanceId,
      }),
    )
  }

  let allModulesCount = 0

  brands.forEach((brand: IBrand) => {
    brand.models.forEach((model: IBrandModel) => {
      allModulesCount += model.availableModulesCount
    })
  })

  const hasBookSessionConfig = (course: ICourse) => {
    const { courseType, allowsBooking, status } = course
    const includedStatus = ['COMPLETED', 'FAILED'].includes(status)
    return (
      !includedStatus &&
      allowsBooking &&
      (courseType === 'CLASSROOM_PHYSICAL' || courseType === 'CLASSROOM_VIRTUAL')
    )
  }

  return (
    <BatteryRepairContainer
      open={moduleAndCourseState['battery-repair']}
      onToggleOpen={() => handleCourseModuleToggle('battery-repair')}
      header={
        <BatteryRepairHeader
          primaryText={batteryRepairHeaderTitle}
          courseCount={allModulesCount}
          iconAlt={'Module'}
        />
      }
    >
      <VehicleNavigator>
        {brands.map(brand => {
          const brandKey = `${evLevelUUID}-b-${brand.brand.brandUUID}`

          return (
            <VehicleBrand
              key={brandKey}
              brandName={brand.brand.brandName}
              open={moduleAndCourseState[brandKey]}
              onToggleOpen={() => handleCourseModuleToggle(brandKey)}
            >
              <Stack spacing={1}>
                {brand.models.map(model => {
                  const modelKey = `${brandKey}-m-${model.model.modelUUID}`

                  return (
                    <VehicleModel
                      key={modelKey}
                      modelName={model.model.modelName}
                      modelImage={model.model.modelImage}
                      unavaiableInMarket={model.model.unavailableInMarket}
                      open={moduleAndCourseState[modelKey]}
                      onToggleOpen={() => handleCourseModuleToggle(modelKey)}
                      count={model.modules.length}
                    >
                      <Stack spacing={1}>
                        {model.modules.map(module => {
                          const moduleKey = `${modelKey}-m-${module.levelModuleUUID}`

                          if (module.courses) {
                            const moduleIsOpen = moduleAndCourseState[moduleKey]

                            return (
                              <ModuleCard
                                isEmbeddedContent
                                open={moduleIsOpen}
                                onToggleOpen={() => handleCourseModuleToggle(moduleKey)}
                                key={moduleKey}
                                header={
                                  <ModuleHeader
                                    textFragment={
                                      <>
                                        <Typography
                                          textTransform="uppercase"
                                          color="#5B788F"
                                        >
                                          <FormattedMessage id="app.labels.modelYears" />
                                        </Typography>
                                        <Typography
                                          color="#525252"
                                          textTransform="uppercase"
                                          data-testid="primary-text"
                                          textAlign="left"
                                        >
                                          {module.moduleTitle}
                                        </Typography>
                                      </>
                                    }
                                    courseCount={module.courses.length}
                                    status={module.status}
                                    isAvailable={module.isAvailable}
                                    isLocked={module.isLocked}
                                    completedDate={module.completedDate}
                                  />
                                }
                              >
                                <ModuleCourseList>
                                  {module.courses.map(course => {
                                    const moduleCourseKey = `${modelKey}-mc-${module.levelModuleUUID}-${course.courseUUID}`
                                    const moduleCourseIsOpen =
                                      moduleAndCourseState[moduleCourseKey]
                                    const { bookedSession, courseUUID } = course
                                    const hasBookSession = hasBookSessionConfig(course)
                                    return (
                                      <CourseCard
                                        onToggleOpen={() =>
                                          handleCourseModuleToggle(moduleCourseKey)
                                        }
                                        open={moduleCourseIsOpen}
                                        key={moduleCourseKey}
                                        status={course.status}
                                        isAvailable={course.isAvailable}
                                        isLocked={course.isLocked}
                                        displayType="vehicleModuleDecendent"
                                        launchPath={course.launchPath}
                                        onClosePortalModal={onClosePortalModal}
                                        isManagerView={isManagerView}
                                        header={
                                          <>
                                            <CourseHeader
                                              courseType={course.courseType}
                                              primaryText={course.courseTitle}
                                              status={course.status}
                                              completedDate={course.completedDate}
                                              isAvailable={course.isAvailable}
                                              isLocked={course.isLocked}
                                              courseCode={course.courseCode}
                                            />
                                          </>
                                        }
                                      >
                                        {!bookedSession && course?.completedDate ? (
                                          <Typography
                                            data-testid="completed-date-text"
                                            color="rgb(151,151,151)"
                                            fontSize={14}
                                            textAlign="left"
                                            m="4px 24px"
                                          >
                                            <FormattedMessage
                                              id="evLevel.completed"
                                              values={{
                                                date: formatDate(course?.completedDate),
                                              }}
                                            />
                                          </Typography>
                                        ) : null}
                                        <CourseDescription>
                                          {course.courseDescription}
                                        </CourseDescription>
                                        {bookedSession ? (
                                          <Box m="16px 24px">
                                            <BookedSessionTable
                                              {...{ bookedSession }}
                                              courseUUID={courseUUID}
                                            />
                                          </Box>
                                        ) : (
                                          <>
                                            {hasBookSession && (
                                              <Button
                                                color="info"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                  m: '16px 24px',
                                                  '&:hover': {
                                                    borderColor: '#1E1E1E',
                                                    backgroundColor: '#1E1E1E',
                                                    color: 'white',
                                                  },
                                                }}
                                                onClick={() => {
                                                  if (courseUUID)
                                                    dispatch(
                                                      setSelectedCourse(courseUUID),
                                                    )
                                                  dispatch(setIsBookingModalOpen(true))
                                                }}
                                              >
                                                <FormattedMessage id="evLevel.labels.bookStaff" />
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </CourseCard>
                                    )
                                  })}
                                </ModuleCourseList>
                                {module?.qualifiesFor?.[0] ? (
                                  <CourseAlsoQualifiesFor
                                    qualifiesFor={module?.qualifiesFor}
                                  />
                                ) : null}
                              </ModuleCard>
                            )
                          }
                        })}
                      </Stack>
                    </VehicleModel>
                  )
                })}
              </Stack>
            </VehicleBrand>
          )
        })}
      </VehicleNavigator>
    </BatteryRepairContainer>
  )
}
