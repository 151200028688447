import BrandWrapper from 'components/common/BrandWrapper'
import ResetPasswordForm from 'components/ResetPasswordForm'

export default function ResetPassword() {
  return (
    <BrandWrapper>
      <ResetPasswordForm />
    </BrandWrapper>
  )
}
