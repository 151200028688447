import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

export type BreadcrumbItemProps = {
  href?: string
  text: ReactNode | string
}

interface BreadCrumbsProps {
  items: BreadcrumbItemProps[]
}

const Breadcrumbs: FC<BreadCrumbsProps> = ({ items }) => {
  const navigate = useNavigate()
  return (
    <MUIBreadcrumbs
      separator={<NavigateNextIcon fontSize="inherit" />}
      aria-label="breadcrumb"
      sx={{
        color: 'secondary.main',
      }}
    >
      {items.map((path, index) => {
        const isLast = index === items.length - 1

        return (
          <Typography
            color="secondary.main"
            textTransform="uppercase"
            variant="button"
            key={index}
            onClick={() => !isLast && path.href && navigate(path.href)}
            sx={{
              cursor: !isLast ? 'pointer' : 'text',
            }}
          >
            {path.text}
          </Typography>
        )
      })}
    </MUIBreadcrumbs>
  )
}

export default Breadcrumbs
