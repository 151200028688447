import { Box, Typography } from '@mui/material'
import { EVLevelIcon } from 'icons/EVIcons'
import { IValueLabel } from 'interfaces'
import { FormattedMessage } from 'react-intl'
import { EVLevelIconName } from 'types'

type LevelProps = {
  label: React.ReactNode
  level: EVLevelIconName
  value: EVLevelIconName
}

const Level = ({ label, level, value }: LevelProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography>{label}:</Typography>
      <Box display="flex" flexDirection="row" columnGap={1}>
        <EVLevelIcon evLevel={value} size={34} />
        <Typography> {level}</Typography>
      </Box>
    </Box>
  )
}

type Props = {
  certifiedLevel?: IValueLabel | null
  workingLevel?: IValueLabel | null
  targetLevel?: IValueLabel | null
}

export default function MyEvTrainingSummary(levels: Props) {
  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Typography textTransform="uppercase" mb={3} align="center">
        <FormattedMessage id="app.labels.myEVTraining" />
      </Typography>
      <MyEvTrainingSummaryContent
        certifiedLevel={levels.certifiedLevel}
        workingLevel={levels.workingLevel}
        targetLevel={levels.targetLevel}
      />
    </Box>
  )
}

export function MyEvTrainingSummaryContent(levels: Props) {
  const showCertifiedLevel = levels.certifiedLevel

  const showWorkingLevel =
    !levels.certifiedLevel ||
    (levels.certifiedLevel && levels.certifiedLevel.value !== levels.workingLevel?.value)
  return (
    <Box
      display="grid"
      gridTemplateColumns="auto"
      alignItems="center"
      columnGap={1}
      rowGap={1}
      padding={2}
    >
      {showCertifiedLevel && (
        <Level
          label={<FormattedMessage id="applicationTerm.certifiedLevel" />}
          level={levels.certifiedLevel?.label as EVLevelIconName}
          value={levels.certifiedLevel?.value as EVLevelIconName}
        />
      )}

      {showWorkingLevel ? (
        <Level
          label={<FormattedMessage id="applicationTerm.workingLevel" />}
          level={levels.workingLevel?.label as EVLevelIconName}
          value={levels.workingLevel?.value as EVLevelIconName}
        />
      ) : null}

      <Level
        label={<FormattedMessage id="applicationTerm.targetLevel" />}
        level={levels.targetLevel?.label as EVLevelIconName}
        value={levels.targetLevel?.value as EVLevelIconName}
      />
    </Box>
  )
}
