import { FC, useEffect, useState } from 'react'
import {
  AntSwitch,
  StyledCardCarrsBoxWrapper,
  StyledCardCarsContent,
  StyledCardCarsWrapper,
  StyledCardsDesktopWrapper,
  StyledColumnIconsBox,
  StyledDashboardDesktopWrapper,
  StyledFilterBoxDesktopWrapper,
  StyledSwitchBox,
} from 'components/DashboardInfos/style'
import { Box, Typography, Checkbox, Paper } from '@mui/material'
import { StyledDashboardTitle } from 'routes/Dashboard/style'
import { UncheckedIcon, CheckedIcon } from 'components/StaffMembers/Filters/styles'
import iconBatteryQualification from 'assets/svgs/icon_battery_qualification.svg'
import iconBattery from 'assets/svgs/icon_battery.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import {
  selectSelectedDashboardCapability,
  selectSelectedDashboardCards,
  setClearJobRoleAndSearchTermFilters,
  useAppDispatch,
  useAppSelector,
} from 'store'
import { filterByDashboard } from 'utils/filterByDashboard'
import CardInfo from 'components/DashboardInfos/CardInfo'
import CardActions from 'components/DashboardInfos/CardActions'
import CardCarCertifieldDesktop from 'components/DashboardInfos/CardCarCertifieldDesktop'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

interface IFilterState {
  jaguar: boolean
  rangeRover: boolean
  discovery: boolean
  defender: boolean
}

const DashboardInfoDesktop: FC = () => {
  const { levels, actionsRequired } = useAppSelector(selectSelectedDashboardCards)
  const { records } = useAppSelector(selectSelectedDashboardCapability)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [filteredArray, setFilteredArray] = useState<typeof records>(records ?? [])

  const [filterState, setFilterState] = useState<IFilterState>({
    jaguar: false,
    rangeRover: false,
    discovery: false,
    defender: false,
  })

  const [isCertified, setIsCertified] = useState<boolean>(false)

  const handleNavigateToBatteryList = () => {
    dispatch(setClearJobRoleAndSearchTermFilters())
    navigate('/dashboard/battery-list')
  }

  useEffect(() => {
    setFilteredArray(records)
  }, [records])

  useEffect(() => {
    setFilteredArray(
      records.filter(record => filterByDashboard(filterState, isCertified, record)),
    )
  }, [filterState, isCertified])

  return (
    <StyledDashboardDesktopWrapper>
      <StyledCardsDesktopWrapper>
        {levels.map(certification => (
          <CardInfo key={certification.levelUUID} data={certification} />
        ))}
        <CardActions data={actionsRequired} />
      </StyledCardsDesktopWrapper>

      <StyledCardCarsWrapper>
        <StyledFilterBoxDesktopWrapper>
          <StyledDashboardTitle as="div">
            <FormattedMessage id="dashboard.liveWorkingCapability.section.title" />
          </StyledDashboardTitle>

          <Box
            display="flex"
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography sx={{ cursor: 'pointer' }} onClick={handleNavigateToBatteryList}>
              <FormattedMessage id="dashboard.liveWorkingCapability.section.button.viewList" />
            </Typography>
            <Box display="flex" gap="20px">
              <Box>
                <Checkbox
                  id="jaguar"
                  edge="start"
                  disableRipple
                  checked={filterState.jaguar}
                  onChange={() =>
                    setFilterState({ ...filterState, jaguar: !filterState.jaguar })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="jaguar">Jaguar</label>
              </Box>
              <Box>
                <Checkbox
                  id="range-rover"
                  edge="start"
                  disableRipple
                  checked={filterState.rangeRover}
                  onChange={() =>
                    setFilterState({
                      ...filterState,
                      rangeRover: !filterState.rangeRover,
                    })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="range-rover">Range Rover</label>
              </Box>
              <Box>
                <Checkbox
                  id="discovery"
                  edge="start"
                  disableRipple
                  checked={filterState.discovery}
                  onChange={() =>
                    setFilterState({
                      ...filterState,
                      discovery: !filterState.discovery,
                    })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="discovery">Discovery</label>
              </Box>
              <Box>
                <Checkbox
                  id="defender"
                  edge="start"
                  disableRipple
                  checked={filterState.defender}
                  onChange={() =>
                    setFilterState({
                      ...filterState,
                      defender: !filterState.defender,
                    })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  sx={{ py: 0 }}
                />
                <label htmlFor="defender">Defender</label>
              </Box>
              <StyledSwitchBox>
                <AntSwitch
                  id="certified"
                  checked={isCertified}
                  onChange={() => setIsCertified(!isCertified)}
                />

                <label htmlFor="certified">
                  <FormattedMessage id="dashboard.liveWorkingCapability.section.switch.showCertifiedOnly" />
                </label>
              </StyledSwitchBox>
            </Box>
          </Box>
        </StyledFilterBoxDesktopWrapper>

        <StyledCardCarrsBoxWrapper component={Paper}>
          <StyledColumnIconsBox>
            <Box>
              <img src={iconBattery} alt="Icon Baterry" />
            </Box>

            <Box>
              <img src={iconBatteryQualification} alt="Icon Baterry Qualification" />
            </Box>
          </StyledColumnIconsBox>

          <StyledCardCarsContent>
            <Swiper
              slidesPerView={3}
              navigation={true}
              modules={[Navigation]}
              className="card-cars-swiper"
            >
              {filteredArray.map(
                ({
                  brand: { brandName },
                  count,
                  model: { modelImage, modelName, modelUUID },
                  year,
                }) => (
                  <SwiperSlide key={modelUUID}>
                    <CardCarCertifieldDesktop
                      {...{ brandName, count, modelImage, modelName, year, modelUUID }}
                    />
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </StyledCardCarsContent>
        </StyledCardCarrsBoxWrapper>
      </StyledCardCarsWrapper>
    </StyledDashboardDesktopWrapper>
  )
}

export default DashboardInfoDesktop
