import { FormattedMessage } from 'react-intl'
import { Button, ButtonBase, Collapse, Paper, Typography } from '@mui/material'
import { IBrand, IEVTrainingLevel } from 'interfaces'
import { useState } from 'react'
import LogLiveBatteryRepair from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair'
import QualifiedList from 'components/EVLevel/EVDetail/LiveWorkingDetail/QualifiedList'
import RepairQualificationsHeader from 'components/EVLevel/EVDetail/LiveWorkingDetail/RepairQualificationsHeader'

type IProps = {
  selectedEVTrainingLevel: IEVTrainingLevel
  brands: IBrand[]
}

const countQualifiedBattery = (brands: IBrand[]) => {
  let completeCount = 0
  brands.forEach(brand => {
    brand.models.forEach(model => {
      model.modules.forEach(module => {
        if (module.status === 'COMPLETED') {
          completeCount++
        }
      })
    })
  })

  return completeCount
}

export default function LiveRepairQualifications({
  brands,
  selectedEVTrainingLevel: { isExpired, expiryDate: expires, isLocked },
}: IProps) {
  const [open, setOpen] = useState(false)
  const [openLogBatteryModal, setOpenLogBatteryModal] = useState(false)

  const onCloseLogBatteryModal = () => {
    setOpenLogBatteryModal(false)
  }
  const onConfirmLogBatteryModal = () => {
    setOpenLogBatteryModal(false)
  }

  const qualifiedCount = countQualifiedBattery(brands)

  if (qualifiedCount < 1) {
    return null
  }

  return (
    <>
      <Paper sx={{ bgcolor: '#C5AD7E' }} elevation={0} square>
        <ButtonBase
          onClick={() => setOpen(!open)}
          sx={{ pr: 3, pl: 4, py: 2, width: '100%' }}
        >
          <RepairQualificationsHeader
            expires={expires as string}
            qualifiedCount={qualifiedCount}
          />
        </ButtonBase>

        <Collapse in={open}>
          {brands.map(brand => (
            <QualifiedList key={brand.brand.brandUUID} brand={brand} />
          ))}
        </Collapse>
      </Paper>
      {!isExpired && !isLocked && countQualifiedBattery(brands) > 0 ? (
        <Button
          onClick={() => {
            setOpenLogBatteryModal(true)
          }}
          fullWidth
          sx={{
            '&:hover': { background: '#444' },
            background: '#000',
          }}
        >
          <Typography sx={{ color: '#FFF', fontSize: '14px' }}>
            <FormattedMessage id="app.labels.logBatteryLiveRepair" />
          </Typography>
        </Button>
      ) : null}
      <LogLiveBatteryRepair
        brands={brands}
        open={openLogBatteryModal}
        onClose={onCloseLogBatteryModal}
        onConfirm={onConfirmLogBatteryModal}
      />
    </>
  )
}
