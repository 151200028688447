import { Box } from '@mui/material'

type Props = {
  children?: React.ReactNode
  shade?: 'light' | 'dark'
}

export default function Body({ children, shade = 'light' }: Props) {
  return (
    <Box flex={1} bgcolor={shade === 'light' ? '#F6F8FA' : '#EBF1F6'}>
      {children}
    </Box>
  )
}
