import { useMediaQuery, useTheme } from '@mui/material'
import { FC } from 'react'
import LevelSelectorDesktop from './LevelSelectorDesktop'
import LevelSelectorMobile from './LevelSelectorMobile'
import { LevelSelectorProps } from './types'

const EVLevelSelector: FC<LevelSelectorProps> = props => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  return isLargeScreen ? (
    <LevelSelectorDesktop {...props} />
  ) : (
    <LevelSelectorMobile {...props} />
  )
}

export default EVLevelSelector
