import { Box, Stack, Typography } from '@mui/material'
import { PersonIcon, StatusIcon } from 'icons/EVIcons'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StatusType } from 'types'
import { formatDate } from 'utils/formatDate'
import { StyledButtonBase } from 'components/EVLevel/EVSelector/LevelSelector/styles'
import expiredIcon from 'assets/svgs/icon_ls_expired.svg'
import recertificationIcon from 'assets/svgs/icon_ls_recertification.svg'

type LevelProps = {
  label: React.ReactNode
  iconName: string
  alt: string
  selected?: boolean
  status: StatusType | null
  expiryDate: string | null
  onClick: () => void
  isExpired: boolean
  isAvailable: boolean
  isLocked: boolean
  isDueForRecertification: boolean | undefined
}

const Level = ({
  label,
  iconName,
  alt,
  selected = false,
  status = null,
  expiryDate = null,
  isAvailable = false,
  isLocked = false,
  isDueForRecertification = false,
  isExpired,
  onClick,
}: LevelProps) => {
  return (
    <StyledButtonBase
      aria-label={label as string}
      aria-checked={selected}
      sx={{
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
      }}
      style={{ height: '100%' }}
      onClick={() => onClick()}
    >
      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        sx={{
          transition: 'all 250ms',
          transform: `scale(${selected ? 1.05 : 0.85})`,
          transformOrigin: '50% 100%',
        }}
      >
        <PersonIcon iconName={iconName} iconAltText={alt} />
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        mt={2}
      >
        <Typography>{label}</Typography>
        {status ? (
          <StatusIcon
            status={status}
            size="small"
            isAvailable={isAvailable}
            isLocked={isLocked}
          />
        ) : null}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        mt={1}
        sx={{
          opacity: !expiryDate && !isExpired ? 0 : 1,
          visibility: !expiryDate && !isExpired ? 'hidden' : 'visible',
          minHeight: !expiryDate && !isExpired ? 0 : 25,
        }}
      >
        <Typography variant="body2" color="#525252">
          {expiryDate && !isExpired ? <FormattedMessage id="evLevel.expires" /> : null}
          {isExpired ? <FormattedMessage id="evLevel.expired" /> : null}
        </Typography>
        {expiryDate ? (
          <Typography color="#838485" variant="body2">
            {formatDate(expiryDate)}
          </Typography>
        ) : null}
        {isDueForRecertification ? (
          <img src={recertificationIcon} width="24" alt="recertification" />
        ) : null}
        {isExpired ? <img src={expiredIcon} width="24" alt="expired" /> : null}
      </Stack>
    </StyledButtonBase>
  )
}

export default Level
