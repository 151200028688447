import { Box } from '@mui/material'
import { FC } from 'react'
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { SwiperSlide } from 'swiper/react'
import Level from './Level'
import { StyledSwiper } from './styles'
import { LevelSelectorProps } from './types'

const LevelSelectorDesktop: FC<LevelSelectorProps> = ({
  selectedIndex,
  evTrainingLevels,
  onChange,
}) => {
  return (
    <div>
      <Box mb={5} pt={4} display="flex">
        <StyledSwiper
          modules={[Navigation, Pagination]}
          slidesPerView={4}
          navigation
          pagination={{
            clickable: true,
          }}
          initialSlide={selectedIndex}
          keyboard={{
            enabled: true,
            onlyInViewport: false,
            pageUpDown: true,
          }}
        >
          {evTrainingLevels.map((evTrainingLevel, index) => {
            return (
              <SwiperSlide key={evTrainingLevel.levelUUID}>
                <Level
                  key={evTrainingLevel.levelUUID}
                  iconName={evTrainingLevel.iconName}
                  alt={evTrainingLevel.name}
                  onClick={() => onChange(index)}
                  selected={selectedIndex === index}
                  label={evTrainingLevel.name}
                  status={evTrainingLevel.status}
                  isAvailable={evTrainingLevel.isAvailable}
                  isLocked={evTrainingLevel.isLocked}
                  expiryDate={evTrainingLevel.expiryDate}
                  isExpired={evTrainingLevel.isExpired}
                  isDueForRecertification={evTrainingLevel.isDueForRecertification}
                />
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      </Box>
    </div>
  )
}

export default LevelSelectorDesktop
