import { store } from 'store'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat' // Import the required plugin
dayjs.extend(customParseFormat) // Use the customParseFormat plugin

// "2023-11-06T08:00:00" example
const expectedFormat = 'YYYY-MM-DDTHH:mm:ss'

export const formatDate = (date: string) => {
  const user = store.getState().user.user

  return dayjs(date, expectedFormat).format(user?.dateTimeFormatCulture || '')
}

export const formatDateTime = (date: string, timezone: string) => {
  const datePart = formatDate(date)
  const timePart = dayjs(date, expectedFormat).format('HH:mm')

  return `${datePart} ${timePart} ${timezone}`
}
