import { Box, CircularProgress, Container, Typography } from '@mui/material'

interface FullScreenLoaderProps {
  title?: string
}

const FullScreenLoader = ({ title = 'Loading...' }: FullScreenLoaderProps) => {
  return (
    <Container sx={{ height: '95vh' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={title ? 'column' : 'row'}
        sx={{ height: '100%', gap: 2 }}
      >
        <CircularProgress />
        {title && <Typography variant="body2">{title}</Typography>}
      </Box>
    </Container>
  )
}

export default FullScreenLoader
