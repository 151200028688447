import {
  Avatar,
  Card,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from '@mui/material'

export const StyledUserDetailsCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardHeader-avatar': {
    marginRight: theme.spacing(3),
  },
}))

export interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand: _, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const StyledUserName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  letterSpacing: '1.8px',
  textTransform: 'uppercase',
  fontSize: '18px',
}))

export const StyledUserJobRoles = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}))
export const StyledUserBussinessUnits = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}))
export const StyledUserInfo = styled(Typography)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& img': {
    width: '25px',
    height: '25px',
    display: 'inline-block',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
  '& span': {
    display: 'inline-flex',
    alignItems: 'center',
  },
}))

export const StyledSpan = styled('span')(() => ({
  width: 70,
}))
export const StyledUserAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[600],
  width: '72px',
  height: '72px',
  fontSize: '28px',
}))
