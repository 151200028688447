import { Box, styled } from '@mui/material'

export const StyledLevelRequested = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '60px',
  gap: '10px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  backgroundColor: theme.palette.grey[600],
  textTransform: 'uppercase',
  color: theme.palette.common.white,
}))

export const StyledDocument = styled(Box)(() => ({
  '.react-pdf__Document': {
    minWidth: '300px',
    minHeight: '300px',
    textAlign: 'center',
  },
  '.react-pdf__message': {
    padding: '20px',
    textAlign: 'center',
  },
  '.pagination': {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
}))
