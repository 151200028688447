import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material'
import downloadIcon from 'assets/svgs/icon_download.svg'
import BatteryRepairHeader from 'components/EVLevel/EVDetail/LiveWorkingDetail/BatteryRepairHeader'
import BatteryRepairContainer from 'components/EVLevel/EVDetail/LiveWorkingDetail/BatteryRepairContainer'
import VehicleBrand from 'components/EVLevel/EVDetail/LiveWorkingDetail/Vehicle/VehicleBrand'
import VehicleNavigator from 'components/EVLevel/EVDetail/LiveWorkingDetail/Vehicle/VehicleNavigator'
import LiveBatteryRepairsVehicle from 'components/EVLevel/EVDetail/LiveWorkingDetail/Vehicle/LiveBatteryRepairsVehicle'
import { IBrandRepair } from 'interfaces/IBrandRepair'
import batteryRepairsIcon from 'assets/svgs/icon_battery_repairs.svg'
import { useLazyRequestLogCertificateQuery } from 'store/api'
import { formatDate } from 'utils/formatDate'

type Props = {
  liveBatteryRepairs: IBrandRepair[]
}

function LiveBatteryRepair({
  brand,
  modelRepairs,
}: Pick<IBrandRepair, 'brand' | 'modelRepairs'>) {
  const [open, setOpen] = useState<boolean>(false)
  const [getLogCertificate, { data: docData, originalArgs, isLoading }] =
    useLazyRequestLogCertificateQuery()

  const onRequestLogCertifcate = (logRepairUUID: string) => {
    getLogCertificate(logRepairUUID)
  }

  useEffect(() => {
    if (docData) {
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        const url = docData.data
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          const blob = xhr.response
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = originalArgs as string
          link.click()
        }
        xhr.send()
      } else {
        window.open(docData.data, '_blank')?.focus()
      }
    }
  }, [docData])

  return (
    <VehicleBrand
      key={brand.brandUUID}
      brandName={brand.brandName}
      open={open}
      onToggleOpen={() => setOpen(!open)}
    >
      <Stack spacing={1}>
        {modelRepairs.map(modelAndModelVersionRepairs => {
          const logRepairsLength = modelAndModelVersionRepairs.modelVersionRepairs.reduce(
            (acc, modelVersion) => {
              return acc + modelVersion.logRepairs.length
            },
            0,
          )

          return (
            <LiveBatteryRepairsVehicle
              key={modelAndModelVersionRepairs.model.modelUUID}
              modelName={modelAndModelVersionRepairs.model.modelName}
              modelImage={modelAndModelVersionRepairs.model.modelImage}
              open={true}
              onToggleOpen={() => function () {}}
              count={logRepairsLength}
              modelYear={
                modelAndModelVersionRepairs.modelVersionRepairs[0].modelVersionName
              }
            >
              {modelAndModelVersionRepairs.modelVersionRepairs.map(modelVersion => {
                return (
                  <div key={modelVersion.modelVersionUUID}>
                    {modelVersion.logRepairs.map((log, idx) => {
                      return (
                        <Stack
                          key={log.logRepairUUID}
                          sx={{
                            background: '#5B788F',
                            color: '#FFF',
                            fontSize: '14px',
                          }}
                        >
                          {idx === 0 ? (
                            <Stack
                              flex={1}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{ padding: '4px 8px 4px 8px' }}
                            >
                              <Typography sx={{ fontSize: '14px' }}>
                                <FormattedMessage id="evLevel.liveBatteryRepairs.repairDate" />
                              </Typography>
                              <Typography sx={{ fontSize: '14px' }}>
                                <FormattedMessage id="evLevel.liveBatteryRepairs.downloadCertificate" />
                              </Typography>
                            </Stack>
                          ) : (
                            <Divider sx={{ background: '#C4C4C4' }} />
                          )}
                          <Stack
                            flex={1}
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                              background: '#FFF',
                              color: '#525252',
                              padding: '4px 8px 4px 8px',
                            }}
                          >
                            <Typography sx={{ fontSize: '14px', marginTop: 0.5 }}>
                              {formatDate(log.repairDate)}
                            </Typography>

                            <Button
                              onClick={() => {
                                onRequestLogCertifcate(log.logRepairUUID)
                              }}
                            >
                              {isLoading && originalArgs === log.logRepairUUID ? (
                                <CircularProgress size={20} color="primary" />
                              ) : (
                                <img src={downloadIcon} width="20" />
                              )}
                            </Button>
                          </Stack>
                        </Stack>
                      )
                    })}
                  </div>
                )
              })}
            </LiveBatteryRepairsVehicle>
          )
        })}
      </Stack>
    </VehicleBrand>
  )
}

export default function LiveBatteryRepairs({ liveBatteryRepairs }: Props) {
  const [open, setOpen] = useState<boolean>(false)

  const batteryRepairsCount = liveBatteryRepairs.reduce((acc, batteryRepairs) => {
    return acc + batteryRepairs.repairsCount
  }, 0)

  return (
    <BatteryRepairContainer
      open={open}
      onToggleOpen={() => setOpen(!open)}
      header={
        <BatteryRepairHeader
          primaryText="Live Battery Repairs"
          courseCount={batteryRepairsCount}
          icon={batteryRepairsIcon}
          iconAlt={'Live Battery Repairs'}
        />
      }
    >
      <VehicleNavigator>
        {liveBatteryRepairs.map((bm: IBrandRepair) => {
          return (
            <LiveBatteryRepair
              key={bm.brand.brandUUID}
              brand={bm.brand}
              modelRepairs={bm.modelRepairs}
            />
          )
        })}
      </VehicleNavigator>
    </BatteryRepairContainer>
  )
}
