import { Stack, Box, Typography, Avatar } from '@mui/material'
import batteryQualificationIcon from 'assets/svgs/icon_battery_qualification_white.svg'
import { FormattedMessage } from 'react-intl'
import { formatDate } from 'utils/formatDate'

type Props = {
  expires: string
  qualifiedCount: number
}

export default function RepairQualificationsHeader({ expires, qualifiedCount }: Props) {
  return (
    <Stack direction="row" alignItems="center" width="100%" spacing={3}>
      <Box minWidth={60}>
        <img src={batteryQualificationIcon} />
      </Box>

      <Box flexGrow={1}>
        <Typography color="white" textAlign="center">
          <FormattedMessage id="evLevel.liveRepairQualifications.liveBatteryRepairQualifications" />
        </Typography>

        {expires ? (
          <Typography color="white" textAlign="center">
            <FormattedMessage id="evLevel.liveRepairQualifications.expires" />
            {formatDate(expires)}
          </Typography>
        ) : null}
      </Box>

      <Box minWidth={60} justifyContent="flex-end" display="flex">
        <Avatar
          sx={{
            width: 40,
            height: 40,
            bgcolor: 'transparent',
            border: '1px solid white',
          }}
        >
          {qualifiedCount.toString()}
        </Avatar>
      </Box>
    </Stack>
  )
}
