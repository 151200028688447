import { Box, Typography, useTheme } from '@mui/material'
import { IRowCollapseProps } from 'interfaces'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ActionRequiredComponent from 'components/StaffMembers/ActionRequired'
import {
  StyledButtonDetails,
  StyledTableCell,
  StyledTableActionButtonCell,
  StyledTableRow,
} from './styles'

const RowTable: FC<IRowCollapseProps> = ({ row }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <StyledTableRow key={`${row.pin} ${row.name} ${row.jobRoles}`}>
      <StyledTableCell>
        <Box display="flex" flexDirection="column">
          <Typography color={theme.palette.grey[600]}>{row.pin}</Typography>
          <Typography width={142}>{row.name}</Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" flexDirection="column">
          {row.jobRoles.map((e: any, index: number, array: any) => (
            <Typography width={110} key={e}>
              {index === array.length - 1 ? e : `${e}, `}
            </Typography>
          ))}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box width={130}>{row.businessUnits}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" gap="10px" alignItems="center" width={115}>
          {row.certifiedLevel?.value && (
            <img src={`/assets/${row.certifiedLevel?.value}`} />
          )}
          {row.certifiedLevel?.label}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" gap="10px" alignItems="center" width={115}>
          {row.workingLevel?.value && <img src={`/assets/${row.workingLevel?.value}`} />}
          {row.workingLevel?.label}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" gap="10px" alignItems="center" width={115}>
          {row.targetLevel?.value && <img src={`/assets/${row.targetLevel?.value}`} />}
          {row.targetLevel?.label}
        </Box>
      </StyledTableCell>
      <StyledTableActionButtonCell>
        <Box display="flex">
          {row.actionsRequired && row?.actionsRequired[0] && (
            <ActionRequiredComponent
              actionRequired={row.actionsRequired[0]}
              userUUID={row.staffMemberUUID}
            />
          )}
        </Box>
      </StyledTableActionButtonCell>
      <StyledTableActionButtonCell>
        <Box paddingRight="10px">
          <StyledButtonDetails
            variant="outlined"
            size="small"
            onClick={() => navigate(`/user-details/${row.staffMemberUUID}`)}
          >
            <FormattedMessage id="membersList.viewDetails" />
          </StyledButtonDetails>
        </Box>
      </StyledTableActionButtonCell>
    </StyledTableRow>
  )
}

export default RowTable
