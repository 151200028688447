import { CircularProgress, Typography, Button } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import useOttRedirect from 'utils/useOttRedirect'
import { useAppSelector } from 'store'
import { selectUserIsImpersonate } from 'store/slices'

const NavigateToExcellenceButton = () => {
  const isImpersonate = useAppSelector(selectUserIsImpersonate)
  const [generatePortalRedirect, redirectState] = useOttRedirect({
    url: '/Account/LoginLMS',
    environment: 'portalLocation',
    params: {
      target: '/Mf/Dashboard',
    },
  })
  return (
    <Button
      color="secondary"
      variant="outlined"
      size="small"
      endIcon={
        redirectState.isLoading || redirectState.isSuccess ? (
          <CircularProgress size={18} />
        ) : (
          <ChevronRight />
        )
      }
      sx={{
        fontSize: '0.75rem',
        '&:hover': {
          borderColor: '#1E1E1E',
          backgroundColor: '#1E1E1E',
          color: 'white',
        },
      }}
      onClick={() => generatePortalRedirect()}
      disabled={isImpersonate}
    >
      <Typography>
        <FormattedMessage id="appNavigation.menuItem.excellence" />
      </Typography>
    </Button>
  )
}

export default NavigateToExcellenceButton
