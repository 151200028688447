import { Box, Container, styled } from '@mui/material'

export const StyledContainerSettingsWrapper = styled(Container)(
  ({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBlock: 20,
    gap: 30,

    '>p': {
      fontSize: 18,
      color: palette.grey[800],
      textTransform: 'uppercase',
      letterSpacing: 1.8,
      textAlign: 'center',
    },
  }),
)

export const StyledBrandBannersWrapper = styled(Box)(({ theme: { palette } }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 45,
  padding: '30px',

  ':after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: palette.common.white,
    borderRadius: 10,
    zIndex: 1,
  },

  div: {
    width: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,

    p: {
      fontSize: 16,
      color: palette.grey[800],
      textTransform: 'uppercase',
      textAlign: 'center',
      letterSpacing: 2.4,
    },
  },
}))

export const StyledCarsCardContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  width: '100%',
  justifyContent: 'center',
  padding: '0 30px',
  gap: 30,
  marginBottom: 12,

  '>div': {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
}))

type CardCarProps = {
  selected: boolean
}

export const StyledCardCarContent = styled(Box)<CardCarProps>(
  ({ theme: { palette }, selected }) => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    boxShadow: selected ? `0 0 0 1px ${palette.grey[600]}` : 'none',
    background: selected ? palette.grey[100] : 'transparent',
    borderRadius: 5,

    label: {
      width: '100%',
      cursor: 'pointer',
    },

    '>span': {
      position: 'absolute',
      top: 8,
      right: 0,
    },
  }),
)

export const StyledButtonBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  marginBottom: '40px',

  button: {
    color: palette.grey[700],
    background: 'transparent',
    border: `1px solid ${palette.grey[500]}`,
    fontSize: 12,

    '&:hover': {
      background: palette.grey[800],
      color: palette.common.white,
    },
  },
}))
