import { Box, Select, styled } from '@mui/material'

export const StyledFilterWrapper = styled(Box)(({ theme: { spacing, palette } }) => ({
  background: palette.grey[100],
  padding: spacing(3),
  borderRadius: spacing(1.25),
  marginBlock: spacing(2.5),
}))

export const StyledBoxSelectsItens = styled(Box)(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '65% 35%',
  gap: spacing(1.25),
  marginBottom: spacing(2.5),
}))

export const StyledBoxSearchItems = styled(Box)(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: spacing(1.25),
  marginBottom: spacing(2.5),
}))

export const StyledSelected = styled(Select)(({ theme: { spacing, palette } }) => ({
  background: palette.common.white,
  height: spacing(5),
  padding: '0 !important',
  borderRadius: '0 !important',
  '>div': {
    paddingBottom: '0 !important',
  },
}))

export const StyledBoxCollapseButtons = styled(Box)(
  ({ theme: { palette, spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-end',
    justifyContent: 'space-between',
    gap: spacing(1.25),

    button: {
      padding: '8px 12px',
      background: 'transparent',
      textTransform: 'uppercase',
      fontSize: spacing(1.5),
      border: '1px solid #8C8C8C',

      ':nth-of-type(1)': {
        background: '#1E1E1E',
        border: '1px solid #1E1E1E',
        color: palette.common.white,
      },

      '&:hover': {
        background: '#1E1E1E',
        border: '1px solid #1E1E1E',
        color: palette.common.white,
      },
    },
    '@media (min-width: 1225px)': {
      justifyContent: 'space-between',
      justifySelf: 'initial',
      gap: spacing(1.25),
    },
  }),
)
