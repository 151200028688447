// Module header component

import { Badge, Box, Stack, Typography, useTheme } from '@mui/material'
import moduleIcon from 'assets/svgs/icon_course_module.svg'
import StatusOrCount from 'components/common/StatusOrCount'
import { FormattedMessage } from 'react-intl'
import { StatusType } from 'types'
import { formatDate } from 'utils/formatDate'

type Props = {
  textFragment?: React.ReactNode
  primaryText?: string
  status?: StatusType
  isAvailable: boolean
  isLocked: boolean
  courseCount?: number
  completedDate?: string | null
  lockedReason?: string
  lockedReasonValues?: Record<string, string | number> | undefined
}

export default function ModuleHeader({
  textFragment,
  primaryText,
  status,
  isAvailable,
  isLocked,
  courseCount,
  completedDate,
  lockedReason = undefined,
  lockedReasonValues = undefined,
}: Props) {
  const { palette } = useTheme()

  return (
    <Stack direction="row" alignItems="center" spacing={2} width="100%">
      <Box minWidth={70} display="flex" justifyContent="center">
        <Badge
          data-testid="course-count-badge"
          color="primary"
          badgeContent={courseCount}
          invisible={!isAvailable}
          sx={{
            '& .MuiBadge-badge	': {
              backgroundColor: 'rgb(239, 243, 247)',
              border: '2px solid white',
              color: 'rgb(82,82,82)',
              top: 34,
              width: 28,
              height: 28,
              borderRadius: '50%',
              left: 15,
            },
          }}
        >
          <img src={moduleIcon} alt="Module" />
        </Badge>
      </Box>
      <Stack flex={1} alignItems="flex-start">
        {textFragment ? (
          textFragment
        ) : (
          <Typography
            color="#525252"
            textTransform="uppercase"
            data-testid="primary-text"
            textAlign="left"
          >
            {primaryText}
          </Typography>
        )}

        {completedDate ? (
          <Typography
            data-testid="secondary-text"
            color="rgb(151,151,151)"
            fontSize={14}
            textAlign="left"
          >
            <FormattedMessage
              id="evLevel.completed"
              values={{
                date: formatDate(completedDate),
              }}
            />
          </Typography>
        ) : null}

        {lockedReason && (
          <Typography color={palette.error.main} fontSize={14} textTransform="initial">
            <FormattedMessage
              id={`errorCodes.${lockedReason}`}
              values={lockedReasonValues}
            />
          </Typography>
        )}
      </Stack>
      <StatusOrCount
        status={status}
        count={courseCount}
        isAvailable={isAvailable}
        isLocked={isLocked}
      />
    </Stack>
  )
}
