import { Theme, Typography, useMediaQuery } from '@mui/material'

type Props = {
  children: string | React.ReactNode
}

export default function SubHeaderLabel({ children }: Props) {
  const isBellowSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Typography
      fontFamily={'JLR Emeric ExtraLight'}
      fontSize={isBellowSmall ? 18 : 24}
      fontWeight={100}
      color="white"
      textTransform="uppercase"
      letterSpacing={2.4}
      align={isBellowSmall ? 'left' : 'center'}
    >
      {children}
    </Typography>
  )
}
