import React from 'react'
import evBoltHeader from 'assets/svgs/ev_bolt_header.svg'
import { Container, Box, Theme, useMediaQuery } from '@mui/material'
import Breadcrumbs, { BreadcrumbItemProps } from 'components/common/Breadcrumbs'
import NavigateToExcellenceButton from 'components/layout/SubHeader/NavigateToExcellenceButton'
interface Props {
  children?: React.ReactNode
  breadcrumbItems?: BreadcrumbItemProps[]
}

export default function SubHeader({ breadcrumbItems, children }: Props) {
  const isBellowSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundImage: `linear-gradient(94deg, rgba(84,107,128,1) 0%, rgba(84,107,128,1) 30%, rgba(140,161,161,1) 100%)`,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          gap: 0.5,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          backgroundImage: isBellowSmall ? 'none' : `url(${evBoltHeader})`,
          backgroundPosition: 'center right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          minHeight: '105px',
          py: 2,
        }}
      >
        {children}
        {breadcrumbItems ? <Breadcrumbs items={breadcrumbItems} /> : null}
        {breadcrumbItems ? <NavigateToExcellenceButton /> : null}
      </Container>
    </Box>
  )
}
