import { Box } from '@mui/material'

type Props = {
  children?: React.ReactNode
}

export default function LevelSelectorPanel({ children }: Props) {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {children}
    </Box>
  )
}
