import { CSSProperties } from 'react'

type PersonIconProps = {
  iconName: string
  iconAltText: string
  customStyles?: CSSProperties
}

export default function PersonIcon({
  iconName,
  iconAltText,
  customStyles = {},
}: PersonIconProps) {
  return (
    <img
      src={process.env.PUBLIC_URL + `/assets/evLevelIcons/${iconName}`}
      alt={iconAltText}
      style={customStyles}
    />
  )
}
