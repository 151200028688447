import { CircularProgress, Paper, Popover, Stack, Typography } from '@mui/material'
import actionRequiredIcon from 'assets/svgs/icon_ls_expired.svg'
import {
  ActionRequiredProps,
  IApproveOrDeclineProposalParams,
  IEVApiResponse,
  ISubmitAlternativeProposalParams,
} from 'interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {
  useApproveOrRejectTrainingLevelProposalCommandMutation,
  useSubmitAlternativeProposalCommandMutation,
} from 'store/api'
import * as yup from 'yup'
import ConfirmEvTraining from 'components/StaffMembers/ActionRequired/components/ConfirmEvTraining'
import ConfirmExpCriteria from 'components/StaffMembers/ActionRequired/components/ConfirmExpCriteria'
import ConfirmExpired from 'components/StaffMembers/ActionRequired/components/ConfirmExpired'
import ConfirmRecertification from 'components/StaffMembers/ActionRequired/components/ConfirmRecertification'
import ProposeAlternative from 'components/StaffMembers/ActionRequired/components/ProposeAlternative'
import RejectRequest from 'components/StaffMembers/ActionRequired/components/RejectRequest'
import ToDo from 'components/StaffMembers/ActionRequired/components/ToDo'
import { StyledARIconButton } from 'components/StaffMembers/ActionRequired/style'
import { IActionRequiredFormInput } from 'components/StaffMembers/ActionRequired/types'
import { FormattedMessage, useIntl } from 'react-intl'

type ActionRequiredComponentProps = ActionRequiredProps & {
  userUUID: string
}

export default function ActionRequired({
  actionRequired,
  userUUID,
}: ActionRequiredComponentProps) {
  const [isOpen, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const intl = useIntl()
  const selectNewTargetLevel = intl.formatMessage({
    id: 'staffMembers.actionRequired.selectNewTargetLevel',
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    //setAnchorEl(null)
    setOpen(false)
  }

  const schema = yup.object().shape({
    // newTargetLevel is required only if formStep is 1 (Propose Alternative)
    newTargetLevel: yup.string().when('formStep', {
      is: 1,
      then: yup.string().required('New target level is required'),
      otherwise: yup.string().notRequired(),
    }),
    // rejectReason is required only if formStep is 2 (Reject)
    rejectReason: yup.string().when('formStep', {
      is: 2,
      then: yup
        .string()
        .required('Reject reason is required')
        .min(10, 'Min 10 characters'),
      otherwise: yup.string().notRequired(),
    }),
  })

  const methods = useForm<IActionRequiredFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      actionRequired,
      formStep: 0,
      newTargetLevel: '',
      rejectReason: '',
      isApproved: false,
    },
    mode: 'onChange',
  })

  const { watch, handleSubmit } = methods
  const [currentStep, isApproved] = watch(['formStep', 'isApproved'])
  const requestResponse = intl.formatMessage({
    id: isApproved
      ? 'staffMembers.actionRequired.requestResponse.success'
      : 'staffMembers.actionRequired.requestResponse.declined',
  })
  const alternativeProposal = intl.formatMessage({
    id: 'staffMembers.actionRequired.alternativeProposal',
  })

  const [
    acceptOrDeclineProposal,
    { isLoading: isLoadingApproval, isSuccess: isSuccessApproval, error: errorApproval },
  ] = useApproveOrRejectTrainingLevelProposalCommandMutation()

  const [
    submitAlternativeProposal,
    {
      isLoading: isLoadingAlternative,
      isSuccess: isSuccessAlternative,
      error: errorAlternative,
    },
  ] = useSubmitAlternativeProposalCommandMutation()

  const onFormSubmit = (data: IActionRequiredFormInput) => {
    const { formStep, newTargetLevel, rejectReason, isApproved } = data

    if ([0, 2].includes(formStep as number)) {
      const acceptOrDeclinePayload: IApproveOrDeclineProposalParams = {
        isApproved,
        proposalUUID: actionRequired.proposalUUID as string,
        rejectReason: rejectReason || '',
      }

      acceptOrDeclineProposal(acceptOrDeclinePayload)
    } else {
      const proposeAlternativePayload: ISubmitAlternativeProposalParams = {
        userUUID,
        previousProposalUUID: actionRequired.proposalUUID as string,
        levelUUID: newTargetLevel as string,
      }
      submitAlternativeProposal(proposeAlternativePayload)
    }
  }

  useEffect(() => {
    if (isSuccessApproval) {
      handleClose()
      toast.success(requestResponse)
    }
  }, [isSuccessApproval])

  useEffect(() => {
    if (isSuccessAlternative) {
      handleClose()
      toast.success(alternativeProposal)
    }
  }, [isSuccessAlternative])

  useEffect(() => {
    if (errorApproval) {
      toast.error((errorApproval as IEVApiResponse).data.errors[0].message)
    }
  }, [errorApproval])

  useEffect(() => {
    if (errorAlternative) {
      toast.error((errorAlternative as IEVApiResponse).data.errors[0].message)
    }
  }, [errorAlternative])

  useEffect(() => {
    methods.setValue('isApproved', currentStep === 0)
  }, [currentStep])

  const renderTiles = () => {
    switch (currentStep) {
      case 0:
        if (actionRequired.actionType === 'CONFIRM_EV_TRAINING')
          return <ConfirmEvTraining {...{ isLoadingApproval }} />
        if (actionRequired.actionType === 'CONFIRM_EXPERIENCE_CRITERIA')
          return <ConfirmExpCriteria {...{ handleClose, actionRequired, userUUID }} />
        if (actionRequired.actionType === 'EXPIRED')
          return (
            <ConfirmExpired
              {...{
                handleClose,
                actionRequired,
                userUUID,
                actionType: actionRequired.actionType,
              }}
            />
          )
        if (actionRequired.actionType === 'RECERTIFICATION')
          return (
            <ConfirmRecertification
              {...{
                handleClose,
                actionRequired,
                userUUID,
                actionType: actionRequired.actionType,
              }}
            />
          )

        return <ToDo {...{ handleClose }} />
      case 1:
        return (
          <ProposeAlternative
            {...{
              title: selectNewTargetLevel,
              userUUID,
              isLoadingAlternative,
            }}
          />
        )
      case 2:
        return <RejectRequest {...{ isLoadingApproval }} />
      default:
        return <CircularProgress />
    }
  }

  return (
    <Stack spacing={2}>
      <StyledARIconButton onClick={handleClick}>
        <img src={actionRequiredIcon} alt="Action Required" width="25" />
        <Typography variant="button" sx={{ fontSize: '12px', paddingLeft: '8px' }}>
          <FormattedMessage id="app.labels.actionRequired" />
        </Typography>
      </StyledARIconButton>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableRestoreFocus
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Paper sx={{ padding: '14px' }} key={currentStep}>
              {renderTiles()}
            </Paper>
          </form>
        </FormProvider>
      </Popover>
    </Stack>
  )
}
