import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import genericIcon from 'assets/svgs/icon_cs_qualification.svg'
import { FC, useState } from 'react'
import {
  StyledBoxCollapseItem,
  StyledBoxCollapseItemImg,
  StyledButtonDetails,
  StyledListCollapse,
  StyledListCollapseItem,
  StyledTypographyRounded,
} from './style'

import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { IBatteryListRowOrCollapseProps } from 'interfaces'
import { formatDate } from 'utils/formatDate'

interface IBatteryListRowCollapseProps {
  row: IBatteryListRowOrCollapseProps
}

const BatteryListRowCollapse: FC<IBatteryListRowCollapseProps> = ({ row }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const totalQualify = Number(row.numberQualifications) + Number(row.numberRepairs)

  return (
    <Box component={Paper} key={row.pin + row.jobRoles} mb={0.5}>
      <StyledListCollapse>
        <ListItem>
          <ListItemButton
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            onClick={() => setOpen(state => !state)}
          >
            <StyledBoxCollapseItemImg>
              <img src={genericIcon} alt="Without Working Level" />

              <Box>
                <Typography color={theme.palette.grey[600]}>{row.pin}</Typography>
                <Typography color={theme.palette.grey[700]} textTransform="capitalize">
                  {row.name}
                </Typography>
              </Box>
            </StyledBoxCollapseItemImg>

            <StyledTypographyRounded>{totalQualify}</StyledTypographyRounded>
          </ListItemButton>
        </ListItem>
      </StyledListCollapse>

      <Collapse in={open}>
        <StyledBoxCollapseItem>
          <StyledListCollapseItem>
            <Box>
              <ListItem>
                <FormattedMessage id="membersList.jobRole" />:
                <Typography display="flex" gap={1}>
                  {row.jobRoles.join(', ')}
                </Typography>
              </ListItem>
              <ListItem>
                <FormattedMessage id="membersList.businessUnit" />:
                <Typography>{row.businessUnits.join(', ')}</Typography>
              </ListItem>
              <ListItem>
                <FormattedMessage id="batteryList.table.header.LBRPExpiry" />:
                <Typography>{formatDate(row.expiryDate)}</Typography>
              </ListItem>
            </Box>

            <Box>
              <ListItem>
                <FormattedMessage id="batteryList.table.header.certifications" />:
                <StyledTypographyRounded>
                  {row.numberQualifications}
                </StyledTypographyRounded>
              </ListItem>

              <ListItem>
                <FormattedMessage id="batteryList.table.header.batteryRepairs" />:
                <StyledTypographyRounded>{row.numberRepairs}</StyledTypographyRounded>
              </ListItem>
            </Box>

            <ListItem>
              <StyledButtonDetails
                variant="outlined"
                size="small"
                onClick={() => navigate(`/user-details/${row.staffMemberUUID}`)}
              >
                <FormattedMessage id="membersList.viewDetails" />
              </StyledButtonDetails>
            </ListItem>
          </StyledListCollapseItem>
        </StyledBoxCollapseItem>
      </Collapse>
    </Box>
  )
}

export default BatteryListRowCollapse
