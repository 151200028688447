import {
  IApiResponse,
  IApproveOrDeclineProposalParams,
  IBookStaffMemberParams,
  IGetAlternativeProposalConfigQueryResponse,
  IGetClassroomDetailsForBookingParams,
  IGetClassroomDetailsForBookingResponse,
  IGetExperienceCriteriaQuery,
  IGetMyStaffConfigQueryParams,
  IGetMyStaffConfigQueryResponse,
  IGetNominationConfigQueryResponse,
  IGetSessionDetailForBookingParams,
  IGetSessionDetailForBookingResponse,
  IGetUserDetailsQueryResponse,
  IRemoveStaffFromWaitListCommandProps,
  ISearchMyStaffParams,
  ISearchMyStaffResponse,
  ISubmitAlternativeProposalParams,
  ISubmitEvTrainingNominationParams,
  IWithDrawUserFromSessionCommandProps,
} from 'interfaces'

import { evAPI } from './api'

export const myStaffApi = evAPI.injectEndpoints({
  endpoints: builder => ({
    getMyStaffConfigQuery: builder.query<
      IGetMyStaffConfigQueryResponse['data'],
      IGetMyStaffConfigQueryParams
    >({
      query: props => {
        return {
          url: 'Management/GetMyStaffConfigQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      transformResponse: ({ data }: IGetMyStaffConfigQueryResponse) => data,

      providesTags: ['MyStaffConfig'],
    }),
    searchMyStaff: builder.query<ISearchMyStaffResponse['data'], ISearchMyStaffParams>({
      query: props => {
        return {
          url: 'Management/SearchMyStaffQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      transformResponse: ({ data }: ISearchMyStaffResponse) => data,
      providesTags: ['SearchMyStaff'],
    }),
    getUserDetails: builder.query<IGetUserDetailsQueryResponse['data'], string>({
      query: id => {
        return {
          url: `Users/GetUserDetailsQuery?userUUID=${id}`,
          method: 'GET',
        }
      },
      transformResponse: ({ data }: IGetUserDetailsQueryResponse) => data,
      providesTags: ['UserDetails'],
    }),
    getNominationConfig: builder.query<IGetNominationConfigQueryResponse['data'], string>(
      {
        query: id => {
          return {
            url: `Management/GetEvTrainingNominationConfigQuery?userUUID=${id}`,
            method: 'GET',
          }
        },
        transformResponse: ({ data }: IGetAlternativeProposalConfigQueryResponse) => data,
      },
    ),
    getAlternativeProposalConfigQuery: builder.query<
      IGetAlternativeProposalConfigQueryResponse['data'],
      string
    >({
      query: id => {
        return {
          url: `Management/GetAlternativeProposalConfigQuery?userUUID=${id}`,
          method: 'GET',
        }
      },
      transformResponse: ({ data }: IGetAlternativeProposalConfigQueryResponse) => data,
      providesTags: ['GetAlternativeProposalConfig'],
    }),
    submitEvTrainingNominationCommand: builder.mutation<
      IApiResponse,
      ISubmitEvTrainingNominationParams
    >({
      query: body => {
        return {
          url: 'Management/SubmitEvTrainingNominationCommand',
          method: 'POST',
          body: { ...body },
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(myStaffApi.util.invalidateTags(['EVTrainingDetails']))
        } catch (error) {
          return
        }
      },
    }),
    submitAlternativeProposalCommand: builder.mutation<
      IApiResponse,
      ISubmitAlternativeProposalParams
    >({
      query: body => {
        return {
          url: 'Management/SubmitAlternativeProposalCommand',
          method: 'POST',
          body: { ...body },
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(myStaffApi.util.invalidateTags(['SearchMyStaff']))
        } catch (error) {
          return
        }
      },
    }),
    approveOrRejectTrainingLevelProposalCommand: builder.mutation<
      IApiResponse,
      IApproveOrDeclineProposalParams
    >({
      query: body => {
        return {
          url: 'Management/ApproveOrRejectTrainingLevelProposalCommand',
          method: 'POST',
          body: { ...body },
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(myStaffApi.util.invalidateTags(['SearchMyStaff']))
        } catch (error) {
          return
        }
      },
    }),
    getExperienceCriteria: builder.query<IGetExperienceCriteriaQuery['data'], string>({
      query: () => {
        return {
          url: 'Management/GetExperienceCriteriaConfigQuery',
          method: 'GET',
        }
      },
      transformResponse: ({ data }: IGetExperienceCriteriaQuery) => data,
    }),
    submitExperienceCriteriaCommand: builder.mutation<IApiResponse, FormData>({
      query: props => {
        return {
          url: 'Management/SubmitExperienceCriteriaCommand',
          method: 'POST',
          body: props,
        }
      },
      invalidatesTags: ['EVTrainingDetails', 'EVTrainingLevels'],
    }),
    submitBookStaffMemberCommand: builder.mutation<IApiResponse, IBookStaffMemberParams>({
      query: props => {
        return {
          url: 'Management/Booking/BookStaffMemberCommand',
          method: 'POST',
          body: props,
        }
      },
      invalidatesTags: ['EVTrainingDetails', 'GetClassroomDetailsForBooking'],
    }),
    submitWaitlistStaffMemberCommand: builder.mutation<
      IApiResponse,
      IBookStaffMemberParams
    >({
      query: props => {
        return {
          url: 'Management/Booking/WaitlistStaffMemberCommnad',
          method: 'POST',
          body: props,
        }
      },
      invalidatesTags: ['EVTrainingDetails', 'GetClassroomDetailsForBooking'],
    }),
    submitWithdrawUserFromSessionCommand: builder.mutation<
      IApiResponse,
      IWithDrawUserFromSessionCommandProps
    >({
      query: props => {
        return {
          url: 'Management/Booking/WithdrawUserFromSessionCommand',
          method: 'POST',
          body: props,
        }
      },
      invalidatesTags: ['EVTrainingDetails', 'GetClassroomDetailsForBooking'],
    }),
    removeStaffFromWaitListCommand: builder.mutation<
      IApiResponse,
      IRemoveStaffFromWaitListCommandProps
    >({
      query: props => {
        return {
          url: 'Management/Booking/RemoveStaffFromWaitListCommand',
          method: 'POST',
          body: props,
        }
      },
      invalidatesTags: ['EVTrainingDetails', 'GetClassroomDetailsForBooking'],
    }),
    getClassroomDetailsForBooking: builder.query<
      IGetClassroomDetailsForBookingResponse['data'],
      IGetClassroomDetailsForBookingParams
    >({
      query: ({ courseUUID, userUUID }) => {
        return {
          url: `Management/Booking/GetClassroomDetailsForBookingQuery?courseUUID=${courseUUID}&userUUID=${userUUID}`,
          method: 'GET',
        }
      },
      transformResponse: ({ data }: IGetClassroomDetailsForBookingResponse) => data,
      providesTags: ['GetClassroomDetailsForBooking'],
    }),
    getSessionDetailForBooking: builder.query<
      IGetSessionDetailForBookingResponse['data'],
      IGetSessionDetailForBookingParams
    >({
      query: ({ sessionUUID, userUUID }) => {
        return {
          url: `Management/Booking/GetSessionDetailForBookingQuery?sessionUUID=${sessionUUID}&userUUID=${userUUID}`,
          method: 'GET',
        }
      },
      transformResponse: ({ data }: IGetSessionDetailForBookingResponse) => data,
    }),
  }),
})

export const {
  useGetUserDetailsQuery,
  useLazySearchMyStaffQuery,
  useGetMyStaffConfigQueryQuery,
  useGetAlternativeProposalConfigQueryQuery,
  useGetNominationConfigQuery,
  useSubmitAlternativeProposalCommandMutation,
  useApproveOrRejectTrainingLevelProposalCommandMutation,
  useGetExperienceCriteriaQuery,
  useSubmitExperienceCriteriaCommandMutation,
  useSubmitEvTrainingNominationCommandMutation,
  useSubmitBookStaffMemberCommandMutation,
  useSubmitWaitlistStaffMemberCommandMutation,
  useSubmitWithdrawUserFromSessionCommandMutation,
  useRemoveStaffFromWaitListCommandMutation,
  useGetClassroomDetailsForBookingQuery,
  useLazyGetClassroomDetailsForBookingQuery,
  useLazyGetSessionDetailForBookingQuery,
} = myStaffApi
