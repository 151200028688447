import { Paper, Stack } from '@mui/material'
import { IBrand } from 'interfaces'
import { getCompletedModules } from 'utils/EVUtils/courseUtils/getCompletedModules'
import BrandHeader from 'components/EVLevel/EVDetail/LiveWorkingDetail/BrandHeader'
import QualifiedModel from 'components/EVLevel/EVDetail/LiveWorkingDetail/QualifiedModel'

type Props = {
  brand: IBrand
}

export default function QualifiedList({ brand }: Props) {
  const { models } = brand

  const completedModules = getCompletedModules(models)

  if (completedModules.length > 0) {
    return (
      <>
        <BrandHeader brand={brand.brand} />

        <Stack m={1} spacing={1}>
          {completedModules.map(info => (
            <Paper key={info.module.levelModuleUUID} sx={{ p: 2 }} elevation={0}>
              <QualifiedModel info={info} />
            </Paper>
          ))}
        </Stack>
      </>
    )
  }
  return null
}
