import { Typography } from '@mui/material'
import { ICompletedLogBatteryItems } from 'interfaces'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { getCompletedModules } from 'utils/EVUtils/courseUtils/getCompletedModules'
import QualifiedModel from 'components/EVLevel/EVDetail/LiveWorkingDetail/QualifiedModel'
import { IFormInput } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair'
import { StyledModelItem } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/styles'

const SelectModel = () => {
  const { setValue, watch } = useFormContext<IFormInput>()
  const brand = watch('selectedBrand')

  const handleSelectModel = (module: ICompletedLogBatteryItems) => {
    const {
      model: {
        model: { modelImage, modelName },
      },
      module: { moduleTitle },
    } = module
    const brandAndModel = {
      moduleLevelUUID: module.module.levelModuleUUID,
      modelImage,
      modelName,
      moduleLevelTitle: moduleTitle,
    }
    setValue('brandAndModel', brandAndModel)
    setValue('formStep', 0)
  }

  const { models } = brand

  const completedModules = getCompletedModules(models)

  return (
    <>
      <Typography variant="h6" align="center" gutterBottom>
        <FormattedMessage id="evLevel.logBatteryLiveRepair.models.title" />
      </Typography>
      {completedModules.map((info: ICompletedLogBatteryItems) => (
        <StyledModelItem
          key={info.module.levelModuleUUID}
          onClick={() => handleSelectModel(info)}
        >
          <QualifiedModel info={info} withoutBadge />
        </StyledModelItem>
      ))}
    </>
  )
}

export default SelectModel
