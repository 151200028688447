import { Box, ButtonBase, Collapse, Paper, Typography } from '@mui/material'
import jaguarLogo from 'assets/svgs/jaguar_black_logo.svg'
import rangeRoverLogo from 'assets/svgs/range_rover_black_logo.svg'
import defenderLogo from 'assets/svgs/defender_black_logo.svg'
import discoveryLogo from 'assets/svgs/discovery_black_logo.svg'
import { Stack } from '@mui/system'
import { StatusType } from 'types'
import { StatusIcon } from 'icons/EVIcons'

type Props = {
  brandName: string
  children?: React.ReactNode
  open: boolean
  onToggleOpen: () => void
  status?: StatusType
  isAvailable?: boolean
  isLocked?: boolean
  count?: number
}

export default function VehicleBrand({
  open,
  onToggleOpen,
  brandName,
  children,
  status,
  isAvailable,
  isLocked,
}: Props) {
  const BrandLogo = () => {
    switch (brandName) {
      case 'Jaguar':
        return <img src={jaguarLogo} alt="Jaguar Logo" />
      case 'Range Rover':
        return <img src={rangeRoverLogo} alt="Range Rover Logo" />
      case 'Discovery':
        return <img src={discoveryLogo} alt="Discovery Logo" />
      case 'Defender':
        return <img src={defenderLogo} alt="Defender Logo" />
      default:
        return (
          <Typography
            data-testid="primary-text"
            textTransform="uppercase"
            letterSpacing={2.5}
            color="#1E1E1E"
          >
            {brandName}
          </Typography>
        )
    }
  }
  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor: 'rgb(244,247,249)',
        border: '1px solid rgb(238 238 238)',
      }}
    >
      <ButtonBase
        onClick={onToggleOpen}
        sx={{
          py: 1,
          minHeight: 65,
          width: '100%',
          ':focus-visible': { outline: '1px solid #cad1d7' },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <Stack flex={1} alignItems="center" justifyContent="center">
            <BrandLogo />
          </Stack>
          {status && isAvailable && isLocked ? (
            <StatusIcon status={status} isAvailable={isAvailable} isLocked={isLocked} />
          ) : null}
        </Stack>
      </ButtonBase>
      <Collapse in={open}>
        <Box p={1}>{children}</Box>
      </Collapse>
    </Paper>
  )
}
