import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import checkIcon from 'assets/svgs/courseIcons/icon_course_complete.svg'
import documentsIcons from 'assets/svgs/icon_document_library.svg'
import rolesIcon from 'assets/svgs/icon_role_responsibilities.svg'
import Button from 'components/common/Button'
import CollapsableDrawer from 'components/common/CollapsableDrawer'
import SanitizedString from 'components/common/SanitizedString'
import { IAPIError, IEVTrainingLevel, ITrainingLevelDetails } from 'interfaces'
import { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch, useAppSelector } from 'store'
import {
  useGetEVTrainingLevelDetailsQuery,
  useGetEVTrainingLevelDocumentDetailsQuery,
} from 'store/api'
import {
  selectEVLevelState,
  toggleDocumentsLibraryDrawer,
  toggleRolesAndResponsibilitiesDrawer,
} from 'store/slices'
import LevelAccessRequest from 'components/EVLevel/LevelAccessRequest'
import ModulesList from 'components/EVLevel/ModulesList'
import Recertification from 'components/EVLevel/Recertification'
import ChangeTargetLevel from 'components/EVLevel/EVDetail/ChangeTargetLevel'
import ExperienceCriteria from 'components/EVLevel/EVDetail/ExperienceCriteria'
import LiveBatteryRepairs from 'components/EVLevel/EVDetail/LiveBatteryRepairs'
import LiveWorkingDetail from 'components/EVLevel/EVDetail/LiveWorkingDetail'
import LiveRepairQualifications from 'components/EVLevel/EVDetail/LiveWorkingDetail/LiveRepairQualifications'
import { StyledLevelRequested } from 'components/EVLevel/EVDetail/styles'

type Props = {
  selectedEVTrainingLevel: IEVTrainingLevel
  levelLabel: string
  userUUID: string
}

const LoadingComponent = () => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress size={20} />
    </Box>
  )
}

const NoDataComponent = () => (
  <Box display="flex" justifyContent="center">
    <Typography variant="body1">
      <FormattedMessage id="evLevel.noData" />
    </Typography>
  </Box>
)

export default function EVDetail({
  selectedEVTrainingLevel,
  levelLabel,
  userUUID,
}: Props) {
  const selectedEvLevelUUID = selectedEVTrainingLevel.levelUUID
  const [showAccessRequest, setShowAccessRequest] = useState(false)
  const [documentToDownload, setDocumentToDownload] = useState<string | null>(null)

  const { data, currentData, isError, error, isFetching, refetch } =
    useGetEVTrainingLevelDetailsQuery(
      {
        userUUID: userUUID!,
        levelUUID: selectedEvLevelUUID,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !userUUID,
      },
    )
  const {
    originalArgs,
    data: docData,
    isFetching: isLoadingDocument,
  } = useGetEVTrainingLevelDocumentDetailsQuery(
    {
      documentUUID: documentToDownload!,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !documentToDownload,
    },
  )

  useEffect(() => {
    if (docData) {
      const url = docData.data
      window.open(url, '_self', 'noopener,noreferrer')
    }
  }, [docData])

  const theme = useTheme()
  const levelState = useAppSelector(selectEVLevelState)[selectedEvLevelUUID]
  const dispatch = useAppDispatch()
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const errorMessages = error as IAPIError
  const APIError = isError && errorMessages?.status === 400
  const isLoading = isFetching

  if (!isLoading && APIError) {
    console.error(
      `Error while fetching EV Level Details: ${errorMessages?.data?.title}. The application will use mocked data.`,
    )
  }
  if (!data) {
    return <LoadingComponent />
  }

  const evLevelData: ITrainingLevelDetails = { ...data }

  const handleRolesAndResponsibilitesToggle = () => {
    dispatch(toggleRolesAndResponsibilitiesDrawer(selectedEvLevelUUID))
  }

  const handleDocumentsLibraryToggle = () => {
    dispatch(toggleDocumentsLibraryDrawer(selectedEvLevelUUID))
  }

  const handleAccessRequest = () => {
    if (
      evLevelData?.isLevelTrainingRequestable ||
      evLevelData?.isLevelTrainingNominated
    ) {
      setShowAccessRequest(true)
    }
  }
  const handleAccessRequestClose = () => setShowAccessRequest(false)

  if (isLoading && data !== currentData) {
    return <LoadingComponent />
  }

  if (!evLevelData) {
    return <NoDataComponent />
  }

  const isManagerView = evLevelData.isManagerViewing
  const hasExperienceCriteria = evLevelData.hasExperienceCriteria
  const levelUUID = levelState.levelUUID
  const showRecertificationExtraMessage = evLevelData.showRecertificationExtraMessage

  return (
    <>
      <Stack spacing={2}>
        <Stack
          mx={{ xs: 1, sm: 0 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minHeight={{ xs: 'auto', sm: 48 }}
        >
          {isDesktopScreen ? (
            <>
              <Typography textTransform="uppercase">{levelLabel}</Typography>

              {evLevelData.isLevelTrainingRequested && !isManagerView ? (
                <StyledLevelRequested sx={{ width: 'auto', height: '40px' }}>
                  <FormattedMessage
                    id="evLevel.requestedTrainingLabel"
                    values={{
                      evLevelTitle: selectedEVTrainingLevel.name,
                    }}
                  />
                  <img src={checkIcon} alt="check" width="20" />
                </StyledLevelRequested>
              ) : null}
            </>
          ) : null}

          {!isManagerView &&
          (evLevelData.isLevelTrainingRequestable ||
            evLevelData.isLevelTrainingNominated) ? (
            <Button
              onClick={handleAccessRequest}
              fullWidth={!isDesktopScreen}
              evColor={selectedEVTrainingLevel.colour}
            >
              {evLevelData.isLevelTrainingRequestable ? (
                <FormattedMessage
                  id="evLevel.requestTrainingButton"
                  values={{
                    evLevelTitle: selectedEVTrainingLevel.name,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="evLevel.nominatedTrainingButton"
                  values={{
                    evLevelTitle: selectedEVTrainingLevel.name,
                  }}
                />
              )}
            </Button>
          ) : null}
          {isManagerView && evLevelData.isAptForLevelTrainingNomination ? (
            <ChangeTargetLevel />
          ) : null}
        </Stack>

        {!isManagerView && evLevelData.isLevelTrainingRequested && !isDesktopScreen ? (
          <StyledLevelRequested>
            <FormattedMessage
              id="evLevel.requestedTrainingLabel"
              values={{
                evLevelTitle: selectedEVTrainingLevel.name,
              }}
            />
            <img src={checkIcon} alt="check" width="20" />
          </StyledLevelRequested>
        ) : null}
        <Stack spacing={1}>
          <CollapsableDrawer
            open={levelState?.rolesAndResponsibilities}
            onToggleDrawer={handleRolesAndResponsibilitesToggle}
            title={<FormattedMessage id="evLevel.rolesAndResponsibilities" />}
            icon={<img src={rolesIcon} width={20} height={20} alt="Role" />}
          >
            <SanitizedString string={evLevelData.rolesAndResponsibilities} />
          </CollapsableDrawer>
          {evLevelData.documents.length > 0 ? (
            <CollapsableDrawer
              open={levelState?.documents}
              onToggleDrawer={handleDocumentsLibraryToggle}
              title={<FormattedMessage id="evLevel.documentsLibrary" />}
              icon={<img src={documentsIcons} width={20} height={20} alt="Documents" />}
            >
              <Stack direction="column" spacing={1} mx={-2}>
                {evLevelData.documents.length > 0 ? (
                  evLevelData.documents.map((document, index) => (
                    <Paper
                      elevation={0}
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                      }}
                    >
                      <Stack>
                        <Typography variant="body2">{document.filename}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          <FormattedMessage
                            id="evLevel.EVDetail.document.size&type"
                            values={{
                              size: document.size,
                              type: document.type,
                            }}
                          />
                        </Typography>
                      </Stack>
                      <Button
                        onClick={() => {
                          setDocumentToDownload(document.documentUUID)
                        }}
                        evColor="#fff"
                        variant="outlined"
                        color="secondary"
                        isLoading={
                          isLoadingDocument &&
                          originalArgs?.documentUUID === document.documentUUID
                        }
                      >
                        <FormattedMessage id="app.buttons.view" />
                      </Button>
                    </Paper>
                  ))
                ) : (
                  <NoDataComponent />
                )}
              </Stack>
            </CollapsableDrawer>
          ) : null}
          {isManagerView && evLevelData.isExperienceCriteriaRequired && (
            <ExperienceCriteria
              hasExperienceCriteria={hasExperienceCriteria}
              levelUUID={levelUUID}
              staffMemberUUID={userUUID}
              experienceCriteria={evLevelData.experienceCriteria}
              experienceCriteriaMonths={evLevelData.experienceCriteriaMonths}
            />
          )}
        </Stack>
        {evLevelData?.recertificationModules &&
          evLevelData?.recertificationModules?.length > 0 && (
            <Recertification
              selectedEVTrainingLevel={selectedEVTrainingLevel}
              selectedEvLevelUUID={selectedEvLevelUUID}
              evLevelData={evLevelData}
              onClosePortalModal={refetch}
              isManagerView={isManagerView}
              showRecertificationExtraMessage={showRecertificationExtraMessage}
            />
          )}

        <Stack px={{ xs: 1, sm: 0 }} spacing={2}>
          {evLevelData.brandModels ? (
            <LiveRepairQualifications
              brands={evLevelData.brandModels}
              selectedEVTrainingLevel={selectedEVTrainingLevel}
            />
          ) : null}
          {/* LIVE BATTERY REPAIRS */}
          {data.liveBatteryRepairs?.[0] ? (
            <LiveBatteryRepairs liveBatteryRepairs={data.liveBatteryRepairs} />
          ) : null}

          {/* ModulesList labeled as LIVE BATTERY REPAIR PROCEDURES */}
          <ModulesList
            evLevelUUID={selectedEvLevelUUID}
            modules={evLevelData.modules}
            filter
            onClosePortalModal={refetch}
            isManagerView={isManagerView}
          />
          {/* LIVE BATTERY REPAIR COURSES */}
          {evLevelData.brandModels ? (
            <LiveWorkingDetail
              evLevelUUID={selectedEvLevelUUID}
              brands={evLevelData.brandModels}
              onClosePortalModal={refetch}
              isManagerView={isManagerView}
            />
          ) : null}
        </Stack>
      </Stack>
      <LevelAccessRequest
        evLevelData={{ ...evLevelData, levelUUID: selectedEvLevelUUID }}
        open={showAccessRequest}
        onClose={handleAccessRequestClose}
        onConfirm={setShowAccessRequest}
      />
    </>
  )
}
