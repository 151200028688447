import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import SubHeader from 'components/layout/SubHeader'
import Body from 'components/common/Body'
import BatteryListDesktopInfo from 'components/BatteryList/BatteryListDesktopInfo'
import BatteryListMobileInfo from 'components/BatteryList/BatteryListMobileInfo'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import BusinessUnitSelector from 'components/common/BusinessUnitSelector'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetStaffQualificationsConfigQueryMutation } from 'store/api'
import { ValueLabelType } from 'types'
import {
  selectQualificationsAndJobRoles,
  selectSelectedBusinessUnits,
  setQualificationsAndJobRoles,
  useAppDispatch,
  useAppSelector,
} from 'store'

const BatteryList = () => {
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const isMediumScreen = useMediaQuery('(max-width: 1023px)')
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)
  const dispatch = useAppDispatch()

  const [getStaffQualifications, { data }] =
    useGetStaffQualificationsConfigQueryMutation()

  const selectedQualificationsAndJobRoles = useAppSelector(
    selectQualificationsAndJobRoles,
  )
  const checkIfHasSelectData = !selectedQualificationsAndJobRoles?.jobRoles.length

  useEffect(() => {
    const getConfigPayload = selectedBusinessUnits.reduce(
      (acc: string[], curr: ValueLabelType) => {
        return [...acc, curr.value]
      },
      [],
    )
    getStaffQualifications({ businessUnits: getConfigPayload })
  }, [])

  useEffect(() => {
    dispatch(setQualificationsAndJobRoles(data))
  }, [data])

  if (checkIfHasSelectData)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="90vh"
      >
        <CircularProgress size={20} />
      </Box>
    )

  return (
    <Body>
      <SubHeader
        breadcrumbItems={[
          {
            text: <FormattedMessage id="app.labels.dashboard" />,
            href: '/dashboard',
          },
          {
            text: <FormattedMessage id="appNavigation.menuItem.batteryList" />,
            href: '/',
          },
        ]}
      >
        <BusinessUnitSelector
          setShouldRefetch={setShouldRefetch}
          shouldRefetch={shouldRefetch}
        />
      </SubHeader>

      {isMediumScreen ? <BatteryListMobileInfo /> : <BatteryListDesktopInfo />}
      <ApplicationFooter />
    </Body>
  )
}

export default BatteryList
