import { TextFieldProps, TextField } from '@mui/material'
import { forwardRef } from 'react'

type CustomTextFieldProps = TextFieldProps & {
  isDark?: boolean
}

const extractRest = (props: CustomTextFieldProps) => {
  const { isDark: _, ...rest } = props
  return { ...rest }
}

export const InputTextField = forwardRef<HTMLInputElement, CustomTextFieldProps>(
  (props, ref) => {
    const { isDark } = props
    return (
      <TextField
        {...extractRest(props)}
        ref={ref}
        variant="filled"
        sx={
          isDark
            ? {
                backgroundColor: 'rgba(82,82,82,0.5)',
                color: 'white',
              }
            : {
                border: '1px solid #ccc',
              }
        }
      />
    )
  },
)

InputTextField.displayName = 'InputTextField'

export default InputTextField
