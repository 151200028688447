import { CssBaseline } from '@mui/material'
import Language from 'components/common/Language'
import { ToastContainer } from 'react-toastify'
import AppRoutes from 'routes/AppRoutes'
import 'react-toastify/dist/ReactToastify.css'
import './fonts/styles.css'

function App() {
  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} />
      <CssBaseline />
      <Language>
        <AppRoutes />
      </Language>
    </>
  )
}

export default App
