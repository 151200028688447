import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type Props = {
  children: React.ReactNode
  isBookingSession?: boolean
  notPadding?: boolean
}

export function CourseDescription({ children, isBookingSession, notPadding }: Props) {
  return (
    <Box>
      <Typography
        variant="body1"
        color="text.primary"
        gutterBottom
        p={isBookingSession ? 0 : '8px 24px 0 24px'}
        mb={isBookingSession ? 0 : '-8px'}
      >
        <FormattedMessage id="app.labels.courseDescription" />
      </Typography>
      <Typography
        variant={isBookingSession ? 'body2' : 'body1'}
        color="text.secondary"
        p={notPadding ? 0 : '16px 24px'}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default CourseDescription
