import { Box, Typography } from '@mui/material'

type Props = {
  value: number
}

export default function BigCounter({ value }: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={40}
      height={40}
      borderRadius="50%"
      bgcolor="#EBF1F6"
    >
      <Typography sx={{ fontSize: '18px' }}>{value.toString()}</Typography>
    </Box>
  )
}
