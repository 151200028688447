import { Collapse, Box, Typography, ButtonBase, styled } from '@mui/material'
import dropDownIcon from 'assets/svgs/icon_drop_arrow.svg'

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  justifyContent: 'start',
  '&:focus-visible': {
    outline: '1px dashed #cad1d7',
  },
}))

type Props = {
  title: React.ReactNode
  icon?: React.ReactNode
  open?: boolean
  onToggleDrawer: () => void
  children?: React.ReactNode
}

export default function CollapsableDrawer({
  title,
  onToggleDrawer,
  open = false,
  icon,
  children,
}: Props) {
  return (
    <Box bgcolor="#F5F8FB">
      <StyledButtonBase onClick={onToggleDrawer}>
        {icon}
        <Typography
          ml={2}
          textTransform="uppercase"
          flexGrow={1}
          textAlign="left"
          color="#1E1E1E"
        >
          {title}
        </Typography>
        <Box
          sx={{
            transform: `rotate(${open ? 180 : 0}deg)`,
            transition: 'all 250ms',
          }}
        >
          <img src={dropDownIcon} alt="Expand and Collapse" />
        </Box>
      </StyledButtonBase>
      <Collapse in={open}>
        <Box px={4} py={2} color="text.secondary">
          {children}
        </Box>
      </Collapse>
    </Box>
  )
}
