import { yupResolver } from '@hookform/resolvers/yup'
import { Close, KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { IAPIError, IBookStaffMemberParams } from 'interfaces'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import {
  closeAllBookingModals,
  getSelectedLanguage,
  selectUser,
  setCurrentStep,
  setSubmitMessage,
  useAppDispatch,
  useAppSelector,
} from 'store'
import {
  useLazyGetSessionDetailForBookingQuery,
  useSubmitBookStaffMemberCommandMutation,
  useSubmitWaitlistStaffMemberCommandMutation,
} from 'store/api'
import * as yup from 'yup'
import RequestBookingApprovalHeader from './RequestBookingApprovalHeader'
import {
  StyledSubtitle,
  StyledSubtitleLabel,
  StyledTitle,
  StyledWrapperBoxInput,
  StyledWrapperBoxTextarea,
} from './styles'

type IFormInput = {
  retailerUUID: string
  hotelUUID: string | undefined
  numberOfNights: number | undefined
  startDate: Date | undefined
  specialRequeriments: string | undefined
  hasHotels: boolean
}

interface IRequestBookingApprovalProps {
  allowsWaitlist: boolean
}

const RequestBookingApproval: FC<IRequestBookingApprovalProps> = ({ allowsWaitlist }) => {
  const dispatch = useAppDispatch()

  const { selectedCourse: courseUUID, currentBookingSessionId } = useAppSelector(
    state => state.booking,
  )

  const { isoCode } = useAppSelector(getSelectedLanguage)
  const intl = useIntl()
  const user = useAppSelector(selectUser)

  const { user: userUUID } = useParams()
  const schema = yup.object().shape({
    retailerUUID: yup.string().required('Required'),
    hotelUUID: yup.string().notRequired(),
    numberOfNights: yup.string().when('hasHotels', {
      is: true,
      then: yup.string().required('Number of Nights is required'),
      otherwise: yup.string().notRequired(),
    }),

    startDate: yup.string().when('hasHotels', {
      is: true,
      then: yup.string().required('Start Date is required'),
      otherwise: yup.string().notRequired(),
    }),
    specialRequeriments: yup.string().notRequired(),
  })

  const [getSessionDetailsForBooking, { data: sessionData }] =
    useLazyGetSessionDetailForBookingQuery()

  useEffect(() => {
    if (currentBookingSessionId) {
      getSessionDetailsForBooking({
        sessionUUID: currentBookingSessionId as string,
        userUUID: userUUID as string,
      })
    }
  }, [currentBookingSessionId])

  const { watch, setValue, control, handleSubmit } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      numberOfNights: 1,
      startDate: sessionData?.startDate ? new Date(sessionData?.startDate) : new Date(),
      specialRequeriments: '',
    },
  })

  const [hotelValue, retailerValue, numberOfNights, specialRequeriments, startDateValue] =
    watch([
      'hotelUUID',
      'retailerUUID',
      'numberOfNights',
      'specialRequeriments',
      'startDate',
    ])

  const [
    submitBookStaffMember,
    {
      isLoading: submitLoading,
      isSuccess: isSuccessSubmit,
      isError: isErrorSubmit,
      error: errorSubmit,
    },
  ] = useSubmitBookStaffMemberCommandMutation()

  const [
    submitWaitlistStaffMember,
    {
      isLoading: waitListLoading,
      isSuccess: isSuccessWaitlist,
      isError: isErrorWaitlist,
      error: errorWaitlist,
    },
  ] = useSubmitWaitlistStaffMemberCommandMutation()

  useEffect(() => {
    if (isSuccessSubmit) {
      dispatch(setCurrentStep(4))
    }
  }, [isSuccessSubmit])

  useEffect(() => {
    if (isSuccessWaitlist) {
      dispatch(
        setSubmitMessage({
          title: intl.formatMessage({
            id: 'bookingStaff.requestBookingApproval.messages.success.title',
          }),
          message: intl.formatMessage({
            id: 'bookingStaff.requestBookingApproval.messages.success.message',
          }),
          success: true,
        }),
      )
      dispatch(setCurrentStep(2))
    }
  }, [isSuccessWaitlist])

  useEffect(() => {
    if (isErrorSubmit || isErrorWaitlist) {
      const error = isErrorSubmit ? errorSubmit : errorWaitlist
      dispatch(
        setSubmitMessage({
          title: intl.formatMessage({
            id: `bookingStaff.requestBookingApproval.messages.error.${
              isErrorSubmit ? 'book' : 'waitlist'
            }.title`,
          }),
          errors: (error as any).data.errors as IAPIError[],
          success: false,
        }),
      )
      dispatch(setCurrentStep(2))
    }
  }, [isErrorSubmit, isErrorWaitlist, errorSubmit, errorWaitlist])

  const onSubmit = (data: IFormInput) => {
    if (userUUID) {
      const { hotelUUID, numberOfNights, retailerUUID, specialRequeriments, startDate } =
        data
      const body: IBookStaffMemberParams = {
        userUUID,
        courseUUID: courseUUID as string,
        sessionUUID: id,
        retailerUUID,
      }

      let payload: IBookStaffMemberParams
      if (sessionData?.hotels?.length) {
        payload = {
          ...body,
          hotelBooking: {
            hotelUUID: hotelUUID as string,
            numberOfNights: numberOfNights as number,
            startDate: dayjs(new Date(startDate as Date)).format('YYYY-MM-DD'),
            // startDate: format(new Date(startDate as Date), 'yyyy-MM-dd'),
            specialRequirements: specialRequeriments as string,
          },
        }
      } else {
        payload = body
      }

      if (sessionData?.availableSpaces === 0 && allowsWaitlist) {
        submitWaitlistStaffMember(payload)
      } else {
        submitBookStaffMember(payload)
      }
    }
  }

  useEffect(() => {
    if (sessionData) {
      const startDate = dayjs(new Date(sessionData.startDate))
      const endDate = dayjs(new Date(sessionData.endDate))

      setValue('retailerUUID', sessionData.retailer.value as string)
      setValue('hasHotels', sessionData?.hotels?.length > 0)
      setValue('numberOfNights', endDate.diff(startDate, 'days'))
      setValue('startDate', new Date(sessionData.startDate))
    }
  }, [sessionData])

  if (!sessionData)
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <CircularProgress size={20} />
      </Box>
    )

  const { description, hotels, retailer, id } = sessionData

  return (
    <Box>
      <RequestBookingApprovalHeader {...sessionData} />
      <Box p="16px 24px">
        <Box>
          <StyledTitle>
            <FormattedMessage id="app.labels.courseDescription" />
          </StyledTitle>
          <StyledSubtitle>{description}</StyledSubtitle>
        </Box>
        <Box>
          <StyledTitle>
            <FormattedMessage id="bookingStaff.requestBookingApproval.title.bookApproval" />
          </StyledTitle>
          <StyledSubtitle sx={{ mb: '14px' }}>
            <FormattedMessage id="bookingStaff.requestBookingApproval.subtitle.bookApproval" />
          </StyledSubtitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledWrapperBoxInput>
              <StyledSubtitleLabel id="retailer">
                <FormattedMessage id="bookingStaff.requestBookingApproval.form.label.retailer" />
              </StyledSubtitleLabel>
              <Controller
                name="retailerUUID"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="retailerUUID"
                    id="retailerUUID"
                    value={retailerValue ?? ''}
                    IconComponent={KeyboardArrowDown}
                    onChange={e => setValue('retailerUUID', e.target.value)}
                    sx={{ background: 'white' }}
                    disabled
                  >
                    <MenuItem value={retailer.value} key={retailer.value}>
                      {retailer.label}
                    </MenuItem>
                  </Select>
                )}
              />
            </StyledWrapperBoxInput>

            {hotels && (
              <Box>
                <StyledWrapperBoxInput
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #C4C4C4 !important',
                    },
                  }}
                >
                  <StyledTitle>
                    <FormattedMessage id="bookingStaff.requestBookingApproval.title.selectHotel" />
                  </StyledTitle>
                  <StyledSubtitleLabel id="hotel">
                    <FormattedMessage id="bookingStaff.requestBookingApproval.form.label.hotel" />
                  </StyledSubtitleLabel>
                  <Controller
                    name="hotelUUID"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        endAdornment={
                          <Close
                            fontSize="small"
                            sx={{
                              display: hotelValue ? '' : 'none',
                              position: 'absolute',
                              right: '30px',
                              cursor: 'pointer',
                            }}
                            onClick={() => setValue('hotelUUID', undefined)}
                          />
                        }
                        fullWidth
                        displayEmpty
                        labelId="hotelUUID"
                        id="hotelUUID"
                        value={hotelValue ?? ''}
                        onChange={e => setValue('hotelUUID', e.target.value)}
                        IconComponent={KeyboardArrowDown}
                        sx={{ background: 'white' }}
                        renderValue={value => {
                          if (value === '') {
                            return 'No Hotel'
                          }
                          return hotels?.find(hotel => hotel.value === value)?.label
                        }}
                      >
                        {hotels?.map(hotel => (
                          <MenuItem value={hotel.value} key={hotel.value}>
                            {hotel.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </StyledWrapperBoxInput>

                <StyledWrapperBoxTextarea>
                  <StyledSubtitleLabel id="nights">
                    <FormattedMessage id="bookingStaff.requestBookingApproval.form.label.numberOfNights" />
                  </StyledSubtitleLabel>
                  <Controller
                    name="numberOfNights"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="numberOfNights"
                        variant="outlined"
                        error={!!error}
                        type="number"
                        helperText={error?.message}
                        onChange={e =>
                          setValue('numberOfNights', parseInt(e.target.value))
                        }
                        value={numberOfNights}
                        inputProps={{
                          disableunderline: 'true',
                        }}
                      />
                    )}
                  />
                </StyledWrapperBoxTextarea>

                <Box sx={{ mt: 2 }}>
                  <StyledSubtitleLabel id="startDate">
                    <FormattedMessage id="bookingStaff.requestBookingApproval.form.label.startDate" />
                  </StyledSubtitleLabel>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={isoCode}
                  >
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          value={startDateValue?.toDateString() ?? null}
                          onChange={newDate => {
                            setValue('startDate', new Date(newDate as string))
                          }}
                          renderInput={params => <TextField {...field} {...params} />}
                          inputFormat={user!.dateTimeFormatCulture}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <br />
                <StyledWrapperBoxTextarea>
                  <StyledTitle>
                    <FormattedMessage id="bookingStaff.requestBookingApproval.title.specialRequeriments" />
                  </StyledTitle>
                  <StyledSubtitleLabel>
                    <FormattedMessage id="bookingStaff.requestBookingApproval.form.label.specialRequeriments" />
                  </StyledSubtitleLabel>
                  <Controller
                    name="specialRequeriments"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="specialRequeriments"
                        multiline
                        rows={3}
                        variant="outlined"
                        error={!!error}
                        helperText={error?.message}
                        onChange={e => setValue('specialRequeriments', e.target.value)}
                        value={specialRequeriments}
                      />
                    )}
                  />
                </StyledWrapperBoxTextarea>
              </Box>
            )}
            <Divider sx={{ mt: 2, ml: '-10%', width: '112.9%' }} />
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                margin: '24px 0px 6px',
              }}
            >
              <Button
                color="info"
                variant="outlined"
                sx={{
                  fontSize: '0.75rem',
                }}
                onClick={() => dispatch(closeAllBookingModals())}
              >
                <FormattedMessage id="app.buttons.close" />
              </Button>

              <Button
                variant="outlined"
                color="info"
                size="small"
                sx={{
                  fontSize: '0.75rem',
                }}
                type="submit"
                disabled={submitLoading || waitListLoading}
              >
                {submitLoading || waitListLoading ? (
                  <CircularProgress size={20} />
                ) : sessionData.availableSpaces === 0 ? (
                  <FormattedMessage id="bookingStaff.requestBookingApproval.button.waitlist" />
                ) : (
                  <FormattedMessage id="bookingStaff.requestBookingApproval.button.book" />
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  )
}

export default RequestBookingApproval
