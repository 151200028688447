import { Button, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledLanguageButton = styled(Button)(({ ...props }) => {
  return {
    marginBottom: '5px',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '::before': {
      transition: 'all 0.3s ease',
      content: '""',
      position: 'absolute',
      top: '-22px',
      left: '11px',
      width: '15px',
      height: '15px',
      transform: 'rotate(45deg)',
      border: '2px solid #fff',
      borderLeft: 'none',
      borderTop: 'none',
      borderBottomRightRadius: '1px',
      backgroundColor: '#fff',
      opacity: props['aria-haspopup'] ? '1' : '0',
      visibility: props['aria-haspopup'] ? 'visible' : 'hidden',
    },
  }
})

export const StyledLanguageButtonLabel = styled('span')(({ theme, ...props }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: props['aria-modal'] ? '15px' : '5px 10px',
    margin: props['aria-modal'] ? '5px 10px' : '0',
    boxShadow: props['aria-modal'] ? '0 0 10px 0 rgba(0,0,0,0.2)' : 'none',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '4px',
    transition: 'all 0.3s ease',
    gap: '10px',
    backgroundColor: props['aria-current']
      ? theme.palette.grey[100]
      : props['aria-modal']
      ? 'white'
      : 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  }
})

export const StyledPopoverContent = styled(Paper)(() => {
  return {
    width: '550px',
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '8px',
  }
})
