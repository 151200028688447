import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { KeyboardArrowDown } from '@mui/icons-material'
import { IBatteryListRowOrCollapseProps } from 'interfaces'
import { FC } from 'react'
import RowTable from 'components/BatteryList/BatteryListDesktopInfo/Components/RowTable'
import RenderLoading from 'components/BatteryList/BatteryListDesktopInfo/Components/RenderLoading'
import {
  StyledHeaderTable,
  StyledHeaderTableCell,
  StyledPagination,
  StyledTable,
  StyledTableContainerWrapper,
} from './style'
import {
  selectPageConfig,
  setPage,
  setPageSize,
  useAppDispatch,
  useAppSelector,
} from 'store'

interface IBatteryListRowCollapseProps {
  records: IBatteryListRowOrCollapseProps[]
  hasResults: boolean
  isLoading: boolean
  totalPages: number
  totalRecords: number
}

const BatteryListTable: FC<IBatteryListRowCollapseProps> = ({
  records,
  hasResults,
  isLoading,
  totalPages,
  totalRecords,
}) => {
  const theme = useTheme()
  const selectedPageConfig = useAppSelector(selectPageConfig)
  const dispatch = useAppDispatch()

  const changeResultsPerPage = (e: SelectChangeEvent<number>) => {
    dispatch(setPage(1))
    dispatch(setPageSize(Number(e.target.value)))
  }

  const { page: currentPage, pageSize: resultsPerPage } = selectedPageConfig

  const rowsPerPageOptions = [10, 20, 50, 100, 200]

  const limitsRowsCalc = resultsPerPage * currentPage
  const totalRecordsFind = totalRecords === 0 ? 1 : Number(totalRecords)
  const limitsRowsPerPage =
    limitsRowsCalc > totalRecordsFind ? totalRecordsFind : limitsRowsCalc

  const actualRows =
    records?.length === 0
      ? 1
      : currentPage === 1
      ? currentPage
      : totalPages === currentPage
      ? totalRecordsFind - (records!.length - 1)
      : resultsPerPage * (currentPage - 1) + 1

  return (
    <>
      <StyledTableContainerWrapper>
        <StyledTable>
          <StyledHeaderTable>
            <TableRow>
              <StyledHeaderTableCell>
                <FormattedMessage id="membersList.pinName" />
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <FormattedMessage id="membersList.jobRole" />
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <FormattedMessage id="membersList.businessUnit" />
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <FormattedMessage id="batteryList.table.header.LBRPExpiry" />
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <FormattedMessage id="batteryList.table.header.certifications" />
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>
                <FormattedMessage id="batteryList.table.header.liveRepairs" />
              </StyledHeaderTableCell>
              <StyledHeaderTableCell />
            </TableRow>
          </StyledHeaderTable>

          <TableBody>
            {isLoading && <RenderLoading />}
            {hasResults && !isLoading ? (
              records.map(row => {
                return <RowTable key={row.staffMemberUUID} {...{ row }} />
              })
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <Typography align="center" color={theme.palette.grey[700]}>
                    <FormattedMessage id="general.lists.noRows" />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && <RenderLoading />}
          </TableBody>
        </StyledTable>
      </StyledTableContainerWrapper>
      {hasResults && (
        <Box sx={{ my: 2 }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="5px">
              <InputLabel id="results-per-page">
                <FormattedMessage id="general.table.resultsPerPage" />
              </InputLabel>
              <Select
                IconComponent={KeyboardArrowDown}
                labelId="results-per-page"
                id="results-per-page-select"
                value={resultsPerPage}
                onChange={changeResultsPerPage}
                size="small"
                sx={{
                  marginLeft: '6px',
                  borderRadius: '0px',
                  '& .MuiSelect-select': {
                    padding: '6px 12px',
                  },
                }}
              >
                {rowsPerPageOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Typography
              display="flex"
              alignItems="center"
              color={theme.palette.grey[700]}
            >
              <FormattedMessage
                id="general.table.showingResults"
                values={{
                  actualRows,
                  limitsRowsPerPage,
                  totalRecordsFind,
                }}
              />
            </Typography>

            <StyledPagination
              color="primary"
              variant="outlined"
              page={currentPage}
              count={totalPages}
              onChange={(_, newPage) => dispatch(setPage(newPage))}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default BatteryListTable
