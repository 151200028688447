import { Box, Stack } from '@mui/material'
import { ActionRequired } from 'interfaces'
import { useNavigate } from 'react-router-dom'
import { StyledARGenericButton } from 'components/StaffMembers/ActionRequired/style'
import ImgChange from './ImgChange'
import { FormattedMessage } from 'react-intl'

interface ConfirmRecertificationProps {
  handleClose: () => void
  actionRequired: ActionRequired
  userUUID: string
  actionType: string
}

const ConfirmRecertification = ({
  handleClose,
  actionRequired,
  userUUID,
  actionType,
}: ConfirmRecertificationProps) => {
  const navigate = useNavigate()

  const navigateToConfirmRecertification = () => {
    handleClose()
    navigate(`/user-details/${userUUID}/${actionRequired.levelUUID!}`)
  }

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          img: {
            width: '28px',
            height: '28px',
          },
        }}
      >
        <ImgChange level={actionRequired.level!} confirmationType={actionType!} />
      </Box>
      <Box
        sx={{
          alignSelf: 'end',
          backgroundColor: 'green',
          width: 'fit-content',
        }}
      >
        <StyledARGenericButton onClick={() => navigateToConfirmRecertification()}>
          <FormattedMessage id="app.buttons.view" />
        </StyledARGenericButton>
      </Box>
    </Stack>
  )
}

export default ConfirmRecertification
