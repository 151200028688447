import { IGetEvNominationParams, IGetEvNominationResponse } from 'interfaces'

import { evAPI } from './api'

export const nominationApi = evAPI.injectEndpoints({
  endpoints: builder => ({
    getEvResolveNomination: builder.query<
      IGetEvNominationResponse['data'],
      IGetEvNominationParams
    >({
      query: ({ id }) => {
        return {
          url: `/EvTraining/ResolveNominationQuery?id=${id}`,
          method: 'GET',
        }
      },
      transformResponse: ({ data }: IGetEvNominationResponse) => data,
    }),
  }),
})

export const { useLazyGetEvResolveNominationQuery, useGetEvResolveNominationQuery } =
  nominationApi
