import {
  CircularProgress,
  FormHelperText,
  InputBase,
  Stack,
  Typography,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { StyledARGenericButton } from 'components/StaffMembers/ActionRequired/style'
import { IActionRequiredFormInput } from 'components/StaffMembers/ActionRequired/types'
import { FormattedMessage } from 'react-intl'

interface RejectRequestProps {
  isLoadingApproval: boolean
}

const RejectRequest = ({ isLoadingApproval }: RejectRequestProps) => {
  const { setValue, control, clearErrors } = useFormContext<IActionRequiredFormInput>()

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: '14px' }}>
        <FormattedMessage id="staffMembers.actionRequired.rejectRequest.text" />
      </Typography>

      <Controller
        name="rejectReason"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <InputBase
              {...field}
              sx={{
                border: `1px solid ${error ? '#9e1b32' : '#cdd7d9'}`,
                padding: '4px',
              }}
              fullWidth
              name="rejectReason"
              id="rejectReason"
              autoComplete="current-password"
              multiline
              autoFocus
              rows={3}
              error={Boolean(error)}
            />
            <FormHelperText
              error={Boolean(error)}
              sx={{ marginTop: '4px !important', fontSize: '13px' }}
            >
              {error ? error.message : null}
            </FormHelperText>
          </>
        )}
        defaultValue=""
      />

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledARGenericButton
          onClick={() => {
            setValue('formStep', 0)
            setValue('rejectReason', '')
            clearErrors('rejectReason')
          }}
        >
          <FormattedMessage id="app.buttons.cancel" />
        </StyledARGenericButton>
        <StyledARGenericButton type="submit" style={{ textTransform: 'uppercase' }}>
          {isLoadingApproval ? (
            <CircularProgress size={15} />
          ) : (
            <FormattedMessage id="app.buttons.confirm" />
          )}
        </StyledARGenericButton>
      </Stack>
    </Stack>
  )
}

export default RejectRequest
