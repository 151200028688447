import React from 'react'
import { Button } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import Dialog from 'components/common/Dialog'

interface AutoHeightIframeProps {
  src: string
  isOpen: boolean
  onClose: () => void
}

const IframeModal: React.FC<AutoHeightIframeProps> = ({ src, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: 15,
          top: 20,
          padding: 0,
          margin: 0,
          zIndex: 100,
          minWidth: 'auto',
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </Button>
      <iframe
        id="myIframe"
        src={src}
        width="100%"
        style={{ border: 'none', height: '75vh' }}
        title="Auto Height Iframe"
        allowFullScreen
        loading="lazy"
      />
    </Dialog>
  )
}

export default IframeModal
