import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CardActions,
  Stack,
  Typography,
} from '@mui/material'
import { IHelpContent } from 'interfaces'
import helpGuideIcon from 'assets/svgs/icon_help_guide.svg'
import helpVideoIcon from 'assets/svgs/icon_help_video.svg'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = {
  content: IHelpContent
  open: boolean
  onToggleOpen: (id: string) => void
}

export default function HelpContent({ content, open, onToggleOpen }: Props) {
  const { title, inlineVideoLink, description } = content
  const intl = useIntl()
  const video = intl.formatMessage({
    id: 'helpCentre.helpContent.subtitle.video',
  })
  const guide = intl.formatMessage({
    id: 'helpCentre.helpContent.subtitle.guide',
  })

  const videoIcon = inlineVideoLink ? (
    <img src={helpVideoIcon} alt="Play" />
  ) : (
    <img src={helpGuideIcon} alt="Guide" />
  )

  const openLink = () => {
    window.open(content.downloadFileLink)
  }

  return (
    <Card>
      <CardActionArea onClick={() => onToggleOpen(content.id)}>
        <CardHeader
          title={title}
          subheader={inlineVideoLink ? video : guide}
          avatar={videoIcon}
        />
      </CardActionArea>
      <Collapse in={open}>
        <CardContent>
          <Stack spacing={2}>
            {inlineVideoLink && <video width="100%" controls src={inlineVideoLink} />}
            <Typography>{description}</Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={openLink}>
            <FormattedMessage id="app.buttons.open" />
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  )
}
