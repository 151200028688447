import { FC } from 'react'
import { IRecordsEvLiveWorkingCapability } from 'interfaces'
import CardCarCertifieldMobile from 'components/DashboardInfos/CardCarCertifieldMobile'
import { StyledCardBoxContent } from 'components/DashboardInfos/style'
import jaguarLogo from 'assets/svgs/jaguar_black_logo.svg'
import rangeRoverLogo from 'assets/svgs/range_rover_black_logo.svg'
import defenderLogo from 'assets/svgs/defender_black_logo.svg'
import discoveryLogo from 'assets/svgs/discovery_black_logo.svg'

const ContentModelCars: FC<{
  cars: IRecordsEvLiveWorkingCapability[]
  brand: string
}> = ({ cars, brand }) => {
  const BrandLogo = () => {
    switch (brand) {
      case 'jaguar':
        return <img src={jaguarLogo} alt="Jaguar Logo" />
      case 'rangeRover':
        return <img src={rangeRoverLogo} alt="Range Rover Logo" />
      case 'discovery':
        return <img src={discoveryLogo} alt="Discovery Logo" />
      case 'defender':
        return <img src={defenderLogo} alt="Defender Logo" />
      default:
        return null
    }
  }
  return (
    <StyledCardBoxContent>
      <BrandLogo />
      {cars.map(({ year, count, model: { modelImage, modelName, modelUUID } }) => (
        <CardCarCertifieldMobile
          key={modelUUID}
          {...{ year, count, modelImage, modelName, modelUUID }}
        />
      ))}
    </StyledCardBoxContent>
  )
}

export default ContentModelCars
