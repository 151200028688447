import FullScreenLoader from 'components/common/FullScreenLoader'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { useGetInitialConfigQuery } from 'store/api'
import { selectEVBaseURL, selectInitialConfig, setEVBaseURL } from 'store/slices'

const GetConfig = ({ children }: any) => {
  const dispatch = useAppDispatch()
  const EVBaseURL = useAppSelector(selectEVBaseURL)
  const initialConfig = useAppSelector(selectInitialConfig)

  useEffect(() => {
    const fetchEVBaseUrl = async () => {
      const response = await fetch('/config.json')
      const data = await response.json()

      // Local config file will contain variable to be replaced during deployment.
      // If this variable is detected and not transfomed all back to dev api url
      if (data.evApi === '#{ENVAPIHOST}') {
        dispatch(setEVBaseURL('https://api.etl.evtraining.jlrexcellence.com/'))
      } else {
        dispatch(setEVBaseURL(data.evApi))
      }
    }
    fetchEVBaseUrl()
  }, [])

  const { isLoading } = useGetInitialConfigQuery(undefined, {
    skip: !EVBaseURL,
  })

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (initialConfig) {
    return children
  }
  return null
}

export default GetConfig
