import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Stack,
  styled,
} from '@mui/material'
import iconCheckmark from 'assets/svgs/icon_checkmark.svg'
import iconCheckmarkDark from 'assets/svgs/icon_checkmark_dark.svg'

export const UncheckedIcon = styled('div')(({ theme }) => ({
  borderRadius: 3,
  width: 19,
  height: 19,
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: '#fff',
}))

interface CheckedIconProps {
  color?: string
  icon?: 'primary' | 'default'
}

export const CheckedIcon = styled(UncheckedIcon)<CheckedIconProps>(
  ({ theme, color, icon }) => ({
    position: 'relative',
    backgroundColor: color ? color : theme.palette.grey[600],
    border: `1px solid ${color ? color : theme.palette.grey[600]}`,
    '&:before': {
      position: 'absolute',
      display: 'block',
      width: 19,
      height: 19,
      backgroundImage: `url(${icon === 'primary' ? iconCheckmarkDark : iconCheckmark})`,
      backgroundSize: '26px',
      backgroundRepeat: 'no-repeat',
      margin: '5px 0 0 3px',
      content: '""',
    },
  }),
)

export const StyledFormControlLabel = styled(FormControlLabel)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 12,
  marginBottom: 10,
  '& .MuiCheckbox-root': {
    padding: 0,
    marginLeft: 6,
  },
})
export const StyledSelectFormControl = styled(FormControl)({})

export const StyledContainer = styled(Container)(({ theme }) => ({
  '& div[role="searchbox"]': {
    borderRadius: '8px',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    marginBlock: theme.spacing(2),
  },
  '& .MuiFormControlLabel-label': {
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.grey[700],
  },
}))

export const StyledMobileContainer = styled(Container)(
  ({ theme: { spacing, palette } }) => ({
    marginTop: '-42px',
    padding: 0,

    '& div[role="searchbox"]': {
      borderRadius: '8px',
      backgroundColor: palette.grey[100],
      paddingInline: spacing(2),
      paddingBlock: spacing(4),
      marginBlock: spacing(2),
    },

    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: palette.grey[700],
    },
  }),
)

export const StyledStack = styled(Stack)({
  '& .MuiButton-root': {
    height: '40px',
  },
})

interface StyledBoxProps {
  open: boolean
}

export const StyledBoxWrapper = styled(Box)<StyledBoxProps>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 16,
  marginBottom: 8,

  button: {
    display: 'flex',
    gap: 12,
    background: open ? theme.palette.grey[600] : theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
    color: open ? theme.palette.common.white : theme.palette.grey[800],
    '&:hover': {
      background: open ? theme.palette.grey[600] : theme.palette.grey[50],
      borderColor: theme.palette.grey[600],
      outline: 'none',
    },
  },
}))
