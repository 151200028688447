import { Box, Chip, FormControl, styled } from '@mui/material'
import iconCheckmark from 'assets/svgs/icon_checkmark.svg'
import iconCheckmarkDark from 'assets/svgs/icon_checkmark_dark.svg'

export const StlyedBoxWrapper = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1),

  div: {
    display: 'flex',
    flexDirection: 'row !important',
  },
}))

export const StyledSelectFormControl = styled(FormControl)({})

export const UncheckedIcon = styled('div')(({ theme }) => ({
  borderRadius: 3,
  width: 19,
  height: 19,
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: '#fff',
}))

export const StyledChipsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}))
export const StyledChip = styled(Chip)(({ theme: { palette } }) => ({
  position: 'relative',
  margin: 2,
  backgroundColor: palette.grey[600],
  color: palette.common.white,
  paddingRight: 16,

  ':hover': {
    backgroundColor: palette.grey[600],
  },
}))

interface CheckedIconProps {
  color?: string
  icon?: 'primary' | 'default'
}

export const CheckedIcon = styled(UncheckedIcon)<CheckedIconProps>(
  ({ theme, color, icon }) => ({
    position: 'relative',
    backgroundColor: color ? color : theme.palette.grey[600],
    border: `1px solid ${color ? color : theme.palette.grey[600]}`,
    '&:before': {
      position: 'absolute',
      display: 'block',
      width: 19,
      height: 19,
      backgroundImage: `url(${icon === 'primary' ? iconCheckmarkDark : iconCheckmark})`,
      backgroundSize: '26px',
      backgroundRepeat: 'no-repeat',
      margin: '5px 0 0 3px',
      content: '""',
    },
  }),
)

export const StyledCloseButtonChip = styled(Box)(() => ({
  content: '""',
  position: 'absolute',
  top: '2px',
  right: 0,
  width: '20px',
  height: '20px',

  '::before,::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1px',
    height: '10px',
    background: 'white',
  },
  '::before': {
    transform: ' rotate(-45deg)',
  },
  '::after': {
    transform: ' rotate(45deg)',
  },
}))
