import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/tr'
import 'dayjs/locale/zh'
import {
  Box,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import actionIcon from 'assets/svgs/icon_cs_action.svg'
import uploadIcon from 'assets/svgs/jaguar_logo_upload.svg'
import Button from 'components/common/Button'
import { useRef, useState } from 'react'
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  useFormContext,
} from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { IFormInput } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair'
import {
  StyledErrorMessage,
  StyledFormLabel,
  StyledInputFile,
  StyledLabelFile,
} from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/styles'
import QualifiedBatteryBox from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/LogBatteryForm/QualifiedBatteryBox'
import { selectUser, useAppSelector, getSelectedLanguage } from 'store'
import { selectUserIsImpersonate } from 'store/slices'

type ITextDateProps = {
  params: TextFieldProps
  field: ControllerRenderProps<IFormInput, 'repairDate'>
  error: FieldError | undefined
  onClick?: () => void
  onClose?: () => void
}

interface ILogBatteryFormProps {
  isLoading: boolean
  allowedFileTypes: string[]
  maximumFileSize: number
}

const LogBatteryForm = ({
  isLoading,
  allowedFileTypes,
  maximumFileSize,
}: ILogBatteryFormProps) => {
  const user = useAppSelector(selectUser)
  const { isoCode } = useAppSelector(getSelectedLanguage)
  const isImpersonate = useAppSelector(selectUserIsImpersonate)
  const t = useIntl()
  const [open, setOpen] = useState(false)
  const { watch, setValue, control, formState } = useFormContext<IFormInput>()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))

  const [battery, certificateFile, repairDate] = watch([
    'brandAndModel',
    'certificateFile',
    'repairDate',
  ])

  const inputRef = useRef<HTMLDivElement>(null)
  const RenderDateInput = ({ field, onClick, onClose, params }: ITextDateProps) => {
    return (
      <>
        <TextField
          id="repairDate"
          {...field}
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: t.formatMessage({
              id: 'evLevel.logBatteryLiveRepair.form.placeholder.repairDate',
            }),
          }}
          fullWidth
          error={!!formState.errors.repairDate}
          onBlur={onClose}
          onFocus={onClick}
          onTouchStart={onClick}
          onClick={onClick}
        />
      </>
    )
  }

  const RenderErrorMessage = ({ error }: Partial<ITextDateProps>) => {
    return error ? (
      <StyledErrorMessage>
        <img src={actionIcon} width="20" />
        <Typography variant="body2" color="white">
          {error.message}
        </Typography>
      </StyledErrorMessage>
    ) : (
      <></>
    )
  }
  const allowed = `.${allowedFileTypes.join(', .')}`

  return (
    <Stack width="100%">
      <Typography variant="h6" align="center">
        <FormattedMessage id="app.labels.logBatteryLiveRepair" />
      </Typography>

      <StyledFormLabel variant="body1">
        <FormattedMessage id="evLevel.logBatteryLiveRepair.form.label.battery" />
      </StyledFormLabel>
      <QualifiedBatteryBox />

      <Button fullWidth onClick={() => setValue('formStep', 1)}>
        <FormattedMessage
          id={`evLevel.logBatteryLiveRepair.form.label.${
            !battery ? 'selectBattery' : 'changeBattery'
          }`}
        />
      </Button>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: isSmall ? theme.spacing(3) : theme.spacing(0),
        }}
      >
        <Controller
          name="repairDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={isoCode}>
              <Box
                display="flex"
                flexDirection="column"
                gap={theme.spacing(1)}
                width="100%"
              >
                <StyledFormLabel variant="body1">
                  <FormattedMessage id="evLevel.logBatteryLiveRepair.form.label.repairDate" />
                </StyledFormLabel>

                {isMedium ? (
                  <MobileDatePicker
                    value={repairDate}
                    onChange={newValue => {
                      setValue('repairDate', new Date(newValue as Date))
                    }}
                    renderInput={params => (
                      <RenderDateInput {...{ field, params, error }} />
                    )}
                    disableFuture
                    showToolbar={false}
                    inputFormat={user!.dateTimeFormatCulture}
                  />
                ) : (
                  <DatePicker
                    inputFormat={user!.dateTimeFormatCulture}
                    value={repairDate}
                    onChange={newValue => {
                      setValue('repairDate', new Date(newValue as Date))
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    renderInput={params => (
                      <>
                        <RenderDateInput
                          {...{ field, params, error }}
                          onClick={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                        />
                        <Box ref={inputRef} sx={{ marginTop: '-5px' }} />
                      </>
                    )}
                    disableFuture
                    PopperProps={{
                      placement: 'bottom-start',
                      anchorEl: inputRef.current,
                    }}
                  />
                )}
                <RenderErrorMessage error={error} />
              </Box>
            </LocalizationProvider>
          )}
        />

        <Box display="flex" width="100%" flexDirection="column">
          <Controller
            name="certificateFile"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <StyledFormLabel variant="body1">
                  <FormattedMessage id="evLevel.logBatteryLiveRepair.form.label.certificate" />
                </StyledFormLabel>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: `${error ? 2 : 1}px solid`,
                    borderColor: error
                      ? theme.palette.error.main
                      : theme.palette.grey[400],
                    height: theme.spacing(5),
                    width: '100%',
                    padding: theme.spacing(1),
                    backgroundColor: 'white',
                    ':hover': {
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                >
                  <StyledInputFile
                    {...field}
                    type="file"
                    name="uploadFiles"
                    id="uploadFiles"
                    accept={allowed}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setValue('certificateFile', e.target.files?.[0] as File)
                    }}
                    value=""
                  />

                  <StyledLabelFile htmlFor="uploadFiles">
                    {certificateFile?.name
                      ? certificateFile.name
                      : t.formatMessage({ id: 'app.labels.upload' })}
                    <img src={uploadIcon} alt="" />
                  </StyledLabelFile>
                </Box>
                <Box
                  sx={{
                    my: theme.spacing(1),
                    fontSize: theme.typography.body2.fontSize,
                    color: theme.palette.grey[500],
                  }}
                >
                  {error ? (
                    <RenderErrorMessage error={error} />
                  ) : (
                    <FormattedMessage
                      id="evLevel.logBatteryLiveRepair.form.helper.certificate"
                      values={{
                        maxFilesize: maximumFileSize,
                      }}
                    />
                  )}
                </Box>
              </>
            )}
          />
        </Box>
        <Button
          fullWidth
          disabled={!battery || !repairDate || !certificateFile || isImpersonate}
          sx={{
            mt: theme.spacing(4),
            mb: theme.spacing(2),
          }}
          type="submit"
          isLoading={isLoading}
        >
          <FormattedMessage id="evLevel.logBatteryLiveRepair.form.submit" />
        </Button>
      </Box>
    </Stack>
  )
}

export default LogBatteryForm
