import DOMPurify from 'dompurify'
import { createElement, FC } from 'react'

interface SanitizedStringProps {
  string: string | null | undefined
}

const SanitizedString: FC<SanitizedStringProps> = ({ string }): JSX.Element => {
  if (!string) return <></>

  const sanitizedString = DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true })
  const render = sanitizedString ? sanitizedString.toString() : ''

  return createElement('div', {
    dangerouslySetInnerHTML: {
      __html: render,
    },
  })
}

export default SanitizedString
