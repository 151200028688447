import { StorageKeys } from 'constant/localStorage'
import {
  IApiResponse,
  IEmailSignInParams,
  IEmailSignInResponse,
  IForgotPasswordParams,
  ILanguageOption,
  ILanguageParams,
  ILanguageResponse,
  IResetPasswordParams,
  IUpdatePasswordParams,
  IUpdatePasswordResponse,
  IRetrieveOTTResponse,
  IGenerateAntiCSRFToken,
} from 'interfaces'
import { setSelectedLanguage, updateTranslations } from 'store/slices'
import { getLocalStorage, setLocalStorage } from 'utils/localStorage'
import { lmsAPI } from './api'

export const authApi = lmsAPI.injectEndpoints({
  endpoints: build => ({
    getLoginConfig: build.mutation<ILanguageResponse, ILanguageParams>({
      query({ languageId }) {
        return {
          url: 'Users/GetLoginConfig',
          method: 'POST',
          body: {
            languageId,
          },
        }
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const payload = (await queryFulfilled).data
        const selectedLanguage = payload.Data.languages.find(
          lang => lang.value === payload.Data.languageId,
        ) as ILanguageOption
        dispatch(setSelectedLanguage(selectedLanguage))
        dispatch(updateTranslations(payload.Data.translations))
      },
    }),
    loginUser: build.mutation<IEmailSignInResponse, IEmailSignInParams>({
      query({ username, password, languageId }) {
        return {
          url: 'Users/Login',
          method: 'POST',
          body: {
            Username: username,
            Password: password,
            LanguageId: languageId,
          },
          credentials: 'include',
        }
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        const payload = (await queryFulfilled).data

        setLocalStorage(StorageKeys.token, payload.Data.Credentials.AuthenticationToken)
        setLocalStorage(StorageKeys.antiCSRFToken, payload.AntiCSRFToken)
      },
    }),
    logoutUser: build.mutation<void, void>({
      query() {
        return {
          url: 'Users/Logout',
          method: 'POST',
          credentials: 'include',
          headers: {
            anticsrftoken: getLocalStorage(StorageKeys.antiCSRFToken),
            authorization: `Bearer ${getLocalStorage(StorageKeys.token)}`,
          },
        }
      },
    }),
    forgotPassword: build.mutation<IApiResponse, IForgotPasswordParams>({
      query({ username, languageId }) {
        return {
          url: 'Users/ForgotPassword',
          method: 'POST',
          body: {
            Username: username,
            LanguageId: languageId,
            IsFromEv: true,
          },
        }
      },
    }),
    changePassword: build.mutation<IApiResponse, IResetPasswordParams>({
      query({ username, languageId, confirmationCode, password }) {
        return {
          url: 'Users/ResetPassword',
          method: 'POST',
          body: {
            Username: username,
            Password: password,
            ConfirmationCode: confirmationCode,
            LanguageId: languageId,
            IsFromEv: true,
          },
        }
      },
    }),
    updatePassword: build.mutation<IUpdatePasswordResponse, IUpdatePasswordParams>({
      query(args) {
        return {
          url: 'Users/UpdateUserPassword',
          method: 'POST',
          body: args,
          headers: {
            authorization: `Bearer ${getLocalStorage(StorageKeys.token)}`,
            anticsrftoken: getLocalStorage(StorageKeys.antiCSRFToken),
          },
        }
      },
    }),
    getGenerateOtt: build.mutation<string, void>({
      query() {
        return {
          url: `Users/GenerateOTT`,
          method: 'POST',
          credentials: 'include',
          headers: {
            anticsrftoken: getLocalStorage(StorageKeys.antiCSRFToken),
            authorization: `Bearer ${getLocalStorage(StorageKeys.token)}`,
          },
        }
      },

      transformResponse: (response: IRetrieveOTTResponse) => response.Data.oneTimeToken,
    }),
    generateAntiCSRFToken: build.mutation<string, void>({
      query() {
        return {
          url: `Users/GenerateAntiCSRFToken`,
          method: 'POST',
          credentials: 'include',
          headers: {
            authorization: `Bearer ${getLocalStorage(StorageKeys.token)}`,
          },
        }
      },
      transformResponse: (_: IGenerateAntiCSRFToken, meta: any) => {
        const anticsrftoken = meta?.response?.headers?.get('Anticsrftoken') as string
        return anticsrftoken
      },
    }),
  }),
})

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useGetLoginConfigMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useUpdatePasswordMutation,
  useGetGenerateOttMutation,
  useGenerateAntiCSRFTokenMutation,
} = authApi
