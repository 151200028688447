import { FC } from 'react'

interface ISettingsIconProps {
  isOpen: boolean
}

const SettingsIcon: FC<ISettingsIconProps> = ({ isOpen }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.401"
      height="23.179"
      viewBox="0 0 17.401 23.179"
    >
      <g
        id="Group_4161"
        data-name="Group 4161"
        transform="translate(250.522 -1052.11) rotate(90)"
      >
        <path
          id="Path_6112"
          data-name="Path 6112"
          d="M1052.834,236.752h6.452a2.892,2.892,0,0,0,5.606,0h9.672a.724.724,0,0,0,0-1.449h-9.672a2.892,2.892,0,0,0-5.606,0h-6.452a.724.724,0,0,0,0,1.449Zm9.255-2.181a1.457,1.457,0,1,1-1.457,1.457A1.459,1.459,0,0,1,1062.09,234.57Z"
          fill={isOpen ? '#fff' : '#5b788f'}
        />
        <path
          id="Path_6113"
          data-name="Path 6113"
          d="M1074.564,245.3h-1.551a2.892,2.892,0,0,0-5.606,0h-14.573a.724.724,0,0,0,0,1.449h14.573a2.892,2.892,0,0,0,5.606,0h1.551a.724.724,0,0,0,0-1.449Zm-4.354,2.181a1.457,1.457,0,1,1,1.457-1.457A1.459,1.459,0,0,1,1070.21,247.486Z"
          transform="translate(0 -4.207)"
          fill={isOpen ? '#fff' : '#5b788f'}
        />
        <path
          id="Path_6114"
          data-name="Path 6114"
          d="M1074.564,255.305H1060.53a2.891,2.891,0,0,0-5.606,0h-2.09a.724.724,0,0,0,0,1.449h2.09a2.891,2.891,0,0,0,5.606,0h14.034a.724.724,0,0,0,0-1.449Zm-16.837,2.181a1.457,1.457,0,1,1,1.457-1.457A1.459,1.459,0,0,1,1057.727,257.487Z"
          transform="translate(0 -8.413)"
          fill={isOpen ? '#fff' : '#5b788f'}
        />
      </g>
    </svg>
  )
}

export default SettingsIcon
