// Redux app feature slice to store all global app state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISubmitMessageProps } from 'types'

interface IBookingState {
  selectedCourse: string | undefined
  selectedSession: string | undefined
  bookingModalOpen: boolean
  withDrawModalOpen: boolean
  removeWaitlistModalOpen: boolean
  currentStep: number
  currentBookingSessionId: string | undefined
  submitMessage: ISubmitMessageProps | undefined
}

const initialState: IBookingState = {
  selectedCourse: undefined,
  selectedSession: undefined,
  bookingModalOpen: false,
  withDrawModalOpen: false,
  removeWaitlistModalOpen: false,
  currentStep: 0,
  currentBookingSessionId: undefined,
  submitMessage: undefined,
}

const bookingSlice = createSlice({
  name: 'bookingSlice',
  initialState: initialState,
  reducers: {
    setSelectedCourse(state, action: PayloadAction<string>) {
      state.selectedCourse = action.payload
    },
    setSelectedSession(state, action: PayloadAction<string>) {
      state.selectedSession = action.payload
    },
    setIsBookingModalOpen(state, action: PayloadAction<boolean>) {
      state.bookingModalOpen = action.payload
    },
    setIsWithDrawModalOpen(state, action: PayloadAction<boolean>) {
      state.withDrawModalOpen = action.payload
    },
    setIsRemoveWaitlistModalOpen(state, action: PayloadAction<boolean>) {
      state.removeWaitlistModalOpen = action.payload
    },
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload
    },
    setCurrentBookingSessionId(state, action: PayloadAction<string | undefined>) {
      state.currentBookingSessionId = action.payload
    },
    setSubmitMessage(state, action: PayloadAction<ISubmitMessageProps | undefined>) {
      state.submitMessage = action.payload
    },
    closeAllBookingModals(state) {
      state.bookingModalOpen = false
      state.withDrawModalOpen = false
      state.removeWaitlistModalOpen = false
      state.currentStep = 0
      state.submitMessage = undefined
    },
  },
})

export const {
  setSelectedCourse,
  setSelectedSession,
  setIsBookingModalOpen,
  setIsWithDrawModalOpen,
  setIsRemoveWaitlistModalOpen,
  setCurrentStep,
  setCurrentBookingSessionId,
  setSubmitMessage,
  closeAllBookingModals,
} = bookingSlice.actions

export default bookingSlice.reducer
