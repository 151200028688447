import { Avatar } from '@mui/material'

type Props = {
  value: number
}

export default function Counter({ value }: Props) {
  return (
    <Avatar
      data-testid="course-count-avatar"
      sx={{
        bgcolor: 'rgb(230,235,239)',
        color: 'rgb(82,82,82)',
        width: 40,
        height: 40,
        fontSize: '1rem',
      }}
    >
      {value}
    </Avatar>
  )
}
