import { useAppSelector } from 'store'
import { selectLanguageInfo } from 'store/slices'
import { useGetLoginConfigMutation } from 'store/api'
import { FormattedMessage } from 'react-intl'
import LanguageSelector from '.'
import { useEffect } from 'react'

export default function LanguageSelectorUser() {
  const {
    selectedLanguage: { value: languageId, label: languageName, isoCode },
  } = useAppSelector(selectLanguageInfo)

  const [getLoginConfig, { isLoading, data: languageResponse }] =
    useGetLoginConfigMutation()

  useEffect(() => {
    getLoginConfig({ languageId })
  }, [getLoginConfig])

  const onHandleSelectLanguage = (languageId: string) => {
    getLoginConfig({ languageId })
  }

  if (languageResponse) {
    return (
      <LanguageSelector
        onHandleSelect={onHandleSelectLanguage}
        languageOptions={languageResponse?.Data?.languages} // Horrible temporary hack -im only doing this temporarily - i want to transform data when it arrives
        isLoading={isLoading}
        languageId={languageId}
        languageName={languageName}
        isoCode={isoCode}
      >
        <FormattedMessage id="login.language" />
      </LanguageSelector>
    )
  }
  return null
}
