import { ButtonBase, IconButton, styled } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import infoIcon from 'assets/svgs/icon_info_button.svg'

const StyledFlipCard = styled('div')({
  backgroundColor: 'tranparent',
  perspective: '1000px',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
})

const InnerCard = styled('div')({
  transition: 'transform 1s',
  transformStyle: 'preserve-3d',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  gridColumn: 1,
  gridRow: 1,
})

const FrontFace = styled('div')({
  backfaceVisibility: 'hidden',
  backgroundColor: 'white',
  borderRadius: '8px',
  color: 'black',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  gridColumn: 1,
  gridRow: 1,
})

const BackFace = styled('div')({
  backfaceVisibility: 'hidden',
  backgroundColor: 'white',
  borderRadius: '8px',
  color: 'black',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  gridColumn: 1,
  gridRow: 1,
  transform: 'rotateY(180deg)',
})

const BackFaceContent = styled('div')({
  gridColumn: 1,
  gridRow: 1,
  alignItems: 'center',
  display: 'flex',
})

const MainButton = styled(ButtonBase)({
  borderRadius: '8px',
  gridColumn: 1,
  gridRow: 1,
  ':focus-visible': {
    outline: '1px solid white',
    outlineOffset: '5px',
  },
})

const FlipButton = styled(IconButton)({
  gridColumn: 1,
  gridRow: 1,
  justifySelf: 'end',
  alignSelf: 'start',
})

type Props = {
  showFace?: 'front' | 'back'
  onShowFront?: () => void
  onShowBack?: () => void
  onFrontClick?: () => void
  frontFace: React.ReactNode
  backFace?: React.ReactNode
}

export default function FlipTile({
  onShowFront,
  onShowBack,
  onFrontClick,
  showFace = 'front',
  frontFace,
  backFace,
}: Props) {
  const rotation = showFace === 'front' ? 0 : 180

  return (
    <StyledFlipCard>
      <InnerCard sx={{ transform: `rotateY(${rotation}deg)` }}>
        <FrontFace>
          <MainButton onClick={onFrontClick}>{frontFace}</MainButton>
          {backFace ? (
            <FlipButton onClick={onShowBack}>
              <img src={infoIcon} />
            </FlipButton>
          ) : null}
        </FrontFace>
        <BackFace>
          <BackFaceContent>{backFace}</BackFaceContent>
          <FlipButton onClick={onShowFront}>
            <HighlightOffIcon />
          </FlipButton>
        </BackFace>
      </InnerCard>
    </StyledFlipCard>
  )
}
