import { Box, Button, List, Typography, styled } from '@mui/material'

export const StyledBoxCollapseItem = styled(Box)(({ theme: { palette } }) => ({
  background: palette.common.white,
  padding: '0 16px 16px',
}))

export const StyledTypographyRounded = styled(Typography)(({ theme: { palette } }) => ({
  background: palette.grey[100],
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledBoxCollapseItemImg = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1.75),
  img: {
    width: '30px',
    height: '30px',
  },
}))

export const StyledButtonDetails = styled(Button)(({ theme: { spacing, palette } }) => ({
  padding: `${spacing(0.5)} ${spacing(1)} !important`,
  fontSize: spacing(1.5),
  width: '100px',

  '&:hover': {
    background: palette.common.black,
    color: palette.common.white,
  },
}))

export const StyledListCollapse = styled(List)(({ theme: { palette } }) => ({
  padding: 0,
  background: palette.common.white,
  li: {
    padding: 0,
    outline: 'none',
    ':hover': {
      background: palette.common.white,
    },
  },
}))

export const StyledListCollapseItem = styled(List)(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2.5),

  li: {
    padding: 0,
    display: 'flex',
    gap: spacing(0.5),
    outline: 'none',

    ':hover': {
      background: palette.common.white,
    },
  },

  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),

    color: palette.grey[800],
    fontWeight: 600,

    p: {
      color: palette.grey[700],
    },

    ':nth-of-type(2)': {
      li: {
        display: 'grid',
        gridTemplateColumns: '130px 1fr',
      },
    },
  },
}))
