import { Paper, ButtonBase, Collapse, Box } from '@mui/material'

type Props = {
  open: boolean
  onToggleOpen: () => void
  header: React.ReactNode
  children: React.ReactNode
  isEmbeddedContent?: boolean
}

export default function ModuleCard({
  open,
  onToggleOpen,
  header,
  children,
  isEmbeddedContent = false,
}: Props) {
  return (
    <Paper
      elevation={isEmbeddedContent ? 0 : 1}
      sx={{
        bgcolor: isEmbeddedContent ? 'rgb(244,247,249)' : 'rgb(255,255,255)',
        border: isEmbeddedContent ? '1px solid rgb(238 238 238)' : 'none',
      }}
    >
      <ButtonBase
        onClick={onToggleOpen}
        sx={{
          px: isEmbeddedContent ? 2 : 3,
          py: 3,
          width: '100%',
          ':focus-visible': { outline: '1px solid #cad1d7' },
        }}
      >
        {header}
      </ButtonBase>
      <Collapse in={open}>
        <Box sx={{ m: 1 }}>{children}</Box>
      </Collapse>
    </Paper>
  )
}
