import { Box, Stack, Typography } from '@mui/material'
import CSQualificationIcon from 'assets/svgs/icon_cs_qualification.svg'
import { ICompletedLogBatteryItems } from 'interfaces'
import { FormattedMessage } from 'react-intl'

type Props = {
  info: ICompletedLogBatteryItems
  withoutBadge?: boolean
}

export default function QualifiedModel({ info, withoutBadge = false }: Props) {
  const {
    model: {
      model: { modelImage, modelName },
    },
    module: { moduleTitle },
  } = info

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <img src={modelImage} width="120" />
      <Box flexGrow={1}>
        <Typography>{modelName}</Typography>
        <Typography color="#5B788F">
          <FormattedMessage id="app.labels.modelYears" />
        </Typography>
        <Typography>{moduleTitle}</Typography>
      </Box>
      {!withoutBadge && <img src={CSQualificationIcon} width={40} height={40} />}
    </Stack>
  )
}
