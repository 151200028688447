import { useMediaQuery, useTheme } from '@mui/material'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import ApplicationHeader from 'components/layout/ApplicationHeader'
import { IBrand } from 'interfaces'
import { Dispatch, SetStateAction } from 'react'
import LogBatteryContent from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/LogBatteryContent'
import { StyledDialog } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/styles'

interface LogLiveBatteryRepairProps {
  open: boolean
  onClose: () => void
  onConfirm: Dispatch<SetStateAction<boolean>>
  brands: IBrand[]
}

export type IFormInput = {
  selectedBrand: IBrand
  formStep: number
  repairDate: Date | null
  levelModuleUUID: string
  certificateFile: File
  brandAndModel: {
    moduleLevelUUID: string
    modelImage: string
    modelName: string
    moduleLevelTitle: string
  }
}

const LogLiveBatteryRepair = ({ open, onClose, brands }: LogLiveBatteryRepairProps) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.only('xs'))

  const handleClose = () => {
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullScreen={isSmall}
      disableEscapeKeyDown
      sx={isSmall ? { '& .MuiDialogContent-root': { minHeight: '73vh' } } : {}}
    >
      {isSmall && <ApplicationHeader isLargeScreen={!isSmall} />}
      <LogBatteryContent onClose={handleClose} brands={brands} />
      {isSmall && <ApplicationFooter />}
    </StyledDialog>
  )
}

export default LogLiveBatteryRepair
