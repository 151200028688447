import {
  Box,
  Button,
  ButtonProps,
  Fade,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import React, { useState } from 'react'
import Dialog from 'components/common/Dialog'
import {
  StyledLanguageButton,
  StyledLanguageButtonLabel,
  StyledPopoverContent,
} from 'components/common/LanguageSelector/styles'
import { FormattedMessage } from 'react-intl'
import { Close } from '@mui/icons-material'
import { ILanguageOption } from 'interfaces'

interface LanguageSelectorProps extends Partial<ButtonProps> {
  onHandleSelect: (languageId: string) => void
  languageOptions: ILanguageOption[]
  isLoading: boolean

  languageId: string
  languageName: string
  isoCode: string
}

export default function LanguageSelector(props: LanguageSelectorProps) {
  const {
    onHandleSelect,
    languageOptions,
    isLoading,
    languageId,
    languageName,
    ...buttonProps
  } = props
  const theme = useTheme()
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'))
  const [anchor, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectLanguage = (languageId: string) => {
    handleClose()
    onHandleSelect(languageId as string)
  }

  const isOpen = Boolean(anchor)

  return (
    <Box>
      {!isLargeUp && (
        <Dialog fullScreen open={Boolean(anchor)}>
          <Box sx={{ display: 'flex', background: 'white', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => handleClose()}
              sx={{ color: '#1E1E1E' }}
              endIcon={<Close />}
            >
              <FormattedMessage id="app.buttons.close" />
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', background: '#EBF1F6' }}>
            <Typography
              variant="button"
              display="block"
              align="center"
              sx={{ paddingBlock: 4 }}
            >
              <FormattedMessage id="login.languageSelector.selectLanguage" />
            </Typography>
            {languageOptions?.map(language => (
              <StyledLanguageButtonLabel
                aria-modal="true"
                aria-current={languageId === language.value}
                key={language.value}
                onClick={() => handleSelectLanguage(language.value as string)}
              >
                {language.label}
              </StyledLanguageButtonLabel>
            ))}
          </Box>
        </Dialog>
      )}
      <StyledLanguageButton
        aria-haspopup={isOpen}
        aria-describedby={'languageButton'}
        onClick={openPopover}
        {...buttonProps}
        sx={{
          color: 'white',
          display: 'flex',
          gap: 1,
        }}
      >
        {isLoading ? <FormattedMessage id="app.messages.loading" /> : languageName}

        <KeyboardArrowDownIcon
          sx={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
            transition: 'all 250ms',
          }}
        />
      </StyledLanguageButton>
      {isLargeUp && (
        <Popover
          anchorEl={anchor}
          TransitionComponent={Fade}
          open={isOpen}
          onClose={handleClose}
          PaperProps={{
            sx: {
              border: 0,
              boxShadow: 'none',
              mt: '-15px',
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <StyledPopoverContent>
            <Typography variant="button" display="block" align="center">
              <FormattedMessage id="login.languageSelector.selectLanguage" />
            </Typography>
            <Box
              sx={{
                mt: '15px',
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '5px',
              }}
            >
              {languageOptions?.map(language => (
                <StyledLanguageButtonLabel
                  aria-current={languageId === language.value}
                  key={language.value}
                  onClick={() =>
                    handleSelectLanguage(language.value.toLowerCase() as string)
                  }
                >
                  {language.label}
                </StyledLanguageButtonLabel>
              ))}
            </Box>
          </StyledPopoverContent>
        </Popover>
      )}
    </Box>
  )
}
