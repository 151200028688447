import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material'

import _without from 'lodash/without'
import {
  selectQualifications,
  selectSelectedCar,
  setQualifications,
  setSelectedCar,
  useAppDispatch,
  useAppSelector,
} from 'store'

import { useEffect } from 'react'
import {
  CheckedIcon,
  StlyedBoxWrapper,
  StyledChip,
  StyledChipsWrapper,
  StyledCloseButtonChip,
  UncheckedIcon,
} from './style'
import { FormattedMessage, useIntl } from 'react-intl'

interface IQualificationsProps {
  qualifications?: {
    modelName: string
    modelYears: string
    modelUUID: string
  }[]
}

const Qualifications = ({ qualifications }: IQualificationsProps) => {
  const selectedQualifications = useAppSelector(selectQualifications)
  const selectedCar = useAppSelector(selectSelectedCar)
  const intl = useIntl()

  const dispatch = useAppDispatch()
  const theme = useTheme()

  if (!qualifications) return null

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const { value } = e.target
    dispatch(setQualifications(value as string[]))
  }

  useEffect(() => {
    if (!qualifications.length) return
    dispatch(setQualifications([selectedCar].filter(Boolean)))
  }, [])

  useEffect(() => {
    if (selectedCar && !selectedQualifications.includes(selectedCar)) return
    dispatch(setSelectedCar(undefined))
  }, [selectedQualifications])

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault()
    dispatch(setQualifications(_without(selectedQualifications, value)))
  }

  return (
    <StlyedBoxWrapper>
      <label htmlFor="qualification-select" style={{ color: theme.palette.grey[800] }}>
        <FormattedMessage id="batteryList.table.header.certifications" />
      </label>
      <Select
        IconComponent={KeyboardArrowDown}
        displayEmpty
        id="qualification-select"
        value={selectedQualifications}
        multiple
        disabled={!qualifications || qualifications.length === 0}
        sx={{
          background: theme.palette.common.white,
          height: theme.spacing(5),
          borderRadius: '0 !important',
          '>div': {
            padding: '0 0 0 12px !important',
            marginRight: '32px',
          },
        }}
        onChange={handleChange}
        renderValue={selected => {
          if (selected.length === 0 || !qualifications || qualifications.length === 0) {
            return intl.formatMessage({
              id: 'app.labels.select',
            })
          }
          const selectedValues = qualifications.map(qualification => {
            if (selected && selected.includes(qualification.modelUUID as string)) {
              return (
                <StyledChipsWrapper key={qualification.modelUUID}>
                  <StyledChip
                    label={`${qualification.modelName} ${qualification.modelYears}`}
                    clickable
                    deleteIcon={
                      <StyledCloseButtonChip
                        onMouseDown={(event: { stopPropagation: () => any }) =>
                          event.stopPropagation()
                        }
                      />
                    }
                    onDelete={e => handleDelete(e, qualification.modelUUID)}
                  />
                </StyledChipsWrapper>
              )
            }
            return null
          })
          return selectedValues
        }}
      >
        {qualifications.map(qualification => (
          <MenuItem
            value={qualification.modelUUID}
            key={qualification.modelUUID}
            sx={{
              padding: '6px 6px !important',
              gap: 1,
              '&.Mui-selected': {
                backgroundColor: '#EBF1F6',
              },
            }}
          >
            <Checkbox
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              checked={
                selectedQualifications &&
                selectedQualifications.findIndex(
                  (item: string) => item === qualification.modelUUID,
                ) >= 0
              }
              size="small"
              disableRipple
            />
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingRight={1.5}
            >
              <Box>
                {qualification.modelName}
                <Typography color={theme.palette.grey[600]}>
                  <FormattedMessage
                    id="batteryList.filter.qualifications.select.modelYears"
                    values={{ model: qualification.modelYears }}
                  />
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </StlyedBoxWrapper>
  )
}

export default Qualifications
