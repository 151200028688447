import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  resetBusinessUnits,
  resetMyStaffFilters,
  useAppDispatch,
  setIsExternalLoggedIn,
} from 'store'

const Logout = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const redirectPath =
      state === null || state === '/' ? '/login' : `/login?redirect=${state}`

    localStorage.clear()
    dispatch(resetBusinessUnits())
    dispatch(resetMyStaffFilters())
    dispatch(setIsExternalLoggedIn(false))
    navigate(redirectPath, {
      state,
    })
  }, [])

  return null
}

export default Logout
