import {
  Button,
  Checkbox,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import * as yup from 'yup'

import { Box } from '@mui/system'
import experienceCriteriaIcon from 'assets/svgs/icon_course_experience_criteria.svg'
import actionIcon from 'assets/svgs/icon_cs_action.svg'
import completedIcon from 'assets/svgs/icon_ls_completed.svg'
import uploadIcon from 'assets/svgs/jaguar_logo_upload.svg'
import downloadIcon from 'assets/svgs/icon_download.svg'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  useGetExperienceCriteriaQuery,
  useSubmitExperienceCriteriaCommandMutation,
} from 'store/api'
import { useAppSelector } from 'store'
import { selectUserIsImpersonate } from 'store/slices'
import ModuleCard from 'components/EVLevel/ModulesList/ModuleCard'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  StyledBoxWrapper,
  StyledButton,
  StyledInputFile,
  StyledLabelFile,
  StyledTable,
  StyledWrapperFileInput,
} from 'components/EVLevel/EVDetail/ExperienceCriteria/styles'
import { ITrainingLevelDetails } from 'interfaces'

interface ExperienceCriteriaProps {
  hasExperienceCriteria: boolean
  levelUUID: string
  staffMemberUUID?: string
  experienceCriteria?: ITrainingLevelDetails['experienceCriteria']
  experienceCriteriaMonths?: string
}

type IFormInput = {
  experienceCriteriaFile: File | null
}

const ExperienceCriteria: FC<ExperienceCriteriaProps> = ({
  hasExperienceCriteria,
  levelUUID,
  staffMemberUUID,
  experienceCriteria,
  experienceCriteriaMonths,
}) => {
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const [expanded, setExpanded] = useState(false)
  const [checked, setChecked] = useState(false)
  const { palette, spacing, typography } = useTheme()
  const intl = useIntl()
  const { data: experiencCriteriaQueryTypes } = useGetExperienceCriteriaQuery('')

  const allowed = `.${experiencCriteriaQueryTypes?.allowedFileTypes?.join(', .')}`
  const schema = yup.object().shape({
    experienceCriteriaFile: yup
      .mixed()
      .required('Certificate is required')
      .test(
        'fileSize',
        intl.formatMessage(
          {
            id: 'evLevel.logBatteryLiveRepair.form.validation.certificate.fileSize',
          },
          {
            maxFilesize: Math.round(
              (experiencCriteriaQueryTypes?.maxFileSizeBytes ?? 10445679) / 1000000,
            ),
          },
        ),
        value =>
          !value ||
          (value && value.size <= Number(experiencCriteriaQueryTypes?.maxFileSizeBytes)),
      )
      .test(
        'fileFormat',
        intl.formatMessage(
          {
            id: 'evLevel.logBatteryLiveRepair.form.validation.certificate.fileFormat',
          },
          {
            supportedFormats: allowed.toLocaleUpperCase(),
          },
        ),
        value =>
          !value ||
          (value &&
            experiencCriteriaQueryTypes?.allowedFileMediaTypes?.includes(value.type)),
      ),
  })
  const methods = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      experienceCriteriaFile: null,
    },
  })

  const { watch, setValue, control, handleSubmit } = methods

  const [experienceCriteriaFile] = watch(['experienceCriteriaFile'])

  const [submitExperienceCriteria, { isLoading }] =
    useSubmitExperienceCriteriaCommandMutation()

  const onFormSubmit = async (data: IFormInput) => {
    const formData = new FormData()
    formData.append('file', data.experienceCriteriaFile as File)
    formData.append('staffMemberUUID', staffMemberUUID as string)
    formData.append('levelUUID', levelUUID)
    submitExperienceCriteria(formData)
  }

  const handleDownloadButton = () => {
    const docData = experienceCriteria?.link
    if (docData) {
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        const url = docData
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          const blob = xhr.response
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.click()
        }
        xhr.send()
      } else {
        window.open(docData, '_blank')?.focus()
      }
    }
  }

  if (!experiencCriteriaQueryTypes) {
    return null
  }

  return (
    <ModuleCard
      open={expanded}
      onToggleOpen={() => setExpanded(!expanded)}
      header={
        <Box
          display="grid"
          gridTemplateColumns="auto 1fr auto"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            width="70px"
            height="43px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              img: {
                width: '44px',
                height: '43px',
              },
            }}
          >
            <img src={experienceCriteriaIcon} alt="Experience Criteria" />
          </Box>
          <Typography
            align="left"
            ml={2}
            color={palette.grey[700]}
            textTransform="uppercase"
          >
            <FormattedMessage id="evLevel.EVDetail.experienceCriteria.title" />
          </Typography>
          <img
            src={hasExperienceCriteria ? completedIcon : actionIcon}
            alt={hasExperienceCriteria ? 'completed icon' : 'warning icon'}
            style={{ width: '40px', height: '40px' }}
          />
        </Box>
      }
    >
      <StyledBoxWrapper>
        <Typography color={palette.grey[700]} fontSize={14} marginBottom={2}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        <Typography color={palette.grey[700]} fontSize={14} marginBottom={2}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle1"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        <Typography color={palette.grey[700]} fontSize={14} marginBottom={2}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle2"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        <Typography color={palette.grey[700]} fontSize={14}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle3"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        <Typography color={palette.grey[700]} fontSize={14}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle4"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        <Typography color={palette.grey[700]} fontSize={14}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle5"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        <Typography color={palette.grey[700]} fontSize={14} marginBottom={2}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle6"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        <Typography color={palette.grey[700]} fontSize={14} marginBottom={2}>
          <FormattedMessage
            id="evLevel.EVDetail.experienceCriteria.subtitle7"
            values={{ numberMonths: experienceCriteriaMonths }}
          />
        </Typography>
        {hasExperienceCriteria ? (
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  <Typography>
                    <FormattedMessage id="evLevel.EVDetail.experienceCriteria.table.fileName" />
                  </Typography>
                </TableCell>
                <TableCell width="40%">
                  <Typography>
                    <FormattedMessage id="evLevel.EVDetail.experienceCriteria.table.fileType" />
                  </Typography>
                </TableCell>
                <TableCell width="20%" align="right">
                  <Typography>
                    <FormattedMessage id="app.buttons.download" />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>{experienceCriteria?.filename}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{experienceCriteria?.filetype}</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => {
                      handleDownloadButton()
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <img src={downloadIcon} width="20" />
                    )}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        ) : (
          <form onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
            <Controller
              name={'experienceCriteriaFile'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Box>
                  <Typography color={palette.grey[700]} fontSize={14} marginBottom={0.5}>
                    <FormattedMessage id="evLevel.EVDetail.experienceCriteria.input.label" />
                  </Typography>

                  <StyledWrapperFileInput error={Boolean(error).toString()}>
                    <StyledInputFile
                      {...field}
                      type="file"
                      name="experienceFile"
                      id="experienceFile"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setValue('experienceCriteriaFile', e.target.files?.[0] as File)
                      }}
                      accept={allowed}
                      value=""
                      disabled={isLoading}
                    />

                    <StyledLabelFile htmlFor="experienceFile">
                      {field.value ? (
                        <Typography
                          variant="body2"
                          color={palette.grey[700]}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {field.value.name}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          color={palette.grey[500]}
                          sx={{ margin: '2px 0 0 0' }}
                        >
                          <FormattedMessage id="app.labels.upload" />
                        </Typography>
                      )}
                      <img src={uploadIcon} alt="" />
                    </StyledLabelFile>
                  </StyledWrapperFileInput>

                  <Box
                    sx={{
                      my: spacing(1),
                      fontSize: typography.body2.fontSize,
                      color: palette.grey[500],
                    }}
                  >
                    <FormattedMessage
                      id="evLevel.logBatteryLiveRepair.form.helper.certificate"
                      values={{
                        maxFilesize: Math.round(
                          experiencCriteriaQueryTypes!.maxFileSizeBytes / 1000000,
                        ),
                      }}
                    />
                  </Box>

                  {error && (
                    <Typography color={palette.error.main} fontSize={14}>
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
            <Box display="flex" alignItems="center" marginLeft="-12px" marginBottom={1}>
              <Checkbox
                id="confimation"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <Typography
                component="label"
                htmlFor="confimation"
                fontSize={14}
                variant="body2"
                color={palette.grey[700]}
              >
                <FormattedMessage id="evLevel.EVDetail.experienceCriteria.checkbox.label" />
              </Typography>
            </Box>

            <StyledButton
              type="submit"
              disabled={isImpersonate || !experienceCriteriaFile || !checked || isLoading}
            >
              {isLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <FormattedMessage id="evLevel.EVDetail.experienceCriteria.button.text" />
              )}
            </StyledButton>
          </form>
        )}
      </StyledBoxWrapper>
    </ModuleCard>
  )
}

export default ExperienceCriteria
