import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import jaguarLogo from 'assets/svgs/jaguar_black_logo.svg'
import rangeRoverLogo from 'assets/svgs/range_rover_black_logo.svg'
import defenderLogo from 'assets/svgs/defender_black_logo.svg'
import discoveryLogo from 'assets/svgs/discovery_black_logo.svg'
import {
  StyledCarCount,
  StyledCarInfoBox,
  StyledCarInfoBrandBox,
  StyledCarInfoContent,
  StyledCardBoxContent,
} from 'components/DashboardInfos/style'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  setClearJobRoleAndSearchTermFilters,
  setSelectedCar,
  useAppDispatch,
} from 'store'
interface CardCarCertifieldDesktopProps {
  brandName: string
  modelName: string
  modelUUID: string
  modelImage: string
  year: string
  count: number
}
const BrandLogo = ({ brandName }: { brandName: string }) => {
  switch (brandName) {
    case 'Jaguar':
      return <img src={jaguarLogo} alt="Jaguar Logo" />
    case 'Range Rover':
      return <img src={rangeRoverLogo} alt="Range Rover Logo" />
    case 'Discovery':
      return <img src={discoveryLogo} alt="Discovery Logo" />
    case 'Defender':
      return <img src={defenderLogo} alt="Defender Logo" />
    default:
      return (
        <StyledCardBoxContent>
          <Typography>{brandName}</Typography>
        </StyledCardBoxContent>
      )
  }
}

const CardCarCertifieldDesktop: FC<CardCarCertifieldDesktopProps> = ({
  brandName,
  count,
  modelName,
  modelUUID,
  modelImage,
  year,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(setClearJobRoleAndSearchTermFilters())
    dispatch(setSelectedCar(modelUUID))

    navigate('/dashboard/battery-list')
  }
  return (
    <Box>
      <StyledCarInfoBox>
        <StyledCarInfoBrandBox>
          <BrandLogo brandName={brandName} />
        </StyledCarInfoBrandBox>

        <StyledCarInfoContent>
          <img src={modelImage} alt={modelName} />
          <Box>
            <Typography>{modelName}</Typography>
            <Typography>
              <FormattedMessage id="app.labels.modelYears" />: {year}
            </Typography>
          </Box>
        </StyledCarInfoContent>
      </StyledCarInfoBox>

      <StyledCarCount onClick={handleClick}>{count}</StyledCarCount>
    </Box>
  )
}

export default CardCarCertifieldDesktop
