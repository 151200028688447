import { Box, CircularProgress, Divider, Typography, useMediaQuery } from '@mui/material'
import jaguarLogo from 'assets/svgs/jaguar_black_logo.svg'
import rangeRoverLogo from 'assets/svgs/range_rover_black_logo.svg'
import defenderLogo from 'assets/svgs/defender_black_logo.svg'
import discoveryLogo from 'assets/svgs/discovery_black_logo.svg'
import Body from 'components/common/Body'
import Button from 'components/common/Button'
import CardCarContent from 'components/SettingsComponents/CardCarContent'
import MobileContentWrapperCards from 'components/SettingsComponents/MobileContentWrapperCards'
import SelectedEvStatus from 'components/SettingsComponents/SelectedEvStatus'
import CountCardsSettings from 'components/SettingsComponents/CountCardsSettings'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import SubHeader from 'components/layout/SubHeader'
import BusinessUnitSelector from 'components/common/BusinessUnitSelector'
import { IEvSettings } from 'interfaces'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { selectSelectedBusinessUnits, useAppSelector, useAppDispatch } from 'store'
import { selectUser, setSelectedBusinessUnits } from 'store/slices'
import { useGetEvSettingsQuery } from 'store/api'
import {
  StyledBrandBannersWrapper,
  StyledButtonBox,
  StyledCarsCardContainer,
  StyledContainerSettingsWrapper,
} from 'routes/Settings/styles'

const Settings = () => {
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const user = useAppSelector(selectUser)

  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)

  const dispatch = useAppDispatch()

  const { data, isLoading, isFetching } = useGetEvSettingsQuery(
    {
      businessUnitUUID: selectedBusinessUnits[0]?.value as string,
    },
    {
      refetchOnMountOrArgChange: shouldRefetch,
      skip: selectedBusinessUnits.length === 0,
    },
  )

  const [models, setModels] = useState<IEvSettings['models']>([])
  const brandArr = ['Jaguar', 'Range Rover', 'Discovery', 'Defender']
  const isLargeScreen = useMediaQuery('(min-width: 1200px)')

  useEffect(() => {
    if (data) {
      setModels(data.models)
    }
  }, [data])

  useEffect(() => {
    dispatch(setSelectedBusinessUnits([user!.businessUnits[0]]))
  }, [])

  const navigate = useNavigate()
  const readyOnly = false

  if (isLoading || isFetching || models.length === 0)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="90vh"
      >
        <CircularProgress size={20} />
      </Box>
    )

  return (
    <Body>
      {!readyOnly ? (
        <>
          <SubHeader
            breadcrumbItems={[
              {
                text: <FormattedMessage id="appNavigation.menuItem.home" />,
                href: '/',
              },
              {
                text: <FormattedMessage id="appNavigation.menuItem.settings" />,
                href: '/settings',
              },
            ]}
          >
            <BusinessUnitSelector
              {...{ setShouldRefetch, shouldRefetch, singleSelect: true }}
            />
          </SubHeader>
          <StyledContainerSettingsWrapper>
            <SelectedEvStatus status={data?.status} />

            <Typography>
              <FormattedMessage id="settings.selectModel.title" />
            </Typography>
            {isLargeScreen ? (
              <>
                <StyledBrandBannersWrapper>
                  <Box>
                    <img src={jaguarLogo} alt="Jaguar Logo" />
                  </Box>
                  <Box>
                    <img src={rangeRoverLogo} alt="Range Rover Logo" />
                  </Box>
                  <Box>
                    <img src={discoveryLogo} alt="Discovery Logo" />
                  </Box>
                  <Box>
                    <img src={defenderLogo} alt="Defender Logo" />
                  </Box>
                </StyledBrandBannersWrapper>

                <StyledCarsCardContainer>
                  <Box>
                    {models
                      .filter(model => model.brand.brandName === 'Jaguar')
                      .map(({ model, year, isEnabled }) => (
                        <CardCarContent
                          key={model.modelUUID}
                          {...{ model, year, isEnabled }}
                        />
                      ))}
                  </Box>

                  <Box>
                    {models
                      .filter(model => model.brand.brandName === 'Range Rover')
                      .map(({ model, year, isEnabled }) => (
                        <CardCarContent
                          key={model.modelUUID}
                          {...{ model, year, isEnabled }}
                        />
                      ))}
                  </Box>

                  <Box>
                    {models
                      .filter(model => model.brand.brandName === 'Discovery')
                      .map(({ model, year, isEnabled }) => (
                        <CardCarContent
                          key={model.modelUUID}
                          {...{ model, year, isEnabled }}
                        />
                      ))}
                  </Box>

                  <Box>
                    {models
                      .filter(model => model.brand.brandName === 'Defender')
                      .map(({ model, year, isEnabled }) => (
                        <CardCarContent
                          key={model.modelUUID}
                          {...{ model, year, isEnabled }}
                        />
                      ))}
                  </Box>
                </StyledCarsCardContainer>
              </>
            ) : (
              brandArr.map(brand => (
                <MobileContentWrapperCards
                  key={brand}
                  brand={brand}
                  cars={models.filter(record => record.brand.brandName === brand)}
                />
              ))
            )}
            <Box width="100%">
              <Divider />
            </Box>
            <StyledButtonBox>
              <Button onClick={() => navigate('/')}>
                <FormattedMessage id="settings.selectModel.closeButton" />
              </Button>
            </StyledButtonBox>
          </StyledContainerSettingsWrapper>
        </>
      ) : (
        <CountCardsSettings />
      )}

      <ApplicationFooter />
    </Body>
  )
}

export default Settings
