import { Card, CardActionArea, CardHeader, CardContent, Collapse } from '@mui/material'
import { Stack } from '@mui/system'
import { IHelpCategory } from 'interfaces'
import HelpContent from 'components/HelpCentre/HelpContent'

type Props = {
  category: IHelpCategory
  open: boolean
  onToggleOpen: (id: string) => void
  selectedContent: Record<string, boolean>
  onToggleContentOpen: (id: string) => void
}

export default function HelpCategory({
  category,
  open,
  onToggleOpen,
  selectedContent,
  onToggleContentOpen,
}: Props) {
  return (
    <Card
      variant="outlined"
      square
      sx={{ borderColor: '#CDD7D9', backgroundColor: '#F5F8FB' }}
    >
      <CardActionArea onClick={() => onToggleOpen(category.id)}>
        <CardHeader
          title={category.title}
          sx={{ px: 0 }}
          titleTypographyProps={{
            align: 'center',
            fontSize: 16,
            textTransform: 'uppercase',
          }}
        />
      </CardActionArea>
      <Collapse in={open}>
        <CardContent sx={{ px: 0 }}>
          <Stack spacing={1.5}>
            {category.helpContents.map(content => (
              <HelpContent
                key={content.title}
                content={content}
                open={Boolean(selectedContent[content.id])}
                onToggleOpen={onToggleContentOpen}
              />
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  )
}
