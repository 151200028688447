import { Box, Typography, useTheme } from '@mui/material'
import { IBatteryListRowOrCollapseProps } from 'interfaces'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
  StyledButtonDetails,
  StyledTableCell,
  StyledTableRow,
  StyledTypographyRounded,
} from 'components/BatteryList/BatteryListDesktopInfo/Components/RowTable/style'
import { formatDate } from 'utils/formatDate'

interface IBatteryListRowCollapseProps {
  row: IBatteryListRowOrCollapseProps
}

const RowTable: FC<IBatteryListRowCollapseProps> = ({ row }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <StyledTableRow key={`${row.pin} ${row.name} ${row.jobRoles}`}>
      <StyledTableCell>
        <Box display="flex" flexDirection="column">
          <Typography color={theme.palette.grey[600]}>{row.pin}</Typography>
          <Typography width={142} textTransform="capitalize">
            {row.name}
          </Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" flexDirection="column">
          {row.jobRoles.map((e: any) => (
            <Typography width={110} key={e + Math.random()}>
              {e}
            </Typography>
          ))}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box>
          {row.businessUnits.map((e: any) => (
            <Typography width={120} key={e + Math.random()}>
              {e}
            </Typography>
          ))}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" gap="10px" width={115}>
          {formatDate(row.expiryDate)}
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" gap="10px" width={115} justifyContent="center">
          <StyledTypographyRounded>{row.numberQualifications}</StyledTypographyRounded>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex" gap="10px" width={115} justifyContent="center">
          <StyledTypographyRounded>{row.numberRepairs}</StyledTypographyRounded>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box paddingRight="10px">
          <StyledButtonDetails
            variant="outlined"
            size="small"
            onClick={() => navigate(`/user-details/${row.staffMemberUUID}`)}
          >
            <FormattedMessage id="membersList.viewDetails" />
          </StyledButtonDetails>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default RowTable
