import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FormattedMessage } from 'react-intl'
import { Stack } from '@mui/system'

type Props = {
  open: boolean
  onClose: () => void
  welcomeVideoLink: string
}

export default function WelcomeVideo({ open, onClose, welcomeVideoLink }: Props) {
  return (
    <Dialog open={open} maxWidth="md" fullWidth scroll="body">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <Typography align="center" fontSize={18} letterSpacing={2} flex={1}>
            <FormattedMessage id="trainingCentre.welcomeToEVTrainingCenter" />
          </Typography>
          <IconButton edge="end" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <video width="100%" controls autoPlay src={welcomeVideoLink}></video>
      </DialogContent>
    </Dialog>
  )
}
