import { IBrandModel, ICompletedLogBatteryItems } from 'interfaces'

export const getCompletedModules = (models: IBrandModel[]) => {
  const completedModules: ICompletedLogBatteryItems[] = []
  models.forEach(model => {
    model.modules.forEach(module => {
      if (module.status === 'COMPLETED') {
        completedModules.push({ model, module })
      }
    })
  })

  return completedModules
}
