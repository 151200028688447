import { Paper, ButtonBase, Collapse, Box } from '@mui/material'

type Props = {
  open: boolean
  onToggleOpen: () => void
  header: React.ReactNode
  children: React.ReactNode
}

export default function BatteryRepairContainer({
  open,
  onToggleOpen,
  header,
  children,
}: Props) {
  return (
    <Paper>
      <ButtonBase
        onClick={onToggleOpen}
        sx={{
          px: 3,
          py: 3,
          width: '100%',
          ':focus-visible': { outline: '1px solid #cad1d7' },
        }}
      >
        {header}
      </ButtonBase>
      <Collapse in={open}>
        <Box m={1}>{children}</Box>
      </Collapse>
    </Paper>
  )
}
