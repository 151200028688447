import { ButtonBase, Typography } from '@mui/material'
import { FC } from 'react'
import {
  CardCarCertifieldWrapper,
  CardCarCertifieldContent,
  CardCarCertifieldCount,
} from 'components/DashboardInfos/style'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  useAppDispatch,
  setSelectedCar,
  setClearJobRoleAndSearchTermFilters,
} from 'store'

interface CardCarCertifieldDesktopProps {
  modelName: string
  modelImage?: string
  modelUUID: string
  year: string
  count: number
}

const CardCarCertifieldMobile: FC<CardCarCertifieldDesktopProps> = ({
  count,
  modelName,
  year,
  modelImage,
  modelUUID,
}) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(setClearJobRoleAndSearchTermFilters())
    dispatch(setSelectedCar(modelUUID))

    navigate('/dashboard/battery-list')
  }

  return (
    <CardCarCertifieldWrapper>
      <img src={modelImage} alt={modelName} />
      <CardCarCertifieldContent>
        <Typography>{modelName}</Typography>
        <Typography>
          <FormattedMessage id="app.labels.modelYears" />
        </Typography>
        <Typography>{year}</Typography>
      </CardCarCertifieldContent>
      <CardCarCertifieldCount>
        <ButtonBase onClick={handleClick}>{count}</ButtonBase>
      </CardCarCertifieldCount>
    </CardCarCertifieldWrapper>
  )
}

export default CardCarCertifieldMobile
