import { Close as CloseIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IRemoveStaffFromWaitListCommandProps } from 'interfaces'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { setIsRemoveWaitlistModalOpen, useAppDispatch, useAppSelector } from 'store'
import { useRemoveStaffFromWaitListCommandMutation } from 'store/api'

const RemoveWaitlistStaffMember = () => {
  const dispatch = useAppDispatch()
  const {
    selectedCourse: courseUUID,
    removeWaitlistModalOpen,
    currentBookingSessionId,
  } = useAppSelector(state => state.booking)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.only('xs'))
  const { user: userUUIDParam } = useParams()
  const [isSuccess, setIsSuccess] = useState(false)
  const isMediumScreen = useMediaQuery('@media (min-width: 940px)')
  const isBelowMediumScreen = useMediaQuery('@media (max-width: 600px)')

  const [
    removeStaffFromWaitList,
    { isLoading, isError, isSuccess: successfullySubmitted },
  ] = useRemoveStaffFromWaitListCommandMutation()

  const onSubmit = () => {
    const body: IRemoveStaffFromWaitListCommandProps = {
      courseUUID: courseUUID as string,
      sessionUUID: currentBookingSessionId as string,
      userUUID: userUUIDParam as string,
    }
    removeStaffFromWaitList(body)
  }

  useEffect(() => {
    if (isError) {
      setIsSuccess(false)
    }
  }, [isError])

  useEffect(() => {
    if (successfullySubmitted) {
      setIsSuccess(true)
    }
  }, [successfullySubmitted])

  const handleClose = () => {
    setIsSuccess(false)

    dispatch(setIsRemoveWaitlistModalOpen(false))
  }

  return (
    <Dialog
      open={removeWaitlistModalOpen}
      onClose={() => handleClose()}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          padding: 0,
          margin: 0,
          zIndex: 100,
          minWidth: 'auto',
        }}
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </Button>
      <DialogContent
        sx={{
          width: isMediumScreen ? '780px' : '100%',
          padding: 0,
        }}
      >
        <Box bgcolor={theme.palette.grey[100]} p="24px 24px 16px">
          <Typography color={theme.palette.grey[700]} fontSize={20}>
            {!isSuccess ? 'REMOVE WAITLIST?' : 'WAITLIST REMOVED'}
          </Typography>
        </Box>
        <Box
          bgcolor={theme.palette.grey[50]}
          sx={{
            padding: '16px 24px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: isBelowMediumScreen ? 'calc( 100vh - 70px)' : 'auto',
          }}
        >
          <Typography color={theme.palette.grey[700]} fontSize="16px">
            {!isSuccess
              ? 'Are you sure you would like to remove this staff member from the session waitlist?'
              : "The staff member has been removed from this session's waitlist"}
          </Typography>

          <Box>
            <Divider sx={{ marginBlock: '16px' }} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1,
              }}
            >
              <Button
                sx={{
                  border: `1px solid ${theme.palette.grey[500]}`,
                  textTransform: 'uppercase',
                  fontSize: '0.75rem',
                }}
                onClick={() => handleClose()}
              >
                Close
              </Button>
              {!isSuccess && (
                <Button
                  sx={{
                    border: `1px solid ${theme.palette.grey[500]}`,
                    textTransform: 'uppercase',
                    fontSize: '0.75rem',
                  }}
                  type="button"
                  onClick={() => onSubmit()}
                  disabled={isLoading}
                >
                  {!isLoading ? 'confirm' : <CircularProgress size={20} />}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default RemoveWaitlistStaffMember
