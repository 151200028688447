// Redux business units feature slice to store all global user state related data.

import { createSlice } from '@reduxjs/toolkit'
import {
  IGetStaffQualificationsConfigQueryResponse,
  ISearchStaffQualificationsQueryResponse,
} from 'interfaces'
import { RootState } from 'store'

interface IInitalStateDashboardProps {
  rowAndCollapseData: ISearchStaffQualificationsQueryResponse['data']
  qualificationsAndJobRoles: IGetStaffQualificationsConfigQueryResponse['data']
  selectedCar?: string

  filters: {
    searchTerm: string
    jobRoles: string[]
    qualifications: string[]
  }

  table: {
    page: number
    pageSize: number
  }
}

const initialState: IInitalStateDashboardProps = {
  selectedCar: undefined,

  rowAndCollapseData: {
    records: [],
    totalPages: 0,
    totalRecords: 0,
  },

  qualificationsAndJobRoles: {
    qualifications: [],
    jobRoles: [],
  },
  filters: {
    qualifications: [],
    jobRoles: [],
    searchTerm: '',
  },
  table: {
    pageSize: 10,
    page: 1,
  },
}

const batteryListSlice = createSlice({
  name: 'batteryListSlice',
  initialState,
  reducers: {
    setSelectedCar(state, action) {
      state.selectedCar = action.payload
    },
    setRowAndCollapseData(state, action) {
      state.rowAndCollapseData = action.payload
    },
    setQualificationsAndJobRoles(state, action) {
      state.qualificationsAndJobRoles = action.payload
    },
    setQualifications(state, action) {
      state.filters.qualifications = [...action.payload]
    },
    setJobRoles(state, action) {
      state.filters.jobRoles = [...action.payload]
    },
    setSearchTermBatteryList(state, action) {
      state.filters.searchTerm = action.payload
    },
    setPage(state, action) {
      state.table.page = action.payload
    },
    setPageSize(state, action) {
      state.table.pageSize = action.payload
    },
    setClearAllFilters(state) {
      state.filters = initialState.filters
    },
    setClearJobRoleAndSearchTermFilters(state) {
      state.filters.jobRoles = initialState.filters.jobRoles
      state.filters.searchTerm = initialState.filters.searchTerm
    },
  },
})

export const {
  setSelectedCar,
  setQualifications,
  setJobRoles,
  setRowAndCollapseData,
  setQualificationsAndJobRoles,
  setSearchTermBatteryList,
  setPage,
  setPageSize,
  setClearAllFilters,
  setClearJobRoleAndSearchTermFilters,
} = batteryListSlice.actions

export const selectSelectedCar = (state: RootState) => state.batteryList.selectedCar
export const selectQualifications = (state: RootState) =>
  state.batteryList.filters.qualifications
export const selectJobRoles = (state: RootState) => state.batteryList.filters.jobRoles
export const selectRowAndCollapseData = (state: RootState) =>
  state.batteryList.rowAndCollapseData
export const selectQualificationsAndJobRoles = (state: RootState) =>
  state.batteryList.qualificationsAndJobRoles
export const selectSearchTermBatteryList = (state: RootState) =>
  state.batteryList.filters.searchTerm
export const selectPageConfig = (state: RootState) => state.batteryList.table
export const selectFilterConfig = (state: RootState) => state.batteryList.filters

export default batteryListSlice.reducer
