import { EVLevelIcon } from 'icons/EVIcons'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { Box, IconButton, Paper, Stack, styled } from '@mui/material'
import dashboardBigIcon from 'assets/svgs/icon_dashboard_big.svg'
import exploreIcon from 'assets/svgs/icon_explore_ev_big.svg'
import helpIcon from 'assets/svgs/icon_help_round.svg'
import infoIcon from 'assets/svgs/icon_info_button.svg'
import myStaffBigIcon from 'assets/svgs/icon_my_staff_big.svg'
import { IValueLabel } from 'interfaces'
import { useAppSelector } from 'store'
import { selectUser } from 'store/slices'
import { EVLevelIconName } from 'types'
import { MyEvTrainingSummaryContent } from 'components/TrainingCentre/MyTrainingTileSummary'

const FlipButton = styled(IconButton)({
  gridColumn: 1,
  gridRow: 1,
  justifySelf: 'end',
  alignSelf: 'start',
  marginLeft: 4,
  '& img': {
    width: 20,
    height: 20,
  },
})

type Props = {
  hasEvTrainingJobRole: boolean | undefined
  isManager: boolean | undefined
  isRetailerAdmin: boolean | undefined
  workingLevel?: IValueLabel | null
  certifiedLevel?: IValueLabel | null
  targetLevel?: IValueLabel | null
}

export default function TrainingTilesMobile({
  hasEvTrainingJobRole,
  isManager,
  isRetailerAdmin,
  workingLevel,
  certifiedLevel,
  targetLevel,
}: Props) {
  const [showFace, setShowFace] = useState<'front' | 'back'>('front')

  const user = useAppSelector(selectUser)

  return (
    <>
      {Boolean(!hasEvTrainingJobRole) && (
        <Finger
          icon={<img src={exploreIcon} alt={'Explore'} width={75} height={75} />}
          link={'/ev-level'}
        >
          <FormattedMessage id="app.labels.exploreEV" />
        </Finger>
      )}

      {Boolean(hasEvTrainingJobRole) && (
        <Finger
          icon={
            <div style={{ transition: '400ms', opacity: showFace === 'back' ? 0 : 1 }}>
              <EVLevelIcon evLevel={workingLevel?.value as EVLevelIconName} />
            </div>
          }
          link={`ev-level/${user?.workingLevelUUID}`}
        >
          <Stack
            sx={{
              height: showFace === 'back' ? '220px' : 'auto',
              transition: '200ms',
              overflow: 'hidden',
              justifyContent: 'center',
            }}
          >
            <div>
              <FormattedMessage id="app.labels.myEVTraining" />
              <FlipButton
                size="small"
                onClick={e => {
                  e.stopPropagation()
                  showFace === 'front' ? setShowFace('back') : setShowFace('front')
                }}
              >
                <img src={infoIcon} />
              </FlipButton>
            </div>

            {showFace === 'back' ? (
              <Box sx={{ marginTop: '12px', marginBottom: '12px' }}>
                <MyEvTrainingSummaryContent
                  certifiedLevel={certifiedLevel}
                  workingLevel={workingLevel}
                  targetLevel={targetLevel}
                />
              </Box>
            ) : null}
          </Stack>
        </Finger>
      )}

      {Boolean(isManager || isRetailerAdmin) && (
        <Finger
          icon={<img src={dashboardBigIcon} alt="Dashboard" width={75} height={75} />}
          link={'/dashboard'}
        >
          <FormattedMessage id="app.labels.dashboard" />
        </Finger>
      )}

      {Boolean(isManager || isRetailerAdmin) && (
        <Finger
          icon={<img src={myStaffBigIcon} alt="MY STAFF" width={75} height={75} />}
          link={'/staff-members'}
        >
          <FormattedMessage id="app.labels.myStaff" />
        </Finger>
      )}

      <Finger
        link={'/help-center'}
        icon={<img src={helpIcon} alt="Help" width={75} height={75} />}
      >
        <FormattedMessage id="app.buttons.help" />
      </Finger>
    </>
  )
}
type FingerProps = {
  icon: React.ReactNode
  link?: string | null
  externalLink?: string | null
  children: React.ReactNode
}

const Finger: FC<FingerProps> = props => {
  const { icon, children, link = null, externalLink = null } = props
  const navigate = useNavigate()
  return (
    <Paper
      onClick={() => {
        link ? navigate(link) : externalLink ? window.open(externalLink) : null
      }}
      elevation={2}
      sx={{
        cursor: 'pointer',
        marginLeft: '-16px',
        paddingLeft: '16px',
        paddingRight: '8px',
        borderRadius: '0px 45px 45px 0px',
        minHeight: '90px',
        maxWidth: '340px', // Design says 312 but need space for languages - may need adjusting later
        letterSpacing: '1.28px',
      }}
    >
      <Stack
        sx={{
          height: '100%',
          minHeight: '90px',
          alignItems: 'flex-start',
          justifyContent: 'center',
          textShadow: 'none',
          position: 'relative',
        }}
      >
        <div>{children}</div>
        {/* absolute because we hide with opacity and needs to not take up space */}
        <div style={{ position: 'absolute', right: '4px', top: '8px' }}>{icon}</div>
      </Stack>
    </Paper>
  )
}
