// Custome theme to override material ui styles where necesary for JLR UI

import { createTheme } from '@mui/material/styles'
import evBolt from './assets/svgs/ev_bolt.svg'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#1E1E1E',
      light: '#8C8C8C',
    },
    secondary: {
      main: '#ffffff',
    },
    grey: {
      50: '#F5F8FB',
      100: '#EBF1F6',
      200: '#0000001A',
      300: '#00000029',
      400: '#C4C4C4',
      500: '#8C8C8C',
      600: '#5B788F',
      700: '#525252',
      800: '#1E1E1E',
      900: '#010101',
    },
    error: {
      main: '#9e1b32',
    },
  },
})

export const customTheme = createTheme({
  ...defaultTheme,
  typography: {
    fontFamily: 'JLR Emeric Regular , JLR Emeric ExtraLight',
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        html: {
          boxSizing: 'border-box',
          minHeight: '100%',
        },

        body: {
          backgroundImage: `url(${evBolt}), linear-gradient(94deg, rgba(158,173,190,1) 0%, rgba(200,210,211,1) 100%)`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          minHeight: '100vh',
          '& #root': { minHeight: '100vh', display: 'flex', flexDirection: 'column' },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: defaultTheme.palette.primary.main,
          position: 'sticky',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          borderRadius: 0,
        },
        MenuProps: {
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            sx: {
              borderRadius: 0,
              marginTop: 0.3,
              border: '1px solid #C4C4C4',
            },
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        sx: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #C4C4C4 !important',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        outlinedInfo: {
          transition: 'all 0.3s ease',
          color: '#1e1e1e',
          borderColor: '#8C8C8C',
          '&:hover': {
            borderColor: '#525252',
            backgroundColor: '#525252',
            color: 'white',
          },
        },
        root: {
          borderRadius: 0,
          padding: '6px 12px',
        },
        text: {
          textTransform: 'none',
        },
        textSecondary: {
          color: '#525252',
          textDecoration: 'underline',
          padding: '5px',
          height: 'unset',
        },
        containedPrimary: {
          backgroundColor: defaultTheme.palette.primary.main,
          borderRadius: 0,
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(30, 30, 30, 0.9)',
          },
          '&:focus-visible': {
            outline: `1px solid ${defaultTheme.palette.primary.main}`,
            outlineOffset: 1,
          },
          '&:disabled': {
            color: 'rgba(255,255,255, 0.5)',
            backgroundColor: 'rgba(30, 30, 30, 0.5)',
          },
        },
        outlinedPrimary: {
          padding: '10px',
          border: '1px solid #8C8C8C',
          backgroundColor: 'transparent',
          color: defaultTheme.palette.primary.main,
          '&:hover': {
            border: `1px solid ${defaultTheme.palette.primary.main}`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#C4C4C4',
          '&.Mui-checked': { color: '#5B788F' },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:focus': { backgroundColor: 'rgb(236, 241, 245)' },
          '&:hover': { backgroundColor: 'rgb(236, 241, 245)' },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          fontSize: 16,
          color: '#525252',
          textTransform: 'uppercase',
        },
        subheaderTypographyProps: {
          fontSize: 14,
          color: '#979797',
        },
      },
      styleOverrides: {
        root: {
          marginLeft: 16,
          marginRight: 16,
          marginTop: 8,
          marginBottom: 8,
        },
        avatar: {
          marginRight: 32,
        },
        action: {
          alignSelf: 'center',
          margin: 0,
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          ':focus-visible': {
            outline: '1px solid gray',
            outlineOffset: '-5px',
          },
        },
        focusHighlight: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          marginLeft: 16,
          marginRight: 16,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          marginLeft: 24,
          marginRight: 24,
          marginBottom: 16,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '&.MuiPopover-root': {
            marginTop: '0 !important',
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        transformOrigin: { vertical: 'top', horizontal: 'center' },
        elevation: 0,
      },
      styleOverrides: {
        paper: {
          borderRadius: 6,
          border: '1px solid #CDD7D9',
          boxShadow: '0px 3px 6px #00000029',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:focus': { backgroundColor: 'rgb(236, 241, 245)' },
          '&:hover': { backgroundColor: 'rgb(236, 241, 245)' },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 1px 6pt #0000001A',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
        InputLabelProps: { shrink: true },
        InputProps: {
          autoComplete: 'current-password',
          disableUnderline: true,
        },
        sx(theme) {
          return {
            borderRadius: '0px',
            'input::placeholder': {
              color: theme.palette.primary.main,
              opacity: 1,
            },
            'input ~ fieldset': {
              borderWidth: '1px !important',
            },
          }
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 1,
          color: '#525252',

          '& .MuiFormLabel-root': {
            color: 'inherit',
            '&.Mui-focused': { color: 'inherit' },
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: defaultTheme.palette.error.main,
            '&.Mui-focused': { color: 'inherit' },
          },
          '& .MuiInputBase-root': {
            borderRadius: 1,
            color: 'inherit',
          },
          '& .MuiInputBase-root.Mui-error': {
            border: `1px solid ${defaultTheme.palette.error.main}`,
            '&.Mui-focused': { border: 'inherit' },
          },
        },
      },
    },
  },
})
