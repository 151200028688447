import * as yup from 'yup'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/tr'
import 'dayjs/locale/zh'

import { yupResolver } from '@hookform/resolvers/yup'

import LogBatteryForm from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/LogBatteryForm'
import { IFormInput } from '..'

import SelectBrand from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/SelectBrand'
import SelectModel from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/SelectModel'
import { ChevronLeft } from '@mui/icons-material'
import { Alert, Box, Button, CircularProgress } from '@mui/material'
import DialogContent from 'components/common/DialogContent'
import DialogTitle from 'components/common/DialogTitle'
import dayjs from 'dayjs'
import { IBrand } from 'interfaces'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  useGetLogBatteryRepairConfigQuery,
  useSubmitLogBatteryRepairMutation,
} from 'store/api'

interface ILogBatteryContentProps {
  onClose: () => void
  brands: IBrand[]
}

const LogBatteryContent = ({ onClose, brands }: ILogBatteryContentProps) => {
  const { data, isLoading: isLoadingConfig } = useGetLogBatteryRepairConfigQuery()
  const [message, setMessage] = useState({
    text: '',
    isVisible: false,
  })
  const t = useIntl()
  const config = data?.data
  const SUPPORTED_FORMATS = config?.allowedFileMediaTypes
  const SUPPORTED_FORMATS_LABEL = config?.allowedFileTypes
  const supportedFormats = SUPPORTED_FORMATS_LABEL?.join(', ').toUpperCase()
  const intl = (id: string) => {
    return t.formatMessage({
      id: `evLevel.logBatteryLiveRepair.form.validation.${id}`,
    })
  }
  const invalidFileTypeValidationMessage = t.formatMessage(
    {
      id: `evLevel.logBatteryLiveRepair.form.validation.certificate.fileFormat`,
    },
    { supportedFormats },
  )
  const schema = yup.object().shape({
    certificateFile: yup
      .mixed()
      .required(intl('certificate.required'))
      .test(
        'fileSize',
        intl('certificate.fileSize'),
        value => !value || (value && value.size <= 10000000),
      )
      .test(
        'fileFormat',
        invalidFileTypeValidationMessage,
        value => !value || (value && SUPPORTED_FORMATS?.includes(value.type)),
      ),
    repairDate: yup
      .date()
      .typeError(intl('repairDate'))
      .required(intl('repairDate'))
      .max(new Date(), intl('repairDateMax')),
  })

  const methods = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      formStep: 0,
      repairDate: null,
      certificateFile: undefined,
      brandAndModel: undefined,
    },
    mode: 'all',
  })

  const { watch, setValue, reset, handleSubmit } = methods
  const steps = watch('formStep')

  const [logBatteryRepair, { isLoading, isSuccess, error, isError }] =
    useSubmitLogBatteryRepairMutation()
  const RenderTiles = () => {
    switch (steps) {
      case 0:
        return (
          <LogBatteryForm
            {...{
              isLoading,
              allowedFileTypes: SUPPORTED_FORMATS_LABEL as string[],
              maximumFileSize: Math.round(config!.maxFileSizeBytes / 1000000),
            }}
          />
        )
      case 1:
        return <SelectBrand {...{ brands }} />
      case 2:
        return <SelectModel />
      default:
        return <CircularProgress />
    }
  }
  const handleClose = () => {
    reset()
    onClose()
  }

  const onFormSubmit = (data: IFormInput) => {
    const date = dayjs(new Date(data.repairDate as Date)).format('YYYY-MM-DD')
    // const date = format(new Date(data.repairDate as Date), 'yyyy-MM-dd')
    const formData = new FormData()
    formData.append('file', data.certificateFile)
    formData.append('repairDate', date)
    formData.append('levelModuleUUID', data.brandAndModel?.moduleLevelUUID)
    logBatteryRepair(formData)
  }

  useEffect(() => {
    if (isSuccess) {
      handleClose()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      let errorMessage = ''
      const errorRaw = (error as any).data.errors[0]
      if (errorRaw.code === 'INVALID_FILE_TYPE') {
        errorMessage = invalidFileTypeValidationMessage
      } else {
        errorMessage = errorRaw.message
      }
      setMessage({
        text: errorMessage,
        isVisible: true,
      })
    }
    if (isLoading) {
      setMessage({
        text: '',
        isVisible: false,
      })
    }
  }, [isError, error, isLoading])

  if (!data && isLoadingConfig) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100px">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <DialogTitle
        onClose={handleClose}
        closeButtonLabel={<FormattedMessage id="app.buttons.close" />}
        title={
          steps > 0 ? (
            <Button
              onClick={() => setValue('formStep', steps - 1)}
              startIcon={<ChevronLeft />}
              variant="text"
            >
              <FormattedMessage id="evLevel.logBatteryLiveRepair.back" />
            </Button>
          ) : null
        }
      />
      {message.isVisible && <Alert severity="error">{message.text}</Alert>}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)} encType="multipart/form-data">
          <DialogContent>
            <Box display="flex" justifyContent="center" flexDirection="column">
              <RenderTiles />
            </Box>
          </DialogContent>
        </form>
      </FormProvider>
    </>
  )
}

export default LogBatteryContent
