import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { ICourse, IModule } from 'interfaces'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch, useAppSelector } from 'store'
import {
  selectEVLevelState,
  setIsBookingModalOpen,
  setSelectedCourse,
  toggleModuleAndCourseDrawer,
} from 'store/slices'
import { formatDate } from 'utils/formatDate'
import BookSessionModal from 'components/EVLevel/EVDetail/BookSession/BookSessionModal'
import BookedSessionTable from 'components/EVLevel/EVDetail/BookSession/BookedSessionTable'
import RemoveWaitlistStaffMember from 'components/EVLevel/ModulesList/BookSession/RemoveWaitlistStaffMember'
import WithdrawStaffMember from 'components/EVLevel/ModulesList/BookSession/WithdrawStaffMember'
import CourseCard from 'components/EVLevel/ModulesList/Course/CourseCard'
import CourseDescription from 'components/EVLevel/ModulesList/Course/CourseDescription'
import CourseHeader from 'components/EVLevel/ModulesList/Course/CourseHeader'
import ModuleCard from 'components/EVLevel/ModulesList/ModuleCard'
import ModuleCourseList from 'components/EVLevel/ModulesList/ModuleCourseList'
import ModuleHeader from 'components/EVLevel/ModulesList/ModuleHeader'
import { EVTab, EVTabs } from 'components/EVLevel/ModulesList/styles'

type Props = {
  evLevelUUID: string
  modules: IModule[]
  filter?: boolean
  onClosePortalModal: () => void
  isManagerView: boolean
}
type RenderLockedReasonsProps = {
  lockedReason: string
  lockedReasonValues: Record<string, string | number> | undefined
}
export default function ModulesList({
  evLevelUUID,
  modules,
  onClosePortalModal,
  filter,
  isManagerView,
}: Props) {
  const dispatch = useAppDispatch()
  const { palette } = useTheme()

  const modulesByStatus = {
    NOT_COMPLETED: modules.map(mod => ({
      ...mod,
      courses: mod.courses.filter(course => course.status !== 'COMPLETED'),
    })),

    COMPLETED: modules.map(mod => ({
      ...mod,
      courses: mod.courses.filter(course => course.status === 'COMPLETED'),
    })),
  }
  const [activeTab, setActiveTab] =
    useState<keyof typeof modulesByStatus>('NOT_COMPLETED')
  const handleChange = (
    _: React.SyntheticEvent,
    newValue: keyof typeof modulesByStatus,
  ) => {
    setActiveTab(newValue)
  }

  const evLevelState = useAppSelector(selectEVLevelState)[evLevelUUID]
  const modulesAndCourses = evLevelState.modulesAndCourses

  const handleCourseModuleToggle = (instanceId: string) => {
    dispatch(
      toggleModuleAndCourseDrawer({
        levelUUID: evLevelUUID,
        moduleAndCourseKey: instanceId,
      }),
    )
  }

  const hasBookSessionConfig = (course: ICourse) => {
    const { courseType, allowsBooking, status } = course
    const includedStatus = ['COMPLETED', 'FAILED'].includes(status)
    return (
      !includedStatus &&
      allowsBooking &&
      (courseType === 'CLASSROOM_PHYSICAL' || courseType === 'CLASSROOM_VIRTUAL')
    )
  }

  const RenderLockedReason = ({
    lockedReason,
    lockedReasonValues,
  }: RenderLockedReasonsProps) => {
    return lockedReason ? (
      <Typography
        color={palette.error.main}
        fontSize={14}
        textTransform="initial"
        p="0 24px 16px 24px"
      >
        <FormattedMessage id={`errorCodes.${lockedReason}`} values={lockedReasonValues} />
      </Typography>
    ) : null
  }
  const renderModule = ({ mod }: { mod: IModule }) => {
    if (mod.courses.length === 1) {
      const {
        courseUUID,
        status,
        courseType,
        courseTitle,
        courseDescription,
        completedDate,
        isAvailable,
        isLocked,
        bookedSession,
        lockedReason,
        lockedReasonValues,
      } = mod.courses[0]
      const courseInstanceId = `c_${evLevelUUID}_${courseUUID}`
      const hasBookSession = hasBookSessionConfig(mod.courses[0])
      const course = mod?.courses[0]
      return (
        <CourseCard
          onToggleOpen={() => {
            handleCourseModuleToggle(courseInstanceId)
          }}
          key={courseInstanceId}
          open={modulesAndCourses[courseInstanceId]}
          status={mod.status}
          isAvailable={isAvailable}
          isLocked={isLocked}
          launchPath={course?.launchPath}
          onClosePortalModal={onClosePortalModal}
          isManagerView={isManagerView}
          header={
            <CourseHeader
              courseType={courseType}
              primaryText={courseTitle}
              status={status}
              completedDate={completedDate}
              isAvailable={isAvailable}
              isLocked={isLocked}
              courseCode={course?.courseCode}
            />
          }
        >
          {!bookedSession && course?.completedDate ? (
            <Typography
              data-testid="completed-date-text"
              color="rgb(151,151,151)"
              fontSize={14}
              textAlign="left"
              m="4px 24px"
            >
              <FormattedMessage
                id="evLevel.completed"
                values={{
                  date: formatDate(course?.completedDate),
                }}
              />
            </Typography>
          ) : null}
          <CourseDescription>{courseDescription}</CourseDescription>
          <RenderLockedReason
            lockedReason={lockedReason}
            lockedReasonValues={lockedReasonValues}
          />
          <WithdrawStaffMember sessionUUID={bookedSession?.sessionId} />
          <RemoveWaitlistStaffMember />
          {bookedSession ? (
            <Box m="16px 24px">
              <BookedSessionTable
                {...{ bookedSession }}
                courseUUID={courseUUID}
                isManagerView={isManagerView}
              />
            </Box>
          ) : (
            <>
              {hasBookSession && (
                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  sx={{
                    m: '16px 24px',
                    '&:hover': {
                      borderColor: '#1E1E1E',
                      backgroundColor: '#1E1E1E',
                      color: 'white',
                    },
                  }}
                  onClick={() => {
                    if (courseUUID) dispatch(setSelectedCourse(courseUUID))
                    dispatch(setIsBookingModalOpen(true))
                  }}
                >
                  <FormattedMessage id="evLevel.labels.bookStaff" />
                </Button>
              )}
            </>
          )}
        </CourseCard>
      )
    } else {
      const moduleInstanceId = `m_${evLevelUUID}_${mod.levelModuleUUID}`

      return (
        <ModuleCard
          open={modulesAndCourses[moduleInstanceId]}
          onToggleOpen={() => handleCourseModuleToggle(moduleInstanceId)}
          key={moduleInstanceId}
          header={
            <ModuleHeader
              primaryText={mod.moduleTitle}
              courseCount={mod.courses.length}
              status={mod.status}
              isAvailable={mod.isAvailable}
              isLocked={mod.isLocked}
              completedDate={mod.completedDate}
              lockedReason={mod.lockedReason}
              lockedReasonValues={mod.lockedReasonValues}
            />
          }
        >
          <ModuleCourseList>
            {mod.courses.map(course => {
              const moduleCourseInstanceId = `mc_${evLevelUUID}_${mod.levelModuleUUID}_${course.courseUUID}`
              const hasBookSession = hasBookSessionConfig(course)
              const { bookedSession, courseUUID } = course
              return (
                <CourseCard
                  onToggleOpen={() => {
                    handleCourseModuleToggle(moduleCourseInstanceId)
                  }}
                  open={modulesAndCourses[moduleCourseInstanceId]}
                  key={moduleCourseInstanceId}
                  status={course.status}
                  isAvailable={course.isAvailable}
                  isLocked={course.isLocked}
                  displayType="moduleDecendent"
                  launchPath={course?.launchPath}
                  onClosePortalModal={onClosePortalModal}
                  isManagerView={isManagerView}
                  header={
                    <CourseHeader
                      courseType={course.courseType}
                      primaryText={course.courseTitle}
                      status={course.status}
                      completedDate={course.completedDate}
                      isAvailable={course.isAvailable}
                      isLocked={course.isLocked}
                      courseCode={course?.courseCode}
                    />
                  }
                >
                  {!bookedSession && course?.completedDate ? (
                    <Typography
                      data-testid="completed-date-text"
                      color="rgb(151,151,151)"
                      fontSize={14}
                      textAlign="left"
                      m="4px 24px"
                    >
                      <FormattedMessage
                        id="evLevel.completed"
                        values={{
                          date: formatDate(course?.completedDate),
                        }}
                      />
                    </Typography>
                  ) : null}
                  <CourseDescription>{course.courseDescription}</CourseDescription>
                  <RenderLockedReason
                    lockedReason={course.lockedReason}
                    lockedReasonValues={course.lockedReasonValues}
                  />
                  <WithdrawStaffMember sessionUUID={bookedSession?.sessionId} />
                  <RemoveWaitlistStaffMember />
                  {bookedSession ? (
                    <Box m="16px 24px">
                      <BookedSessionTable
                        {...{ bookedSession }}
                        isManagerView={isManagerView}
                        allowedButton={bookedSession.bookStatus !== 'BOOKED'}
                        courseUUID={courseUUID}
                      />
                    </Box>
                  ) : (
                    <>
                      {hasBookSession && (
                        <Button
                          color="info"
                          variant="outlined"
                          size="small"
                          sx={{
                            m: '16px 24px',
                            '&:hover': {
                              borderColor: '#1E1E1E',
                              backgroundColor: '#1E1E1E',
                              color: 'white',
                            },
                          }}
                          onClick={() => {
                            if (courseUUID) dispatch(setSelectedCourse(courseUUID))
                            dispatch(setIsBookingModalOpen(true))
                          }}
                        >
                          <FormattedMessage id="evLevel.labels.bookStaff" />
                        </Button>
                      )}
                    </>
                  )}
                </CourseCard>
              )
            })}
          </ModuleCourseList>
        </ModuleCard>
      )
    }
  }

  const currentTabContent = modulesByStatus[activeTab]

  return (
    <>
      {filter && (
        <EVTabs
          value={activeTab}
          onChange={handleChange}
          aria-label="filter modules list"
          variant="fullWidth"
        >
          <EVTab
            label={<FormattedMessage id="app.status.notComplete" />}
            value="NOT_COMPLETED"
          />
          <EVTab
            label={<FormattedMessage id="app.status.complete" />}
            value="COMPLETED"
          />
        </EVTabs>
      )}

      {currentTabContent.map(mod =>
        mod.courses.length ? (
          <Stack spacing={2} key={mod.levelModuleUUID}>
            {renderModule({ mod })}
          </Stack>
        ) : null,
      )}

      <BookSessionModal
        onClosePortalModal={onClosePortalModal}
        isManagerView={isManagerView}
      />
    </>
  )
}
