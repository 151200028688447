import { Box, Stack, Typography } from '@mui/material'

import { IQualifiesModule } from 'interfaces'
import { FormattedMessage } from 'react-intl'

type Props = {
  qualifiesFor: IQualifiesModule[]
}

export default function CourseAlsoQualifiesFor({ qualifiesFor }: Props) {
  return (
    <Stack spacing={1} mt={3}>
      <Typography px={2} color="#1E1E1E" fontSize={18}>
        <FormattedMessage id="evLevel.EVDetail.liveWorkingDetail.vehicleModel.courseAlsoQualifiesFor.title.text" />
      </Typography>

      {qualifiesFor.map(item => (
        <Box
          bgcolor="#eef3f5"
          border="1px solid #e7eff4"
          borderRadius="4px"
          py={2}
          key={item.model.modelUUID}
        >
          <Stack direction="row" spacing={2} px={1}>
            <img src={item.model.modelImage} alt="Vehicle" width={120} />

            <Stack textTransform="uppercase" color="#525252">
              <Typography>{item.model.modelName}</Typography>
              <Typography color="#5B788F">
                <FormattedMessage id="app.labels.modelYears" />
              </Typography>
              <Typography>{item.moduleTitle}</Typography>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Stack>
  )
}
