import {
  StyledCheckbox,
  StyledSelectedEvStatusCardWrapper,
  StyledSelectedEvStatusContent,
  StyledSelectedEvStatusTitleCount,
  StyledSelectedEvStatusWrapper,
} from 'components/SettingsComponents/style'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import checkboxIcon from 'assets/svgs/icon_checkmark_dark.svg'
import settingsIcon from 'assets/svgs/icon_settings.svg'
import { FC } from 'react'

interface SelectedEvStatusProps {
  status: string | undefined
}

const SelectedEvStatus: FC<SelectedEvStatusProps> = ({ status }) => {
  const none = status === 'NONE'
  const batteryRestricted = status === 'BATTERY_RESTRICTED'
  const batteryEnabled = status === 'BATTERY_ENABLED'
  return (
    <StyledSelectedEvStatusWrapper>
      <StyledSelectedEvStatusTitleCount>
        <img src={settingsIcon} alt="Settings Icon" />
        <Typography>
          <FormattedMessage id="settings.selectedEVStatus.title" />
        </Typography>
      </StyledSelectedEvStatusTitleCount>

      <StyledSelectedEvStatusContent>
        <StyledSelectedEvStatusCardWrapper selected={none}>
          <Typography>
            <FormattedMessage id="app.status.none" />
          </Typography>
          {none && (
            <StyledCheckbox>
              <img src={checkboxIcon} alt="Checkbox Icon" />
            </StyledCheckbox>
          )}
        </StyledSelectedEvStatusCardWrapper>

        <StyledSelectedEvStatusCardWrapper selected={batteryRestricted}>
          <Typography>
            <FormattedMessage id="settings.selectedEVStatus.batteryRestricted" />
          </Typography>
          {batteryRestricted && (
            <StyledCheckbox>
              <img src={checkboxIcon} alt="Checkbox Icon" />
            </StyledCheckbox>
          )}
        </StyledSelectedEvStatusCardWrapper>

        <StyledSelectedEvStatusCardWrapper selected={batteryEnabled}>
          <Typography>
            <FormattedMessage id="settings.selectedEVStatus.batteryEnabled" />
          </Typography>
          {batteryEnabled && (
            <StyledCheckbox>
              <img src={checkboxIcon} alt="Checkbox Icon" />
            </StyledCheckbox>
          )}
        </StyledSelectedEvStatusCardWrapper>
      </StyledSelectedEvStatusContent>
    </StyledSelectedEvStatusWrapper>
  )
}

export default SelectedEvStatus
