import { Box, Button, TableCell, TableRow } from '@mui/material'
import { BookingSession } from 'interfaces'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  closeAllBookingModals,
  setCurrentBookingSessionId,
  setCurrentStep,
  setIsRemoveWaitlistModalOpen,
  useAppDispatch,
  useAppSelector,
} from 'store'
import { formatDateTime } from 'utils/formatDate'
import { selectUserIsImpersonate } from 'store/slices'
import {
  StyledTBody,
  StyledTHead,
  StyledTable,
  StyledTitle,
} from 'components/EVLevel/ModulesList/BookSession/AvailableSessionsTable/styles'

interface AvailableSessionsTableProps {
  sessions?: BookingSession[]
  isAvailableTable?: boolean
  bookingSession?: BookingSession
}

const AvailableSessionsTable: FC<AvailableSessionsTableProps> = ({
  sessions,
  isAvailableTable,
  bookingSession,
}) => {
  const dispatch = useAppDispatch()
  const { currentStep } = useAppSelector(state => state.booking)
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const getSessionActionLabel = (session: BookingSession) => {
    if (
      session.allowsWaitlist &&
      session.availableSpaces === 0 &&
      !session.isWaitlisted
    ) {
      return <FormattedMessage id="bookingStaff.availableSessions.labels.addWaitlist" />
    }
    if (session.allowsWaitlist && session.isWaitlisted) {
      return (
        <FormattedMessage id="bookingStaff.availableSessions.labels.removeWaitlist" />
      )
    }
    if (session.availableSpaces > 0 && !session.isWaitlisted) {
      return <FormattedMessage id="evLevel.labels.bookStaff" />
    }
    return null
  }
  const handleSessionClick = (session: BookingSession) => {
    dispatch(setCurrentBookingSessionId(session.id))
    if (session.allowsWaitlist && session.isWaitlisted) {
      dispatch(closeAllBookingModals())
      dispatch(setIsRemoveWaitlistModalOpen(true))
    } else {
      dispatch(setCurrentStep(currentStep + 1))
    }
  }
  if (!isAvailableTable && sessions)
    return (
      <Box height="auto">
        <StyledTitle>
          <FormattedMessage id="bookingStaff.availableSessions.title" />
        </StyledTitle>
        <StyledTable>
          <StyledTHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="app.labels.location" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.market" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.starts" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.ends" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.availableSpaces" />
              </TableCell>
              <TableCell />
            </TableRow>
          </StyledTHead>
          <StyledTBody>
            {sessions.map(session => (
              <TableRow key={session.id}>
                <TableCell>{session.location}</TableCell>
                <TableCell>{session.market}</TableCell>
                <TableCell>
                  {formatDateTime(session.startDate, session.timezone)}
                </TableCell>
                <TableCell>{formatDateTime(session.endDate, session.timezone)}</TableCell>
                <TableCell>{session.availableSpaces}</TableCell>
                <TableCell>
                  {getSessionActionLabel(session) !== null && (
                    <Button
                      color="info"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        '&:hover': {
                          borderColor: '#1E1E1E',
                          backgroundColor: '#1E1E1E',
                          color: 'white',
                        },
                      }}
                      disabled={isImpersonate}
                      onClick={() => handleSessionClick(session)}
                    >
                      {getSessionActionLabel(session)}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </StyledTBody>
        </StyledTable>
      </Box>
    )
  else if (isAvailableTable && bookingSession)
    return (
      <Box height="auto">
        <StyledTable>
          <StyledTHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="app.labels.location" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.market" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.starts" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.ends" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.availableSpaces" />
              </TableCell>
              <TableCell />
            </TableRow>
          </StyledTHead>
          <StyledTBody>
            <TableRow key={bookingSession.id}>
              <TableCell>{bookingSession.location}</TableCell>
              <TableCell>{bookingSession.market}</TableCell>
              <TableCell>
                {formatDateTime(bookingSession.startDate, bookingSession.timezone)}
              </TableCell>
              <TableCell>
                {formatDateTime(bookingSession.endDate, bookingSession.timezone)}
              </TableCell>
              <TableCell>{bookingSession.availableSpaces}</TableCell>
              <TableCell>
                {getSessionActionLabel(bookingSession) !== null && (
                  <Button
                    color="info"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{
                      '&:hover': {
                        borderColor: '#1E1E1E',
                        backgroundColor: '#1E1E1E',
                        color: 'white',
                      },
                    }}
                    onClick={() => handleSessionClick(bookingSession)}
                    disabled={
                      isImpersonate ||
                      (bookingSession.availableSpaces > 0 && !bookingSession.isWaitlisted)
                    }
                  >
                    {getSessionActionLabel(bookingSession)}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </StyledTBody>
        </StyledTable>
      </Box>
    )
  else return null
}

export default AvailableSessionsTable
