import { Button, Container } from '@mui/material'
import { useEffect, useMemo } from 'react'
import {
  selectFilterConfig,
  selectPageConfig,
  selectQualificationsAndJobRoles,
  selectRowAndCollapseData,
  selectSelectedBusinessUnits,
  setClearAllFilters,
  setRowAndCollapseData,
  useAppDispatch,
  useAppSelector,
} from 'store'
import BatteryListTable from 'components/BatteryList/BatteryListDesktopInfo/Components/BatteryListTable'
import SearchBar from 'components/BatteryList/BatteryListDesktopInfo/Components/SearchBar'
import JobRoles from 'components/BatteryList/BatteryListDesktopInfo/Components/JobRoles'
import Qualifications from 'components/BatteryList/BatteryListDesktopInfo/Components/Qualifications'
import {
  StyledBoxCollapseButtons,
  StyledBoxSearchItems,
  StyledBoxSelectsItens,
  StyledFilterWrapper,
} from './style'
import { useGetSearchStaffQualificationsQueryMutation } from 'store/api'
import { FormattedMessage } from 'react-intl'

const BatteryListDesktopInfo = () => {
  const dispatch = useAppDispatch()

  const selectedQualificationsAndJobRoles = useAppSelector(
    selectQualificationsAndJobRoles,
  )
  const selectedRowAndCollapseData = useAppSelector(selectRowAndCollapseData)
  const selectedQueryConfig = useAppSelector(selectPageConfig)
  const selectedFilterConfig = useAppSelector(selectFilterConfig)
  const selectedBusinessUnits = useAppSelector(selectSelectedBusinessUnits)
  const businessUnits = useMemo(
    () => selectedBusinessUnits?.map(({ value }) => value) || [],
    [selectedBusinessUnits],
  )

  // collapse and rows data destructuring
  const records = selectedRowAndCollapseData?.records
  const totalRecords = selectedRowAndCollapseData?.totalRecords
  const totalPages = selectedRowAndCollapseData?.totalPages

  // selects data destructuring
  const qualifications = selectedQualificationsAndJobRoles?.qualifications
  const jobRoles = selectedQualificationsAndJobRoles?.jobRoles

  const hasResults = records?.length > 0

  const [getStaffQualificationsQuery, { data, isLoading }] =
    useGetSearchStaffQualificationsQueryMutation()

  const cleanQueryParam = () => {
    const { jobRoles, qualifications, searchTerm } = selectedFilterConfig

    return {
      businessUnits,
      ...selectedQueryConfig,
      ...(jobRoles?.length && { jobRoles }),
      ...(qualifications?.length && { qualifications }),
      ...(searchTerm && { searchTerm }),
    }
  }

  const handleFilterQuery = async () => {
    await getStaffQualificationsQuery(cleanQueryParam())
  }

  const handleClearAllFilters = async () => {
    dispatch(setClearAllFilters())
    await getStaffQualificationsQuery({ ...selectedQueryConfig, businessUnits })
  }

  useEffect(() => {
    handleFilterQuery()
  }, [selectedQueryConfig])

  useEffect(() => {
    dispatch(setRowAndCollapseData(data))
  }, [selectedRowAndCollapseData?.records, data])

  return (
    <Container>
      <StyledFilterWrapper>
        <StyledBoxSelectsItens>
          <Qualifications qualifications={qualifications} />
          <JobRoles jobRoles={jobRoles} />
        </StyledBoxSelectsItens>

        <StyledBoxSearchItems>
          <SearchBar />

          <StyledBoxCollapseButtons>
            <Button onClick={handleFilterQuery}>
              <FormattedMessage id="filters.search" />
            </Button>
            <Button onClick={handleClearAllFilters}>
              <FormattedMessage id="filters.clear" />
            </Button>
          </StyledBoxCollapseButtons>
        </StyledBoxSearchItems>
      </StyledFilterWrapper>

      <BatteryListTable
        records={records}
        hasResults={hasResults}
        isLoading={isLoading}
        totalRecords={totalRecords}
        totalPages={totalPages}
      />
    </Container>
  )
}

export default BatteryListDesktopInfo
