import {
  Box,
  Button,
  List,
  Pagination,
  styled,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

export const StyledButtonDetails = styled(Button)(({ theme: { spacing } }) => ({
  padding: `${spacing(0.5)} ${spacing(1)} !important`,
  fontSize: spacing(1.5),
  width: '100px',
}))

export const StypedTypographyPin = styled(Typography)(({ theme: { palette } }) => ({
  span: {
    color: palette.grey[600],
  },
}))

export const StyledPagination = styled(Pagination)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  button: {
    borderRadius: 0,
  },
  '.MuiPaginationItem-page': {
    borderRadius: 0,
    ':hover': {
      color: 'white',
      backgroundColor: palette.grey[700],
    },
  },
  '.Mui-selected': {
    color: `${palette.common.white} !important`,
    backgroundColor: `${palette.grey[800]} !important`,
  },
  '.MuiPaginationItem-previousNext': {
    border: 'none',
    ':hover': {
      backgroundColor: palette.grey[50],
    },
  },
}))

export const StyledTable = styled(Table)(_ => ({
  borderCollapse: 'separate',
  borderSpacing: '0 6px',
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
}))

export const StyledTableContainerWrapper = styled(TableContainer)(
  ({ theme: { spacing } }) => ({
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    mb: spacing(2),
  }),
)

export const StyledHeaderTable = styled(TableHead)(({ theme: { palette } }) => ({
  tr: {
    height: '56px',

    backgroundColor: palette.grey[600],
    th: {
      color: palette.common.white,
    },
  },
}))

export const StyledHeaderTableCell = styled(TableCell)(
  ({ theme: { spacing }, role }) => ({
    padding: 0,
    paddingLeft: role ? 0 : spacing(1.25),
  }),
)

export const StyledTableRow = styled(TableRow)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  height: '60px',
}))

export const StyledTableCell = styled(TableCell)(({ theme: { spacing } }) => ({
  fontSize: spacing(1.75),
  padding: 0,
  paddingLeft: spacing(1.25),
  p: {
    fontSize: spacing(1.75),
  },
  div: {
    img: {
      width: spacing(3),
      height: spacing(3),
    },
    button: {
      padding: 0,
    },
  },
}))
export const StyledTableActionButtonCell = styled(TableCell)(
  ({ theme: { spacing } }) => ({
    fontSize: spacing(1.75),
    padding: 0,
    paddingLeft: spacing(1.25),
    p: {
      fontSize: spacing(1.75),
    },
  }),
)

export const StyledHeaderMobile = styled(Box)(({ theme: { spacing, palette } }) => ({
  background: palette.grey[600],
  paddingBlock: spacing(1.75),
  p: {
    marginLeft: spacing(2),
    color: palette.common.white,
  },
}))

export const StyledWrapperTableCollapse = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
}))

export const StyledListCollapse = styled(List)(({ theme: { palette } }) => ({
  padding: 0,
  background: palette.common.white,
  li: {
    padding: 0,
    outline: 'none',
    ':hover': {
      background: palette.common.white,
    },
  },
}))

export const StyledListCollapseItem = styled(List)(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1.25),
  li: {
    padding: 0,
    display: 'flex',
    gap: spacing(0.5),
    outline: 'none',
    ':hover': {
      background: palette.common.white,
    },

    img: {
      width: '30px',
      height: '30px',
    },
  },
}))

export const StyledBoxCollapseItem = styled(Box)(({ theme: { spacing, palette } }) => ({
  background: palette.common.white,
  padding: spacing(2),
}))

export const StyledListCollapseItemBoxAction = styled(Box)(
  ({ theme: { spacing, palette } }) => ({
    width: '100%',
    paddingBlock: spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.grey[50],

    button: {
      width: '180px',
    },
  }),
)

export const StyledBoxCollapseItemImg = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1.75),
  img: {
    width: '30px',
    height: '30px',
  },
}))
