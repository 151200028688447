import { Box, Button, TableCell, TableRow } from '@mui/material'
import completeIcon from 'assets/svgs/icon_ls_completed_inverted.svg'
import inProgressIcon from 'assets/svgs/icon_ls_in_progress.svg'
import { IBookedSession } from 'interfaces'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  setIsWithDrawModalOpen,
  useAppDispatch,
  setSelectedCourse,
  useAppSelector,
} from 'store'
import { formatDateTime } from 'utils/formatDate'
import {
  StyledTBody,
  StyledTHead,
  StyledTable,
  StyledTitle,
} from 'components/EVLevel/EVDetail/BookSession/BookedSessionTable/styles'
import { selectUserIsImpersonate } from 'store/slices'

interface BookSessionTableProps {
  bookedSession: IBookedSession | undefined
  isManagerView?: boolean
  allowedButton?: boolean
  courseUUID: string
}

const BookedSessionTable: FC<BookSessionTableProps> = ({
  bookedSession,
  isManagerView,
  allowedButton = false,
  courseUUID,
}) => {
  const dispatch = useAppDispatch()
  if (!bookedSession) {
    return null
  }
  const pxs = 20
  const isBooked = bookedSession.bookStatus === 'BOOKED'
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const handleOpenWithdraw = () => {
    dispatch(setIsWithDrawModalOpen(true))
    dispatch(setSelectedCourse(courseUUID))
  }

  return (
    <>
      <Box height="auto">
        <StyledTitle>
          {isBooked ? (
            <>
              <span>
                <FormattedMessage id="evLevel.EVDetail.bookSession.bookedSessionTable.title.booked" />
              </span>
              <img src={completeIcon} alt="Complete" width={pxs} height={pxs} />
            </>
          ) : (
            <>
              <span>
                <FormattedMessage id="evLevel.EVDetail.bookSession.bookedSessionTable.title.pending" />
              </span>
              <img src={inProgressIcon} alt="In Progress" width={pxs} height={pxs} />
            </>
          )}
        </StyledTitle>
        <StyledTable>
          <StyledTHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="app.labels.location" />
              </TableCell>

              <TableCell width="115px">
                <FormattedMessage id="bookingStaff.availableSessions.column.starts" />
              </TableCell>
              <TableCell width="115px">
                <FormattedMessage id="bookingStaff.availableSessions.column.ends" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="bookingStaff.availableSessions.column.availableSpaces" />
              </TableCell>
              <TableCell />
            </TableRow>
          </StyledTHead>
          <StyledTBody>
            <TableRow key={bookedSession.sessionId}>
              <TableCell>{bookedSession.location}</TableCell>
              <TableCell width="115px">
                {formatDateTime(bookedSession.startDate, bookedSession.timezone)}
              </TableCell>
              <TableCell width="115px">
                {formatDateTime(bookedSession.endDate, bookedSession.timezone)}
              </TableCell>
              <TableCell>{bookedSession.availableSpaces}</TableCell>
              <TableCell>
                {isManagerView && !allowedButton && (
                  <Button
                    color="info"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{
                      fontSize: '0.75rem',
                      '&:hover': {
                        borderColor: '#1E1E1E',
                        backgroundColor: '#1E1E1E',
                        color: 'white',
                      },
                    }}
                    onClick={handleOpenWithdraw}
                    disabled={isImpersonate}
                  >
                    <FormattedMessage
                      id={`bookingStaff.availableSessions.labels.${
                        isBooked ? 'withdraw' : 'removeWaitlist'
                      }`}
                    />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </StyledTBody>
        </StyledTable>
      </Box>
    </>
  )
}

export default BookedSessionTable
