// Redux user feature slice to store all global user state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from 'interfaces'
import { RootState } from 'store'

interface IUserState {
  user: IUser | null
  hasViewedVideo: boolean
}

const initialState: IUserState = {
  user: null,
  hasViewedVideo: false,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState: initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser>) {
      // HARD CODED IN BUISNESS UNITS TO TEST WHILE AWAITING ENDPOINT
      state.user = action.payload
    },
    setUserHasViewedVideo(state, action: PayloadAction<boolean>) {
      state.hasViewedVideo = action.payload
    },
  },
})

export const { setUser, setUserHasViewedVideo } = userSlice.actions

export const selectUser = (state: RootState) => state.user?.user
export const selectUserUUID = (state: RootState) => state.user?.user?.userUUID
export const selectUsername = (state: RootState) => state.user?.user?.username
export const selectUserHasViewedVideo = (state: RootState) => state.user.hasViewedVideo
export const selectUserBusinessUnits = (state: RootState) =>
  state.user.user?.businessUnits
export const selectUserIsImpersonate = (state: RootState) =>
  state.user?.user?.isImpersonate

export default userSlice.reducer
