import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CardHeader, CircularProgress, Collapse, Container, Grid } from '@mui/material'
import { Box } from '@mui/system'
import Body from 'components/common/Body'
import SubHeader from 'components/layout/SubHeader'
import BusinessUnitSelector from 'components/common/BusinessUnitSelector'
import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import EVLevel from 'routes/EVLevel'
import { useGetUserDetailsQuery } from 'store/api'
import {
  ExpandMore,
  StyledSpan,
  StyledUserAvatar,
  StyledUserBussinessUnits,
  StyledUserDetailsCard,
  StyledUserInfo,
  StyledUserJobRoles,
  StyledUserName,
} from 'routes/UserDetails/style'

const UserDetails: FC = () => {
  const { user: id } = useParams<{ user: string }>()
  const { data } = useGetUserDetailsQuery(id as string)
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  if (!data)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="calc(100vh - 70px)"
      >
        <CircularProgress />
      </Box>
    )

  // function to get the username initials
  const getInitials = () => {
    const names = data.username.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase()
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }

  //function to translate the user status
  const translateStatus = () => {
    const statusType = {
      ACTIVE: 'active',
      LOCKED_OUT: 'locked_out',
      ACCOUNT_CLOSED: 'account_closed',
      PENDING: 'pending',
      SUSPENDED: 'suspended',
      GDPR_DECLINED: 'gdpr_declined',
      MERGED: 'merged',
    }
    const userStatus = data.status as keyof typeof statusType

    return <FormattedMessage id={`userDetails.status.${statusType[userStatus]}`} />
  }

  const userStatus = translateStatus()
  return (
    <Body>
      <SubHeader
        breadcrumbItems={[
          {
            text: <FormattedMessage id="app.labels.dashboard" />,
            href: '/',
          },
          {
            text: <FormattedMessage id="app.labels.myStaff" />,
            href: '/staff-members',
          },
          {
            text: data.username,
          },
        ]}
      >
        <BusinessUnitSelector readOnly />
      </SubHeader>
      <br />
      <Container>
        <StyledUserDetailsCard elevation={0}>
          <CardHeader
            sx={{ padding: '0' }}
            avatar={
              <StyledUserAvatar aria-label={data.username}>
                {getInitials()}
              </StyledUserAvatar>
            }
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="medium" />
              </ExpandMore>
            }
            title={<StyledUserName variant="button">{data.username}</StyledUserName>}
            subheader={
              <>
                <StyledUserJobRoles variant="body2">
                  {data.jobRoles.join(', ')}
                </StyledUserJobRoles>
                <StyledUserBussinessUnits variant="body2">
                  {data.businessUnits.join(', ')}
                </StyledUserBussinessUnits>
              </>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box padding={2}>
              <Grid container gap={12} justifyContent={'space-evenly'}>
                <Grid md={4} sm={12} xs={12}>
                  <StyledUserInfo paragraph>
                    <span>
                      <FormattedMessage id="userDetails.pin.label" />
                    </span>
                    <StyledSpan>{data.pin}</StyledSpan>
                  </StyledUserInfo>
                  <StyledUserInfo paragraph>
                    <span>
                      <FormattedMessage id="userDetails.status.label" />
                    </span>
                    <StyledSpan>{userStatus}</StyledSpan>
                  </StyledUserInfo>
                </Grid>

                <Grid md={4} sm={12} xs={12}>
                  <StyledUserInfo paragraph>
                    <span>
                      <FormattedMessage id="userDetail.certifiedLevel.text" />
                    </span>
                    {data.certifiedLevel ? (
                      <span>
                        <img
                          src={`/assets/${data.certifiedLevel.value}`}
                          alt={data.certifiedLevel.label}
                        />
                        {data.certifiedLevel.label}
                      </span>
                    ) : (
                      'N/A'
                    )}
                  </StyledUserInfo>
                  <StyledUserInfo paragraph>
                    <FormattedMessage id="userDetail.workingLevel.text" />
                    {data.workingLevel ? (
                      <span>
                        <img
                          src={`/assets/${data.workingLevel.value}`}
                          alt={data.workingLevel.label}
                        />
                        {data.workingLevel.label}
                      </span>
                    ) : (
                      'N/A'
                    )}
                  </StyledUserInfo>
                  <StyledUserInfo paragraph>
                    <span>
                      <FormattedMessage id="userDetail.targetLevel.text" />
                    </span>
                    {data.targetLevel ? (
                      <span>
                        <img
                          src={`/assets/${data.targetLevel.value}`}
                          alt={data.targetLevel.label}
                        />
                        {data.targetLevel.label}
                      </span>
                    ) : (
                      'N/A'
                    )}
                  </StyledUserInfo>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </StyledUserDetailsCard>
      </Container>
      <EVLevel userUUID={id} isManagerView />
    </Body>
  )
}

export default UserDetails
