// Course Icon renders the correct icon based on the course type provided

import { CourseType } from 'types'

import classroom from 'assets/svgs/courseIcons/icon_course_classroom.svg'
import eLearning from 'assets/svgs/courseIcons/icon_course_eLearning.svg'
import endTest from 'assets/svgs/courseIcons/icon_course_end_test.svg'
import psm from 'assets/svgs/courseIcons/icon_course_psm.svg'
import quicklearn from 'assets/svgs/courseIcons/icon_course_quicklearn.svg'
import virtualClassroom from 'assets/svgs/courseIcons/icon_course_virtual_classroom.svg'

type Props = {
  courseType: CourseType
}

export default function EVCourseIcon({ courseType }: Props) {
  switch (courseType) {
    case 'ONLINE_LEARNING_ELEARNING':
      return <img src={eLearning} alt="eLearning" />
    case 'ONLINE_LEARNING_PSM':
      return <img src={psm} alt="PSM" />
    case 'ONLINE_LEARNING_QUICKLEARNING':
      return <img src={quicklearn} alt="Quick Learning" />
    case 'CLASSROOM_PHYSICAL':
      return <img src={classroom} alt="Physical" />
    case 'CLASSROOM_VIRTUAL':
      return <img src={virtualClassroom} alt="Virtual" />
    case 'EXAM':
      return <img src={endTest} alt="Exam" />
    case 'EXAM_ENDTEST':
      return <img src={endTest} alt="End Test" />
    default:
      return null
  }
}
