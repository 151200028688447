import { yupResolver } from '@hookform/resolvers/yup'
import { Close as CloseIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IAPIError, IWithDrawUserFromSessionCommandProps } from 'interfaces'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import {
  closeAllBookingModals,
  setCurrentStep,
  setSubmitMessage,
  useAppDispatch,
  useAppSelector,
} from 'store'
import { useSubmitWithdrawUserFromSessionCommandMutation } from 'store/api'
import * as yup from 'yup'
import BookingConfirmation from 'components/EVLevel/ModulesList/BookSession/BookingConfirmation'

interface IWithdrawStaffMemberProps {
  sessionUUID: string | undefined
}

interface IFormInput {
  reason: string
}

const WithdrawStaffMember = ({ sessionUUID }: IWithdrawStaffMemberProps) => {
  const dispatch = useAppDispatch()
  const booking = useAppSelector(state => state.booking)
  const { selectedCourse: courseUUID, withDrawModalOpen, currentStep } = booking
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.only('xs'))
  const isMediumScreen = useMediaQuery('@media (min-width: 940px)')
  const isBelowMediumScreen = useMediaQuery('@media (max-width: 600px)')
  const isBelowSmallScreen = useMediaQuery('@media (max-width: 425px)')

  const { user: userUUIDParam } = useParams()
  const [isSuccess, setIsSuccess] = useState(false)
  const intl = useIntl()

  const withdrawLabelRequired = intl.formatMessage({
    id: 'bookingStaff.withdraw.form.reasonLabel',
  })
  const withdrawLabelMin = intl.formatMessage({
    id: 'bookingStaff.withdraw.form.reasonMin',
  })

  const [
    submitWithdrawUser,
    { isLoading, isError, error, isSuccess: successfullySubmitted },
  ] = useSubmitWithdrawUserFromSessionCommandMutation()

  const schema = yup.object().shape({
    reason: yup.string().required(withdrawLabelRequired).min(10, withdrawLabelMin),
  })

  const { watch, setValue, control, handleSubmit, reset } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: '',
    },
  })

  const onSubmit = (data: IFormInput) => {
    if (!sessionUUID || !courseUUID || !data.reason || !userUUIDParam) return
    const body: IWithDrawUserFromSessionCommandProps = {
      courseUUID: courseUUID,
      sessionUUID: sessionUUID,
      userUUID: userUUIDParam,
      reason: data.reason,
    }
    submitWithdrawUser(body)
  }

  useEffect(() => {
    if (isError) {
      dispatch(
        setSubmitMessage({
          title: 'FAILED TO WITHDRAW USER FROM SESSION',
          errors: (error as any).data.errors as IAPIError[],
          success: false,
        }),
      )
      reset()
      setIsSuccess(false)
      dispatch(setCurrentStep(2))
    }
  }, [isError])

  useEffect(() => {
    if (successfullySubmitted) {
      setIsSuccess(true)
    }
  }, [successfullySubmitted])

  const handleClose = () => {
    setIsSuccess(false)
    reset()
    dispatch(closeAllBookingModals())
  }

  const [reasonValue] = watch(['reason'])
  if (!sessionUUID || !booking.selectedCourse) return null

  return (
    <Dialog
      open={withDrawModalOpen}
      onClose={() => handleClose()}
      maxWidth="md"
      fullScreen={isSmall}
    >
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          padding: 0,
          margin: 0,
          zIndex: 100,
          minWidth: 'auto',
        }}
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </Button>
      <DialogContent
        sx={{
          minWidth: isMediumScreen ? '780px' : '100%',
          padding: 0,
        }}
      >
        {currentStep === 2 ? (
          <>
            <Box bgcolor={theme.palette.grey[100]} p="24px 24px 16px">
              <Typography
                color={theme.palette.grey[700]}
                fontSize={isBelowSmallScreen ? 18 : 20}
              >
                <FormattedMessage id="bookingStaff.withdraw.title.failed" />
              </Typography>
            </Box>
            <BookingConfirmation />
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box bgcolor={theme.palette.grey[100]} p="24px 24px 16px">
              <Typography color={theme.palette.grey[700]} fontSize={20}>
                {!isSuccess ? (
                  <FormattedMessage id="bookingStaff.withdraw.title.withdraw" />
                ) : (
                  <FormattedMessage id="bookingStaff.withdraw.title.withdrawn" />
                )}
              </Typography>
            </Box>
            <Box
              bgcolor={theme.palette.grey[50]}
              sx={{
                padding: '16px 24px',
                height: isBelowMediumScreen ? 'calc(100vh - 70px)' : 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography color={theme.palette.grey[700]} fontSize="16px">
                  {!isSuccess ? (
                    <FormattedMessage id="bookingStaff.withdraw.confirmationQuestion" />
                  ) : (
                    <FormattedMessage id="bookingStaff.withdraw.confirmationMessage" />
                  )}
                </Typography>
                {!isSuccess && (
                  <Box sx={{ marginTop: 2 }}>
                    <FormLabel sx={{ fontSize: '14px', color: theme.palette.error.main }}>
                      <FormattedMessage id="bookingStaff.withdraw.form.reasonLabel" />
                    </FormLabel>
                    <Controller
                      name="reason"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="reason"
                          multiline
                          rows={3}
                          variant="outlined"
                          error={!!error}
                          helperText={error?.message}
                          onChange={e => setValue('reason', e.target.value)}
                          value={reasonValue}
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <Divider sx={{ marginBlock: '16px' }} />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 1,
                  }}
                >
                  <Button
                    color="info"
                    variant="outlined"
                    size="small"
                    sx={{
                      fontSize: '0.75rem',
                    }}
                    type="button"
                    onClick={() => handleClose()}
                  >
                    <FormattedMessage id="app.buttons.close" />
                  </Button>
                  {!isSuccess && (
                    <Button
                      color="info"
                      variant="outlined"
                      size="small"
                      sx={{
                        fontSize: '0.75rem',
                      }}
                      type="submit"
                      disabled={isLoading}
                    >
                      {!isLoading ? (
                        <FormattedMessage id="app.buttons.confirm" />
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default WithdrawStaffMember
