import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import actionRequiredIcon from 'assets/svgs/icon_ls_expired.svg'
import {
  StyledCardActionWrapper,
  StyledCardActionInfo,
  StyledCardAction,
} from 'components/DashboardInfos/style'
import { FormattedMessage } from 'react-intl'

type CardActionsProps = {
  data: {
    totalAction: number
    confirmEvTrainingCount: number
    confirmExperienceCount: number
    recertificationCount: number
    expiredCount: number
  }
}

const CardActions: FC<CardActionsProps> = ({
  data: {
    confirmEvTrainingCount,
    confirmExperienceCount,
    expiredCount,
    totalAction,
    recertificationCount,
  },
}) => {
  return (
    <StyledCardActionWrapper>
      <StyledCardAction>
        <img src={actionRequiredIcon} alt="Action Required Icon" />
        <Box>
          <Typography variant="h1" fontSize={40}>
            {totalAction}
          </Typography>
          <Typography>
            <FormattedMessage id="app.labels.actionRequired" />
          </Typography>
        </Box>
      </StyledCardAction>

      <StyledCardActionInfo>
        <Box>
          <Typography>
            <FormattedMessage id="dashboard.evCapability.title.confirmEvTraining" />
          </Typography>
          <span>{confirmEvTrainingCount}</span>
        </Box>
        <Box>
          <Typography>
            <FormattedMessage id="dashboard.evCapability.title.confirmExperience" />
          </Typography>
          <span>{confirmExperienceCount}</span>
        </Box>
        <Box>
          <Typography>
            <FormattedMessage id="dashboard.evCapability.title.recertification" />
          </Typography>
          <span>{recertificationCount}</span>
        </Box>
        <Box>
          <Typography>
            <FormattedMessage id="dashboard.evCapability.title.expired" />
          </Typography>
          <span>{expiredCount}</span>
        </Box>
      </StyledCardActionInfo>
    </StyledCardActionWrapper>
  )
}

export default CardActions
