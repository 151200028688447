import { Box, Collapse, CollapseProps, styled, useTheme } from '@mui/material'
import { BoxProps } from '@mui/system'
import { useRef } from 'react'
import SanitizedString from 'components/common/SanitizedString'

const StyledMessage = styled(Box)(({ theme }) => ({
  fontSize: 14,

  '& ul': {
    padding: 0,
    listStylePosition: 'inside',
    marginTop: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1 / 2),
  },
}))

interface FormValidationMessageProps extends CollapseProps {
  message: string
  isOpen: boolean
  boxProps?: BoxProps
  mode?: 'light' | 'dark'
}

export const FormValidationMessage = ({
  isOpen,
  message,
  boxProps,
  mode = 'light',
  ...rest
}: FormValidationMessageProps) => {
  const currentMessage = useRef<string | undefined>()
  const theme = useTheme()

  if (isOpen) {
    currentMessage.current = message
  }

  const bgColor = mode === 'light' ? 'transparent' : 'rgba(246,248,250,0.8)'

  const px = mode === 'light' ? '1px' : '12px'
  const py = mode === 'light' ? 0 : theme.spacing(1 / 2)

  return (
    <Collapse in={isOpen} {...rest}>
      <Box
        {...boxProps}
        bgcolor={bgColor}
        color={theme.palette.error.main}
        px={px}
        py={py}
      >
        <StyledMessage>
          <SanitizedString string={currentMessage.current} />
        </StyledMessage>
      </Box>
    </Collapse>
  )
}

export default FormValidationMessage
