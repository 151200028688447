import recertificationIcon from 'assets/svgs/icon_ls_recertification.svg'
import expiredIcon from 'assets/svgs/icon_ls_expired.svg'
import { FormattedMessage } from 'react-intl'
import ModulesList from 'components/EVLevel/ModulesList'
import { Box, Paper, Typography, Stack } from '@mui/material'
import { IEVTrainingLevel, ITrainingLevelDetails } from 'interfaces'
import { formatDate } from 'utils/formatDate'

type Props = {
  selectedEVTrainingLevel: IEVTrainingLevel
  selectedEvLevelUUID: string
  evLevelData: ITrainingLevelDetails
  onClosePortalModal: () => void
  isManagerView: boolean
  showRecertificationExtraMessage: boolean
}

export default function Recertification({
  selectedEVTrainingLevel,
  selectedEvLevelUUID,
  evLevelData,
  onClosePortalModal,
  isManagerView,
  showRecertificationExtraMessage,
}: Props) {
  const { isExpired, expiryDate, name } = selectedEVTrainingLevel

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: isExpired ? '#9E1B32' : '#5B788F',
        p: 1,
        pt: 2,
      }}
    >
      <Stack direction="row" mb={2} color="white">
        <Box pl={1.5} pr={3} flexShrink={0}>
          <Box display="flex" justifyContent="center" mb={1}>
            <img
              src={isExpired ? expiredIcon : recertificationIcon}
              width={40}
              height={40}
              alt="recertification"
            />
          </Box>

          <Typography align="center">
            {expiryDate && !isExpired ? <FormattedMessage id="evLevel.expires" /> : null}
            {isExpired ? <FormattedMessage id="evLevel.expired" /> : null}
          </Typography>
          {expiryDate && (
            <Typography align="center">{formatDate(expiryDate as string)}</Typography>
          )}
        </Box>

        <Box>
          <Typography>
            <FormattedMessage
              id={
                isExpired
                  ? 'evLevel.certificationHasExpired'
                  : 'evLevel.certificationIsDueToExpire'
              }
              values={{ name }}
            />
          </Typography>
          <Box style={{ paddingRight: '5%', paddingBottom: '10px' }}>
            {showRecertificationExtraMessage && (
              <>
                <br />
                <Typography style={{ paddingBottom: '5px' }}>
                  <FormattedMessage id={'evLevel.certificationIsDueToExpireTitle1'} />
                </Typography>
                <Typography style={{ paddingBottom: '5px' }}>
                  <FormattedMessage id={'evLevel.certificationIsDueToExpireTitle2'} />
                </Typography>
                <Typography>
                  <FormattedMessage id={'evLevel.certificationIsDueToExpireTitle3'} />
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Stack>
      {evLevelData.recertificationModules ? (
        <ModulesList
          evLevelUUID={selectedEvLevelUUID}
          modules={evLevelData.recertificationModules}
          onClosePortalModal={onClosePortalModal}
          isManagerView={isManagerView}
        />
      ) : null}
    </Paper>
  )
}
