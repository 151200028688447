import { Typography } from '@mui/material'
import { IBrand } from 'interfaces'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { getCompletedModules } from 'utils/EVUtils/courseUtils/getCompletedModules'
import BrandHeader from 'components/EVLevel/EVDetail/LiveWorkingDetail/BrandHeader'
import { IFormInput } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair'
import { StyledBrandItem } from 'components/EVLevel/EVDetail/LiveWorkingDetail/LogLiveBatteryRepair/styles'

interface SelectBrandProps {
  brands: IBrand[]
}
type IQualifiedBatteryBrandsProps = {
  brand: IBrand
}

const SelectBrand: FC<SelectBrandProps> = ({ brands }) => {
  const { setValue } = useFormContext<IFormInput>()

  const handleSelectBrand = (brand: IBrand) => {
    setValue('selectedBrand', brand)
    setValue('formStep', 2)
  }
  const QualifiedBatteryBrands = ({ brand }: IQualifiedBatteryBrandsProps) => {
    const { models } = brand

    const completedModules = getCompletedModules(models)

    if (completedModules.length > 0) {
      return (
        <StyledBrandItem onClick={() => handleSelectBrand(brand)}>
          <BrandHeader standalone brand={brand.brand} />
        </StyledBrandItem>
      )
    }
    return null
  }

  return (
    <>
      <Typography variant="h6" align="center" gutterBottom>
        <FormattedMessage id="evLevel.logBatteryLiveRepair.brands.title" />
      </Typography>
      {brands.map(brand => (
        <QualifiedBatteryBrands key={brand.brand.brandUUID} brand={brand} />
      ))}
    </>
  )
}

export default SelectBrand
