import { useNavigate, useParams } from 'react-router-dom'
import { useGetEvResolveNominationQuery } from 'store/api'
import FullScreenLoader from 'components/common/FullScreenLoader'

const Nomination = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data: levelId, isLoading } = useGetEvResolveNominationQuery({
    id: id as string,
  })

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (levelId) {
    navigate(`/ev-level/${levelId}`)
  } else {
    navigate('/')
  }
  return null
}

export default Nomination
