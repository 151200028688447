// Redux business units feature slice to store all global user state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { ValueLabelType } from 'types'

interface IBusinessUnitsState {
  selectedUnits: ValueLabelType[]
}

const initialState: IBusinessUnitsState = {
  selectedUnits: [],
}

const businessUnitsSlice = createSlice({
  name: 'businessUnitsSlice',
  initialState: initialState,
  reducers: {
    resetBusinessUnits(state) {
      state.selectedUnits = []
    },
    setSelectedBusinessUnits(state, action: PayloadAction<ValueLabelType[]>) {
      state.selectedUnits = action.payload
    },
  },
})

export const { setSelectedBusinessUnits, resetBusinessUnits } = businessUnitsSlice.actions

export const selectSelectedBusinessUnits = (state: RootState) =>
  state.businessUnit.selectedUnits

export default businessUnitsSlice.reducer
