import { LinearProgress, TableRow } from '@mui/material'
import { StyledHeaderTableCell } from './style'

const RenderLoading = () => (
  <TableRow>
    <StyledHeaderTableCell colSpan={8} role="complementary">
      <LinearProgress variant="indeterminate" />
    </StyledHeaderTableCell>
  </TableRow>
)
export default RenderLoading
