import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export default function EVTitle() {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography
        fontSize={36}
        color="white"
        align="center"
        textTransform="uppercase"
        fontWeight="100"
        letterSpacing={4}
      >
        <FormattedMessage id="application.title" />
      </Typography>
    </Box>
  )
}
