//Need to confirm but i beleive this page is only accesible after user receives an email?

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FormattedMessage } from 'react-intl'

const Footer = () => {
  return (
    <Box gridRow={3} gridColumn="1/3" color="white" py={4} alignSelf="end">
      <Typography textTransform="uppercase">
        <FormattedMessage id="footer.jaguarLandRoverLimited" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="footer.registeredOffice" />
      </Typography>
    </Box>
  )
}

export default Footer
