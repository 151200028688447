import { IValueLabel } from 'interfaces'
import { FormattedMessage } from 'react-intl'

export const getMyTrainingTileText = (
  workingLevel?: IValueLabel | null,
  certifiedLevel?: IValueLabel | null,
) => {
  if (certifiedLevel && certifiedLevel.value === workingLevel?.value) {
    return (
      <>
        <FormattedMessage id="applicationTerm.certifiedLevel" />
        {`- ${certifiedLevel?.label} `}
      </>
    )
  }

  return (
    <>
      <FormattedMessage id="applicationTerm.workingLevel" />
      {` - ${workingLevel?.label} `}
    </>
  )
}
