import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryLMS, customBaseQuery } from './customFetchBase'

export const lmsAPI = createApi({
  reducerPath: 'lmsAPI',
  baseQuery: baseQueryLMS,
  endpoints: () => ({}),
})

export const evAPI = createApi({
  reducerPath: 'evAPI',
  baseQuery: customBaseQuery,
  tagTypes: [
    'EVTrainingLevels',
    'EVTrainingDetails',
    'AppConfig',
    'EVLevels',
    'Help',
    'User',
    'Reports',
    'ReportsConfig',
    'SearchMyStaff',
    'MyStaffConfig',
    'GetAlternativeProposalConfig',
    'UserDetails',
    'GetClassroomDetailsForBooking',
  ],
  endpoints: () => ({}),
})
