import { useGetHelpQuery } from 'store/api'
import {
  Box,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Container,
  CircularProgress,
  Stack,
} from '@mui/material'
import helpNoBgIcon from 'assets/svgs/icon_help_white_circle.svg'
import { IHelpContent } from 'interfaces'
import { FormattedMessage } from 'react-intl'
import Body from 'components/common/Body'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import SubHeader from 'components/layout/SubHeader'
import LanguageSelectorHelp from 'components/common/LanguageSelector/LanguageSelectorHelp'
import { useAppDispatch, useAppSelector } from 'store'
import {
  selectHelpLanguageInfo,
  selectLanguageInfo,
  selectSelectedCategories,
  selectSelectedHelpContent,
  selectSelectedTab,
  setSelectedTab,
  toggleSelectedCategories,
  toggleSelectedHelpContent,
} from 'store/slices'
import HelpContent from 'components/HelpCentre/HelpContent'
import HelpCategory from 'components/HelpCentre/HelpCategory'
import SubHeaderLabel from 'components/layout/SubHeaderLabel'

const HelpCenter = () => {
  const theme = useTheme()
  const isXSScreen = useMediaQuery(theme.breakpoints.only('xs'))

  const dispatch = useAppDispatch()

  const {
    selectedLanguage: { value: userLanguageId },
  } = useAppSelector(selectLanguageInfo)

  const {
    selectedLanguage: { value: helpLanguageId },
  } = useAppSelector(selectHelpLanguageInfo)

  const { data: helpData, isLoading } = useGetHelpQuery(
    helpLanguageId ? helpLanguageId : userLanguageId!,
    {
      pollingInterval: 1000 * 60 * 30 /* Invalidate because server refreshes the token */,
    },
  )

  const selectedTab = useAppSelector(selectSelectedTab)
  const selectedCategories = useAppSelector(selectSelectedCategories)
  const selectedContent = useAppSelector(selectSelectedHelpContent)

  const handleChange = (event: React.SyntheticEvent, tabSelected: number) => {
    dispatch(setSelectedTab(tabSelected))
  }

  const onToggleCategoryOpen = (categoryId: string) => {
    dispatch(toggleSelectedCategories(categoryId))
  }

  const onToggleContentOpen = (contentId: string) => {
    dispatch(toggleSelectedHelpContent(contentId))
  }

  return (
    <>
      <Body shade={isXSScreen ? 'dark' : 'light'}>
        <SubHeader>
          <Box
            display="grid"
            gridTemplateColumns="auto auto 1fr"
            alignItems="center"
            gap={2}
            width="100%"
          >
            <img src={helpNoBgIcon} />

            <SubHeaderLabel>
              <FormattedMessage id="helpCenter.helpCenter" />
            </SubHeaderLabel>

            <Box justifySelf="center">
              <LanguageSelectorHelp />
            </Box>
          </Box>
        </SubHeader>
        <Container>
          <>
            {isLoading && (
              <Box sx={{ display: 'grid', justifyItems: 'center', marginTop: '20px' }}>
                <CircularProgress size={20} />
              </Box>
            )}

            {!isXSScreen ? (
              <Box>
                <Box
                  sx={{
                    padding: '30px 0px 30px 0px',
                  }}
                >
                  <Tabs
                    sx={{ top: '2px', mx: -0.5 }}
                    value={selectedTab}
                    onChange={handleChange}
                    aria-label="help category tabs"
                    TabIndicatorProps={{
                      style: { display: 'none' },
                    }}
                    variant="fullWidth"
                  >
                    {helpData?.helpCategories.map((category, i) => {
                      return (
                        <Tab
                          sx={{
                            backgroundColor: selectedTab === i ? '#EBF1F6' : '#5B788F',
                            color:
                              selectedTab === i
                                ? '#1e1e1e !important'
                                : '#FFFFFF !important',
                            borderRadius: '10px 10px 0 0',

                            letterSpacing: '1.4px',
                            border: '1px',
                            borderBottom: '0',
                            borderStyle: 'solid',
                            borderColor: selectedTab === i ? '#CDD7D9' : '#5B788F',
                            marginLeft: 0.5,
                            marginRight: 0.5,
                          }}
                          key={category.title}
                          label={category.title}
                        />
                      )
                    })}
                  </Tabs>

                  <Box
                    bgcolor="#EBF1F6"
                    border="1px solid #CDD7D9"
                    mt={'-1px'}
                    sx={{
                      py: 6,
                      px: 8,
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    {helpData?.helpCategories.map((helpCat, i) => {
                      if (i === selectedTab) {
                        return (
                          <Box key={i}>
                            <Stack spacing={1.5}>
                              {helpCat.helpContents.map((content: IHelpContent) => (
                                <HelpContent
                                  open={selectedContent[content.id]}
                                  key={content.title}
                                  content={content}
                                  onToggleOpen={onToggleContentOpen}
                                />
                              ))}
                            </Stack>
                          </Box>
                        )
                      }
                      return null
                    })}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box py={2}>
                <Stack spacing={1.5}>
                  {helpData?.helpCategories.map(category => (
                    <HelpCategory
                      key={category.title}
                      category={category}
                      open={Boolean(selectedCategories[category.id])}
                      onToggleOpen={onToggleCategoryOpen}
                      selectedContent={selectedContent}
                      onToggleContentOpen={onToggleContentOpen}
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </>
        </Container>
      </Body>
      <ApplicationFooter />
    </>
  )
}

export default HelpCenter
