import { Box, Stack, Typography } from '@mui/material'
import recertificationIcon from 'assets/svgs/icon_ls_recertification.svg'
import { PersonIcon, StatusIcon } from 'icons/EVIcons'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { SwiperSlide } from 'swiper/react'
import useGetEVTrainingLevelLabel from 'utils/EVUtils/levelUtils/useGetEVTrainingLevelLabel'
import { formatDate } from 'utils/formatDate'
import LevelSelectorPanel from 'components/EVLevel/EVSelector/LevelSelectorPanel'
import {
  StyledMobileSelectorLabel,
  StyledSwiper,
} from 'components/EVLevel/EVSelector/LevelSelector/styles'
import { LevelSelectorProps } from 'components/EVLevel/EVSelector/LevelSelector/types'

const LevelSelectorMobile: FC<LevelSelectorProps> = ({
  selectedIndex,
  evTrainingLevels,
  onChange,
}) => {
  const getEVTrainingLevelLabel = useGetEVTrainingLevelLabel()
  return (
    <div>
      <Box mb={1} pt={4} display="flex">
        <StyledSwiper
          key={selectedIndex}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          navigation
          pagination={{
            clickable: true,
          }}
          initialSlide={selectedIndex}
          keyboard={{
            enabled: true,
            onlyInViewport: false,
            pageUpDown: true,
          }}
          onSlideChange={(swiper: Swiper) => {
            onChange(swiper.activeIndex)
          }}
        >
          {evTrainingLevels.map((evTrainingLevel, index) => {
            return (
              <SwiperSlide key={evTrainingLevel.levelUUID}>
                <LevelSelectorPanel key={evTrainingLevel.levelUUID}>
                  <PersonIcon
                    iconName={evTrainingLevel.iconName}
                    iconAltText={evTrainingLevel.name}
                    customStyles={{ marginLeft: '40px' }}
                  />
                  <StyledMobileSelectorLabel variant="subtitle1" mt={4} color="#1E1E1E">
                    {getEVTrainingLevelLabel(index, evTrainingLevels)}
                    <StatusIcon
                      status={evTrainingLevel.status}
                      size="small"
                      isAvailable={evTrainingLevel.isAvailable}
                      isLocked={evTrainingLevel.isLocked}
                    />
                  </StyledMobileSelectorLabel>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    mt={1}
                    sx={{
                      opacity: !evTrainingLevel.expiryDate ? 0 : 1,
                      visibility: !evTrainingLevel.expiryDate ? 'hidden' : 'visible',
                    }}
                  >
                    <Typography variant="subtitle1" color="#525252">
                      <FormattedMessage id="evLevel.expires" />
                    </Typography>
                    {evTrainingLevel.expiryDate && (
                      <Typography color="#838485">
                        {formatDate(evTrainingLevel.expiryDate as string)}
                      </Typography>
                    )}

                    {evTrainingLevel.isDueForRecertification ? (
                      <>
                        <img src={recertificationIcon} width="28" alt="recertification" />
                      </>
                    ) : null}
                  </Stack>
                </LevelSelectorPanel>
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      </Box>
    </div>
  )
}

export default LevelSelectorMobile
