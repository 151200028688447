import { IRecordsEvLiveWorkingCapability } from 'interfaces'
import { camelCase } from 'lodash'

interface IFilterState {
  jaguar: boolean
  rangeRover: boolean
  discovery: boolean
  defender: boolean
}

export const filterByDashboard = (
  filterState: IFilterState,
  isCertified: boolean,
  record: IRecordsEvLiveWorkingCapability,
  setSelectedBrands?: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  const keys = Object.keys(filterState) as string[]
  const values = Object.values(filterState) as boolean[]
  const ifSomeFilterIsTrue = values.some(value => value)
  const findAllBrandSelected = keys.filter((_key, index) => values[index])

  if (setSelectedBrands) setSelectedBrands(findAllBrandSelected)

  if (isCertified && !ifSomeFilterIsTrue) return Boolean(record.count)
  if (!isCertified && !ifSomeFilterIsTrue) return true

  if (!isCertified)
    return findAllBrandSelected.includes(camelCase(record.brand.brandName))
  if (isCertified)
    return (
      findAllBrandSelected.includes(camelCase(record.brand.brandName)) &&
      Boolean(record.count)
    )
}
