import { IEVTrainingLevel, IValueLabel } from 'interfaces'

export function findSelectedTrainingLevelIndex(
  selectedEVTrainingLevel?: IEVTrainingLevel,
  evTrainingLevels?: IEVTrainingLevel[],
) {
  if (selectedEVTrainingLevel && evTrainingLevels) {
    return evTrainingLevels.findIndex(
      level => level.levelUUID === selectedEVTrainingLevel.levelUUID,
    )
  }

  return 0
}

export function findSelectedLevelFromLoggedUser(
  workingLevel?: IValueLabel,
  evTrainingLevels?: IEVTrainingLevel[],
) {
  if (workingLevel && evTrainingLevels) {
    return evTrainingLevels.find(
      level => level.name === workingLevel.label && level.iconName === workingLevel.value,
    ) as IEVTrainingLevel
  }

  return undefined
}
