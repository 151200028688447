import { Typography, useMediaQuery } from '@mui/material'
import checkboxIcon from 'assets/svgs/icon_checkmark_dark.svg'
import { FC } from 'react'
import {
  CardCarCertifieldChecked,
  CardCarCertifieldContent,
  CardCarCertifieldWrapper,
  StyledCheckbox,
} from 'components/SettingsComponents/style'
import { FormattedMessage } from 'react-intl'

interface CardCarCertifieldDesktopProps {
  modelName: string
  modelImage: string
  year: string
  isEnabled?: boolean
}

const CardCar: FC<CardCarCertifieldDesktopProps> = ({
  modelName,
  year,
  modelImage,
  isEnabled,
}) => {
  const isMediumScreen = useMediaQuery('(max-width: 1200px)')

  return (
    <CardCarCertifieldWrapper>
      <img src={modelImage} alt={modelName} />
      <CardCarCertifieldContent>
        <Typography>{modelName}</Typography>
        <Typography>
          <FormattedMessage id="app.labels.modelYears" />
        </Typography>
        <Typography>{year}</Typography>
      </CardCarCertifieldContent>
      {isMediumScreen && isEnabled && (
        <CardCarCertifieldChecked>
          <StyledCheckbox>
            <img src={checkboxIcon} alt="Checkbox Icon" />
          </StyledCheckbox>
        </CardCarCertifieldChecked>
      )}
    </CardCarCertifieldWrapper>
  )
}

export default CardCar
