// Application Wrapper contains headee component to be used in all sub routes. This could
// also be the place to check authentication in later sprints.

import { useMediaQuery, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import ApplicationHeader from 'components/layout/ApplicationHeader'

export default function ApplicationWrapper() {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <>
      <ApplicationHeader isLargeScreen={isLargeScreen} />
      <Outlet />
    </>
  )
}
