import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type Props = {
  duration: number
  durationType: string
}

export function CourseDuration({ duration, durationType }: Props) {
  return duration !== 0 ? (
    <Box sx={{ mb: 3 }}>
      <Typography variant="body1" color="text.primary" gutterBottom>
        <FormattedMessage id="app.labels.courseDuration" />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {`${duration} ${durationType}`}
      </Typography>
    </Box>
  ) : null
}

export default CourseDuration
