import { IValueLabel } from 'interfaces'
import { FormattedMessage } from 'react-intl'

interface IImageChangeProps {
  level: IValueLabel
  confirmationType: string
}

const ImgChange = ({ confirmationType, level }: IImageChangeProps) => {
  return (
    <>
      <img src={`./assets/${level.value}`} alt={level.label} />
      <FormattedMessage
        id={
          confirmationType === 'EXPIRED'
            ? 'staffMembers.actionRequired.confirmExpCriteria.hasExpired'
            : 'staffMembers.actionRequired.confirmExpCriteria.isDueForRecertification'
        }
        values={{
          evLevelName: level.label,
        }}
      />
    </>
  )
}

export default ImgChange
