// EV Level Page

import { Box, CircularProgress, Container, useMediaQuery, useTheme } from '@mui/material'
import Body from 'components/common/Body'
import FullScreenLoader from 'components/common/FullScreenLoader'
import ApplicationFooter from 'components/layout/ApplicationFooter'
import EVDetail from 'components/EVLevel/EVDetail'
import EVLevelSelector from 'components/EVLevel/EVSelector/LevelSelector/LevelSelector'
import SubHeader from 'components/layout/SubHeader'
import SubHeaderLabel from 'components/layout/SubHeaderLabel'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetEVTrainingLevelsQuery } from 'store/api'
import { selectUser } from 'store/slices'
import useGetEVTrainingLevelLabel from 'utils/EVUtils/levelUtils/useGetEVTrainingLevelLabel'
import { findSelectedTrainingLevelIndex } from 'utils/EVUtils/levelUtils/findSelectedTrainingLevelInfo'
import { useAppSelector } from 'store/hooks/useStoreHooks'
import { FormattedMessage } from 'react-intl'

interface EVLevelProps {
  userUUID?: string
  isManagerView?: boolean
}

const EVLevel: FC<EVLevelProps> = ({ userUUID: userFromProps, isManagerView }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const userUUID = userFromProps ?? (user?.userUUID as string)

  const { data: evTrainingLevels } = useGetEVTrainingLevelsQuery(userUUID, {
    refetchOnMountOrArgChange: true,
  })

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const selectedEvTrainingLevel = id
    ? evTrainingLevels?.find(level => level.levelUUID === id)
    : evTrainingLevels?.[0]

  const handleEVSelection = (selection: number) => {
    const selectedLevel = evTrainingLevels?.[selection].levelUUID
    if (!isManagerView) {
      navigate(`/ev-level/${selectedLevel}`)
    } else {
      navigate(`/user-details/${userUUID}/${selectedLevel}`)
    }
  }
  const initialIndex = findSelectedTrainingLevelIndex(
    selectedEvTrainingLevel,
    evTrainingLevels,
  )
  const getEVTrainingLevelLabel = useGetEVTrainingLevelLabel()

  if (!userUUID) {
    return <FullScreenLoader />
  }

  if (!evTrainingLevels) {
    return (
      <Box display="flex" justifyContent="center" py={2}>
        <CircularProgress size={20} />
      </Box>
    )
  }

  return (
    <>
      <Body>
        {isLargeScreen
          ? !isManagerView && (
              <SubHeader>
                <SubHeaderLabel>
                  <FormattedMessage id="app.labels.myEVTraining" />
                </SubHeaderLabel>
              </SubHeader>
            )
          : null}
        <Container sx={{ px: { xs: 0, sm: 2 } }}>
          <EVLevelSelector
            selectedIndex={initialIndex}
            onChange={handleEVSelection}
            evTrainingLevels={evTrainingLevels}
          />

          <Box
            bgcolor="#EBF1F6"
            borderRadius={isLargeScreen ? 3 : 0}
            border={isLargeScreen ? '1px solid #CDD7D9' : 0}
            py={{ xs: 1, sm: 3, lg: 4 }}
            px={{ sm: 3, lg: 9 }}
            mb={{ sm: 4 }}
          >
            <EVDetail
              selectedEVTrainingLevel={
                selectedEvTrainingLevel ? selectedEvTrainingLevel : evTrainingLevels![0]
              }
              levelLabel={getEVTrainingLevelLabel(initialIndex ?? 0, evTrainingLevels)}
              userUUID={userUUID}
            />
          </Box>
        </Container>
      </Body>
      <ApplicationFooter />
    </>
  )
}

export default EVLevel
